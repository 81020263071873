import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from "swiper";
import {BsPlus} from 'react-icons/bs';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {Link} from "react-router-dom";
import Title from "../Title";
import ButtonViewAll from "../ButtonViewAll";
import ButtonArrow from "../ButtonArrow";
import reactHtmlParser from "react-html-parser";

const Strength = ({padding,data,dataSlider}) => {



    const [offset, setOffset] = useState(90)

    useEffect(() => {


        window.addEventListener('load', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [])


    const NewsPrev = () => {
        document.querySelector('.swiper-button-prev').click();
    };

    const NewsNext = () => {
        document.querySelector('.swiper-button-next').click();
    };

    // filter data
    const DepartmentData = data?.section_data;
    const DepartmentSlider =dataSlider;
    const unsortedList = DepartmentSlider || [];

    const sortedComponents = [...unsortedList].sort((a, b) => {
        const sortA = a?.data?.sort_order;
        const sortB = b?.data?.sort_order;

        if (sortA === sortB) {
            return a.id - b.id;
        }

        return sortA - sortB;
    });


    return (
        <StyledStrength remove offset={offset}
                        className={`pionneering parallax-inner-box ${padding ? padding : 'pb-200'}  `}>
            <Container className={'department-title'}>
                <Row>
                    <Col md={12}>
                        <div className="d-flex navigation_wrapper">
                            <div className="title">
                                <Title text={DepartmentData?.subtitle ? DepartmentData?.subtitle : ''} color={'#1E1E2E'}/>
                            </div>
                            <ul className="navigation">
                                <div className={'navigation__icon'}>
                                    <li className={'prev_swipper3'} onClick={NewsPrev}>
                                        <svg id="Prev" xmlns="http://www.w3.org/2000/svg" width="44" height="44"
                                             viewBox="0 0 44 44">
                                            <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke="#fff"
                                               stroke-width="1" opacity="0.2">
                                                <rect width="44" height="44" rx="22" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="none"/>
                                            </g>
                                            <g id="Group_4824" data-name="Group 4824" transform="translate(17 17)">
                                                <g id="Group_4823" data-name="Group 4823">
                                                    <line id="Line_9" data-name="Line 9" x1="5" y2="5" fill="none"
                                                          stroke="#fff" strokeLinecap="round" stroke-width="1"/>
                                                    <line id="Line_10" data-name="Line 10" x2="5" y2="5"
                                                          transform="translate(0 5)" fill="none" stroke="#fff"
                                                          strokeLinecap="round" stroke-width="1"/>
                                                </g>
                                                <line id="Line_11" data-name="Line 11" x1="10"
                                                      transform="translate(0 5)"
                                                      fill="none" stroke="#fff" strokeLinecap="round" stroke-width="1"/>
                                            </g>
                                            <g id="Rectangle_5661" data-name="Rectangle 5661"
                                               transform="translate(0 44) rotate(-90)" fill="none" stroke="#fff"
                                               stroke-width="1" strokeDasharray="1 140" opacity="0">
                                                <rect width="44" height="44" rx="22" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="none"/>
                                            </g>
                                        </svg>
                                    </li>
                                    <li className={'next_swipper3'} onClick={NewsNext}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44"
                                             viewBox="0 0 44 44">
                                            <g id="Next" transform="translate(16429 -2014)">
                                                <g id="Rectangle_492" data-name="Rectangle 492"
                                                   transform="translate(-16429 2014)" fill="none" stroke="#fff"
                                                   stroke-width="1" opacity="0.2">
                                                    <rect width="44" height="44" rx="22" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="none"/>
                                                </g>
                                                <g id="Group_4824" data-name="Group 4824"
                                                   transform="translate(-17065.5 434.5)">
                                                    <g id="Group_4823" data-name="Group 4823"
                                                       transform="translate(11 4)">
                                                        <line id="Line_9" data-name="Line 9" x2="5" y2="5"
                                                              transform="translate(647.5 1592.5)" fill="none"
                                                              stroke="#fff"
                                                              strokeLinecap="round" stroke-width="1"/>
                                                        <line id="Line_10" data-name="Line 10" x1="5" y2="5"
                                                              transform="translate(647.5 1597.5)" fill="none"
                                                              stroke="#fff"
                                                              strokeLinecap="round" stroke-width="1"/>
                                                    </g>
                                                    <line id="Line_11" data-name="Line 11" x2="10"
                                                          transform="translate(653.5 1601.5)" fill="none" stroke="#fff"
                                                          strokeLinecap="round" stroke-width="1"/>
                                                </g>
                                                <g id="Rectangle_5661" data-name="Rectangle 5661"
                                                   transform="translate(-16429 2058) rotate(-90)" fill="none"
                                                   stroke="#fff"
                                                   stroke-width="1" strokeDasharray="1 140" opacity="0">
                                                    <rect width="44" height="44" rx="22" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="none"/>
                                                </g>
                                            </g>
                                        </svg>

                                    </li>
                                </div>
                                <div>
                                    <ButtonViewAll src={'/department'} color={'black'} text={'View All'}
                                                   icon={'black'}
                                                   hoverColor={'#E72E4B'} margin={'0 0 0 40px'}/>
                                </div>

                            </ul>

                            <ul className={'navigation-mobile'}>
                                <div className={'navigation__icon'}>
                                    <li className={'prev_swipper3'} onClick={NewsPrev}>
                                        <svg id="Button_-_Nav_-_Prev_Dark_" data-name="Button - Nav - Prev (Dark)"
                                             xmlns="http://www.w3.org/2000/svg" width="41" height="41"
                                             viewBox="0 0 41 41">
                                            <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke="#1e1e2e"
                                               stroke-width="1" opacity="0.2">
                                                <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                            </g>
                                            <g id="Group_4824" data-name="Group 4824" transform="translate(16 15)">
                                                <g id="Group_4823" data-name="Group 4823">
                                                    <line id="Line_9" data-name="Line 9" x1="5" y2="5" fill="none"
                                                          stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                                    <line id="Line_10" data-name="Line 10" x2="5" y2="5"
                                                          transform="translate(0 5)" fill="none" stroke="#e72e4b"
                                                          stroke-linecap="round" stroke-width="1"/>
                                                </g>
                                                <line id="Line_11" data-name="Line 11" x1="10"
                                                      transform="translate(0 5)" fill="none" stroke="#e72e4b"
                                                      stroke-linecap="round" stroke-width="1"/>
                                            </g>
                                            <g id="Rectangle_5661" data-name="Rectangle 5661"
                                               transform="translate(0 41) rotate(-90)" fill="none" stroke="#e72e4b"
                                               stroke-width="1" stroke-dasharray="130 130">
                                                <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                            </g>
                                        </svg>
                                    </li>
                                    <li className={'next_swipper3'} onClick={NewsNext}>
                                        <svg id="Button_-_Nav_-_Next_Dark_" data-name="Button - Nav - Next (Dark)"
                                             xmlns="http://www.w3.org/2000/svg" width="41" height="41"
                                             viewBox="0 0 41 41">
                                            <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke="#1e1e2e"
                                               stroke-width="1" opacity="0.2">
                                                <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                            </g>
                                            <g id="Group_4824" data-name="Group 4824"
                                               transform="translate(-637.5 -1581.5)">
                                                <g id="Group_4823" data-name="Group 4823" transform="translate(11 4)">
                                                    <line id="Line_9" data-name="Line 9" x2="5" y2="5"
                                                          transform="translate(647.5 1592.5)" fill="none"
                                                          stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                                    <line id="Line_10" data-name="Line 10" x1="5" y2="5"
                                                          transform="translate(647.5 1597.5)" fill="none"
                                                          stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                                </g>
                                                <line id="Line_11" data-name="Line 11" x2="10"
                                                      transform="translate(653.5 1601.5)" fill="none" stroke="#e72e4b"
                                                      stroke-linecap="round" stroke-width="1"/>
                                            </g>
                                            <g id="Rectangle_5661" data-name="Rectangle 5661"
                                               transform="translate(0 41) rotate(-90)" fill="none" stroke="#e72e4b"
                                               stroke-width="1" stroke-dasharray="130 130">
                                                <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                            </g>
                                        </svg>

                                    </li>
                                </div>

                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='about-section__bottom'>
                <Row>
                    {DepartmentSlider?.length > 0 &&
                    <Swiper
                        spaceBetween={30}
                        slidesPerView={3}
                        slideNextClass={'.next'}
                        allowSlideNext={true}
                        slidePrevClass={'.prev'}
                        allowSlidePrev={true}
                        allowTouchMove={true}
                        longSwipesMs={900}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 30,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                        }}
                        navigation={true} modules={[Navigation]}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        {DepartmentSlider?.length > 0 && DepartmentSlider?.map((i, index)=>{
                                const icon = i?.images?.list?.find(f => f?.thumb === 'on')?.full_path;
                            return(
                                <SwiperSlide key={index + 1}>
                                    <Col sm={4} className='about-section__bottom__single p-0'>

                                        <div className="about-section__bottom__single__inner">
                                            <Link
                                                to={`/department/${i?.page_data?.slug}`}></Link>
                                            <div className="about-section__bottom__single__inner__img">

                                            </div>
                                            <p>{reactHtmlParser(i?.page_data?.short_desc)}</p>
                                            <h4>{reactHtmlParser(i?.page_data?.title)}</h4>
                                            <span>
                                                <img src={icon}/>
                                    </span>
                                            <div className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="11.414"
                                                     viewBox="0 0 11.207 11.414">
                                                    <g id="Group_15992" data-name="Group 15992" transform="translate(0.5 0.85)">
                                                        <line id="Line_3858" data-name="Line 3858" x2="5" y2="5"
                                                              transform="translate(5 -0.143)" fill="none" stroke="#f9f9f9"
                                                              stroke-linecap="round" stroke-width="1"/>
                                                        <line id="Line_3859" data-name="Line 3859" y1="5" x2="5"
                                                              transform="translate(5 4.857)" fill="none" stroke="#f9f9f9"
                                                              stroke-linecap="round" stroke-width="1"/>
                                                        <line id="Line_3860" data-name="Line 3860" x1="10"
                                                              transform="translate(0 4.857)" fill="none" stroke="#f9f9f9"
                                                              stroke-linecap="round" stroke-width="1"/>
                                                    </g>
                                                </svg>

                                            </div>
                                        </div>
                                    </Col>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                    }
                    <div className={'mobile-button'}>
                        <ButtonArrow src={'/department'} text={'View All'} background={'transparent'} color={'#1E1E2E'}
                                     border={'1px solid rgb(30 30 46 / 20%)'} margin={'0 0 0 40px'}/>
                    </div>

                </Row>
            </Container>
        </StyledStrength>
    );
};

const StyledStrength = styled.section`
  background: #E4ECF0;
  position: relative;
  overflow: hidden;

  &:after {
    height: calc(50% - 30px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: #F6F9F8;
    content: "";
  }
  
  .department-title{
    position: relative;
    z-index: 5;
  }

  .title_wrapper {
    display: flex;
  }

  .swiper-initialized {
    padding-right: ${props => props.offset ? props.offset + 15  + 'px' : '90px'};
    @media (max-width: 767px) {
      padding-right: 0 !important;
    }
  }

  .swiper-button-prev, .swiper-button-next {
    opacity: 0;
    visibility: hidden;
  }


  .navigation_wrapper {
    justify-content: space-between;
    margin-bottom: 80px;

    @media (max-width: 767px) {
      margin-bottom: 40px;
    }

    .title {
      h4 {
        color: #1E1E2E;
      }
    }

    .d-flex {
      justify-content: space-between;
    }

    .navigation {
      display: flex;

      &__icon {
        cursor: pointer;;
        display: flex;

        li {
          cursor: pointer;

          &:first-child {
            margin-right: 20px;
          }

          svg {
            height: 41px;

            g, line {
              stroke: black;
              transition: stroke 0.2s ease;
            }

            path, #Rectangle_5661 {
              //transition: stroke-dasharray 0.3s ease-in-out;
              transition: all 1s cubic-bezier(.25, .74, .22, .99);
            }
          }

          &:hover {
            svg {
              g, line {
                stroke: #E72E4B;
                transition: stroke 0.2s ease;
              }

              #Rectangle_5661 {
                opacity: 1;
                stroke-dasharray: 140;
              }
            }
          }
        }
      }
    }
  }


  @media (min-width: 768px) {
    .mobile-button {
      display: none;
    }
  }

  .navigation_wrapper {
    justify-content: space-between;
    margin-bottom: 80px;


    @media (max-width: 767px) {
      margin-bottom: 0px;
    }

    .title {
      h3 {
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        color: #4F616B;
      }
    }

    .d-flex {
      justify-content: space-between;
    }
  }

  @media (max-width: 767px) {
    .swiper {
      padding-right: 90px;
    }

    .top {
      display: none;
    }

    .navigation {
      display: none!important;
    }

    .navigation_wrapper {
      flex-direction: column;
    }

    .navigation-mobile {

      .navigation__icon {
        margin-bottom: 30px;
        cursor: pointer;;
        display: flex;

        li:first-child {
          margin-right: 20px;
        }
      }
    }

    .mobile-button {
      .dc-btn {
        margin: 40px 0 0 0;
      }
    }
  }
 @media (min-width: 768px){
   .navigation-mobile{
     display: none;
   }
 }

  .about-section__bottom {
    //margin-top: -50px;
    padding-left: ${props => props.offset ? props.offset + 30 + 'px' : '90px'};
    @media (max-width: 767px) {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    &__single {
      max-width: 100%;

      &__inner {
        position: relative;
        //padding-top: calc(370 / 370 * 100%);
        height: 50vh;
        overflow: hidden;
        border-right: 1px solid #FFF;

        &:after {
          content: '';
          background-color: #1E1E2E;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 100%;
          width: 100%;
          transition: .4s ease;
          //mix-blend-mode: multiply;
        }

        a {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0px;
          z-index: 5;
        }

        &__img {
          &:after {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #1E1E2E;
            mix-blend-mode: multiply;
            opacity: 1;
            transition: .8s ease;
            transition-delay: .3s;
          }
        }

        p {
          position: absolute;
          left: 30px;
          right: 30px;
          top: 70px;
          color: #ffffff;
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          transform: translateY(-100%);
          transition: all .5s ease;
          opacity: 0;
        }

        .img-top {
          position: absolute;
          top: 40px;
          left: 30px;
          z-index: 2;
          opacity: 1;
          transition: .3s ease;
          height: 60px;
        }

        .circle {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 50px;
          width: 50px;
          border: 1px solid #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
          opacity: 1;
          transition: .3s ease;

          svg {
            color: #ffffff;
            font-size: 25px;
            transition: .3s ease;
          }
        }

        h4 {
          position: absolute;
          bottom: 30px;
          left: 30px;
          right: 30px;
          line-height: 36px;
          color: #FFFFFF;
          z-index: 2;
          transition: all .5s ease;

        }

        .icon {
          position: absolute;
          top: 30px;
          right: 30px;
          z-index: 1;
          transition: all .8s ease;
          transform: rotate(0deg);

          svg {
            li {
              transition: all .8s ease;

            }
          }

        }

        span {
          position: absolute;
          z-index: 1;
          top: 30px;
          left: 30px;
          font-size: 60px;
          font-weight: 500;
          line-height: 60px;
          color: #F9F9F9;
          transition: all .5s ease;

        }

      }

      &:hover {
        .about-section__bottom__single__inner {
          &:after {
            height: 0;
          }

          .icon {
            transform: rotate(-45deg);

            svg {
              line {
                stroke: #FFFFFF;
                fill: #FFFFFF;
              }
            }
          }

          span {
            top: -10px;
            opacity: 0;
          }

          p {
            transform: none;
            opacity: 1;
          }


          &__img:after {
            opacity: 1;
            transition-delay: 0s;
          }

          .img-top {
            transform: translateY(-20px);
            opacity: 0;
          }

          .circle {
            transform: scale(1.9);
            opacity: 0;

            svg {
              transform: rotate(50deg);
            }
          }

        }

      }
    }
  }

`;

export default React.memo(Strength);
