import React, {useState} from 'react';
import styled from "styled-components";
import 'react-modal-video/css/modal-video.min.css'
import {Link} from "react-router-dom";
import {Img} from "../Img";


const BoxImageWithText = ({name, image, link, desc, number}) => {

    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState('');

    let handelOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
    };

    return (

        <StyledBox className={''}>

            <div className="project-slider__single">
                <div className="project-slider__single__inner">
                    <Img src={image} alt="product"/>
                    <Link to={link}></Link>
                    <div className="project-slider__single__inner__content">
                        <p>{name}</p>
                        <h6>{number}</h6>
                    </div>
                    <p className="viewdetails">{desc}</p>
                </div>
            </div>
        </StyledBox>

    );
};
const StyledBox = styled.div`
  width: 100%;
  overflow: hidden;
  height:100%;

  .project-slider__single {

    &__inner {
      //padding-top: calc(510 / 370 * 100%);
      position: relative;
      height: 65vh;
      overflow: hidden;
      &:after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        background: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 100%;
      }

      a {
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 3;
        width: 100%;
      }

      h6 {
        font-size: 140px;
        font-weight: 500;
        line-height: 80px;
        position: absolute;
        bottom: 70px;
        width: 100%;
        padding: 0px 10px 0 10px;
        z-index: 2;
        margin: 0;
        text-align: center;
        transition: all .6s ease;
        color: #fffff24d;
        @media (max-width: 991px) {
          font-size: 100px;
        }
      }


      p {
        font-size: 32px;
        line-height: 36px;
        font-weight: 500;
        color: #F7F3F0;
        position: absolute;
        bottom: 90px;
        width: 100%;
        padding: 0px 10px 0 10px;
        z-index: 2;
        margin: 0;
        text-align: center;
        transition: all .6s ease;
        transform: translateY(0px);

        &.viewdetails {
          transform: translateY(40px);
          transition: all .6s ease;
          opacity: 0;
          bottom: 40px;
          color: #F7F3F0;
          font-weight: 400;
          line-height: 20px;
          font-size: 16px;
        }
      }

      &:after {
        content: '';
        position: absolute;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1;
        bottom: 0;
        left: 0;
        height: 0%;
        width: 100%;
        transition: all .6s ease;
        opacity: 0;
      }
    }

    &:hover {
      .project-slider__single__inner {

        &:after {
          opacity: 1;
          height: 100%;
          transition: all 0.5s ease;
        }

        p {
          transform: translateY(-55px);

          &.viewdetails {
            transform: translateY(0px);
            opacity: 1;
          }
        }
      }

      .project-slider__single__hover {
        .plus {
          width: 250px;
          height: 250px;
          opacity: 0;
        }

        .content {
          width: calc(100% - 46px);
          padding-top: 89%;
          opacity: 1;

          &__inner {
            opacity: 1;

            p, h4, ul, li {
              opacity: 1;
              transition-delay: .6s;

            }
          }
        }
      }
    }
  }

`

export default BoxImageWithText;