import React, {useEffect, useState} from 'react';
import styled from "styled-components";

import reactHtmlParser from "react-html-parser";
import {Link} from "react-router-dom";
import {Img} from "../Img";

const MyComponent = ({
                         no_modal,
                         image,
                         text,
                         multitext,
                         titleColor,
                         paddingTop,
                         titleMargin,
                         download,
                         breadcome,
                         expert,
                         designation,
                         contentMargin,
                         marginTop,
                         backGround,
                         contentPadding,
                         radius,
                         sectionBorder,
                         singleBorder,
                         link,
                         des,
                         target,
                         adding

                     }
) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <StyledComponent className={`box-image ${adding ? 'hover-icon' : ''}`}

                         titleColor={titleColor}
                         paddingTop={paddingTop}
                         titleMargin={titleMargin}
                         contentMargin={contentMargin}
                         marginTop={marginTop}
                         backGround={backGround}
                         contentPadding={contentPadding}
                         sectionBorder={sectionBorder}
                         radius={radius}
                         singleBorder={singleBorder}

        >

            <div className="box-image__single" >



                <Link to={link ? link : ""}   ><a target={'_blank'} className={'link'}></a></Link>




                <div>
                    <div className="box-image__single__image">
                        <Img src={image}/>
                    </div>
                </div>



                <div className="box-image__single__content">
                    <h6>{text}</h6>
                </div>


            </div>
        </StyledComponent>
    );
};
const StyledComponent = styled.div`

  background: ${props => props.backGround || ''};
  border-radius: ${props => props.sectionBorder || ''};
  .reveal{
    border-radius: 16px;
    overflow: hidden;
  }
  &.hover-icon {
    //devicon.svg
    .box-image__single__image {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.4);
        background-image: url('/images/static/devicon.svg') !important;
        background-repeat: no-repeat !important;
        background-size: 70px 70px !important;
        background-position: center !important;
        transition: 0.7s all ease;
        opacity: 0;
        transform: scale(1.3) rotate(15deg);
        border-radius: 16px;
      }
    }
  }

  .box-image__single__content {

  }

  .box-image__single {
    margin-top: ${props => props.marginTop || '0px'};
    border-radius: ${props => props.singleBorder || ''};
    overflow: hidden;


    .link   {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      cursor: pointer;
    }

    &:hover {
      .box-image__single__image{
        &:after{
          transform: scale(1) rotate(0deg);
          opacity: 1 !important;
        }
      }
      
      .global-image {
        transition: all 0.6s cubic-bezier(0.4, 0, 0, 1);
        transform: scale(1.06);
      }

      .box-image__single__downlaod__btn {
        svg {
          #Ellipse_636 {
            r: 30px;
          }
        }
      }
    }


    &__image {
      position: relative;
      padding-top: ${props => props.paddingTop || 'calc(270 / 370 * 100% )'};
      overflow: hidden;
      border-radius: ${props => props.radius || ''};

      .global-image {
        transform: scale(1.01);
        transition: all 0.6s cubic-bezier(0.4, 0, 0, 1);
      }
    }

    &__content {

      margin: ${props => props.contentMargin || '20px 0 0 0'};
      padding: ${props => props.contentPadding || '0 0 0 0'};

      .breadcome {
        font-size: 12px;
        line-height: 20px;
        color: #18AEAE;
        font-weight: 500;
        margin-bottom: 20px;
      }

      h6 {
        color: ${props => props.titleColor || '#ffffff'};
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      .read-btn {
        color: #FF6F96;
        z-index: 3;
      }

      .experth3 {
        color: #18AEAE;
        font-weight: 700;
      }

      .expertp {
        color: #32355D;
        line-height: 20px;
      }

    }

    &__downlaod {
      display: flex;
      justify-content: space-between;
      margin: 20px 0 0 0;
      width: 100%;

      &__text {
        p {
          font-size: 12px;
          line-height: 20px;
          color: #18AEAE;
          font-weight: 500;
          margin-bottom: 10px;
        }

        h6 {
          margin: ${props => props.titleMargin || '0 0 40px 0'};
          color: ${props => props.titleColor || '#ffffff'};
        }
      }

      &__btn {
        cursor: pointer;

        svg {
          #Ellipse_636 {
            transition: 0.7s all ease;
          }
        }
      }
    }
  }

  @media(max-width: 1200px) and (min-width: 768px){
    .box-image__single__content .breadcome{
      min-height: 40px;
    }
  }
  @media (max-width: 767px) {

    .box-image__single__content h6{
      min-height: unset !important;
      margin-bottom: 30px !important;

    }
    .box-image__single {
      margin-top: 0px;
      margin-bottom: 30px;

      //&:nth-last-child(1){
      //  margin-bottom: 0;
      //}
    
    }

  }


`;

export default React.memo(MyComponent);