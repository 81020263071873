import React, {useEffect, useState, useRef} from 'react';
import styled from "styled-components";
import {Col, Container, Form, Row} from "react-bootstrap";
import Button from "../Button";
import {useDispatch, useSelector} from "react-redux";


import {Link} from "react-router-dom";
import Select, {components} from "react-select";
import {Img} from "../Img";
import ButtonHover from "../ButtonHover";
import moment from "moment/moment";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data, search, cat}) => {

    // API data filter
   const AcademicAC = data;

    const selectTypeInputRef = useRef();
    const [itemsToShow, setItemsToShow] = useState(6);
    const [isCompleted, setIsCompleted] = useState(false)
    const [selectType, setSelectType] = useState('All')
    const [selectedCategory, setSelectedCategory] = useState("All");
    const [filteredData, setFilteredData] = useState(data?.data?.list); // Initialize with all data
    const [activeItem, setActiveItem] = useState('All'); // Set 'All' as the default active item

    const handleItemClick = (item) => {
        setActiveItem(item);
        // Filter the data when an item is clicked
        filterData(item);
    };

    const handleType = (e) => {
        setSelectType(e);
        // Filter the data when the select dropdown changes
        filterData(e);
    };

    useEffect(() => {
        setActiveItem('All');
    }, []);
    const handleCategoryChange = (selectedValue) => {
        setSelectedCategory(selectedValue);
    };

    useEffect(() => {
        setFilteredData(data?.data?.list?.slice()?.reverse())
    }, [data?.data?.list])

    const filterData = (filter) => {
        const dataList = data?.data?.list?.slice()?.reverse();
        if (filter === 'All') {
            setFilteredData(dataList); // Show all data
        } else {
            // Filter the data based on the selected category title
            setFilteredData(dataList?.filter((item) => item?.page_data?.category === filter));
        }
    };

    const handleLoadMore = () => {
        setItemsToShow(prevItemsToShow => prevItemsToShow + 6);
        if (itemsToShow + 6 >= filteredData.length) {
            setIsCompleted(true);
        } else {
            setIsCompleted(false);
        }
    };



    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Update the window width when the window is resized
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <StyledComponent id={'got-to-list'} className={'article-list pb-200'}>
            <Container>
                <Row className={'filter_wrap'}>
                    <Col md={{span: 3}}>
                        <div className="search">
                            {/*<Form className={'form'} onSubmit={''}>*/}
                            {/*    <div className="form-group">*/}
                            {/*        <Form.Group className={'flex'} controlId="formBasicEmail">*/}
                            {/*            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"*/}
                            {/*                 viewBox="0 0 17 17">*/}
                            {/*                <g id="_12" data-name="12" transform="translate(-1.96 -1.96)">*/}
                            {/*                    <path id="Path_8655" data-name="Path 8655"*/}
                            {/*                          d="M18.709,17.505l-2.4-2.392a8.074,8.074,0,1,0-1.2,1.2l2.392,2.4a.852.852,0,1,0,1.2-1.2ZM3.69,10.051a6.361,6.361,0,1,1,6.361,6.361A6.361,6.361,0,0,1,3.69,10.051Z"*/}
                            {/*                          transform="translate(0 0)" fill="#e72e4b"/>*/}
                            {/*                </g>*/}
                            {/*            </svg>*/}


                            {/*            <Form.Control type="text" value={''} onChange={e => (e)} placeholder="Search"*/}
                            {/*                          name={'search'}*/}

                            {/*                          className={'form-control-lg'}*/}

                            {/*            />*/}
                            {/*        </Form.Group>*/}
                            {/*    </div>*/}
                            {/*</Form>*/}
                        </div>
                    </Col>
                </Row>


                <Row className={'min-height'}>

                    {filteredData &&
                        filteredData?.slice(0, itemsToShow)?.map((i, index) => {

                            return (
                                <Col className={'profile-gap'} md={6} lg={4} key={index}>
                                    <div className=''>
                                        <Link to={`/academic-activities/:slug?details=${reactHtmlParser(i?.page_data?.slug)}`}>
                                            <a>
                                                <div className='presslist__single'>
                                                    <div className='presslist__single__img'>
                                                        <Img src={i?.images?.list?.[0]?.full_path}/>
                                                    </div>
                                                    <div className='presslist__single__content'>
                                                        <h2>{reactHtmlParser(i?.page_data?.subtitle)}</h2>
                                                        <div className='presslist__single__content__text'>
                                                            <h6>{reactHtmlParser(i?.page_data?.category)} </h6>
                                                            <p className={'split-up'}>{reactHtmlParser(i?.page_data?.title)}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </a>
                                        </Link>
                                    </div>
                                </Col>
                            );
                        })}


                    <Col md={12}>
                        <div className="list-loadmore">
                            <Button width={'auto'} src={'#'} margin={'80px 0 0'} text={'Learn More'}
                                    hoverBackground={'#004195'} borderColor={'#FF6F96'}
                                    color={'#FFFFFF'} background={'#FF6F96'} fontSize={'20'} lineHeight={'20px'}
                                    fontWeight={'bold'} letterSpacing={'0'}/>
                        </div>
                    </Col>
                    {itemsToShow < filteredData?.length && (
                        <Col sm={12} className={'load_more'}>
                            <div onClick={handleLoadMore}>
                                <ButtonHover text={'Load More'} margin={'0 auto'} color={'#FFFFFF'} background={'#E8E8E8'}/>
                            </div>
                        </Col>
                    )}
                </Row>

            </Container>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  padding-top: 80px;
  overflow: hidden;
  background-color: #F6F9F8;

  .list-loadmore {
    display: flex;
    justify-content: center;
    display: none;
  }

  .presslist {
    &__single {
      min-height: 100%;
      //box-sizing: border-box;
      transition: 1s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      position: relative;
      padding-top: calc(460/370*100%);
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: scale(1.01);
        transition: 1.4s ease;
      }
      &:hover {
        img {
          transform: scale(1.06) !important;
        }
      }


      &__content {
        padding: 40px 40px 40px;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 992px) {
          font-size: 18px;
          line-height: 27px;
        };

        //&__text {
        //  min-height: 135px;
        //}

        &__link {
          position: absolute;
          bottom: 31px;
        }

        span {
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          min-height: 290px;
        }

        h5 {
          color:#fff;
        }


        p {
          color: #fff;
          margin-bottom: 30px;
          font-size: 16px;
          line-height: 20px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 500;

        }

        &__learn {
          position: relative;

          &:after {
            content: '';
            height: 3px;
            width: 0px;
            background-image: linear-gradient(#AD0000, #FF0000);
            position: absolute;
            transition: .3s;
          }

          h2 {
            color: #222222;
            font-weight: 500;
            cursor: pointer;
            border-bottom: 2px solid #222222;
            width: 26%;
          }
        }

        &__text {
          p {
            margin: 0 20px 0 0;
            display: flex;
            align-items: center;
            font-weight: 700;
          }
          display: flex;
          justify-content: end;

          svg {

            border-radius: 30px;

            circle[id="Ellipse_476"] {
              cy: -15;
              fill: #E72E4B;
              transition: all 0.5s ease;
            }
            #Arrow {
              line {
                stroke: #000;
                transition: all 0.5s ease;
              }
            }

            &:hover {
              #Arrow {
                line {
                  stroke: #fff;
                  transition: all 0.5s ease;
                }
              }
              circle[id="Ellipse_476"] {
                cy: 15;
                fill: #E72E4B;
                transition: all 0.5s ease;
              }
            }
          }
        }

        &__height {
          min-height: 190px;
        }
        &:hover {
          svg {
            #Arrow {
              line {
                stroke: #fff;
                transition: all 0.5s ease;
              }
            }

            circle[id="Ellipse_476"] {
              cy: 15;
              fill: #E72E4B;
              transition: all 0.5s ease;
            }
          }
        }
      }

      @media (max-width: 767px) {
        margin-bottom: 20px !important;
        :last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
  
  

  .filter_wrap {
    justify-content: flex-end;
    .filter {
      ul {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        row-gap: 30px;

        a {
          overflow: hidden;
          display: inline-block;
          border-radius: 50px;
          padding: 12px 36px;
          color: #1E1E2E;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          border: 1px solid #1E1E2E;
          margin-right: 30px;
          position: relative;
          cursor: pointer;
          transition: 0.7s all ease;

          &:after {
            position: absolute;
            background: black;
            width: 100%;
            height: 100%;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: 0.5s all ease;
            transform: translateX(-300px);;
            border-radius: 21px;
            z-index: 0;
          }

          span {
            position: relative;
            z-index: 1;
            transition: 0.5s all ease;

          }

          &:last-child {
            margin-right: 0;
          }

          &.active {
            background: black;
            color: white;
          }

          &:hover {
            color: white!important;

            span {
              color: white;

            }
            &:after {
              transform: scale(1);
            }
          }
        }
      }
    }
  }
  .load_more {

    .dc-btn {
      display: flex;
      justify-content: center;
      a {

        margin: auto;
        background-color: #1E1E2E;
        &:hover{
          color: white!important;
        }
      }
    }
  }

  .search {
    .form-group {
      position: relative;

      svg{
        position: absolute;
        top: 12px;
        left: 20px;
        right: 12px;
        bottom: 12px;
      }

      .form-control {
        border: 1px solid #1E1E2E;
        background: transparent;
        border-radius: 21px;
        height: 40px;
        padding: 0 15px 0 45px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #1E1E2E;

        &::placeholder {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          color: #1E1E2E;
        }
      }
    }
  }
}
.min-height{
  padding-top: 80px;
  .profile-gap{
    padding-bottom: 80px;
  }
}

.profile-image{
  position: relative;
  padding-top: calc(458/360 * 100%);
}
.profile-text{
  h6{
    padding-top: 20px;
    color: #1E1E2E;
  }
  p{
    padding-top: 5px;
    color: #000000a8;
    font-size: 16px;
    font-weight: 400;
  }
}

.filter-mobile {
  display: none;
}

.css-1wa3eu0-placeholder, .css-1uccc91-singleValue {
  color: white !important;
  font-size: 10px;
  font-weight: 600 !important;
}

.read-btn {

  position: relative;
}

@media (min-width: 992px) {
  .min-height {
    min-height: 490px;
  }
}
@media (max-width: 767px) {
  padding-top: 60px;
  .profile-gap:not(:last-child){
    padding-bottom: 60px;
  }
  .filter_wrap {
    flex-direction: column-reverse;
    
  }
  .load_more{
    margin-top: 0;
  }

  .filter-mobile {
    display: block;
  }

  .filter {
    display: none;
  }

  .css-13cymwt-control, .css-t3ipsp-control{
    background-color: #1E1E2E;
    border-radius: 50px;
    .css-1jqq78o-placeholder{
      color: white;
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      margin:10px 0 10px 30px;
    }
    .css-1fdsijx-ValueContainer{
      padding: unset;
      .css-1dimb5e-singleValue{
        margin-left: 20px;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: #FFF;
        opacity: 60%;
      }
    }
    .css-1u9des2-indicatorSeparator{
      display: none;
    }
    .css-1xc3v61-indicatorContainer{
      padding-right: 30px;
    }
  }

  .css-t3ipsp-control{
    border-color:unset!important;
    box-shadow: unset!important;
  }
  .css-qbdosj-Input{
    input{
      min-width: 0px!important;
      opacity: 0!important;
    }
  }
  .css-15lsz6c-indicatorContainer{
    padding-right: 30px;
    color: hsl(0, 0%, 80%);
  }


  .css-yk16xz-control, .css-1pahdxg-control {
    border-radius: 18px !important;
    border-color: #989898;
    height: 40px;
    background-color: #E50019;
    cursor: pointer;
    padding-left: 15px;
  }

  .css-yk16xz-control .css-1wa3eu0-placeholder, .css-1pahdxg-control .css-1wa3eu0-placeholder, .css-1uccc91-singleValue {
    color: #ffffff !important;
    font-size: 12px !important;
    font-weight: bold;
    cursor: pointer;
  }

  .css-yk16xz-control .css-tlfecz-indicatorContainer, .css-1pahdxg-control .css-tlfecz-indicatorContainer, .css-yk16xz-control .css-1gtu0rj-indicatorContainer, .css-1pahdxg-control .css-1gtu0rj-indicatorContainer {
    margin-right: 20px;
  }

`;
export default React.memo(MyComponent);