import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Col, Container, Form, Row} from "react-bootstrap";
import Button from "../Button";
import {toast} from "react-toastify";
import {Img} from "../Img";
import {useForm} from "react-hook-form";

import ButtonShortSubmit from "../ButtonShortSubmit";
import reactHtmlParser from "react-html-parser";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/career";
const ListWithForm = ({padding, data, id}) => {

    const store = useSelector(store=>store)
    const Careerjob = data?.section_data;
    const [uploadText, setUploadText] = useState('Upload CV*')

    // form submission
    const {register, control, handleSubmit, formState: {errors, isSubmitSuccessful}, reset, watch} = useForm({
        mode: "all",
    })
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const dispatch = useDispatch()
    let apiEndPoint = apiEndPoints.FORM_SUBMIT

    const handleFormSubmit = (e) => {
        var formData = new FormData();
        formData.append("spam_protector", "");
        formData.append("form_id", "career-form");
        formData.append('first_name', e?.name);
        formData.append('email', e?.email);
        formData.append('phone', e?.number);
        formData.append('position', 'nothing');
        formData.append('message', e?.message);
        formData.append('file', cv);
        dispatch(postForm([apiEndPoint, formData]))
    }

    useEffect(() => {
        if (isSubmitSuccessful && !store?.careerReducer?.loading) {
            success('Thanks for your interest, We will contact you shortly')
            reset()

        }
    }, [isSubmitSuccessful,store?.careerReducer])

    const [cv, setCv] = useState(null)
    const [selectedFileName, setSelectedFileName] = useState('');
    const cvRef = useRef()
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setCv(event.target.files[0]);
        setSelectedFileName(file.name);
    };

    const handleUpload = (event) => {
        const selectedFile = event.target.files[0];

        setCv(selectedFile);

        if (selectedFile) {
            const fileName = selectedFile.name;
            setUploadText(fileName);
            // You might want to remove or adjust this line depending on your styling
            document.querySelector('.career_upload').classList.add('hide');
        }
    };


    return (
        <StyledListWithForm id={`${id ? id : 'ListWithForm'}`}
                            className={`list_with_form pt-120 ${padding ? padding : 'pb-200'} `}>
            <Container>
                <Row>
                    <Col lg={6} className={'left-col'}>
                        <h4 className={'split-up'}>{reactHtmlParser(Careerjob?.subtitle)}</h4>
                        <h5 className={'split-up'}>{reactHtmlParser(Careerjob?.short_desc)}</h5>
                        <p className={'split-up'}>{reactHtmlParser(Careerjob?.description)}</p>
                    </Col>
                    <Col lg={{span: 5, offset: 1}} className={'right-col'}>
                        <div className="form_wrapper">
                            <h4 className={'split-up'}>{reactHtmlParser(Careerjob?.title)}</h4>
                            <Form className={'form'} onSubmit={''}>

                                <input name={'spam_protector'} type='hidden'/>
                                <div className="form-group">
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Control className={'form-control-lg'}
                                                      {...register('name', {
                                                          required: {
                                                              value: true,
                                                              message: "Please enter your full name",
                                                          }

                                                      })}
                                                      type="text"
                                                      placeholder="Name*"
                                        />

                                    </Form.Group>
                                    <p className={'form-error'}>{errors.name?.message}</p>
                                </div>
                                <div className="form-group">
                                    <Form.Group controlId="formBasicEmail">

                                        <Form.Control className={'form-control-lg'}
                                                      {...register('email', {
                                                          required: {
                                                              value: true,
                                                              message: "Please enter a valid email address"
                                                          },
                                                          pattern: {
                                                              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                              message: "Please enter a valid email address"
                                                          }
                                                      })}
                                                      type="email"
                                                      placeholder="Email*"/>
                                        <p className={'form-error'}>{errors.email?.message}</p>
                                    </Form.Group>
                                </div>
                                <div className="form-group">
                                    <Form.Group controlId="formBasicPhone">

                                        <Form.Control className={'form-control-lg'}
                                                      {...register("number", {
                                                          required: {
                                                              value: true,
                                                              message: 'please enter your phone first'
                                                          },
                                                          pattern: {
                                                              value: /^01[0-9]{9}$/,
                                                              message: 'please enter a valid 11 digit phone number'
                                                          }
                                                      })}
                                                      type="number"
                                                      placeholder="Phone Number*"/>
                                    </Form.Group>
                                </div>
                                <div className="form-group">
                                    <Form.Group controlId="formBasicPhone">

                                        <Form.Control className={'form-control-lg'}
                                                      {...register('message')}
                                                      placeholder="Message (Optional)"/>
                                    </Form.Group>
                                </div>

                                <div className={'form-group width-fit'}>
                                    <div className="attachCvName">
                                        <div className="attach-cv">

                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 xmlnsXlink="http://www.w3.org/1999/xlink" width="17" height="17"
                                                 viewBox="0 0 17 17">
                                                <g id="Mask_Group_429" data-name="Mask Group 429"
                                                   transform="translate(-858 -2127)">
                                                    <path id="attach"
                                                          d="M13.468,3.458a3.116,3.116,0,0,0-4.408,0L7.272,5.248a.524.524,0,0,1-.747,0,.538.538,0,0,1,0-.755L8.317,2.7a4.175,4.175,0,0,1,5.894,5.914L7.63,15.2a.524.524,0,0,1-.747,0,.538.538,0,0,1,0-.755l6.582-6.587A3.11,3.11,0,0,0,13.468,3.458ZM8.889,5.563A1.746,1.746,0,0,1,11.35,8.04L5.34,14.055a2.96,2.96,0,0,1-4.177-4.2L4.74,6.279a.524.524,0,0,1,.747,0,.538.538,0,0,1,0,.755l-3.577,3.58A1.9,1.9,0,1,0,4.6,13.3l6.009-6.014a.678.678,0,0,0,0-.96.689.689,0,0,0-.974,0L4.2,11.763a.524.524,0,0,1-.747,0,.538.538,0,0,1,0-.755Z"
                                                          transform="translate(858.257 2126.737)" fill="#f9f9f9"
                                                          fill-rule="evenodd"/>
                                                </g>
                                            </svg>


                                            <Form.Control
                                                ref={cvRef}
                                                type="file"
                                                accept=".pdf"
                                                id="resume-upload"
                                                style={{display: "none"}}
                                                onChange={handleFileChange}
                                            />
                                            <Form.Label htmlFor="resume-upload" className="resume-upload-label"
                                                        style={{display: selectedFileName ? "none" : "block"}}>
                                                Upload CV
                                            </Form.Label>
                                            {selectedFileName && (
                                                <div className="file-name">
                                                    {selectedFileName}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div onClick={handleSubmit(handleFormSubmit)}>
                                        <div className={'small-screen'}>
                                            <Button color={'white'} background={'#323232'} text={'Submit Message'}
                                                    hoverColor={'white'}/>
                                        </div>
                                        <div className={'all-screen'}>
                                            <ButtonShortSubmit text={'Submit Message'} color={'white'}/>
                                        </div>
                                    </div>

                                </div>

                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledListWithForm>
    )
};

const StyledListWithForm = styled.div`
  background: #F6F9F8;
  
  

  .left-col {
    padding-right: 0px;
    h4{
      padding-bottom: 60px;
    }
    h5{
      padding-bottom: 20px;
      color: #98223C;
    }
  }
  

  .right-col {
    padding-left: 0px;
  }

  .image-wrapper {
    position: relative;
    padding-top: calc(604 / 600 * 100%);
    height: 100%;
  }

  .form_wrapper {
    background: #1E1E2E;
    padding: 60px;
    height: 100%;

    h4 {
      color: #fff;
      padding-bottom: 40px;
    }

    @media (min-width: 1550px) {

    }
    @media (max-width: 992px) and (min-width: 768px) {
      padding: 60px;
    }

    form {
      display: flex;
      height: 100%;
      flex-direction: column;
      gap: 40px;
    }

    .title {
      font-size: 24px;
      font-weight: 4000;
      line-height: 28px;
      color: #F9F9F9;
      margin: 0 0 40px;
    }

    .form-group {
      margin-bottom: 0;
      input:-webkit-autofill {
        -webkit-text-fill-color: white !important;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .form-control {
        box-shadow: none;
        outline: 0;
        border-radius: 0;
        background: transparent;
        height: auto;
        border-left: none;
        border-right: none;
        border-top: none;
        border-color: rgba(255, 255, 255, 0.5);
        padding: 0 0 20px;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;

        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
    
    .small-screen{
      display: none;
    }

    @media (min-width: 991px) and (max-width: 1150px) {
      .all-screen{
        display: none;
      }
      .small-screen{
        display: block;
        .dc-btn {
          a {
            padding: 12px 36px;
            background-color: transparent;
            border: 1px solid #ffffff36;

            &:before {
              background: #fff;
            }

            span {
              color: #FFFFFF;
              font-size: 16px;
              font-weight: 400;
              line-height: 20px;
            }

            &:hover {
              span {
                color: #3C3C3B;
              }
            }
          }
        }
      }
    }

    .filter__placeholder {
      color: #C4C4C4;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    .filter__control {
      height: 30px !important;
      padding: 0 !important;
      background: transparent !important;
      border-left: none !important;
      border-right: none !important;
      border-top: none !important;
      border-color: rgba(246, 246, 247, 0.2) !important;

      .filter__single-value {
        color: #C4C4C4 !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 20px !important;
      }

      .filter__indicator-separator {
        display: none;
      }

      .filter__value-container {
        padding: 0;
      }

      .filter__dropdown-indicator, .filter__input-container, .filter__placeholder {
        margin: 0;
      }
    }
  }

.width-fit{
  display: flex;
  gap:20px;
  
  .attachCvName{
    width: 169px;
    cursor: pointer;
    border-radius: 50px;
    gap:5px;
    border: 1px solid rgba(255, 255, 255, 0.21);
    border-style: dashed;
    .attach-cv{
      gap:5px;
      height: 41px;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      cursor: pointer;
      padding-left: 12px;
      padding-right: 12px;
      input{
        top:10px;
      }
      p {
        position: absolute;
        bottom: -25px;
        color: #ffffff;
        font-size: 12px;
        //left: 0;
      }
      
      svg{
        g{
          path{
            fill:white;
          }
        }
      }
      label{
        cursor: pointer;
        margin-bottom: 0;
        margin-top: 0;
        color: white;
      }
    }
  }
}



@media (max-width: 767px) {
  padding-top: 0;
  padding-bottom: 0px;
  .form_wrapper {
    padding: 60px 15px;
    margin-top: 0px;
  }

  .left-col {
    padding-bottom: 40px;
    padding-right: 15px;
  }

  .container {
    padding: 15px 15px 0;
  }

  .right-col {
    padding: 0px;
  }
}

    @media (min-width: 768px) and (max-width: 991px) {
      .left-col {
        padding-bottom: 40px;
        padding-right: 0px;
      }

      .right-col {
        padding-left: 15px;
        padding-right: 0;
      }

    }
`;


export default React.memo(ListWithForm);
