import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Modal, Form} from 'react-bootstrap';
import {Col, Container, Row} from "react-bootstrap";
import 'simplebar-react/dist/simplebar.min.css';
import Select, {components} from "react-select";
import Button from "./Button";
import {useDispatch, useSelector} from "react-redux";
import reactHtmlParser from "react-html-parser";
import {apiEndPoints} from "../api/network/apiEndPoints";
import {postForm} from "../api/redux/appoinment";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";

const AppointmentPopup = ({
                         show,
                         handleClose,
                         item,
                         handleShow,
                         description,
                         data,
                         subtitle,
                         img
                     }) => {

    const popupData = data;
    const [winWidth, setWinWidth] = useState(true)
    const [selectedFileName, setSelectedFileName] = useState('');
    const dispatch = useDispatch()
    // const getData = useSelector(state => state.doctorsReducer);
    const selectInputRef = useRef()

    const [dateValue, setDateValue] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFileName(file.name);
    };
    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])


    // dropdown
    const genderOption = [
        {value: '1', label: 'Male'},
        {value: '2', label: 'Female'},
        {value: 'Other', label: 'Other'},
    ]

    const optionVisit = [
        {value: 'yes', label: 'Yes'},
        {value: 'no', label: 'No'},
    ]


    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen && "rotate(180deg)"
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? `#000000` : '#535353',
            backgroundColor: state.isSelected ? `rgba(232, 231, 231, 0.6)` : '#FFF',
            margin: 0,
            fontSize: 16,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            fontWeight: state.isSelected ? 700 : 400,
            "&:hover": {
                backgroundColor: `#000000`,
                color: '#FFF',
                cursor: 'pointer'
            },

        }), menu: (provided, state) => ({
            ...provided,
            color: '#FFF',
            backgroundColor: state.isSelected ? `#000000` : 'rgba(255,255,255,0)',
            margin: 0,
            borderRadius: 5,
            fontSize: 16,
            // width: 200,
            "&:hover": {
                cursor: 'pointer'
            },
        }), menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#191818' : '#FFF',
            borderRadius: 0,
            fontWeight: '400',
            color: '#FFF',
            fontSize: 16,
            "&:hover": {
                cursor: 'pointer'
            },
        }),

    };

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon/>
            </components.DropdownIndicator>
        );
    };


    const CaretDownIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.414" viewBox="0 0 13.414 7.414">
            <g id="Group_15659" data-name="Group 15659" transform="translate(1479.207 -192.793) rotate(90)">
                <line id="Line_3883" data-name="Line 3883" x2="6" y2="6" transform="translate(193.5 1466.5)" fill="none"
                      stroke="#231f20" stroke-linecap="round" stroke-width="1"/>
                <line id="Line_3884" data-name="Line 3884" y1="6" x2="6" transform="translate(193.5 1472.5)" fill="none"
                      stroke="#231f20" stroke-linecap="round" stroke-width="1"/>
            </g>
        </svg>
            ;
    };


    //------  Doctor data refactor
    const doctorData = useSelector(store => store.appointReducer)

    // remove common department name
    const onlyDepartment = [];
    doctorData?.posts?.data?.map(i => {
        onlyDepartment.push(i?.data?.category_title)
    })
    const UniqueDepartmentSet = new Set(onlyDepartment)
    // map array
    const DepartmentMap = []
    UniqueDepartmentSet && UniqueDepartmentSet.forEach(e => {
            DepartmentMap.push(e)
        }
    )
    // Department dropdown
    const Departments = DepartmentMap
        .filter(e => e.trim().length > 0) // Filter out empty values
        .map(e => ({ value: reactHtmlParser(e), label: reactHtmlParser(e) }));

    //--- filter doctor based on department
    const [department, setDepartment] = useState('');

    const handleSelectDrop = (e) => {
        setDepartment(e)
        selectInputRef.current.clearValue();
    }
    // doctor names dropdown
    const doctorMap = doctorData?.posts?.data?.filter(f => f?.data?.category_title == department)

    const option = doctorMap?.map(e => (
        {value: e?.data?.id, label: reactHtmlParser(e?.data?.title)}
    ))


    // form submission
    const [drId, setDrId] = useState('')
    const [genderId, setGenderId] = useState('')
    const [visit, setVisit] = useState('')
    const [timeSlot, setTimeSlot] = useState('')


    const handleDrId = (e) => {
        setDrId(e)
    }
    const timeDrId = (e) => {
        setTimeSlot(e)
    }
    const handleGender = (e) => {
        setGenderId(e)
    }
    const handleVisit = (e) => {
        setVisit(e)
    }


    const [dateInput, setDateInput] = useState('');

    const handleDateChange = (event) => {
        setDateInput(event.target.value);
        setDateValue(event.target.value);
    };
    // time-slot --------
    const timeMap = doctorData?.posts?.data?.filter(f => f?.data?.id == drId)
    const timeSlots = timeMap?.[0]?.appintment_data;

    // const timeSlotsArray = timeSlots?.split(", ").map(slot => slot.trim());

    const finaltimeData = timeSlots?.filter((i) => i?.date === dateInput);

    const formattedDataArray = finaltimeData?.map(data => {
        return {
            "ID": data.id,
            "Time": `${data.start_time} - ${data.end_time}`
        };
    });

    const finaltimeSlot = formattedDataArray?.map((e) => ({
        value: e?.Time,
        label: reactHtmlParser(e?.Time),
        ID:e?.ID
    })) || [];



    // ---------------

    const {register, control, handleSubmit, formState: {errors, isSubmitSuccessful}, reset, watch} = useForm({
        mode: "all",
    })
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });


    let apiEndPoint = apiEndPoints.POST_APPOINTMENT

    const handleFormSubmit = async (e) => {
        try {
            var formData = new FormData();
            formData.append("spam_protector", "");
            formData.append("doctor_id", drId);
            formData.append("slot_id", timeSlot?.ID);
            formData.append("department", department);
            formData.append('name', e?.name);
            formData.append('email', e?.email);
            formData.append('mobile', e?.number);
            formData.append('gender', genderId);
            formData.append('birth', e?.birth);
            formData.append('age', '12');
            formData.append('visit', visit);
            formData.append('date', e?.date);
            formData.append('time_slot', timeSlot?.value);
            formData.append('description', e?.description);

            const response = await dispatch(postForm([apiEndPoint, formData]));

            if (response && response.payload?.status === 200) {
                success('Appointment has been booked');
                reset(); // Reset form fields
            } else if (response) {
                error(response?.payload?.response?.data?.message);
                reset(); // Reset form fields
            } else {
                error('Failed to submit form. Please try again later.');
            }
        } catch (error) {
            error('Failed to submit form. Please try again later.');
        }
    };


    return (

        <StyledModal>
            <Modal
                show={show}
                item={item}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="appointment"
            >
                <Modal.Body>
                    <Container>
                        <Row className={'for-close'} onClick={handleClose}>
                            <div className="modal-close hover">
                                <svg id="Button_-_Close" data-name="Button - Close" xmlns="http://www.w3.org/2000/svg"
                                     xmlnsxlink="http://www.w3.org/1999/xlink" width="40" height="40"
                                     viewBox="0 0 40 40">
                                    <g id="Mask_Group_396" data-name="Mask Group 396" transform="translate(-1268 -20)">
                                        <g id="Ellipse_18" data-name="Ellipse 18" transform="translate(1268 20)"
                                           fill="none" stroke="#1e1e2e" stroke-width="1">
                                            <circle cx="20" cy="20" r="20" stroke="none"/>
                                            <circle cx="20" cy="20" r="19.5" fill="none"/>
                                        </g>
                                        <circle id="Ellipse_617" data-name="Ellipse 617" cx="20" cy="20" r="20"
                                                transform="translate(1268 60)" fill="#e72e4b"/>
                                        <g id="Group_18979" data-name="Group 18979" transform="translate(-1883 -86.5)">
                                            <line id="Line_4" data-name="Line 4" x2="8" y2="8"
                                                  transform="translate(3167 122.5)" fill="none" stroke="#1e1e2e"
                                                  stroke-linecap="round" stroke-width="1"/>
                                            <line id="Line_3877" data-name="Line 3877" x1="8" y2="8"
                                                  transform="translate(3167 122.5)" fill="none" stroke="#1e1e2e"
                                                  stroke-linecap="round" stroke-width="1"/>
                                        </g>
                                    </g>
                                </svg>

                            </div>
                        </Row>
                    </Container>
                    <Container className={'details'}>
                        <Row>
                            <Col className={'right-col'}>
                                <div className="form_wrapper">
                                    <h4 className={'split-up'}>Make an Appointment</h4>
                                    <Form className={'form'} onSubmit={''}>

                                        <input name={'spam_protector'} type='hidden'/>
                                        <input name={'form_id'} value={'contact-form'} type='hidden'/>
                                        <div className="form-group">
                                            <Form.Group controlId="formBasicEmail">

                                                <Form.Control
                                                    className={`form-control-lg ${errors.name?.message ? 'form-error' : ''}`}
                                                    {...register('name', {
                                                        required: {
                                                            value: true,
                                                            message: "Please enter your full name",
                                                        }
                                                    })}
                                                    type="text"
                                                    placeholder="Name*"
                                                />
                                                <p className={'form-error'}>{errors.name?.message}</p>
                                            </Form.Group>
                                        </div>
                                        <div className="form-group">
                                            <Form.Group controlId="formBasicEmail" className={'double-field'}>
                                                <div className={'double-field__left'}>
                                                    <Form.Control
                                                        className={`form-control-lg ${errors.email?.message ? 'form-error' : ''}`}
                                                        {...register("email", {
                                                            required: {
                                                                value: true,
                                                                message: 'please enter your email'
                                                            },
                                                            pattern: {
                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                message: 'Please enter a valid email address'
                                                            }
                                                        })}
                                                        type="email"
                                                        placeholder="Email*"/>
                                                    <p className={'form-error'}>{errors.email?.message}</p>
                                                </div>
                                                <div className={'double-field__right'}>
                                                <Form.Control
                                                    className={`form-control-lg ${errors.number?.message ? 'form-error' : ''}`}
                                                    {...register("number", {
                                                        required: {
                                                            value: true,
                                                            message: 'Please enter your phone number'
                                                        },
                                                        pattern: {
                                                            value: /^01[0-9]{9}$/,
                                                            message: 'Please enter a valid 11 digit phone number'
                                                        }
                                                    })}
                                                    type="number"
                                                    placeholder="Phone Number*"/>
                                                <p className={'form-error'}>{errors.number?.message}</p>
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="form-group">
                                            <Form.Group controlId="formBasicPhone" className={'double-field'}>
                                                <Select classNamePrefix="filter" className={'dropdown'}
                                                        name={'interested'}
                                                        isSearchable={false}

                                                        onChange={(e) => handleGender(e?.value)}
                                                        placeholder='Gender' styles={customStyles}
                                                        components={{DropdownIndicator}}
                                                        options={genderOption}/>
                                                <Form.Control className={'form-control-lg'}
                                                              {...register("birth")}
                                                              type="text"
                                                              placeholder="Date of Birth (mm/dd/yyyy)"/>

                                            </Form.Group>
                                        </div>

                                        <div className="form-group">
                                            <Form.Group controlId="formBasicPhone" className={'double-field'}>
                                                <Select classNamePrefix="filter" className={'dropdown'}
                                                        name={'interested'}
                                                        isSearchable={false}
                                                        onChange={(e) => handleSelectDrop(e.value)}
                                                        placeholder='Department' styles={customStyles}
                                                        components={{DropdownIndicator}}
                                                        options={Departments}/>
                                                <Select classNamePrefix="filter" ref={selectInputRef}
                                                        className={'dropdown'}
                                                        name={'interested'}
                                                        isSearchable={false}
                                                        onChange={(e) => handleDrId(e?.value)}
                                                        placeholder='Doctor' styles={customStyles}
                                                        components={{DropdownIndicator}}
                                                        options={option}/>

                                            </Form.Group>
                                        </div>
                                        <div className="form-group">
                                            <Form.Group controlId="formBasicPhone" className={'double-field'}>
                                                <Form.Control
                                                    className={`form-control-lg ${dateValue === '' ? 'date' : ''} `}
                                                    {...register("date")}
                                                    type="date"
                                                    placeholder="Date of Birth"
                                                    value={dateInput}
                                                    onChange={handleDateChange}/>
                                                <Select classNamePrefix="filter" className={'dropdown'}
                                                        name={'interested'}
                                                        isSearchable={false}

                                                        onChange={(e) => handleVisit(e?.value)}
                                                        placeholder='Is this your first visit?' styles={customStyles}
                                                        components={{DropdownIndicator}}
                                                        options={optionVisit}/>

                                            </Form.Group>
                                        </div>

                                        <div className="form-group">
                                            <Form.Group controlId="formBasicPhone" className={'double-field'}>
                                                <Select classNamePrefix="filter" className={'dropdown'}
                                                        name={'interested'}
                                                        isSearchable={false}
                                                        onChange={(e) => timeDrId(e)}
                                                        placeholder='Time-slot' styles={customStyles}
                                                        components={{DropdownIndicator}}
                                                        options={finaltimeSlot}/>
                                                <Form.Control className={'form-control-lg'}
                                                              {...register("description", {
                                                                  required: {
                                                                      // value:true,
                                                                      message: 'please enter your Message'
                                                                  },

                                                              })}
                                                              type="text"
                                                              placeholder="Please describe the reason of your visit"/>

                                            </Form.Group>
                                        </div>

                                        <div className={'form-group width-fit'}>

                                            <div onClick={handleSubmit(handleFormSubmit)} className={'submit-button'}>
                                                <Button color={'black'} background={'black'} text={'Submit Message'}
                                                        hoverColor={'white'}/>
                                            </div>

                                        </div>

                                    </Form>
                                    <div className={'phone'}>
                                        <p>Make an Appointment by Call <a href="tel:+8809666750075">09666750075</a></p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </StyledModal>

    )
};

const StyledModal = styled.section`
  background-color: #F5F5F5;

  .modal-dialog {
    max-width: 100% !important;
  }

  h4 {
    letter-spacing: 1.5px;
  }


`;


export default React.memo(AppointmentPopup);
