import React, {useEffect, useState,useRef} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const Founder = ({des, designation, img, name,data}) => {


// offset
    const [offset, setOffset] = useState(100)
    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)

        window.addEventListener('load', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
    }, [])

    // halfcut

    const sourceDivRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            const fullHeight = sourceDivRef.current?.offsetHeight;
            const adjustedHeight = (fullHeight / 2)+200;
            document.documentElement.style.setProperty('--target-height', `${adjustedHeight}px`);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <StyledComponent offset={offset}>
            <Container fluid className="profile">
                <Row>
                    <Col lg={'7'} className="profile__body">
                        <div className={'profile__body__inner'}>
                            <div className="profile__body__name">
                                <h4 className={'split-up'}>{data?.section_data?.subtitle}</h4>
                            </div>
                            {data?.section_data?.description && reactHtmlParser(data?.section_data?.description)}
                        </div>
                        <h6 className={'split-up'}>{data?.section_data?.name} </h6>
                        <p className={'sub-deg split-up'}>{data?.section_data?.designation}</p>
                    </Col>
                    <Col lg={'5'} className={'profile-left'}>
                        <div className="profile__image" ref={sourceDivRef}>
                            <Img src={data?.images?.list?.[0]?.full_path}/>
                        </div>
                    </Col>
                </Row>

            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #1E1E2E;
  position: relative;
  padding-top: 140px;
  .profile{
    z-index: 1;
    position: relative;
    @media(max-width: 767px){
      padding-bottom: 80px;
      padding-top: 150px;
    }
    &__body{
      padding-left: ${props => props.offset ? props.offset + 15 + 'px' : '100px'}!important;
      padding-top: 120px;
      padding-right: 100px;
      background-color: #1E1E2E;
      h6{
        color: #fff;
      }
      h4{
        color: #FFFFFF;
      }
      p{
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }
      &__name{
        padding-bottom: 40px;
        p{
          color: #323232;
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
        }
      }
      &__inner{
        padding-bottom: 40px;
      }
      .sub-deg{
        padding-top: 10px;
        opacity: 50%;
      }
    }
    &__image{
      position: relative;
      padding-bottom: 40px;
      padding-top: calc(568/470*100%);
    }
  }
  .profile-left{
    padding-left: 0;
    padding-right: ${props => props.offset ? props.offset + 15 + 'px' : '100px'}!important;
  }
  .row{
    padding-bottom: 200px;
  }
  .row:nth-child(even) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    .profile-left{
      padding-right: 0!important; 
    }
    padding-top: 120px;
    .row{
      flex-direction: column-reverse !important;
    }
  }


  @media(max-width: 767px){
    .row{
      padding-bottom: 120px;
    }
    .profile-left{
      padding-right: 0px;
    }
    .profile{
      padding-top: 0px;
      padding-bottom: unset;
      &__body{
        padding-left: 15px!important;
        padding-bottom: 40px;
        padding-top: 60px;
        padding-right: 15px;
        &__name{
          padding-bottom: 40px;
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px){
    .profile{
      .row{
        flex-direction: column-reverse;
      }
    }
    .profile-left{
      padding-right: 0px;
    }
    .profile__body{
      padding-right: 0!important;
    }
  }

`;

export default React.memo(Founder);
