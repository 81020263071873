import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        gsap.to('.af-map__text img', {
            // yPercent: '120%',
            y: '-100px',
            scrollTrigger: {
                trigger: '.af-map__text',
                scrub: true,
                invalidateOnRefresh: true // to make it responsive
            }
        })
    }, [])

    return (
        <StyledComponent className={'af-map pb-200 '}>
            <Container>
                <Row>
                    <Col sm={3} className={'af-map__text'}>
                        <img src="/images/static/horizontal-text.svg" alt=""/>
                        <h2>{reactHtmlParser(data?.section_data?.short_desc)}</h2>
                    </Col>

                    <Col sm={9}>
                        <img src={data?.images?.list?.[0]?.full_path} alt=""/>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #E1E1E1;
  margin-top: -1px;

  .af-map__text {
    img {
      width: 140px;
      margin-left: 5px;
    }
    h2{
      color: #1E1E2E;
      padding-bottom: 80px;
      display: none;
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 60px;
    .af-map__text img {
      width: 100px;
      margin-bottom: 40px;
    }
    .af-map__text{
      img{
        display: none;
      }
      h2{
        display: block;
      }
    }


  }
`;

export default React.memo(MyComponent);
