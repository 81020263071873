import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { SplitText } from "gsap/SplitText";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

const SVGAnimation = () => {
    const location = useLocation();
    const logoRef = useRef(null);
    const pathsRefs = useRef([]);
    let MainStore = useSelector(state => state)
    gsap.registerPlugin(SplitText);

    useEffect(() => {
        const tl = gsap.timeline();

        tl.from(logoRef.current, { duration: 1, opacity: 0, scale: 0.1, ease: 'power2.out' });


        pathsRefs.current.forEach((pathRef, index) => {
            tl.from(
                pathRef,
                {
                    duration: 0.01,
                    y: '100px', // Move each path from -100px vertically
                    ease: 'power2.out',
                    stagger:0.05,

                },
                // `+=0.9` // Add stagger to the animation
            );
        });


        // Optionally, return a cleanup function
        return () => {
            tl.kill(); // Kill the timeline to prevent memory leaks
        };
    }, [location.pathname,]);


    return (
        <div className="animated-svg-wrapper">
            <svg ref={logoRef} id="Group_21242" data-name="Group 21242" xmlns="http://www.w3.org/2000/svg"
                 xmlnsXlink="http://www.w3.org/1999/xlink" width="97" height="97"
                 viewBox="0 0 97 97">
                <defs>
                    <clipPath id="clip-path">
                        <rect id="Rectangle_5924" data-name="Rectangle 5924" width="67.34"
                              height="82.25" transform="translate(14.83 7.38)" fill="none"/>
                    </clipPath>
                </defs>
                <g id="Group_21239" data-name="Group 21239">
                    <rect id="Rectangle_5842-2" data-name="Rectangle 5842-2" width="97"
                          height="97" fill="transparent"/>
                    <g id="Logo">
                        <g id="Group_21265" data-name="Group 21265"
                           clip-path="url(#clip-path)">
                            <g id="Group_20865" data-name="Group 20865">
                                <path id="Path_8656" data-name="Path 8656"
                                      d="M71.36,27.36c-.34-.6-1.39.09-1.39.09a71.322,71.322,0,0,0-10,1.16,31.28,31.28,0,0,0,8.44-10c.9-.26,1.12-.79.78-1.15-.46-.55-1.31.36-1.31.36A68.75,68.75,0,0,0,56.4,21.93a29.32,29.32,0,0,0,4-9.86c.78-.46.86-1,.43-1.3-.6-.38-1.17.69-1.17.69a67,67,0,0,0-7.54,4.95A38.1,38.1,0,0,0,49,9.33c.47-1.29,0-2-.8-1.95-1.15.07-.73,1.88-.73,1.88-.91,2.31-1.7,4.48-2.35,6.56A32.36,32.36,0,0,0,36,11.12c-.38-.81-.94-1-1.31-.58s.57,1.19.57,1.19a58.379,58.379,0,0,0,6,10.45,34.46,34.46,0,0,0-12.93-4c-.56-.7-1.16-.73-1.42-.3-.37.6.82,1,.82,1a62.85,62.85,0,0,0,8.19,8.83,33.64,33.64,0,0,0-10.39-.87c-.7-.6-1.28-.51-1.46-.05-.24.66,1,.89,1,.89a56.6,56.6,0,0,0,14.21,9.67,8.32,8.32,0,0,1,3.42-2.1l.31-.73a14,14,0,0,1,3.82,2.74v5.42c-2.29-1.46-6.62-3.79-9.88-3.16-1.33.27-13.56,1.75-9.87,16.1.84,3.25,5.3,11,18.13,20.61.7.53,2.63,2.42,3,3.64.32-1.22,2.26-3.11,3-3.64,12.82-9.6,17.28-17.36,18.12-20.61,3.69-14.35-8.53-15.83-9.86-16.1-3.3-.63-7.66,1.74-9.93,3.19V37.38a10.06,10.06,0,0,1,3-2.14l.24.55A10.73,10.73,0,0,1,56,37.89a33.75,33.75,0,0,0,14.29-9.57c.94-.05,1.27-.52,1-1M41.58,34,26.52,27.92c4.57-1.15,14.64,2.59,15.31,2.86-.21,1.84-.25,2.95-.25,3.2M42,29.74Zm.31-2.11-13.2-8.25c4.07-.3,11.46,3.67,13.82,5-.25,1.16-.45,2.25-.61,3.23M44,19.9l-7.49-7.42c2.46.35,5.69,2.46,8.33,4.49-.31,1-.6,2-.84,2.93m23-1c-1.34,4.45-10.47,11-10.47,11a9,9,0,0,0-1.84-.85c0-.53-.07-1.06-.12-1.57Zm-7.88-6.18C59,15.62,56.35,20,54.3,23a2.46,2.46,0,0,1-.39.22c-.23-1.19-.48-2.32-.76-3.39ZM50.6,57.28a2.42,2.42,0,0,1-4.83,0,2.25,2.25,0,0,1,1-1.86V44.31h2.65v11a2.24,2.24,0,0,1,1.16,1.94m-2.51-20.5V11.38C52.73,17,53,33.6,53,33.6a12.45,12.45,0,0,0-5,3.15m7.93.09A8.46,8.46,0,0,0,53,35c.93-.47,1.66-.76,1.66-.76v-.12l14.12-5.76C66.45,32.36,56,36.84,56,36.84"
                                      fill="#e41e26"/>
                                <path ref={(ref) => (pathsRefs.current[0] = ref)} id="Path_8657" data-name="Path 8657"
                                      d="M15.85,45.82l-.16.87,3.48.82a39.3,39.3,0,0,0-3.84,1.13l-.15.83,5.39.8.13-.73-3.52-.62a34.738,34.738,0,0,1,3.82-1l.12-.7Z"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[1] = ref)} id="Path_8657" data-name="Path 8658"
                                      d="M18.48,53.12a1.63,1.63,0,0,0,.44-.43,1.24,1.24,0,0,0,.17-.5,1.33,1.33,0,0,0-.54-1.07,2.93,2.93,0,0,0-1.49-.61,2.28,2.28,0,0,0-1.53.3,1.37,1.37,0,0,0-.67,1.09,1.18,1.18,0,0,0,.09.55,1.62,1.62,0,0,0,.38.52H14.9l-.05.85,4,.08.05-.75Zm-.69-.24a1.4,1.4,0,0,1-.87.2,1.32,1.32,0,0,1-.85-.3.78.78,0,0,1-.29-.67.75.75,0,0,1,.37-.61,1.33,1.33,0,0,1,.87-.18,1.5,1.5,0,0,1,.83.32.78.78,0,0,1,.29.64.75.75,0,0,1-.35.6"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[2] = ref)} id="Path_8657" data-name="Path 8659"
                                      d="M18.86,54.75v-.39H18v.4H14.84v.86L18,55.54V56h.87v-.45h1.48v-.72Z"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[3] = ref)} id="Path_8657" data-name="Path 8660"
                                      d="M20.39,56.23a.77.77,0,0,0-.45-.11.87.87,0,0,0-.47.17.44.44,0,0,0-.18.35.4.4,0,0,0,.2.32.66.66,0,0,0,.47.09.71.71,0,0,0,.46-.17.37.37,0,0,0,0-.65m-5.48.3v.85L19,57v-.75Z"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[4] = ref)} id="Path_8657" data-name="Path 8661"
                                      d="M18.62,58.16A2.5,2.5,0,0,0,17,57.79a2.16,2.16,0,0,0-1.41.67,1.55,1.55,0,0,0-.44,1.32,1.65,1.65,0,0,0,.41.86,1.62,1.62,0,0,0,.86.49,2.32,2.32,0,0,0,1.08,0,2.64,2.64,0,0,0,1-.44,2,2,0,0,0,.68-.74,1.36,1.36,0,0,0,.16-.84,1.34,1.34,0,0,0-.69-1m-.48,1.78a1.41,1.41,0,0,1-.79.39,1.2,1.2,0,0,1-.87-.1A.82.82,0,0,1,16.3,59a1.26,1.26,0,0,1,.8-.36,1.46,1.46,0,0,1,.87.13.76.76,0,0,1,.41.56.75.75,0,0,1-.23.66"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[5] = ref)} id="Path_8657" data-name="Path 8662"
                                      d="M15.67,62.24l.2.84,1.57-.41c.26-.07.53-.13.8-.18a1.26,1.26,0,0,1,.61.09.5.5,0,0,1,.29.34.43.43,0,0,1-.05.33.65.65,0,0,1-.3.27,5,5,0,0,1-.91.33l-1.69.52.24.82L19,64.34a1.81,1.81,0,0,0,.91-.58,1,1,0,0,0,.21-.86.81.81,0,0,0-.2-.39,2.34,2.34,0,0,0-.51-.36l.4-.1-.18-.74Z"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[6] = ref)} id="Path_8657" data-name="Path 8663"
                                      d="M20.85,66.87a2,2,0,0,0,.21-.57,1,1,0,0,0,0-.54,1.18,1.18,0,0,0-.89-.73,2.7,2.7,0,0,0-1.59.12,2.35,2.35,0,0,0-1.26.95,1.5,1.5,0,0,0-.19,1.3,1.18,1.18,0,0,0,.86.77l-.4.17.36.78,3.66-1.76-.32-.68Zm-.74.1a1.4,1.4,0,0,1-.68.57,1.34,1.34,0,0,1-.9.13.74.74,0,0,1-.52-.48.81.81,0,0,1,.1-.74,1.46,1.46,0,0,1,.72-.55,1.34,1.34,0,0,1,.88-.07.66.66,0,0,1,.5.43.85.85,0,0,1-.1.71"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[7] = ref)} id="Path_8657" data-name="Path 8664"
                                      d="M18.24,69.83l.36.77,4.95-2.67-.3-.65Z"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[8] = ref)} id="Path_8657" data-name="Path 8665"
                                      d="M19.88,72.93l.45.76,2-1.36a10.42,10.42,0,0,0,.79,1.17L21.19,75l.51.71L26,72.24l-.42-.6-1.67,1.25a12.491,12.491,0,0,1-.77-1.13l1.72-1.18-.38-.64Z"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[9] = ref)} id="Path_8657" data-name="Path 8666"
                                      d="M25.81,77.63a2.9,2.9,0,0,0,.85-1.51,1.43,1.43,0,0,0-.3-1.3,1.33,1.33,0,0,0-1.19-.42,2.76,2.76,0,0,0-2.34,2,1.56,1.56,0,0,0,.39,1.38,1.67,1.67,0,0,0,.74.51,1.62,1.62,0,0,0,.9,0l-.17-.81a1,1,0,0,1-.81-.32.85.85,0,0,1-.23-.66,1.2,1.2,0,0,1,.32-.68l1.7,2Zm-1.2-2.31a1.3,1.3,0,0,1,.58-.14.74.74,0,0,1,.55.26.76.76,0,0,1,.19.54,1.26,1.26,0,0,1-.2.62Z"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[10] = ref)} id="Path_8657" data-name="Path 8667"
                                      d="M29,78.37a1.62,1.62,0,0,0-.05-.62,1,1,0,0,0-.27-.45,1.16,1.16,0,0,0-1.13-.23,2.9,2.9,0,0,0-1.37.85,2.51,2.51,0,0,0-.72,1.44,1.43,1.43,0,0,0,.4,1.23,1.15,1.15,0,0,0,.48.27,1.44,1.44,0,0,0,.62,0l-.28.34.63.55,2.54-3.26L29.31,78Zm-.61.43a1.58,1.58,0,0,1-.38.84,1.3,1.3,0,0,1-.74.51.71.71,0,0,1-.68-.17.84.84,0,0,1-.23-.7A1.66,1.66,0,0,1,27.55,78a.63.63,0,0,1,.65.15.76.76,0,0,1,.22.67"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[11] = ref)} id="Path_8657" data-name="Path 8668"
                                      d="M27.87,82.18l.65.51,1-1.37.12-.16a3.38,3.38,0,0,1,.76-.88c.18-.12.32-.13.41-.06a.47.47,0,0,1,.12.2l.68-.6a.56.56,0,0,0-.17-.26.5.5,0,0,0-.39-.1,1.09,1.09,0,0,0-.48.21l.3-.42-.5-.39Z"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[12] = ref)} id="Path_8657" data-name="Path 8669"
                                      d="M31.93,80.11l-.3-.23-.49.74.31.24-1.76,2.72.71.45,1.7-2.77.37.24.46-.78-.37-.23.8-1.3-.59-.37Z"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[13] = ref)} id="Path_8657" data-name="Path 8670"
                                      d="M32.78,85.5l.77.39,1.08-2.3,1.14.58.4-1-1.1-.55.44-.93,1.07.55.42-1-1.68-.84Z"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[14] = ref)} id="Path_8657" data-name="Path 8671"
                                      d="M37.11,83.49A2.69,2.69,0,0,0,36,84.8a2.32,2.32,0,0,0-.13,1.62,1.49,1.49,0,0,0,.9,1,1.45,1.45,0,0,0,.92,0,1.79,1.79,0,0,0,.83-.55,2.55,2.55,0,0,0,.52-1,2.94,2.94,0,0,0,.12-1.11,2.23,2.23,0,0,0-.29-1,1.34,1.34,0,0,0-.64-.56,1.28,1.28,0,0,0-1.15.17m1.28,1.29a1.66,1.66,0,0,1-.05.91,1.39,1.39,0,0,1-.5.75.69.69,0,0,1-.7.1.72.72,0,0,1-.47-.54,1.5,1.5,0,0,1,.08-.91,1.53,1.53,0,0,1,.53-.72.73.73,0,0,1,.67-.12.76.76,0,0,1,.44.53"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[15] = ref)} id="Path_8657" data-name="Path 8672"
                                      d="M39.78,85.81a6.851,6.851,0,0,0-.29,1.36,1.69,1.69,0,0,0,.24,1,1.29,1.29,0,0,0,.77.52,1.27,1.27,0,0,0,.86,0A1.43,1.43,0,0,0,42,88a5.78,5.78,0,0,0,.41-1.46l.33-1.74a5.649,5.649,0,0,1-.74-.2l-.43,2a3.77,3.77,0,0,1-.2.75.65.65,0,0,1-.29.3.43.43,0,0,1-.35,0,.5.5,0,0,1-.3-.21.64.64,0,0,1-.11-.4,3.28,3.28,0,0,1,.14-.81l.49-2-.71-.19Z"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[16] = ref)} id="Path_8657" data-name="Path 8673"
                                      d="M42.75,89l.82.13.23-1.67a8,8,0,0,1,.15-.83,1.27,1.27,0,0,1,.32-.55.44.44,0,0,1,.4-.15.39.39,0,0,1,.28.18.72.72,0,0,1,.12.4,6.69,6.69,0,0,1,0,1l-.19,1.83c.27.05.54,0,.81.06l.22-2.76a2,2,0,0,0-.17-1.11A1,1,0,0,0,45,85a.74.74,0,0,0-.42,0,1.77,1.77,0,0,0-.5.34v-.42l-.72-.11Z"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[17] = ref)} id="Path_8657" data-name="Path 8674"
                                      d="M48.83,85.79a1.5,1.5,0,0,0-.43-.4.93.93,0,0,0-.49-.17,1.16,1.16,0,0,0-1,.56,3.07,3.07,0,0,0-.5,1.58,2.45,2.45,0,0,0,.37,1.58,1.32,1.32,0,0,0,1.07.69,1.13,1.13,0,0,0,.54-.1,2,2,0,0,0,.47-.44v.44h.82l-.21-5.81h-.69Zm-.19.76a1.76,1.76,0,0,1,0,1.79.67.67,0,0,1-.63.33.78.78,0,0,1-.61-.38,1.52,1.52,0,0,1-.21-.91,1.58,1.58,0,0,1,.26-.87.67.67,0,0,1,.59-.31.78.78,0,0,1,.59.35"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[18] = ref)} id="Path_8657" data-name="Path 8675"
                                      d="M52.48,85.5a1.38,1.38,0,0,0-.48-.35.83.83,0,0,0-.49-.07,1.15,1.15,0,0,0-.93.67,3,3,0,0,0-.31,1.62,2.45,2.45,0,0,0,.55,1.53,1.3,1.3,0,0,0,1.14.56,1.2,1.2,0,0,0,.51-.21,1.61,1.61,0,0,0,.43-.5l.05.44.82-.12-.63-4.14-.73.11Zm-.1.77a1.57,1.57,0,0,1,.34.84,1.42,1.42,0,0,1-.13.93.81.81,0,0,1-.58.45.73.73,0,0,1-.65-.3,1.63,1.63,0,0,1-.32-.88,1.6,1.6,0,0,1,.16-.89.75.75,0,0,1,.55-.4.68.68,0,0,1,.63.24"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[19] = ref)} id="Path_8657" data-name="Path 8676"
                                      d="M54,84.8h-.37l.15.91.38-.07.58,3.24.82-.17L54.9,85.5l.43-.12-.2-.87-.41.1-.31-1.51-.68.14Z"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[20] = ref)} id="Path_8657" data-name="Path 8677"
                                      d="M55.07,83a.94.94,0,0,0,0,.5.82.82,0,0,0,.23.44.39.39,0,0,0,.37.11.41.41,0,0,0,.26-.28.82.82,0,0,0,0-.49.83.83,0,0,0-.24-.44.36.36,0,0,0-.35-.11.39.39,0,0,0-.26.27m1.26,5.57.8-.21-1-4.06-.7.19Z"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[21] = ref)} id="Path_8657" data-name="Path 8678"
                                      d="M57.2,84.45a2.64,2.64,0,0,0-.06,1.71A2.45,2.45,0,0,0,58,87.53a1.31,1.31,0,0,0,1.33.21,1.46,1.46,0,0,0,.73-.59,1.89,1.89,0,0,0,.31-1,2.65,2.65,0,0,0-.19-1.11,2.85,2.85,0,0,0-.58-.94,2.05,2.05,0,0,0-.82-.55,1.16,1.16,0,0,0-.82,0,1.46,1.46,0,0,0-.79.88m1.77.16a1.71,1.71,0,0,1,.57,1.66.78.78,0,0,1-.47.54.66.66,0,0,1-.69-.1,1.41,1.41,0,0,1-.49-.75,1.52,1.52,0,0,1,0-.92.7.7,0,0,1,.44-.53.64.64,0,0,1,.66.11"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[22] = ref)} id="Path_8657" data-name="Path 8679"
                                      d="M61.6,86.77l.74-.38-.66-1.53c-.17-.4-.28-.66-.32-.79a1.73,1.73,0,0,1,0-.64.54.54,0,0,1,.27-.37.4.4,0,0,1,.32,0,.82.82,0,0,1,.32.26,7.229,7.229,0,0,1,.46.87l.79,1.64.74-.37L63,83a1.89,1.89,0,0,0-.72-.84.86.86,0,0,0-.85-.06.85.85,0,0,0-.33.29,2.42,2.42,0,0,0-.23.58l-.18-.38L60,82.9Z"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[23] = ref)} id="Path_8657" data-name="Path 8680"
                                      d="M64.8,80.75a2.69,2.69,0,0,0,.4,1.66,2.33,2.33,0,0,0,1.22,1,1.28,1.28,0,0,0,1.34-.16,1.51,1.51,0,0,0,.54-.79,2,2,0,0,0,0-1,2.7,2.7,0,0,0-.46-1,3,3,0,0,0-.82-.73,2,2,0,0,0-.93-.29,1.2,1.2,0,0,0-.79.25,1.55,1.55,0,0,0-.53,1.06m1.75-.36a1.44,1.44,0,0,1,.68.56,1.37,1.37,0,0,1,.3.87.79.79,0,0,1-.3.67.67.67,0,0,1-.69.1,1.39,1.39,0,0,1-.68-.61,1.52,1.52,0,0,1-.26-.85.82.82,0,0,1,.29-.64.66.66,0,0,1,.66-.1"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[24] = ref)} id="Path_8657" data-name="Path 8681"
                                      d="M67.36,79.36l.21-.17,2,2.57.63-.55-2-2.52.36-.33L68,77.69l-.35.32-.32-.4a.54.54,0,0,1-.13-.22.27.27,0,0,1,.1-.18l.22-.13-.51-.6a1.07,1.07,0,0,0-.45.23.69.69,0,0,0-.22.47,1,1,0,0,0,.18.54c.05.09.25.34.58.77l-.21.16Z"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[25] = ref)} id="Path_8657" data-name="Path 8682"
                                      d="M73.13,78.25a1.72,1.72,0,0,0,.43-.78,1.45,1.45,0,0,0-.1-.77,2,2,0,0,0-1.26-1.12,1.49,1.49,0,0,0-.81,0,1.38,1.38,0,0,0-.15-.57,1.33,1.33,0,0,0-.36-.45,1.68,1.68,0,0,0-.76-.42,1.09,1.09,0,0,0-.73.09,2.51,2.51,0,0,0-.57.51l-.4.46,3.86,4Zm-3.38-3a.54.54,0,0,1,.37-.19.63.63,0,0,1,.38.19.65.65,0,0,1,.23.41.56.56,0,0,1-.16.43l-.11.13-.84-.82Zm1.49,1.51a.93.93,0,0,1,.6-.38.81.81,0,0,1,.55.23.72.72,0,0,1,.24.47,1.16,1.16,0,0,1-.35.64l-.13.16-1-1Z"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[26] = ref)} id="Path_8657" data-name="Path 8683"
                                      d="M73.13,72.33a1.61,1.61,0,0,0-.58.1,1.2,1.2,0,0,0-.41.33A1.29,1.29,0,0,0,72,74a2.77,2.77,0,0,0,1,1.29,2.21,2.21,0,0,0,1.44.61,1.31,1.31,0,0,0,1.14-.53,1.39,1.39,0,0,0,.21-.52,1.54,1.54,0,0,0,0-.64l.34.25.48-.71-3.37-2.32-.4.64Zm.46.59a1.51,1.51,0,0,1,.85.3A1.41,1.41,0,0,1,75,74a.77.77,0,0,1-.1.72.71.71,0,0,1-.64.3,1.24,1.24,0,0,1-.82-.34,1.51,1.51,0,0,1-.54-.73.74.74,0,0,1,.08-.69.69.69,0,0,1,.62-.29"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[27] = ref)} id="Path_8657" data-name="Path 8684"
                                      d="M77,73l.42-.73L76,71.41a6.24,6.24,0,0,1-.68-.46,1.39,1.39,0,0,1-.37-.5A.47.47,0,0,1,75,70a.39.39,0,0,1,.25-.2.67.67,0,0,1,.4,0,5,5,0,0,1,.87.43l1.55.87.35-.77L76.05,69.1a1.7,1.7,0,0,0-1-.26,1,1,0,0,0-.7.48.68.68,0,0,0-.11.42,1.41,1.41,0,0,0,.11.61L74,70.14l-.39.64Z"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[28] = ref)} id="Path_8657" data-name="Path 8685"
                                      d="M76.42,65.92a1.24,1.24,0,0,0-.55.27,1.17,1.17,0,0,0-.3.4,1.22,1.22,0,0,0,.08,1.1,2.51,2.51,0,0,0,1.2,1.1,2.19,2.19,0,0,0,1.52.26,1.39,1.39,0,0,0,1-.8,1.13,1.13,0,0,0,.09-.57,1.64,1.64,0,0,0-.2-.6,2.11,2.11,0,0,1,.62.35.88.88,0,0,1,.2.41,1,1,0,0,1-.08.58,1.09,1.09,0,0,1-.27.4.91.91,0,0,1-.38.19l-.4.86a1.86,1.86,0,0,0,.78,0,1.44,1.44,0,0,0,.64-.35,2.06,2.06,0,0,0,.5-.74,1.74,1.74,0,0,0-.1-1.63,2.62,2.62,0,0,0-1.3-.87L76.25,65l-.25.71Zm.59.45a1.32,1.32,0,0,1,.83.08,1.28,1.28,0,0,1,.68.55.76.76,0,0,1,.08.73.74.74,0,0,1-.55.46,1.31,1.31,0,0,1-.87-.12,1.25,1.25,0,0,1-.62-.56.71.71,0,0,1,0-.69.66.66,0,0,1,.49-.45"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[29] = ref)} id="Path_8657" data-name="Path 8686"
                                      d="M80.28,65.75l.28-.82L75.25,63.2l-.25.68Z"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[30] = ref)} id="Path_8657" data-name="Path 8687"
                                      d="M77.85,61a1.41,1.41,0,0,0-.5.33,1.28,1.28,0,0,0-.26.47,1.21,1.21,0,0,0,.34,1.13,2.7,2.7,0,0,0,1.36.86,2.23,2.23,0,0,0,1.57,0,1.33,1.33,0,0,0,.84-.94,1.1,1.1,0,0,0,0-.57,1.73,1.73,0,0,0-.28-.58l.42.1.14-.86-4-.75-.12.74Zm.65.36a1.36,1.36,0,0,1,.89-.06,1.38,1.38,0,0,1,.79.43.8.8,0,0,1-.09,1.12,1,1,0,0,1-.23.13A1.33,1.33,0,0,1,79,63a1.47,1.47,0,0,1-.77-.47.76.76,0,0,1-.18-.66.75.75,0,0,1,.47-.52"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[31] = ref)} id="Path_8657" data-name="Path 8688"
                                      d="M78.37,57.18a1.24,1.24,0,0,0-.42.4,1.08,1.08,0,0,0-.21.49,1.19,1.19,0,0,0,.45,1.08,2.67,2.67,0,0,0,1.44.67,2.25,2.25,0,0,0,1.55-.23,1.37,1.37,0,0,0,.76-1,1,1,0,0,0,0-.56,1.85,1.85,0,0,0-.37-.54h.42v-.85l-5.57-.36V57Zm.7.26a1.36,1.36,0,0,1,.86-.16,1.38,1.38,0,0,1,.85.33.71.71,0,0,1,.27.68.83.83,0,0,1-.42.6,1.38,1.38,0,0,1-.89.13,1.4,1.4,0,0,1-.8-.36A.66.66,0,0,1,78.7,58a.8.8,0,0,1,.38-.59"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[32] = ref)} id="Path_8657" data-name="Path 8689"
                                      d="M80.05,52.45a2.63,2.63,0,0,0-1.57.55,1.34,1.34,0,0,0-.54,1.18,1.49,1.49,0,0,0,.58,1.14,2.57,2.57,0,0,0,1.5.52,2.22,2.22,0,0,0,1.52-.44,1.49,1.49,0,0,0,.62-1.3,1.78,1.78,0,0,0-.26-.9,1.87,1.87,0,0,0-.7-.61l-.37.73a1,1,0,0,1,.45.79.74.74,0,0,1-.25.64,1.14,1.14,0,0,1-.7.3,22.769,22.769,0,0,0-.09-2.61ZM79.54,55a1.16,1.16,0,0,1-.5-.29,1,1,0,0,1-.25-.57.75.75,0,0,1,.18-.53,1,1,0,0,1,.52-.33A11,11,0,0,1,79.54,55"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[33] = ref)}id="Path_8657" data-name="Path 8690"
                                      d="M77.9,50.55a1,1,0,0,0-.14.57.84.84,0,0,0,.38.68A1.2,1.2,0,0,0,79,52a1.46,1.46,0,0,0,1.19-.89l.15-.29c.13-.26.28-.4.43-.41a.29.29,0,0,1,.23.07.41.41,0,0,1,.11.25.86.86,0,0,1-.1.38l-.32.37.71.46a1.4,1.4,0,0,0,.6-1.25,1,1,0,0,0-.44-.84,1.2,1.2,0,0,0-1-.2,1.24,1.24,0,0,0-.66.27,2.23,2.23,0,0,0-.5.71l-.17.32a.83.83,0,0,1-.17.26l-.14.06-.19-.06-.08-.2a.86.86,0,0,1,.34-.58l-.7-.39a1.34,1.34,0,0,0-.41.51"
                                      fill="#ffffff"/>
                                <path ref={(ref) => (pathsRefs.current[34] = ref)} id="Path_8657" data-name="Path 8691"
                                      d="M81.59,48.81,81.44,48l-1.58.31a4.661,4.661,0,0,1-.82.13,1.09,1.09,0,0,1-.59-.13.5.5,0,0,1-.27-.35.42.42,0,0,1,.06-.33.75.75,0,0,1,.33-.26,7.3,7.3,0,0,1,.91-.27l1.72-.41-.15-.84-2.57.69a1.89,1.89,0,0,0-1,.53,1,1,0,0,0-.27.84.8.8,0,0,0,.21.42,1.46,1.46,0,0,0,.45.37L76,49l.14.7Z"
                                      fill="#ffffff"/>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default SVGAnimation;
