import {configureStore} from "@reduxjs/toolkit";
import postReducer from '../redux/post'
import globalReducer from '../redux/global'
import aboutReducer from '../redux/about'
import academicReducer from '../redux/academicactivities'
import activitiesReducer from '../redux/activities'
import affiliatedReducer from '../redux/affiliated-body'
import careerReducer from '../redux/career'
import ccdReducer from '../redux/ccd'
import childHeartReducer from '../redux/childheartdetails'
import clinicalServiceReducer from '../redux/clinicalservices'
import conferenceReducer from '../redux/conference'
import contactReducer from '../redux/contact'
import controlPreventionReducer from '../redux/controlprevention'
import departmentReducer from '../redux/department'
import doctorsReducer from '../redux/doctors'
import executiveCouncilReducer from '../redux/executive-council'
import founderReducer from '../redux/founder'
import homeReducer from '../redux/home'
import hypertensionProgramReducer from '../redux/hypertensionprogram'
import newsEventsReducer from '../redux/newsevents'
import onlineDetailsReducer from '../redux/onlinedetails'
import publicationsReducer from '../redux/publications'
import researchReducer from '../redux/research'
import tobaccoProgramReducer from '../redux/tobaccoprogram'
import preventionvitaminReducer from '../redux/preventionofvitamin'
import roadsafetyReducer from '../redux/roadsafety'
import SaltreductionReducer from '../redux/saltintactreduction'
import transfatProgramReducer from '../redux/transfatprogram'
import hospitalratesReducer from '../redux/hospitalrates'
import trusteesReducer from '../redux/board-of-trustees'
import ufatReducer from '../redux/ufat'
import booksReducer from '../redux/books'
import appointReducer from '../redux/appoinment'
import founderbooksReducer from '../redux/founderbooks'
import founderpublicationsReducer from '../redux/founderpublications'

const store = configureStore({
    reducer: {
        postReducer,
        globalReducer,
        aboutReducer,
        academicReducer,
        activitiesReducer,
        affiliatedReducer,
        careerReducer,
        ccdReducer,
        childHeartReducer,
        clinicalServiceReducer,
        conferenceReducer,
        contactReducer,
        controlPreventionReducer,
        departmentReducer,
        doctorsReducer,
        executiveCouncilReducer,
        founderReducer,
        homeReducer,
        hypertensionProgramReducer,
        newsEventsReducer,
        onlineDetailsReducer,
        publicationsReducer,
        booksReducer,
        researchReducer,
        tobaccoProgramReducer,
        transfatProgramReducer,
        ufatReducer,
        preventionvitaminReducer,
        roadsafetyReducer,
        SaltreductionReducer,
        appointReducer,
        hospitalratesReducer,
        trusteesReducer,
        founderbooksReducer,
        founderpublicationsReducer,
    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

export default store

