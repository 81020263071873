import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({overviewData,affiliatedData}) => {

    const [offset, setOffset] = useState(90)
    const [getHeight, setHeight] = useState()
    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft + 15)
        window.addEventListener('resize', () => {
            setOffset(document.querySelector('.container').offsetLeft + 15)
        })

        // background set
        setHeight((document.querySelector('.affiliated-body__bottom__content').clientHeight / 2) + 200)
        window.addEventListener('resize', () => {
            setHeight((document.querySelector('.affiliated-body__bottom__content').clientHeight / 2) + 200)
        })
    }, [])

    return (
        <StyledComponent getHeight={getHeight} className={'affiliated-body pt-150'}>
            <Container>
                <Row>
                    <Col sm={10} className={'affiliated-body__top'}>
                        <h6 className={'split-up'}>{overviewData?.section_data?.subtitle}</h6>
                        <h4 className={'split-up'}>{overviewData?.section_data?.short_desc}</h4>
                        <p className={'split-up'}>{overviewData?.section_data?.description}</p>

                    </Col>
                </Row>
            </Container>
            <Container fluid className={'affiliated-body__bottom'} style={{paddingLeft: offset + 'px'}}>
                <div className="affiliated-body__bottom__content">
                    <h3>{affiliatedData?.section_data?.subtitle}</h3>
                    {reactHtmlParser(affiliatedData?.section_data?.description)}

                </div>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #E4ECF0;

  .affiliated-body__top {
    margin-bottom: 200px;

    h6 {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 20px;
      font-weight: 400;
    }

    h4 {
      font-size: 32px;
      line-height: 40px;
      font-weight: 500;
      margin-bottom: 60px;
    }

    p {
      font-size: 20px;
      line-height: 26px;
    }
  }

  .container-fluid {
    padding: 0;
  }

  .affiliated-body__bottom {
    position: relative;
    padding-bottom: 200px;

    &:after {
      content: '';
      position: absolute;
      height: ${p => p.getHeight}px;
      width: 100%;
      background-color: #E1E1E1;
      left: 0;
      bottom: 0;
    }

    &__content {
      background-color: #1E1E2E;
      padding: 120px 100px;
      position: relative;
      z-index: 2;

      h3 {
        font-size: 60px;
        line-height: 60px;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 40px;
      }

      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          width: 50%;
          padding-right: 30px;
          font-size: 20px;
          font-weight: 500;
          line-height: 26px;
          color: #ffffff;
          margin-bottom: 20px;
          display: flex;

          span {
            color: rgba(255, 255, 255, 0.5);
            width: 30px;
            display: inline-block;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .affiliated-body__bottom__content {
      padding: 80px 20px;
    }
  }

  @media (max-width: 767px) {
    .affiliated-body__top {
      margin-bottom: 100px;
    }


    .affiliated-body__bottom {
      padding-bottom: 100px;
      padding-left: 0!important;
    }

    .affiliated-body__bottom__content {
      h3 {
        font-size: 40px;
        line-height: 42px;
        margin-bottom: 30px;
      }

      ul li {
        width: 100%;
        padding-right: 0;

        span {
          min-width: 50px;
        }
      }
    }
  }


`;

export default React.memo(MyComponent);
