import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {Link, useLocation} from "react-router-dom";
import Select from "react-select";
import { Img } from "../Img";
import ButtonHover from "../ButtonHover";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({ data, cat }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialDepartment = queryParams.get('department');

    const [searchInput, setSearchInput] = useState('');
    const [department, setDepartment] = useState(initialDepartment);
    const [filteredData, setFilteredData] = useState([]);
    const [itemsToShow, setItemsToShow] = useState(8);
    const [isCompleted, setIsCompleted] = useState(false);

    useEffect(() => {
        if (data && Array.isArray(data.doctors)) {
            const filteredResults = data.doctors.filter(item => {
                const isDepartmentMatch = !department || item.data?.category_slug === department;
                const isSearchMatch = !searchInput || item.data?.title.toLowerCase().includes(searchInput.toLowerCase());
                return isDepartmentMatch && isSearchMatch;
            });
            setFilteredData(filteredResults);
            setItemsToShow(8);
        }
    }, [data, department, searchInput]);

    const handleInput = (e) => {
        setSearchInput(e.target.value);
    }

    const searchSubmit = (e) => {
        e.preventDefault();
        // Perform any action needed on submit
    }

    const handleLoadMore = () => {
        setItemsToShow(prevItemsToShow => prevItemsToShow + 8);
        if (itemsToShow + 8 >= filteredData.length) {
            setIsCompleted(true);
        } else {
            setIsCompleted(false);
        }
    };

    return (
        <StyledComponent id={'got-to-list'} className={'article-list pb-200'}>
            <Container>
                <Row className={'filter_wrap'}>
                    <Col md={{ offset: 9, span: 3 }}>
                        <div className="search">
                            <Form className={'form'} onSubmit={searchSubmit}>
                                <div className="form-group">
                                    <Form.Group className={'flex'} controlId="formBasicEmail">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                                            <g id="_12" data-name="12" transform="translate(-1.96 -1.96)">
                                                <path id="Path_8655" data-name="Path 8655" d="M18.709,17.505l-2.4-2.392a8.074,8.074,0,1,0-1.2,1.2l2.392,2.4a.852.852,0,1,0,1.2-1.2ZM3.69,10.051a6.361,6.361,0,1,1,6.361,6.361A6.361,6.361,0,0,1,3.69,10.051Z" transform="translate(0 0)" fill="#e72e4b"/>
                                            </g>
                                        </svg>
                                        <Form.Control type="text" value={searchInput} onChange={handleInput} placeholder="Search" name={'search'} className={'form-control-lg'} />
                                    </Form.Group>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>

                <Row className={'min-height'}>


                    {filteredData &&
                        filteredData?.slice(0, itemsToShow)?.map((i, index) => {
                            return (
                                <Col className={'profile-gap'} md={4} lg={3}>
                                    <Link to={`/doctors/:details?profile=${reactHtmlParser(i?.data?.slug)}`}>
                                        <div className={'profile-image'}>
                                            <Img src={i?.images?.list?.[0]?.full_path}/>
                                        </div>
                                        <div className={'profile-text'}>
                                            <h6 className={''}>{reactHtmlParser(i?.data?.title)}</h6>
                                            <p className={''}>{reactHtmlParser(i?.data?.link)}</p>
                                            <h5 className={''}>{reactHtmlParser(i?.data?.education)}</h5>
                                        </div>
                                    </Link>
                                </Col>
                            );
                        })}

                    {itemsToShow < filteredData?.length && (
                        <Col sm={12} className={'load_more'}>
                            <div onClick={handleLoadMore}>
                                <ButtonHover text={'Load More'} margin={'0 auto'} color={'#FFFFFF'} background={'#E8E8E8'}/>
                            </div>
                        </Col>
                    )}
                </Row>

            </Container>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  padding-top: 80px;
  overflow: hidden;
  background-color: #F6F9F8;

  .list-loadmore {
    display: flex;
    justify-content: center;
    display: none;
  }

  .filter_wrap {
    .filter {
      ul {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        row-gap: 30px;

        a {
          overflow: hidden;
          display: inline-block;
          border-radius: 50px;
          padding: 12px 36px;
          color: #1E1E2E;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          border: 1px solid #1E1E2E;
          margin-right: 30px;
          position: relative;
          cursor: pointer;
          transition: 0.7s all ease;

          &:after {
            position: absolute;
            background: #E72E4B;
            width: 100%;
            height: 100%;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: 0.2s all ease;
            transform: translateY(300px);;
            border-radius: 21px;
            z-index: 0;
          }

          span {
            position: relative;
            z-index: 1;
            transition: 0.2s all ease;

          }

          &:last-child {
            margin-right: 0;
          }

          &.active {
            background: black;
            color: white;
          }

          &:hover {
            color: white !important;
            transition: 0.2s all ease;

            span {
              color: white;

            }

            &:after {
              transform: scale(1);
            }
          }
        }
      }
    }
  }

  .load_more {
    margin-top: 20px;

    .dc-btn {
      display: flex;
      justify-content: center;

      a {

        margin: auto;
        background-color: #1E1E2E;

        &:hover {
          color: white !important;
        }
      }
    }
  }

  .search {
    .form-group {
      position: relative;

      svg {
        position: absolute;
        top: 12px;
        left: 20px;
        right: 12px;
        bottom: 12px;
      }

      .form-control {
        border: 1px solid #1E1E2E;
        background: transparent;
        border-radius: 21px;
        height: 40px;
        padding: 0 15px 0 45px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #1E1E2E;

        &::placeholder {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          color: #1E1E2E;
        }
      }
    }
  }
}

.min-height {
  padding-top: 80px;

  .profile-gap {
    padding-bottom: 80px;
  }
}


.profile-gap {

  .profile-image {
    position: relative;
    padding-top: calc(458 / 360 * 100%);

    .global-image {
      overflow: hidden;

      img {
        transform: scale(1.01);
        transition: 1.4s ease;
      }
    }

    &:hover {
      .global-image img {
        transform: scale(1.06) !important;
      }
    }
  }

  .profile-text {
    h6 {
      padding-top: 20px;
      color: #1E1E2E;
      transition: color 0.5s ease;
    }

    p {
      padding: 5px 0;
      color: rgba(0, 0, 0, 0.78);
      font-size: 16px;
      font-weight: 500;
    }

    h5 {
      font-size: 14px;
      color: #000000a8;
      line-height: 18px;
      font-weight: 300;
    }
  }

  &:hover {
    h6 {
      color: #E72E4B;
      transition: color 0.5s ease;
    }
  }
  @media(max-width: 767px){
    width: 50%!important;
  }
}


.filter-mobile {
  display: none;
}

.css-1wa3eu0-placeholder, .css-1uccc91-singleValue {
  color: white !important;
  font-size: 10px;
  font-weight: 600 !important;
}

.read-btn {

  position: relative;
}

@media (min-width: 992px) {
  .min-height {
    min-height: 490px;
  }
}

@media (max-width: 767px) {
  padding-top: 60px;
  .profile-gap:not(:last-child) {
    padding-bottom: 60px;
  }

  .min-height {
    padding-top: 40px;
  }

  .filter_wrap {
    flex-direction: column-reverse;

    .search {
      margin-bottom: 40px;
    }
  }

  .load_more {
    margin-top: 0;
  }

  .filter-mobile {
    display: block;
  }

  .filter {
    display: none;
  }

  .css-13cymwt-control, .css-t3ipsp-control {
    background-color: #1E1E2E;
    border-radius: 50px;

    .css-1jqq78o-placeholder {
      color: white;
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      margin: 10px 0 10px 30px;
    }

    .css-1fdsijx-ValueContainer {
      padding: unset;

      .css-1dimb5e-singleValue {
        margin-left: 20px;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: #FFF;
        opacity: 60%;
      }
    }

    .css-1u9des2-indicatorSeparator {
      display: none;
    }

    .css-1xc3v61-indicatorContainer {
      padding-right: 30px;
    }
  }

  .css-t3ipsp-control {
    border-color: unset !important;
    box-shadow: unset !important;
  }

  .css-qbdosj-Input {
    input {
      min-width: 0px !important;
      opacity: 0 !important;
    }
  }

  .css-15lsz6c-indicatorContainer {
    padding-right: 30px;
    color: hsl(0, 0%, 80%);
  }


  .css-yk16xz-control, .css-1pahdxg-control {
    border-radius: 18px !important;
    border-color: #989898;
    height: 40px;
    background-color: #E50019;
    cursor: pointer;
    padding-left: 15px;
  }

  .css-yk16xz-control .css-1wa3eu0-placeholder, .css-1pahdxg-control .css-1wa3eu0-placeholder, .css-1uccc91-singleValue {
    color: #ffffff !important;
    font-size: 12px !important;
    font-weight: bold;
    cursor: pointer;
  }

  .css-yk16xz-control .css-tlfecz-indicatorContainer, .css-1pahdxg-control .css-tlfecz-indicatorContainer, .css-yk16xz-control .css-1gtu0rj-indicatorContainer, .css-1pahdxg-control .css-1gtu0rj-indicatorContainer {
    margin-right: 20px;
  }

`;
export default React.memo(MyComponent);