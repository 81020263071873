import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Modal from "react-bootstrap/Modal";
import {Col, Container, Row} from "react-bootstrap";
import 'simplebar-react/dist/simplebar.min.css';
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchHome, fetchInfo} from "../../api/redux/home";


const PopupV3 = ({
                     show,
                     onClose,
                     data,
                 }) => {

    const dispatch = useDispatch()
    const getData = useSelector(state => state.homeReducer)

    // api call
    useEffect(() => {

        let apiUrl = apiEndPoints.SECTIONS

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'home',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }


        dispatch(fetchHome([apiUrl, api_params]))

    }, [])
    const Notice = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'notice');

    const popupData = data;
    const [winWidth, setWinWidth] = useState(true)
    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])

    return (
        <>
            {Notice?.images?.list?.[0] &&
                <StyledModal>
                    <Modal
                        onHide={onClose}
                        backdrop="static"
                        keyboard={false}
                        show={show}
                        className="notice_modal"
                    >
                        <div onClick={onClose} className="modal-close hover">
                            <svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="11.414"
                                 viewBox="0 0 11.414 11.414">
                                <g id="Group_15651" data-name="Group 15651" transform="translate(-13907.793 -19.793)">
                                    <line id="Line_3833" data-name="Line 3833" x2="10" y2="10"
                                          transform="translate(13908.5 20.5)" fill="none" stroke="#4f616b"
                                          stroke-linecap="round" stroke-width="1"/>
                                    <line id="Line_3834" data-name="Line 3834" x1="10" y2="10"
                                          transform="translate(13908.5 20.5)" fill="none" stroke="#4f616b"
                                          stroke-linecap="round" stroke-width="1"/>
                                </g>
                            </svg>
                        </div>
                        <Container>
                            <Modal.Body>
                                <Row>
                                    <Col md={12}>
                                        <div className={'notice_image'}>
                                            <div className="bod_img">
                                                <img src={Notice?.images?.list?.[0]?.full_path}/>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Modal.Body>
                        </Container>
                    </Modal>
                </StyledModal>
            }
        </>
    )
};


const StyledModal = styled.div`

  .modal-dialog {
    margin: 0;
  }

  h3 {
    color: #180E81;
  }


`;


export default PopupV3;
