import React, {useLayoutEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";
import SimpleBar from "simplebar-react";
import Modal from "react-bootstrap/Modal";
import ModalVideo from "react-modal-video";
import {gsap} from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
import ButtonLong from "../ButtonLong";
import ButtonLongDownload from "../ButtonLongDownload";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import {useLocation} from "react-router-dom";


const ClinicalServicesDetailsPage = ({data}) => {


    // API filter
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialCategory = queryParams.get('category');

    const [category, setCategory] = useState(initialCategory);


    const filteredData = data?.filter((item) => item.page_data?.slug === category);


    const bodyData = filteredData?.[0]?.sections;


    let [open, setOpen] = useState(false);

    let handelOpen = (open, id) => {
        setOpen(open);
        setShow(true)
    };

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }

    const handleCloseModal = () => {
        setOpen(false);
        handleClose();
    };

    // sticky social icon
    gsap.registerPlugin(ScrollTrigger);

    useLayoutEffect(() => {
        const col4Element = document.querySelector('.detail-page');

        if (col4Element && window.innerWidth > 768) {
            gsap.to(col4Element, {
                scrollTrigger: {
                    trigger: col4Element,
                    start: 'top +100',
                    end: 'bottom +250',
                    pin: true,
                    pinSpacing: false,
                    pinType: 'transform',

                    // ease: "elastic",

                    anticipatePin: 1,
                    immediateRender: false,

                    // invalidateOnRefresh: true,
                    toggleActions: "play none none reverse",
                    // pinReparent: true,
                    // end: '-=800',

                },
                height: "unset",
            });
        }
    }, []);

    return (
        <StyledComponent className='portfolio-details pt-200 pb-200'>
            <Container>
                <Row>
                    <Col md={3} className={'detail-page'}>
                        <div className={'detail-page__left'}>
                            <p className={'detail-page__left__text'}>Share:</p>
                            <div className={'social__icon'}>
                                <div className={'social__icon__icons'}>
                                    <div className={'social__icon__icons__facebook'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                             viewBox="0 0 25 25">
                                            <g id="Group_20865" data-name="Group 20865"
                                               transform="translate(-1063 -659)">
                                                <g id="Path_8656" data-name="Path 8656" transform="translate(1063 659)"
                                                   fill="#000" opacity="1">
                                                    <path
                                                        d="M12.5,0A12.5,12.5,0,0,1,25,12.5c0,2.755-2.587,7.616-2.587,7.616S16.649,25,12.5,25a12.5,12.5,0,0,1,0-25Z"
                                                        stroke="none"/>
                                                    <path
                                                        d="M 12.5 1 C 9.428239822387695 1 6.54033088684082 2.196210861206055 4.368270874023438 4.368270874023438 C 2.196210861206055 6.54033088684082 1 9.428239822387695 1 12.5 C 1 15.5717601776123 2.196210861206055 18.45967102050781 4.368270874023438 20.63172912597656 C 6.54033088684082 22.80379104614258 9.428239822387695 24 12.5 24 C 14.00426959991455 24 16.06558036804199 23.21265983581543 18.4610710144043 21.72310066223145 C 19.99300384521484 20.7705078125 21.21415519714355 19.80403900146484 21.62111854553223 19.4726448059082 C 22.36193656921387 18.0429630279541 24 14.46980571746826 24 12.5 C 24 9.428239822387695 22.80379104614258 6.54033088684082 20.63172912597656 4.368270874023438 C 18.45967102050781 2.196210861206055 15.5717601776123 1 12.5 1 M 12.5 0 C 19.40356063842773 0 25 5.596439361572266 25 12.5 C 25 15.25452041625977 22.41300010681152 20.11565017700195 22.41300010681152 20.11565017700195 C 22.41300010681152 20.11565017700195 16.64904022216797 25 12.5 25 C 5.596439361572266 25 0 19.40356063842773 0 12.5 C 0 5.596439361572266 5.596439361572266 0 12.5 0 Z"
                                                        stroke="none" fill="#000"/>
                                                </g>
                                                <g id="Path_8657" data-name="Path 8656" transform="translate(1063 659)"
                                                   fill="none" opacity="1">
                                                    <path
                                                        d="M12.5,0A12.5,12.5,0,0,1,25,12.5c0,2.755-2.587,7.616-2.587,7.616S16.649,25,12.5,25a12.5,12.5,0,0,1,0-25Z"
                                                        stroke="none"/>
                                                    <path
                                                        d="M 12.5 1 C 9.428239822387695 1 6.54033088684082 2.196210861206055 4.368270874023438 4.368270874023438 C 2.196210861206055 6.54033088684082 1 9.428239822387695 1 12.5 C 1 15.5717601776123 2.196210861206055 18.45967102050781 4.368270874023438 20.63172912597656 C 6.54033088684082 22.80379104614258 9.428239822387695 24 12.5 24 C 14.00426959991455 24 16.06558036804199 23.21265983581543 18.4610710144043 21.72310066223145 C 19.99300384521484 20.7705078125 21.21415519714355 19.80403900146484 21.62111854553223 19.4726448059082 C 22.36193656921387 18.0429630279541 24 14.46980571746826 24 12.5 C 24 9.428239822387695 22.80379104614258 6.54033088684082 20.63172912597656 4.368270874023438 C 18.45967102050781 2.196210861206055 15.5717601776123 1 12.5 1 M 12.5 0 C 19.40356063842773 0 25 5.596439361572266 25 12.5 C 25 15.25452041625977 22.41300010681152 20.11565017700195 22.41300010681152 20.11565017700195 C 22.41300010681152 20.11565017700195 16.64904022216797 25 12.5 25 C 5.596439361572266 25 0 19.40356063842773 0 12.5 C 0 5.596439361572266 5.596439361572266 0 12.5 0 Z"
                                                        stroke="none" fill="#fff" opacity="0"/>
                                                </g>
                                                <path id="Path_26" data-name="Path 26"
                                                      d="M1012.361,3918.425v-.9a1.965,1.965,0,0,1,.019-.315.612.612,0,0,1,.086-.219.351.351,0,0,1,.216-.149,1.551,1.551,0,0,1,.4-.041h.9V3915h-1.441a2.344,2.344,0,0,0-1.8.594,2.5,2.5,0,0,0-.546,1.75v1.086h-1.08v1.8h1.079v5.233h2.166v-5.233h1.442l.19-1.8Z"
                                                      transform="translate(63.884 -3248.731)" fill="#fff"/>
                                            </g>
                                        </svg>


                                    </div>
                                </div>

                                <div className={'social__icon__icons'}>
                                    <div className={'social__icon__icons__twitter'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                             viewBox="0 0 25 25">
                                            <g id="Group_20866" data-name="Group 20866"
                                               transform="translate(-1108 -659)">
                                                <g id="Rectangle_1998" data-name="Rectangle 1998"
                                                   transform="translate(1108 659)" fill="#000" stroke="#000"
                                                   stroke-width="1" opacity="1">
                                                    <rect width="25" height="25" rx="12.5" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="24" height="24" rx="12" fill="none"/>
                                                </g>
                                                <g id="Rectangle_1999" data-name="Rectangle 1998"
                                                   transform="translate(1108 659)" fill="#000" stroke="#000"
                                                   stroke-width="1" opacity="1">
                                                    <rect width="25" height="25" rx="12.5" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="24" height="24" rx="12" fill="none"
                                                          opacity="0"/>
                                                </g>
                                                <path id="Path_5915" data-name="Path 5915"
                                                      d="M453.489,20.125a6.955,6.955,0,0,0,7-7q0-.16-.007-.318a5.011,5.011,0,0,0,1.228-1.274,4.912,4.912,0,0,1-1.413.387,2.47,2.47,0,0,0,1.082-1.362,4.933,4.933,0,0,1-1.563.6,2.464,2.464,0,0,0-4.195,2.245,6.988,6.988,0,0,1-5.073-2.571,2.463,2.463,0,0,0,.762,3.286A2.444,2.444,0,0,1,450.2,13.8c0,.01,0,.021,0,.031a2.462,2.462,0,0,0,1.975,2.413,2.459,2.459,0,0,1-1.111.042,2.464,2.464,0,0,0,2.3,1.71,4.938,4.938,0,0,1-3.057,1.054,5,5,0,0,1-.587-.034,6.968,6.968,0,0,0,3.773,1.106"
                                                      transform="translate(665.284 656.625)" fill="#fff"/>
                                            </g>
                                        </svg>

                                    </div>
                                </div>

                                <div className={'social__icon__icons'}>
                                    <div className={'social__icon__icons__insta'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                             viewBox="0 0 25 25">
                                            <g id="Group_20867" data-name="Group 20867"
                                               transform="translate(-1153 -659)">
                                                <g id="Rectangle_1998" data-name="Rectangle 1998"
                                                   transform="translate(1153 659)" fill="#000" stroke="#000"
                                                   stroke-width="1" opacity="1">
                                                    <rect width="25" height="25" rx="12.5" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="24" height="24" rx="12" fill="none"/>
                                                </g>
                                                <g id="Rectangle_19999" data-name="Rectangle 1998"
                                                   transform="translate(1153 659)" fill="none" stroke="#000"
                                                   stroke-width="1" opacity="1">
                                                    <rect width="25" height="25" rx="12.5" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="24" height="24" rx="12" fill="none"
                                                          opacity="0"/>
                                                </g>
                                                <g id="Group_13724" data-name="Group 13724"
                                                   transform="translate(-1007 -8363)">
                                                    <path id="Path_5912" data-name="Path 5912"
                                                          d="M5.593,1.008c1.493,0,1.67.006,2.26.033a3.093,3.093,0,0,1,1.038.193,1.733,1.733,0,0,1,.643.418,1.733,1.733,0,0,1,.418.643,3.1,3.1,0,0,1,.193,1.038c.027.59.033.767.033,2.26s-.006,1.67-.033,2.26a3.1,3.1,0,0,1-.193,1.038A1.852,1.852,0,0,1,8.891,9.953a3.093,3.093,0,0,1-1.038.193c-.59.027-.767.033-2.26.033s-1.67-.006-2.26-.033a3.093,3.093,0,0,1-1.038-.193,1.733,1.733,0,0,1-.643-.418,1.733,1.733,0,0,1-.418-.643A3.093,3.093,0,0,1,1.04,7.853c-.027-.59-.033-.767-.033-2.26s.006-1.67.033-2.26a3.093,3.093,0,0,1,.193-1.038,1.733,1.733,0,0,1,.418-.643,1.733,1.733,0,0,1,.643-.418A3.093,3.093,0,0,1,3.333,1.04c.59-.027.767-.033,2.26-.033M5.593,0C4.074,0,3.883.006,3.287.034a4.106,4.106,0,0,0-1.358.26A2.741,2.741,0,0,0,.939.939a2.741,2.741,0,0,0-.645.991,4.106,4.106,0,0,0-.26,1.358C.006,3.883,0,4.074,0,5.593S.006,7.3.034,7.9a4.106,4.106,0,0,0,.26,1.358,2.741,2.741,0,0,0,.645.991,2.743,2.743,0,0,0,.991.645,4.106,4.106,0,0,0,1.358.26c.6.027.787.034,2.306.034S7.3,11.179,7.9,11.152a4.106,4.106,0,0,0,1.358-.26,2.86,2.86,0,0,0,1.636-1.636,4.106,4.106,0,0,0,.26-1.358c.027-.6.034-.787.034-2.306s-.006-1.709-.034-2.306a4.106,4.106,0,0,0-.26-1.358,2.743,2.743,0,0,0-.645-.991A2.741,2.741,0,0,0,9.256.294,4.106,4.106,0,0,0,7.9.034C7.3.006,7.112,0,5.593,0"
                                                          transform="translate(2167 9029)" fill="#fff"/>
                                                    <path id="Path_5913" data-name="Path 5913"
                                                          d="M23.548,20.676a2.872,2.872,0,1,0,2.872,2.872,2.872,2.872,0,0,0-2.872-2.872m0,4.736a1.864,1.864,0,1,1,1.864-1.864,1.864,1.864,0,0,1-1.864,1.864"
                                                          transform="translate(2149.045 9011.045)" fill="#fff"/>
                                                    <path id="Path_5914" data-name="Path 5914"
                                                          d="M61.429,15.384a.671.671,0,1,1-.671-.671.671.671,0,0,1,.671.671"
                                                          transform="translate(2114.82 9016.224)" fill="#fff"/>
                                                </g>
                                            </g>
                                        </svg>

                                    </div>
                                </div>

                                <div className={'social__icon__icons'}>
                                    <div className={'social__icon__icons__youtube linkedin'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                             viewBox="0 0 25 25">
                                            <g id="Group_20868" data-name="Group 20868"
                                               transform="translate(-1198 -659)">
                                                <g id="Rectangle_1998" data-name="Rectangle 1998"
                                                   transform="translate(1198 659)" fill="#000" stroke="#000"
                                                   stroke-width="1" opacity="1">
                                                    <rect width="25" height="25" rx="12.5" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="24" height="24" rx="12" fill="none"/>
                                                </g>
                                                <g id="Rectangle_19988" data-name="Rectangle 1998"
                                                   transform="translate(1198 659)" fill="none" stroke="#000"
                                                   stroke-width="1" opacity="1">
                                                    <rect width="25" height="25" rx="12.5" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="24" height="24" rx="12" fill="none"
                                                          opacity="0"/>
                                                </g>
                                                <g id="Group_28" data-name="Group 28"
                                                   transform="translate(1205.801 666.756)">
                                                    <path id="LinkedIn"
                                                          d="M1096.5,3921.554v3.313h-1.942v-3.091c0-.776-.281-1.306-.984-1.306a1.061,1.061,0,0,0-1,.7,1.3,1.3,0,0,0-.065.469v3.226h-1.943s.026-5.234,0-5.777h1.943v.82l-.013.018h.013v-.018a1.932,1.932,0,0,1,1.751-.955C1095.539,3918.955,1096.5,3919.781,1096.5,3921.554Zm-7.964-5.249a1,1,0,1,0-.026,2h.013a1,1,0,1,0,.013-2Zm-.984,8.561h1.943v-5.777h-1.943Z"
                                                          transform="translate(-1087.435 -3916.306)" fill="#fff"/>
                                                </g>
                                            </g>
                                        </svg>

                                    </div>
                                </div>

                                <div className={'social__icon__icons'}>
                                    <div className={'social__icon__icons__youtube'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                             viewBox="0 0 25 25">
                                            <g id="Group_20869" data-name="Group 20869"
                                               transform="translate(-1243 -659)">
                                                <g id="Rectangle_1998" data-name="Rectangle 1998"
                                                   transform="translate(1243 659)" fill="#000" stroke="#000"
                                                   stroke-width="1" opacity="1">
                                                    <rect width="25" height="25" rx="12.5" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="24" height="24" rx="12" fill="none"/>
                                                </g>
                                                <g id="Rectangle_199888" data-name="Rectangle 1998"
                                                   transform="translate(1243 659)" fill="none" stroke="#000"
                                                   stroke-width="1" opacity="1">
                                                    <rect width="25" height="25" rx="12.5" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="24" height="24" rx="12" fill="none"
                                                          opacity="0"/>
                                                </g>
                                                <path id="Path_29" data-name="Path 29"
                                                      d="M1176.069,3919.422c-.36-.425-1.025-.6-2.294-.6h-4.606c-1.3,0-1.974.185-2.333.638a3.24,3.24,0,0,0-.349,1.993v1.717c0,1.745.415,2.631,2.682,2.631h4.606a2.913,2.913,0,0,0,2.1-.529,2.759,2.759,0,0,0,.577-2.1v-1.717A3.158,3.158,0,0,0,1176.069,3919.422Zm-3.181,3.128-2.092,1.087a.322.322,0,0,1-.47-.284v-2.168a.32.32,0,0,1,.154-.273.325.325,0,0,1,.315-.011l2.092,1.081a.319.319,0,0,1,0,.568Z"
                                                      transform="translate(84.027 -3251.113)" fill="#fff"/>
                                            </g>
                                        </svg>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col md={9} className={'left-content'}>
                        <Row className={'upper-text'}>
                            <Col md={12}>

                                {bodyData?.length > 0 && bodyData?.map(i => (

                                    <>
                                        {
                                            i?.section_data?.short_desc &&
                                            <h5>{reactHtmlParser(i?.section_data?.short_desc)}</h5>
                                        }


                                        {
                                            i?.section_data?.subtitle &&
                                            <h6 className={'opacity-50 split-up'}>{i?.section_data?.subtitle}</h6>
                                        }

                                        {
                                            i?.section_data?.description &&
                                            <p className={'split-up'}>{reactHtmlParser(i?.section_data?.description)}</p>
                                        }
                                    </>

                                ))}


                            </Col>


                        </Row>

                    </Col>

                </Row>
            </Container>

            <Modal
                show={show}
                // item={item}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                className="gph_modal modal_video_popup popup-version-one"
                dialogClassName="custom-modal-dialog"
            >
                <SimpleBar className="main_scroll ">
                    <Modal.Body>
                        <Container>
                            <Row>


                                <div className="modal-data d-flex">
                                    <ModalVideo channel='youtube' isOpen={open}
                                                videoId={'wT3cpXL7HiE'}
                                                onClose={handleCloseModal}/>

                                </div>
                            </Row>
                        </Container>
                    </Modal.Body>
                </SimpleBar>
            </Modal>
        </StyledComponent>


    );
};

const StyledComponent = styled.section`
  background: #F6F9F8;

  .left-content {
    padding-bottom: 60px;

    &:after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 15px;
      background: #323232;
    }
  }

  .img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  .upper-text {
    @media (max-width: 767px) {
      padding-top: 60px;
    }

    .mb-10 {
      margin-bottom: 10px !important;
    }

    p {
      margin-bottom: 60px;
    }

    h3 {
      margin-bottom: 20px;
    }

    .info {
      display: flex;
      margin-bottom: 40px;

      li {
        display: inline-block;
        position: relative;
        padding-right: 20px;
        margin-right: 20px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #1E1E2E;

        &:after {
          margin: 0;
          height: 100%;
          width: 1px;
          background: #707070;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          content: '';
        }

        &:nth-last-child(1) {
          margin-right: 0px;

          &:after {
            content: unset;
          }
        }
      }
    }

    .img-wrapper {
      position: relative;
      padding-top: calc(432 / 870 * 100%);
      margin-bottom: 60px;

      &.img-half {
        padding-top: calc(250 / 420 * 100%);
      }
    }

    .img-wrapper-text {
      margin-bottom: 60px;
    }

    .img-wrapper-two {
      position: relative;
      padding-top: calc(520 / 870 * 100%);
      margin-bottom: 20px !important;
    }

    h5 {
      color:rgb(152, 34, 60);
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      margin-bottom: 40px;
    }

    h6 {
      margin-bottom: 20px;
    }

    .opacity-50 {
      opacity: 50%;
    }

    .list {
      margin-bottom: 40px;

      li {
        position: relative;
        padding: 15px 0 15px 20px;
        font-size: 20px;
        line-height: 24px;
        font-weight: 300;
        color: rgba(50, 50, 50, 0.8);
        border-bottom: 1px solid rgb(50 50 50 / 10%);
        counter-increment: count;

        &:after {
          content: '';
          position: absolute;
          height: 10px;
          width: 10px;
          padding: 3.4px 0px 0px 9px;
          margin: 20px 0;
          font-size: 12px;
          line-height: 20px;
          font-weight: 500;
          border: none;
          background-color: #E50019;
          border-radius: 50%;
          //color: #070524;
          top: 0;
          left: 0;
        }

        &:nth-child(1) {
          padding-top: 0;

          &:after {
            margin-top: 5px;
          }
        }

        &:nth-last-child(1) {
          padding-bottom: 0;
          border-bottom: 0;

        }
      }
    }

    table {
      margin-bottom: 40px;

      tr {
        border-bottom: 1px solid rgba(50, 50, 50, 0.2);


        &:nth-child(1) {
          border-bottom: 1px solid rgba(50, 50, 50, 1);
        }

        &:nth-last-child(1) {
          border-bottom: 0;

          td {
            padding-bottom: 0;
          }
        }

        th {
          font-size: 20px;
          line-height: 26px;
          font-weight: 700;
          color: #1E1E2E;
          padding: 0 0 20px 0;
        }

        td {
          font-size: 20px;
          line-height: 26px;
          font-weight: 400;
          padding: 20px 0;
          color: #1E1E2E;
        }
      }
    }

    blockquote {
      font-size: 20px;
      line-height: 24px;
      font-weight: 300;
      margin-bottom: 20px;
      padding-left: 20px;
      color: #323232;
      border-left: 4px solid #E50019;
    }
  }

  @media (max-width: 767px) {
    .portfolio-details__social {
      display: flex;
      justify-content: space-between;

      ul {
        display: flex;
        gap: 20px;
      }
    }

    table {
      th {
        width: 50%;
        padding: 0 20px 20px 0 !important;
      }
    }
  }

  .video {
    .video-wrapper {
      position: relative;
      overflow: hidden;
      padding-top: calc(520 / 870 * 100%);
      margin: 0 0 60px 0;
      cursor: pointer;

      .video-icon {
        overflow: unset !important;
        position: absolute;
        z-index: 8;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: fit-content;
        height: fit-content;
        margin: auto;

        svg {
          overflow: hidden;
          border-radius: 50%;

          .video-hover {
            cx: -90px;
            opacity: 0;
            transition: all 0.8s cubic-bezier(0.76, 0, 0.24, 1);
          }

          path {
            transition: all 0.8s cubic-bezier(0.76, 0, 0.24, 1);
          }
        }
      }

      &:hover {
        .video-icon {
          svg {
            .video-hover {
              cx: 30px;
              opacity: 1;
              transition: all 0.8s cubic-bezier(0.76, 0, 0.24, 1);
            }

            path {
              fill: #fff;
              transition: all 0.8s cubic-bezier(0.76, 0, 0.24, 1);
            }
          }
        }
      }
    }
  }


  .detail-page__left__text {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 20px;
  }

  .social {
    width: 100%;
    position: absolute;
    bottom: 40px;

    &__icon {
      display: flex;
      padding-bottom: 40px;

      &__icons:not(:last-child) {
        padding-right: 20px;
      }

      &__icons {

        &__facebook {
          svg {
            cursor: pointer;
            border-radius: 50px;

            #Path_8657 {
              fill: #E72E4B;
              opacity: 1;
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              transform: translate(1063px, 690px) !important;
            }

            &:hover {
              #Path_8657 {
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                transform: translate(1063px, 659px) !important;
                //path{
                //  fill:yellow;
                //}
              }

              #Path_2115 {
                fill: white;
              }
            }
          }

        }

        &__twitter {
          svg {
            cursor: pointer;
            border-radius: 50px;

            #Rectangle_1999 {
              fill: #E72E4B;
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              transform: translate(1108px, 690px) !important;
            }

            &:hover {
              #Rectangle_1999 {
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                transform: translate(1108px, 659px) !important;
              }

              #Path_2113 {
                fill: white;
              }
            }
          }
        }

        &__insta {
          svg {
            cursor: pointer;
            border-radius: 50px;

            #Rectangle_19999 {
              fill: #E72E4B;
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              transform: translate(1153px, 690px) !important;
            }

            &:hover {
              #Rectangle_19999 {
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                transform: translate(1153px, 659px) !important;
              }

              #Path_2110, #Path_2109, #Path_2111 {
                fill: white;
              }
            }
          }
        }


        &__youtube {
          svg {
            cursor: pointer;
            border-radius: 50px;

            #Rectangle_19988 {
              fill: #E72E4B;
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              transform: translate(1198px, 690px) !important;
            }

            #Rectangle_199888 {
              fill: #E72E4B;
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              transform: translate(1243px, 690px) !important;
            }

            &:hover {
              #Rectangle_19988 {
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                transform: translate(1198px, 659px) !important;
              }

              #Rectangle_199888 {
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                transform: translate(1243px, 659px) !important;
              }

              #Path_2114 {
                fill: white;
              }
            }
          }
        }
      }
    }
  }
`;

export default React.memo(ClinicalServicesDetailsPage);
