import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import controlPreventionReducer, {fetchPosts,fetchChild} from "../../api/redux/controlprevention";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";

import ControlPreventionListing from "../../components/ControlPrevention/ControlPreventionListing";
import InnerBanner from "../../components/InnerBanner";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import reactHtmlParser from "react-html-parser";


const ControlpreventionIndex = () => {

    const dispath = useDispatch()
    const getData = useSelector(state=> state.controlPreventionReducer)

    // api call
    useEffect(() => {
        let apiUrl = apiEndPoints.CHILD_PAGE
        let apiUrlSection = apiEndPoints.SECTIONS

        let api_params = {
            [ApiParamKey.page_id]: 123,
            [ApiParamKey.sections]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }
        let api_params_sections = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'control--prevention',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }

        dispath(fetchChild([apiUrl, api_params]))
        dispath(fetchPosts([apiUrlSection, api_params_sections]))
    }, [])

    // filter data
    const BannerData = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'control-banner');
    const PreventionData = getData?.child?.data?.list;
    const page_data = getData?.posts?.data?.page_data;

    return (
        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.meta_title ? page_data?.meta_title + ' | ' : ''}`}National Heart
                    Foundation</title>

                <meta name="meta:title" content={page_data?.meta_title}/>
                <meta name="description" content={page_data?.meta_description}/>
                <meta property="og:title" content={page_data?.og_title}/>
                <meta property="og:subtitle" content={page_data?.og_title}/>
                <meta property="og:description" content={page_data?.og_description}/>
                <meta property="og:image" content={BannerData?.images?.list?.[0]?.full_path}/>
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

        <StyledSection>
            {
                BannerData &&
                <InnerBanner title={reactHtmlParser(BannerData?.section_data?.short_desc ? BannerData?.section_data?.short_desc : '')}
                             subtitle={reactHtmlParser(BannerData?.section_data?.description ? BannerData?.section_data?.description : '')}
                             breadcrumbs={reactHtmlParser(BannerData?.section_data?.subtitle ? BannerData?.section_data?.subtitle : '')} img={BannerData?.images?.list[0]?.full_path ? BannerData?.images?.list[0]?.full_path : ''}/>
            }
            {
                PreventionData &&
                <ControlPreventionListing data={PreventionData}/>
            }
        </StyledSection>
        </HelmetProvider>
    );
};

const StyledSection = styled.section`

`;

export default ControlpreventionIndex;
