import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";


const MyComponent = ({data}) => {

    // filter data
    const Quotes = data?.section_data;

    return (
        <StyledComponent className={'pb-200'}>
             <Container>
                 <Row>
                     <Col md={12} className={'big-title'}>
                         <h2 className={'split-up'}>{reactHtmlParser(Quotes?.description ? Quotes?.description : '')}</h2>
                     </Col>
                 </Row>
             </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F6F9F8;
  
  .big-title{
    h2{
      color: #1E1E2E;
    }
  }

`;

export default React.memo(MyComponent);
