import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import VisibilitySensor from "react-visibility-sensor";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {hover} from '../../styles/globalStyleVars'
import {Link} from "react-router-dom";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const MediaCenter = ({data}) => {


    // filter data
    const MajorData = data?.section_data;
    const MajorSlider = data?.posts?.list



    // SwiperCore.use([Autoplay]);
    let leftRef = useRef();
    let rightRef = useRef();
    let mLeftRef = useRef();
    let mRightRef = useRef();
    let containerRef = useRef();
    let sliderRef = useRef();
    const [current, setCurrent] = useState('')
    let [offset, setOffset] = useState(90)
    let [theWidth, SetTheWidth] = useState(0)
    let [activeNumber, setActiveNumber] = useState(1)
    let [totalNumber, setTotalNumber] = useState(5)


    useEffect(() => {
        setOffset(containerRef.current?.offsetLeft)
        window.addEventListener('resize', () => {
            setOffset(containerRef.current?.offsetLeft)
            SetTheWidth(window.innerWidth)
        })

        // slider number
        const getActiveItem = document.querySelector('.swiper-pagination-current')?.innerHTML
        const getTotalItem = document.querySelector('.swiper-pagination-total')?.innerHTML
        setActiveNumber(getActiveItem)
        setTotalNumber(getTotalItem)
    }, [null])


    useEffect(() => {
        const getTotalItem = 5
        setTotalNumber(getTotalItem)
    }, [data])

    let sliderNumber = () => {
        const getActiveItem = document.querySelector('.swiper-pagination-current').innerHTML
        // const getTotalItem = document.querySelector('.swiper-pagination-total').innerHTML
        setActiveNumber(getActiveItem)
        // setTotalNumber(getTotalItem)
    }


    return (
        <StyledBlog offset={offset} className='blog-slider pb-200'>
            <Container ref={containerRef}>
                <Row>
                    <Col sm={12} className="full-title">
                        <Col md={5} className="blog-title">
                            <h4 className={'split-up'}>{reactHtmlParser(MajorData?.subtitle ? MajorData?.subtitle : '')}</h4>
                        </Col>

                        <div className="blog-button">
                            <div className="slider-nav">
                                <ul>
                                    <li className=" slider_prev">
                                        <svg id="Button_-_Nav_-_Prev_Dark_" data-name="Button - Nav - Prev (Dark)" xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
                                            <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke="#1e1e2e" stroke-width="1" opacity="0.2">
                                                <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                            </g>
                                            <g id="Group_4824" data-name="Group 4824" transform="translate(16 15)">
                                                <g id="Group_4823" data-name="Group 4823">
                                                    <line id="Line_9" data-name="Line 9" x1="5" y2="5" fill="none" stroke="#1e1e2e" stroke-linecap="round" stroke-width="1"/>
                                                    <line id="Line_10" data-name="Line 10" x2="5" y2="5" transform="translate(0 5)" fill="none" stroke="#1e1e2e" stroke-linecap="round" stroke-width="1"/>
                                                </g>
                                                <line id="Line_11" data-name="Line 11" x1="10" transform="translate(0 5)" fill="none" stroke="#1e1e2e" stroke-linecap="round" stroke-width="1"/>
                                            </g>
                                            <g id="Rectangle_5661" data-name="Rectangle 5661" transform="translate(0 41) rotate(-90)" fill="none" stroke="#e72e4b" stroke-width="1" stroke-dasharray="0 130" opacity="0">
                                                <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                            </g>
                                        </svg>

                                    </li>
                                    <li className=" slider_next">
                                        <svg id="Button_-_Nav_-_Next_Dark_" data-name="Button - Nav - Next (Dark)" xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
                                            <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke="#1e1e2e" stroke-width="1" opacity="0.2">
                                                <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                            </g>
                                            <g id="Group_4824" data-name="Group 4824" transform="translate(-637.5 -1581.5)">
                                                <g id="Group_4823" data-name="Group 4823" transform="translate(11 4)">
                                                    <line id="Line_9" data-name="Line 9" x2="5" y2="5" transform="translate(647.5 1592.5)" fill="none" stroke="#1e1e2e" stroke-linecap="round" stroke-width="1"/>
                                                    <line id="Line_10" data-name="Line 10" x1="5" y2="5" transform="translate(647.5 1597.5)" fill="none" stroke="#1e1e2e" stroke-linecap="round" stroke-width="1"/>
                                                </g>
                                                <line id="Line_11" data-name="Line 11" x2="10" transform="translate(653.5 1601.5)" fill="none" stroke="#1e1e2e" stroke-linecap="round" stroke-width="1"/>
                                            </g>
                                            <g id="Rectangle_5661" data-name="Rectangle 5661" transform="translate(0 41) rotate(-90)" fill="none" stroke="#e72e4b" stroke-width="1" stroke-dasharray="0 130" opacity="0">
                                                <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                            </g>
                                        </svg>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <VisibilitySensor offset={{top: -150}}>
                {({isVisible}) =>
                    <div className={` fade-up blog-slider__slider-wrap`}>
                        <div className="blog-slider__slider-wrap__inner">
                            {MajorSlider?.length > 0 &&
                            <Swiper loop={true}
                                    spaceBetween={30}
                                    slidesPerView={3}
                                    allowSlideNext={true}
                                    allowSlidePrev={true}
                                    allowTouchMove={true}
                                    speed={900}
                                    pagination={{
                                        type: "fraction",
                                    }}
                                    navigation={{
                                        prevEl: '.slider_prev',
                                        nextEl: '.slider_next',
                                    }}
                                    modules={[Autoplay, Pagination, Navigation]}

                                // autoplay={{
                                //     delay: 2500,
                                //     disableOnInteraction: false,
                                // }}

                                    breakpoints={{
                                        300: {
                                            slidesPerView: 1,
                                            spaceBetween: 30,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                            spaceBetween: 30,
                                        },
                                        1024: {
                                            slidesPerView: 3,
                                            spaceBetween: 30,
                                        },
                                    }}
                                    onSlideChange={(s) => sliderNumber()}

                                // navigation={true} modules={[Navigation]}
                                    onSwiper={(swiper) => console.log(swiper)}

                                    ref={sliderRef}>

                                {MajorSlider?.length > 0 && MajorSlider?.map((i,index)=>(

                                <SwiperSlide key={index}>

                                    <div className="blog-single">
                                        <div className="blog-single__inner">
                                            <Link to={'/'}></Link>
                                            <Img src={i?.images[0]?.full_path} objectFit={'cover'} layout={'fill'}/>
                                            <div className="blog-single__inner__content">
                                                <div className="blog-single__inner__content__top">
                                                    <div className="upper">
                                                        <p className={'split-up'}>{i?.data?.description}</p>
                                                    </div>

                                                    <h2>{index+1}</h2>

                                                </div>

                                                <div className="blog-single__inner__content__bottom">
                                                    <h4 className={'split-up'}>{i?.data?.subtitle}</h4>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </SwiperSlide>
                                ))}

                            </Swiper>
                            }
                        </div>
                    </div>
                }
            </VisibilitySensor>
        </StyledBlog>
    );
};

const StyledBlog = styled.section`
  background-color: #E4ECF0;
  position: relative;
  &:after{
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(50% + 40px);
    position: absolute;
    background: #1E1E2E;
  }
  .sub-title {
    font-weight: 500!important;
    margin: 0 0 20px 0;
  }

  .full-title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0 80px 0;
  }

  .blog-title {
    padding-left: 0;

    h4 {
      color: #1E1E2E;
      font-size: 28px;
      line-height: 32px;
      font-weight: 500;
    }
  }

  .blog-single {
    .blog-single__inner {
      padding-top: calc(370 / 370 * 100%);
      position: relative;

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 3;
      }

      &__content {
        &:after {
          content: '';
          height: 0;
          width: 100%;
          background-color: #98223C;
          opacity: 75%;
          fill: #E50019;
          bottom: 0;
          left: 0;
          right: 0;
          position: absolute;
          transition: height .4s ease;
        }

        &__top {
          .upper {
            position: absolute;
            left: 30px;
            top: 40px;
            z-index: 2;
            right: 30px;
            margin: 0;
            transform: translateY(-30px);
            opacity: 0;
            transition: all .6s ease;
          }

          p {
            font-size: 20px;
            line-height: 24px;
            font-weight: 300;
            color: #FFFFFF;
            margin: 0 0 40px 0;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }

          .read-more {
            font-size: 20px;
            line-height: 24px;
            font-weight: 300;
            color: #FFFFFF;
            margin: 0;
            text-decoration: underline;
          }

          h2 {
            position: absolute;
            top: 40px;
            left: 40px;
            right: 40px;
            z-index: 2;
            font-size: 72px;
            font-weight: 400;
            line-height: 84px;
            color: #FFFFFF;
            margin: 0;
            transition: all .3s ease;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }
        }

        &__bottom {
          position: absolute;
          margin: 0;
          left: 40px;
          right: 40px;
          bottom: 40px;
          display: flex;
          justify-content: space-between;
          padding-top: 20px;
          z-index: 2;

          h4 {
            color: #1E1E2E;
            font-size: 28px!important;
            line-height: 32px!important;
            font-weight: 500;
            transition: color .3s ease;
            p {
              display: block;
              color: #FFFFFF;
            }
          }
          h4{
            color: #FFFFFF;
          }

          p {
            position: relative;
            top: 40px;
            left: 40px;
            font-size: 16px;
            color: #FFFFFF;
            text-align: right;
            line-height: 20px;
            transition: color .3s ease;

            span {
              display: block;
              color: #FFFFFF;
            }
          }
        }
      }

      &:hover {
        .blog-single__inner__content__top {
          h2 {
            opacity: 0;
            transform: translateY(-20px);
          }

          .upper {
            transform: none;
            opacity: 1;
          }
        }

        .blog-single__inner__content__bottom {
          border-color: #FFF;

          h4 {
            color: #ffffff;
          }

          p {
            color: #ffffff;

            span {
              color: #ffffff;
            }
          }
        }
      }

    }

    &:hover {
      .blog-single__inner__content:after {
        height: 100%;
      }
    }
  }

  .blog-button {
    ul{
      display: flex;
      justify-content: space-between;
      //margin-bottom: 85px;
      align-items: center;
      li:first-child{
        padding-right: 20px;
      }
      svg{
        cursor: pointer;
      }
    }
  }

  .swiper-button-next, .swiper-button-prev {
    position: absolute;
    height: 40px;
    width: 40px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 99;
    background-color: red;
  }

  .swiper-button-next, .swiper-button-prev, .swiper-pagination {
    opacity: 0;
    visibility: hidden;
  }

  .swiper-initialized {
    margin-left: ${props => props.offset + 15}px;
    padding-right: ${props => props.offset + 15}px;
  }
  

  .slider-nav-mobile {
    margin-top: 80px;
  }

  @media (max-width: 767px) {
    &:after{
      content: '';
      bottom: 0;
      left: 0;
      width: 100%;
      height: calc(50% + 40px);
      position: absolute;
      background: rgb(228, 236, 240);
    }
    .swiper-initialized {
      margin: 0;
      padding: 0;
    }

    .blog-button {
      ul {
        justify-content: unset;
      }
    }

    .blog-slider {
      &__slider-wrap {
        margin: 0;
      }
    }

    .full-title {
      display: unset;
      margin: 0;
    }

    .blog-title {
      padding: 0;
      margin-bottom: 40px;
    }

    .blog-button {
      margin-bottom: 40px;
    }

    .blog-single {
      .blog-single__inner {
        padding-top: calc(440 / 375 * 100%);

        &__content {
          &__bottom {
            h3 {
              color: red;

              p {
                color: #FFFFFF;
              }
            }
          }
        }

        &:hover {
          .blog-single__inner__content__bottom {
            h3 {
              color: #ffffff;
            }
          }
        }
      }

      .blog-single__inner__content__top {
        .upper, h2 {
          left: 15px;
          right: 15px;
        }
      }

      .blog-single__inner__content__bottom h4, .blog-single__inner__content__bottom p {
        left: 15px;
        right: 15px;
      }
    }

    .slider-nav-mobile {
      margin-top: 40px;
    }

  }

`;
export default React.memo(MediaCenter);