import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    // filter data
    const ContactData = data?.posts?.list;
    const ContactMap = data?.images?.list;


    return (
        <StyledComponent>
           <Container>

               <Row>
                   {ContactData?.length > 0 && ContactData?.map(i => (
                   <Col lg={2}>
                       <p>{reactHtmlParser(i?.data?.subtitle)}</p>
                       <h6>{reactHtmlParser(i?.data?.short_desc)}</h6>
                   </Col>
                       ))}
               </Row>

               <Row>
                   <Col className={'map-col'}>
                       <div className={'map'}>
                           <Img src={ContactMap[0]?.full_path}/>
                       </div>
                   </Col>
               </Row>
           </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
 padding-top: 80px;
  padding-bottom: 200px;
 background-color: #1E1E2E;
  position: relative;
  
  .container{
    &:after{
      content: "";
      height: calc(50% - 20px);
      width: 100%;
      position: absolute;
      background: #F6F9F8;
      bottom: 0;
      left: 0;
    }
  }
  
  p{
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: rgb(255 255 255 / 70%);
    padding-bottom: 10px;
  }
  h6,a{
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #FFFFFF;
    transition: 0.5s ease color;
    &:hover{
      color:#E72E4B;
      transition: 0.5s ease color;
    }
  }
  .map{
    z-index: 1;
    position: relative;
    margin-top: 80px;
    padding-top: calc(560/1170 * 100%);
    @media(max-width: 767px){
      padding-top: calc(250 / 345 * 100%);
    }
  }
  @media(max-width: 767px){
    padding-top: 60px;
    padding-bottom: 120px;
    .map-col {
      padding: 0;
    }
    .container{
      &:after{
        content: "";
        height: 20%;
        width: 100%;
        position: absolute;
        background: #F6F9F8;
        bottom: 0;
        left: 0;
      }
    }
    .row{
      gap:40px;
    }
  }
  @media(min-width: 768px) and (max-width: 991px){
    .container{
      &:after{
        content: "";
        height: 35%;
        width: 100%;
        position: absolute;
        background: #F6F9F8;
        bottom: 0;
        left: 0;
      }
    }
  }
`;

export default React.memo(MyComponent);
