import React, {useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchCareer} from "../../api/redux/career";
import Job from "../../components/Career/Job";
import CareerJobForm from "../../components/Career/CareerJobForm";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import reactHtmlParser from "react-html-parser";



const Career = () => {
    const dispath = useDispatch()
    const getData = useSelector(state=> state.careerReducer)

    // api call
    useEffect(() => {
        let apiUrl = apiEndPoints.SECTIONS

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'career',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }

        dispath(fetchCareer([apiUrl, api_params]))
    }, [])

    // filter data
    const BannerData = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'career-banner');
    const Careerjob = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'job-application-form');
    const Availablejob = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'available-jobs');


    const page_data = getData?.posts?.data?.page_data;
    return (
        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.meta_title ? page_data?.meta_title + ' | ' : ''}`}National Heart
                    Foundation</title>

                <meta name="meta:title" content={page_data?.meta_title}/>
                <meta name="description" content={page_data?.meta_description}/>
                <meta property="og:title" content={page_data?.og_title}/>
                <meta property="og:subtitle" content={page_data?.og_title}/>
                <meta property="og:description" content={page_data?.og_description}/>
                <meta property="og:image" content={BannerData?.images?.list?.[0]?.full_path}/>
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>
            <StyledComponent>
                {
                    BannerData &&
                    <InnerBanner title={reactHtmlParser(BannerData?.section_data?.short_desc ? BannerData?.section_data?.short_desc : '')}
                                 subtitle={reactHtmlParser(BannerData?.section_data?.description ? BannerData?.section_data?.description : '')}
                                 breadcrumbs={reactHtmlParser(BannerData?.section_data?.subtitle ? BannerData?.section_data?.subtitle : '')} img={BannerData?.images?.list[0]?.full_path ? BannerData?.images?.list[0]?.full_path : ''}/>
                }

                {
                    Careerjob &&
                    <CareerJobForm data={Careerjob}/>
                }

                {
                    Availablejob &&
                    <Job data={Availablejob}/>
                }


            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default Career;
