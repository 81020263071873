import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Img} from "../Img";
import {CSSPlugin, gsap, TimelineLite} from "gsap";

import reactHtmlParser from "react-html-parser";
import {hover, text} from "../../styles/globalStyleVars";


gsap.registerPlugin(CSSPlugin);
let tl = new TimelineLite();
const MyComponent = ({img, name, designation,education}) => {

    // offset
    const [offset, setOffset] = useState(90)
    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)
    }, [])

    return (
        <>
            <StyledComponent>
                <div className="leaders__slider__single">
                    <div className="leaders__slider__single__img">
                            <Img src={img}/>
                    </div>
                    <div className="leaders__slider__single__content">
                        {reactHtmlParser(name) && <h6>{reactHtmlParser(name)}</h6>}
                        {reactHtmlParser(designation) && <p>{reactHtmlParser(designation)}</p>}
                        {reactHtmlParser(designation) && <span>{reactHtmlParser(education)}</span>}
                    </div>
                </div>
            </StyledComponent>

        </>
    );
};
const StyledComponent = styled.section`
  .leaders__slider {
    &__single {
      cursor: pointer;

      &__img {
        padding-top: calc(370 / 370 * 100%);
        position: relative;

        .global-image {
          overflow: hidden;

          img {
            transform: scale(1.01);
            transition: 1.4s ease;
          }
        }
      }

      &:hover {
        .global-image img {
          transform: scale(1.06) !important;
        }
      }

      &__content {
        margin-top: 20px;

        h6 {
          color: #1E1E2E;
        }

        p {
          padding-top: 5px;
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          color: #1E1E2E;
          opacity: 70%;
        }
      }
    }
  }

`

export default React.memo(MyComponent);