import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import {hover, title} from '../../styles/globalStyleVars';
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import {Autoplay, Navigation, Pagination} from "swiper";
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import {ImgParallax} from "../ImgParallax";
import Button from "../Button";
import isMobile from 'is-mobile';
import {gsap, TweenLite} from "gsap";
import {useLocation} from "react-router-dom";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {SplitText} from "gsap/SplitText";
import ButtonLong from "../ButtonLong";
import ButtonShort from "../ButtonShort";
import ButtonCCDApply from "../ButtonCCDApply";
import ButtonShortBannerCCD from "../ButtonShortBannerCCD";
import AppointmentPopUp from "../AppointmentPopUp";


// for button

// Function to handle button animation


const Banner = ({data, feature_data}) => {
    gsap.registerPlugin(SplitText);
    const interleaveOffset = 0.5;
    const swiperRef = useRef(null);
    const swiperRefTwo = useRef(null);
    const AutoplayDelay = 5000;
    const [offset, setOffset] = useState(90)
    const location = useLocation();
    const buttonRef = useRef(null);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (i) => {
        setShow(true)
    }


    const applyButtonAnimation = () => {
        // Check if the window width is greater than 767px
        if (window.innerWidth > 767) {
            gsap.from(".banner-button", {
                duration: 2.5,
                translateY: 250,
                opacity: 1,
                delay: .4,
                rotationZ: "15",
                ease: "power4.out",
                scrollTrigger: {
                    trigger: ".banner-button",
                    toggleActions: "restart none none reset",
                },
            });
        }
    };


    // navigation
    const prev = () => {
        document.querySelector('.home-banner .swiper-button-prev').click();
    };

    const next = () => {
        document.querySelector('.home-banner .swiper-button-next').click();
    };

    useEffect(() => {

        setOffset(document.querySelector(' .container').offsetLeft)
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [data])


    // handle slider progress
    const handleProgress = (swiper, event) => {
        var interleaveOffset = 0.5;
        for (let i = 0; i < swiper.slides.length; i++) {
            var slideProgress = swiper.slides[i].progress;
            var innerOffset = swiper.width * interleaveOffset;
            var innerTranslate = slideProgress * innerOffset;
            swiper.slides[i].querySelector(".global-image").style.transform =
                "translate3d(" + innerTranslate + "px, 0, 0)";
        }

    };

    // hand touch move not required this slider
    const handleTouchStart = (swiper) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = "";
        }
    };


    // handle image transition on change
    const handleSetTransition = (swiper, speed) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = `${speed}ms`;
            swiper.slides[i]
                .querySelector(".global-image")
                .style.transition = `${speed}ms`;
        }
    };


    // slider-speed-control for mobile and laotop
    const [swiperSpeed, setSwiperSpeed] = useState('1800');

    // Check if the device is a mobile
    useEffect(() => {
        const isMobileDevice = /Mobi/i.test(navigator.userAgent);

        // If it's a mobile device, set the speed to '1500'
        if (isMobileDevice) {
            setSwiperSpeed('1500');
        }
    }, [data]);

    // autoplay off in mobile

    useEffect(() => {
        if (data?.length > 0) {
            const swiper = swiperRef.current?.swiper;

            // Check if the user is on a mobile device
            const shouldDisableAutoplay = isMobile();

            // Disable autoplay if on a mobile device
            if (shouldDisableAutoplay) {
                swiper.autoplay.stop();
            } else {
                swiper.autoplay.start();
            }
        }

    }, [data]);


    // handle pagination
    var pagination_title = ['Financial freedom at the palm of your hands', 'Investing in growth to open new big opportunities', 'Financial freedom at the palm of your hands', 'Investing in growth to open new big opportunities']
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<div class="' + className + '"><span>' + pagination_title[index] + "</span></div>";
        },
    };
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);


    const handleSlideChange = (event) => {

        const newActiveSlideIndex = event.realIndex;
        setActiveSlideIndex(newActiveSlideIndex);
        if (swiperRefTwo.current) {
            swiperRefTwo.current.swiper.slideTo(newActiveSlideIndex);
        }

    };

    const handleButtonClickPagination = (data) => {
        if (swiperRef.current) {
            setActiveSlideIndex(data);
            swiperRef.current.swiper.slideToLoop(data);
        }
    };


    // Move this split text animation code to a separate function to reuse it
    // const applySplitTextAnimation = (element) => {
    //
    // };

    useEffect(() => {
        if (window.innerWidth > 767) {
            document.fonts.ready.then(() => {
                gsap.registerPlugin(SplitText, ScrollTrigger); // Register SplitText and ScrollTrigger plugins if not already registered

                gsap.utils.toArray(".split-up-titlee").forEach((item, i) => {
                    if (i === activeSlideIndex) {
                        const splitText = new SplitText(item, {
                            type: "lines",
                            linesClass: "line",
                        });

                        const lines = splitText.lines;
                        lines.forEach((item) => {
                            const chars = new SplitText(item, {
                                type: "chars",
                                charsClass: "char",
                            });

                            gsap.from(chars.chars, {
                                duration: 1.8,
                                delay: 1,
                                yPercent: 150,
                                rotationZ: "10",
                                ease: "power4.out",
                                stagger: 0.01,
                                scrollTrigger: {
                                    trigger: item,
                                    toggleActions: "restart none none reset",
                                },
                                // onComplete: () => splitText.revert()
                            });
                        });
                    }
                });
                applyButtonAnimation();
            });
            gsap.from(".banner-button", {
                duration: 1,
                translateY: 0,
                opacity: 1,
                rotationZ: "0",
                delay: 0.4,
                ease: "power4.out",
                scrollTrigger: {
                    trigger: ".banner-button",
                    toggleActions: "restart none none reset",
                },
            });
        }
    }, [activeSlideIndex]);


    // filter data
    const imagesData = data?.images?.list;

    return (
        <>
            <StyledBanner offset={offset} className={'home-banner'}>

                {imagesData &&
                    <Swiper
                        ref={swiperRef}
                        spaceBetween={0}
                        loop={true}
                        autoplay={true}
                        /*{isMobile() ? false : { delay: AutoplayDelay, disableOnInteraction: false }}*/
                        speed={swiperSpeed}
                        // swiperSpeed
                        onSlideChange={handleSlideChange}
                        onProgress={handleProgress}
                        touchStart={handleTouchStart}
                        onSetTransition={handleSetTransition}
                        grabCursor={false}
                        watchSlidesProgress={true}
                        mousewheelControl={true}
                        keyboardControl={true}
                        navigation={{
                            prevEl: '#banner-prev',
                            nextEl: '#banner-next',
                        }}
                        pagination={pagination}
                        modules={[Autoplay, Pagination, Navigation]}
                        sName="mySwiper main-swiper"
                    >

                        {imagesData?.length > 0 && imagesData?.map((i, index) => (
                            <SwiperSlide key={index + 1}>
                                <div className="slide-inner">

                                    {
                                        window.innerWidth > 767 ?

                                            <div className="desktop">
                                                <ImgParallax src={i?.full_path ? i?.full_path : ''}/>
                                            </div>

                                            :

                                            <div className="mobile">
                                                <ImgParallax src={i?.full_path ? i?.full_path : ''}/>
                                            </div>
                                    }


                                    <Container>
                                        <Row>
                                            <Col>
                                                <div className="slide-inner__info">
                                                    <h2 className={'swiper__title split-up-titlee'}>{ReactHtmlParser(i?.short_title)}</h2>

                                                    <div className={'banner-button'}>
                                                        {

                                                                <div key={index}>
                                                                    {i?.img_alt === 'nhf-ccd' ? (
                                                                        <ButtonShortBannerCCD src={i?.img_alt} color={'white'} margin={'40px 0 0 0'} text={'View Details'} hoverColor={'white'}/>
                                                                    ) : (
                                                                        ''
                                                                    )}

                                                                    { i?.img_alt === 'appointment' ? (
                                                                        <div onClick={() => handleShow()}>
                                                                            <ButtonShort src={'/'} text={'Book An Appointment'} color={'white'} margin={'40px 0 0 0'}/>
                                                                        </div>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </div>

                                                        }

                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </SwiperSlide>
                        ))}


                    </Swiper>
                }


                <Container className={'custom-navigation'}>
                    <Row>
                        <Col>
                            <div className="swipper-navigation-slider-custom">
                                {imagesData &&
                                    <Swiper
                                        ref={swiperRefTwo}
                                        speed={swiperSpeed}
                                        loop={true}
                                        autoplay={true}
                                        slidesPerView={6}
                                        spaceBetween={0}
                                        breakpoints={{
                                            320: {
                                                slidesPerView: 1,
                                                spaceBetween: 30,

                                            },
                                            768: {
                                                slidesPerView: 2,
                                                spaceBetween: 0,

                                            },
                                            1024: {
                                                slidesPerView: 6,
                                                spaceBetween: 0,
                                            },
                                        }}
                                        modules={[Autoplay, Pagination, Navigation]}
                                        className="mySwiperNext"
                                    >

                                        {imagesData?.length > 0 && imagesData?.map((d, i) => (
                                            <SwiperSlide key={i}>
                                                <div
                                                    className={`slide-inner-item ${activeSlideIndex === i ? 'active' : ''}`}
                                                    onClick={() => handleButtonClickPagination(i)}>
                                                    <span>{ReactHtmlParser(d?.short_desc)}</span>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>

            </StyledBanner>
            <AppointmentPopUp show={show} handleClose={handleClose}/>
        </>
    );
};

const StyledBanner = styled.section`
  height: 100vh;
  overflow: hidden;
  position: relative;

  .swiper-button-prev, .swiper-button-next, .swiper-pagination, .mobile {
    display: none;
  }

  .swiper-slide {
    overflow: hidden;
  }

  .swiper-pagination {
    display: none;
  }

  .swiper-button-disabled {
    opacity: 0.5 !important;
  }


  .main-swiper {
    //.swiper-slide {
    //  transform: unset;
    //  -webkit-flex-shrink: 0;
    //  -ms-flex-negative: 0;
    //  flex-shrink: 0;
    //  backface-visibility: hidden;
    //}
  }

  .global-image {
    transition: clipPath .8s cubic-bezier(.29, .73, .45, 1), border-color 3.6s linear, -webkit-clipPath .8s cubic-bezier(.29, .73, .45, 1);
    will-change: clipPath;
    overflow: hidden;
    -webkit-clipPath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clipPath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  .swiper-slide-active, .swiper-slide-visible {
    .global-image {
      clipPath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }

    .slide-inner {
      img {
        height: 100vh !important;
      }

      //&__info {
      //  transition-delay: 0.3s;
      //  opacity: 1;
      //  transform: translateX(0px) !important;
      //
      //  .dc-btn {
      //    transition-delay: 0.4s;
      //    opacity: 1;
      //    transform: translateX(0px) !important;
      //  }
      //}

    }
  }

  //custom navigation
  .swipper-navigation-slider-custom {
    display: flex;
    position: absolute;
    left: 0px;
    right: 0px;
    min-height: 75px;
    text-align: left;
    margin: 0px auto;
    padding-left: 15px;
    padding-right: 15px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    gap: 15px;
    flex-direction: row;


    .swiper {
      width: 100% !important;
      margin: unset !important;
    }

    .slide-inner-item {
      width: 100%;
      position: relative;
      background: transparent !important;
      opacity: 1 !important;
      padding: 20px 40px 0 0;
      cursor: pointer;

      span {
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
        opacity: 1 !important;
        transition: 0.7s all ease;
        display: block;
        display: -webkit-box;
        max-width: 100%;
        margin: 0 auto;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 4px;
        background: #E50019;
        width: 0;
        opacity: 0;
        z-index: 100;
      }

      &:hover {
        &:after {

          width: 92%;
          opacity: 1;
          background: #C9C9C9;
          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }

      &:before {
        content: '';
        background: transparent !important;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 99;
        border-top: 1px solid #C9C9C9;
      }

      &.active {

        &:after {
          width: 92%;
          opacity: 1;
          background: #E50019;
          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }
    }

    .swiper-slide:last-child {
      .slide-inner-item {
        &:hover {
          &:after {

            width: 100%;
            opacity: 1;
            background: #C9C9C9;
            @media (max-width: 767px) {
              width: 100%;
            }
          }
        }

        &.active {

          &:after {
            width: 100%;
            opacity: 1;
            background: #E50019;
            @media (max-width: 767px) {
              width: 100%;
            }
          }
        }
      }
    }

    .last-child {
      &.active {

        &:after {
          width: 100%;
          opacity: 1;
          background: #E50019;
          @media (max-width: 767px) {
            width: 100%;
          }
        }

      }

      &:hover {
        &:after {

          width: 100%;
          opacity: 1;
          background: #C9C9C9;
          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }
    }
  }

  .custom-navigation {
    position: relative;
    bottom: 120px;
    @media (max-width: 767px) {
      bottom: 65px;
    }
  }


  .slide-inner {
    position: relative;
    height: 100vh;

    .container {
      position: absolute;
      left: 0;
      right: 0;
      top: calc(50% - 15vh);
      //transform: translateY(-50%);
      z-index: 2;
      overflow: hidden;
    }

    .global-image {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 1;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
      }
    }

    &__info {
      position: relative;
      overflow: hidden;

      h1 {
        line-height: 88px;
      }

      .banner-button {
        opacity: 0;
        overflow: hidden;
        width: 250px;

        .dc-btn {
          position: relative;
          overflow: hidden;
        }
      }

      //transform: translateX(50%);
      //transition: all 2s cubic-bezier(.25, .74, .22, .99);
      //transition-delay: 1.2s;
      //opacity: 0;
      @media (min-width: 1024px) {
        width: 70%;
      }

      h1 {
        .line {
          overflow: hidden;
        }
      }

      //.dc-btn {
      //  position: relative;
      //  //transform: translateY(400px);
      //  transition-delay: 1.2s;
      //  transform: translateX(50%);
      //
      //  transition: 2.4s all cubic-bezier(.25, .74, .22, .99);
      //  opacity: 0;
      //
      //}
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .slide-inner__info {
      margin-right: 50px;

      h1 {
        font-size: 60px;
        line-height: 68px;
      }
    }
  }


  //navigation

  .navigation {
    position: absolute;
    right: ${props => props.offset ? props.offset : '90'}px;
    top: calc(50% - 10vh);
    display: inline-block;
    z-index: 1;

    ul {
      display: flex;
      flex-direction: column;

      li {
        cursor: pointer;

        &:first-child {
          margin-bottom: 20px;
          margin-right: 0px;
        }

        svg {
          path, #Rectangle_5661 {
            //transition: stroke-dasharray 0.3s ease-in-out;
            transition: all 1s cubic-bezier(.25, .74, .22, .99);
          }
        }

        &:hover {
          svg {
            #Rectangle_5661 {
              opacity: 1;
              stroke-dasharray: 140;
            }

            path {
              stroke: ${hover};
            }
          }
        }
      }
    }
  }


  //responsive
  @media (min-width: 1550px) {
    .slide-inner {
      &__info {
        position: relative;
        overflow: hidden;

        h2 {
          font-size: 80px;
          line-height: 100px;
        }

        //.dc-btn {
        //  a {
        //    font-size: 22px;
        //    line-height: 33px;
        //  }
        //}
      }
    }

    .feature-box-container {
      bottom: -150px;
    }

    .feature-box-container {
      .single-item-wrapper {
        min-height: 300px;

        .content {
          min-height: 250px;
        }
      }
    }
  }
  @media (max-width: 992px) {
    .swipper-navigation-slider-custom {
      bottom: 250px;

    }
  }

  @media (max-width: 992px) and (min-width: 768px) {
    height: 100vh;
    .custom-navigation {
      bottom: 0;
    }

    .swipper-navigation-slider-custom {
      bottom: 60px;

      .swiper-initialized {
        padding-right: 30%;
      }
    }

    .slide-inner {
      height: 100vh;

      .container {
        top: 45%;
      }
    }

    .feature-box-container {
      display: none;
      position: relative;
      left: 0px;
      right: 0px;
      bottom: 200px;
      z-index: 999;
      width: calc(100% - 30px);
      margin: 0px auto;


      .single-item-wrapper a .content {
        align-items: flex-start;
      }

      .feature-box-container_item:last-child {
        order: 1;
        flex: 0 0 100%;
        max-width: 100%;
      }

      .feature-box-container_item:first-child {
        order: 2;
        flex: 0 0 100%;
        max-width: 100%;

        .single-item-wrapper {
          border-right: none;
          border-bottom: 1px solid rgba(198, 198, 198, 0.5);
        }

      }

      .feature-box-container_item:nth-of-type(2) {
        order: 3;
        flex: 0 0 50%;
        max-width: 50%;
      }

      .feature-box-container_item:nth-of-type(3) {
        order: 4;
        flex: 0 0 50%;
        max-width: 50%;

        .single-item-wrapper {
          border-right: none;

        }

      }
    }


  }
  @media (max-width: 767px) {
    height: 100vh;
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }

    .slide-inner {
      height: 100vh;

      .container {
        top: unset;
        transform: unset;
        bottom: 220px;
      }

      &__info {
        position: relative;
        overflow: hidden;


        h2 {
          font-size: 40px;
          line-height: 48px;
        }

        h1 {
          line-height: 55px;
        }


        .dc-btn {
          margin-top: 30px;
        }
        .banner-button{
          opacity: 1;
        }
      }
    }

    .swipper-navigation-slider-custom {
      padding-left: 15px !important;
      padding-right: 15px !important;
      bottom: 0px;

      .swiper-initialized {
        padding-right: 30%;
      }
    }


    .scrollTo {
      left: 15px;
      right: unset;
      display: inline-block;
      bottom: 20px;
      transform: translateX(0);
    }

    .navigation {
      display: none;
    }


    .feature-box-container {

      display: none;
      position: relative;
      bottom: 150px;

      .feature-box-container_item {
        &:first-child {
          order: 2;
        }

        &:nth-of-type(2) {
          order: 3;
        }

        &:nth-of-type(3) {
          order: 4;
        }

        &:last-child {
          order: 1;

          .content {
          }
        }
      }

      .single-item-wrapper {
        border-right: none;
        border-bottom: 1px solid rgba(198, 198, 198, 0.5);

        .content {
          align-items: flex-start !important;
        }

        &.last {
          border: none;

          .content {
            align-items: center !important;
          }
        }


      }

      .container {
        padding: 0;
      }
    }
  }







`;

export default React.memo(Banner);