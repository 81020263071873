import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Img} from "../Img";
import moment from "moment/moment";
import Title from "../Title";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    // filtering data
    const publications = data?.posts?.list[0];
    const books = data?.posts?.list[1];

    return (
        <StyledComponent className={'pb-200 pt-200'}>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="title">
                            <Title text={data?.section_data?.short_desc} color={'#1E1E2E'}/>
                        </div>
                    </Col>
                    <Col className={'profile-gap'} md={6}>
                        <div className=''>
                            <Link to={"/publications"}>
                                <a>
                                    <div className='presslist__single'>
                                        <div className='presslist__single__img'>
                                            <Img src={publications?.images[0]?.full_path}/>
                                        </div>
                                        <div className='presslist__single__content'>
                                            <h4>{reactHtmlParser(publications?.data?.subtitle)}</h4>
                                            <div className='presslist__single__content__text'>
                                                <p className={'split-up'}>{reactHtmlParser(publications?.data?.description)}</p>
                                            </div>

                                        </div>
                                    </div>
                                </a>
                            </Link>
                        </div>
                    </Col>
                    <Col className={'profile-gap-books'} md={6}>
                        <div className=''>
                            <Link to={"/books"}>
                                <a>
                                    <div className='presslist__single'>
                                        <div className='presslist__single__img'>
                                            <Img src={books?.images[0]?.full_path}/>
                                        </div>
                                        <div className='presslist__single__content'>
                                            <h4>{reactHtmlParser(books?.data?.subtitle)}</h4>
                                            <div className='presslist__single__content__text'>
                                                <p className={'split-up'}>{reactHtmlParser(books?.data?.description)}</p>
                                            </div>

                                        </div>
                                    </div>
                                </a>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`

  .title{
    margin-bottom: 40px;
  }

  .profile-gap{
    padding: 40px 40px 40px 15px;
  }
  .profile-gap-books{
    padding: 40px 40px 40px 15px;
  }
  .presslist {
    &__single {
      margin-bottom: 30px;
      //box-sizing: border-box;
      transition: 1s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      border: 1px solid rgb(25 27 29 / 10%);

      //&:hover {
      //  //outline: auto;
      //  //outline-color:  #EE1B24;
      //  border: 1px solid #EE1B24;
      //  transition: 1s ease-in-out;
      //  -webkit-transition: all 0.2s ease-in-out;
      //  box-shadow: 0 5px 30px rgba(238, 27, 36, 0.08);
      //  border-radius: unset;
      //
      //}

      &__img {
        position: relative;
        padding-top: calc(200 / 370 * 100%);
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
          left: 0;
          right: 0;
          bottom: 0;
          transform: scale(1.01);
          transition: 1.4s ease;
        }

      }

      &__content {
        padding: 40px 40px 100px 40px;
        position: relative;
        background-color: #F6F9F8;
        @media (max-width: 992px) {
          font-size: 18px;
          line-height: 27px;
        };
        @media (max-width: 767px){
          padding: 40px 40px 40px 15px;
        }

        &__link {
          position: absolute;
          bottom: 31px;
        }

        h4 {
          color: #E72E4B;
          opacity: 80%;
          margin-bottom: 20px;
        }

        p {
          color: #1E1E2E;
          margin-bottom: 30px;
          font-size: 16px;
          line-height: 20px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 700;

        }

        &__learn {
          position: relative;

          &:after {
            content: '';
            height: 3px;
            width: 0px;
            background-image: linear-gradient(#AD0000, #FF0000);
            position: absolute;
            transition: .3s;
          }

          h2 {
            color: #222222;
            font-weight: 500;
            cursor: pointer;
            border-bottom: 2px solid #222222;
            width: 26%;
          }
        }

      }

      @media (max-width: 767px) {
        margin-bottom: 20px !important;
        :last-child {
          margin-bottom: 0px;
        }
      }

      &:hover {
        img {
          transform: scale(1.06) !important;
        }
      }

    }
  }

  .profile-text {
    h6 {
      padding-top: 20px;
      color: #1E1E2E;
    }

    p {
      padding-top: 5px;
      color: #000000a8;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .filter-mobile {
    display: none;
  }

  .css-1wa3eu0-placeholder, .css-1uccc91-singleValue {
    color: white !important;
    font-size: 10px;
    font-weight: 600 !important;
  }

  .read-btn {

    position: relative;
  }

  @media (min-width: 992px) {
    .min-height {
      min-height: 490px;
    }

  }

  @media(min-width: 768px) and (max-width: 991px){
    .profile-gap{
      padding: 25px 25px 25px 15px;
    }
    .profile-gap-books{
      padding: 25px 25px 25px 15px;
    }
  }

  @media (max-width: 767px) {

    .profile-gap{
      padding: 15px;
    }
    .profile-gap-books{
      padding: 15px;
    }

    padding-top: 60px;
    .profile-gap:not(:last-child) {
      padding-bottom: 60px;
    }

    .filter_wrap {
      flex-direction: column-reverse;

      .search {
        margin-bottom: 40px;
      }
    }

    .load_more {
      margin-top: 0;
    }

    .filter-mobile {
      display: block;
    }

    .filter {
      display: none;
    }

    .css-13cymwt-control, .css-t3ipsp-control {
      background-color: #1E1E2E;
      border-radius: 50px;

      .css-1jqq78o-placeholder {
        color: white;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        margin: 10px 0 10px 30px;
      }

      .css-1fdsijx-ValueContainer {
        padding: unset;

        .css-1dimb5e-singleValue {
          margin-left: 20px;
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
          color: #FFF;
        }
      }

      .css-1u9des2-indicatorSeparator {
        display: none;
      }

      .css-1xc3v61-indicatorContainer {
        padding-right: 30px;
      }
    }

    .css-t3ipsp-control {
      border-color: unset !important;
      box-shadow: unset !important;
    }

    .css-qbdosj-Input {
      input {
        min-width: 0px !important;
        opacity: 0 !important;
      }
    }

    .css-15lsz6c-indicatorContainer {
      padding-right: 30px;
      color: hsl(0, 0%, 80%);
    }


    .css-yk16xz-control, .css-1pahdxg-control {
      border-radius: 18px !important;
      border-color: #989898;
      height: 40px;
      background-color: #E50019;
      cursor: pointer;
      padding-left: 15px;
    }

    .css-yk16xz-control .css-1wa3eu0-placeholder, .css-1pahdxg-control .css-1wa3eu0-placeholder, .css-1uccc91-singleValue {
      color: #ffffff !important;
      font-size: 12px !important;
      font-weight: bold;
      cursor: pointer;
    }

    .css-yk16xz-control .css-tlfecz-indicatorContainer, .css-1pahdxg-control .css-tlfecz-indicatorContainer, .css-yk16xz-control .css-1gtu0rj-indicatorContainer, .css-1pahdxg-control .css-1gtu0rj-indicatorContainer {
      margin-right: 20px;
    }
  }
`;

export default React.memo(MyComponent);
