import React, {useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import Boardoftrustees from "./index";
import TrusteesList from "../../components/BoardofTrustees/TrusteesList";
import {fetchTrustees} from "../../api/redux/board-of-trustees";

const MyComponent = () => {
    const dispath = useDispatch()
    const getData = useSelector(state=> state.trusteesReducer)
    // api call
    useEffect(() => {
        let apiUrl = apiEndPoints.SECTIONS

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'board-of-trustees',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }

        dispath(fetchTrustees([apiUrl, api_params]))
    }, [])

    const BannerData = getData?.posts?.data?.page_data;
    return (
        <HelmetProvider>
            {/*<Helmet>*/}
            {/*    <title>{`${page_data?.meta_title ? page_data?.meta_title + ' | ' : ''}`}National Heart*/}
            {/*        Foundation</title>*/}

            {/*    <meta name="meta:title" content={page_data?.meta_title}/>*/}
            {/*    <meta name="description" content={page_data?.meta_description}/>*/}
            {/*    <meta property="og:title" content={page_data?.og_title}/>*/}
            {/*    <meta property="og:subtitle" content={page_data?.og_title}/>*/}
            {/*    <meta property="og:description" content={page_data?.og_description}/>*/}
            {/*    <meta property="og:image" content={BannerData?.images?.list?.[0]?.full_path}/>*/}
            {/*    <meta property="og:url" content={window?.location?.href}/>*/}
            {/*    <meta property="og:type" content={"website"}/>*/}
            {/*</Helmet>*/}
            <StyledComponent>
                <TrusteesList data={BannerData}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
