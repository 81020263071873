import React, {useEffect} from 'react';
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import activitiesReducer, {fetchActivities} from "../../api/redux/activities";

import InnerBanner from "../../components/InnerBanner";
import Care from "../../components/Activites/Care";
import MajorSlider from "../../components/Activites/MajorSlider";
import AcActivites from "../../components/Activites/AcActivites";
import ActivitesSlider from "../../components/Activites/ActivitesSlider";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";


const Activites = () => {
    const dispath = useDispatch()

    const getData = useSelector(state=> state.activitiesReducer)


    // filter data
    const BannerData = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'activities-banner');
    const ActivitiesOverview = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'activity-overview');
    const Majorslider = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'major-facilities');
    const Otheractivities = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'other-activities');
    const Academicactivities = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'academic-activities-activities');


    const OurActivitiesSlider = getData?.posts?.academic_activities?.list;

    // api call
    useEffect(() => {
        let apiUrl = apiEndPoints.SECTIONS

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'activities',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }

        dispath(fetchActivities([apiUrl, api_params]))
    }, [])

    const page_data = getData?.posts?.data?.page_data;

    return (
        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.meta_title ? page_data?.meta_title + ' | ' : ''}`}National Heart
                    Foundation</title>

                <meta name="meta:title" content={page_data?.meta_title}/>
                <meta name="description" content={page_data?.meta_description}/>
                <meta property="og:title" content={page_data?.og_title}/>
                <meta property="og:subtitle" content={page_data?.og_title}/>
                <meta property="og:description" content={page_data?.og_description}/>
                <meta property="og:image" content={BannerData?.images?.list?.[0]?.full_path}/>
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>
            <StyledComponent>
                {
                    BannerData &&
                    <InnerBanner img={BannerData?.images?.list[0]?.full_path ? BannerData?.images?.list[0]?.full_path : ''} breadcrumbs={BannerData?.section_data?.subtitle ? BannerData?.section_data?.subtitle : ''}
                                 subtitle={BannerData?.section_data?.description ? BannerData?.section_data?.description : ''}
                                 title={BannerData?.section_data?.short_desc ? BannerData?.section_data?.short_desc : ''}/>
                }
                {
                    ActivitiesOverview &&
                    <Care data={ActivitiesOverview}/>
                }

                {
                    Majorslider &&
                    <MajorSlider data={Majorslider}/>
                }
                {
                     Academicactivities &&
                    <AcActivites data={Academicactivities} dataSlider={OurActivitiesSlider}/>
                }
                {
                    Otheractivities &&
                    <ActivitesSlider data={Otheractivities}/>
                }
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default Activites;
