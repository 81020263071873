import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Title from "../Title";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {text} from "../../styles/globalStyleVars";
import Button from "../Button";
import {Link} from "react-router-dom";
import {Img} from "../Img";
import moment from 'moment';
import ButtonArrow from "../ButtonArrow";

const MyComponent = ({data}) => {


    let sliderRef2 = useRef();

    // offset
    const [offset, setOffset] = useState(100)
    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)

        window.addEventListener('load', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
    }, [])

    // slider next prev action
    return (
        <StyledComponent offset={offset}
                         className='media-event-slider asNewsOnly pt-200 pb-200'>

            <Container>
                <Row>
                    <Col md={12}>
                        <div className="d-flex navigation_wrapper">
                            <div className="title-main">
                                <Title text={'Related Publications'} color={'#1E1E2E'}/>
                            </div>
                            <ul className="navigation">
                                <div className={'navigation__icon'}>
                                    <li className={'prev_swipper'}>
                                        <svg id="Button_-_Nav_-_Prev_Dark_" data-name="Button - Nav - Prev (Dark)" xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
                                            <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke="#1e1e2e" stroke-width="1" opacity="0.2">
                                                <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                            </g>
                                            <g id="Group_4824" data-name="Group 4824" transform="translate(16 15)">
                                                <g id="Group_4823" data-name="Group 4823">
                                                    <line id="Line_9" data-name="Line 9" x1="5" y2="5" fill="none" stroke="#1e1e2e" stroke-linecap="round" stroke-width="1"/>
                                                    <line id="Line_10" data-name="Line 10" x2="5" y2="5" transform="translate(0 5)" fill="none" stroke="#1e1e2e" stroke-linecap="round" stroke-width="1"/>
                                                </g>
                                                <line id="Line_11" data-name="Line 11" x1="10" transform="translate(0 5)" fill="none" stroke="#1e1e2e" stroke-linecap="round" stroke-width="1"/>
                                            </g>
                                            <g id="Rectangle_5661" data-name="Rectangle 5661" transform="translate(0 41) rotate(-90)" fill="none" stroke="#e72e4b" stroke-width="1" stroke-dasharray="0 130" opacity="0">
                                                <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                            </g>
                                        </svg>



                                    </li>
                                    <li className={'next_swipper'}>
                                        <svg id="Button_-_Nav_-_Next_Dark_" data-name="Button - Nav - Next (Dark)" xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
                                            <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke="#1e1e2e" stroke-width="1" opacity="0.2">
                                                <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                            </g>
                                            <g id="Group_4824" data-name="Group 4824" transform="translate(-637.5 -1581.5)">
                                                <g id="Group_4823" data-name="Group 4823" transform="translate(11 4)">
                                                    <line id="Line_9" data-name="Line 9" x2="5" y2="5" transform="translate(647.5 1592.5)" fill="none" stroke="#1e1e2e" stroke-linecap="round" stroke-width="1"/>
                                                    <line id="Line_10" data-name="Line 10" x1="5" y2="5" transform="translate(647.5 1597.5)" fill="none" stroke="#1e1e2e" stroke-linecap="round" stroke-width="1"/>
                                                </g>
                                                <line id="Line_11" data-name="Line 11" x2="10" transform="translate(653.5 1601.5)" fill="none" stroke="#1e1e2e" stroke-linecap="round" stroke-width="1"/>
                                            </g>
                                            <g id="Rectangle_5661" data-name="Rectangle 5661" transform="translate(0 41) rotate(-90)" fill="none" stroke="#e72e4b" stroke-width="1" stroke-dasharray="0 130" opacity="0">
                                                <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                            </g>
                                        </svg>
                                    </li>
                                </div>
                                <div>
                                    <ButtonArrow text={'View All'} background={'transparent'} color={'#1E1E2E'} border={'1px solid rgb(30 30 46 / 20%)'} margin={'0 0 0 40px'}/>
                                </div>

                            </ul>

                            <ul className={'navigation-mobile'}>
                                <div className={'navigation__icon'}>
                                    <li className={'prev_swipper'}>
                                        <svg id="Button_-_Nav_-_Prev_Dark_" data-name="Button - Nav - Prev (Dark)" xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
                                            <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke="#1e1e2e" stroke-width="1" opacity="0.2">
                                                <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                            </g>
                                            <g id="Group_4824" data-name="Group 4824" transform="translate(16 15)">
                                                <g id="Group_4823" data-name="Group 4823">
                                                    <line id="Line_9" data-name="Line 9" x1="5" y2="5" fill="none" stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                                    <line id="Line_10" data-name="Line 10" x2="5" y2="5" transform="translate(0 5)" fill="none" stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                                </g>
                                                <line id="Line_11" data-name="Line 11" x1="10" transform="translate(0 5)" fill="none" stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                            </g>
                                            <g id="Rectangle_5661" data-name="Rectangle 5661" transform="translate(0 41) rotate(-90)" fill="none" stroke="#e72e4b" stroke-width="1" stroke-dasharray="130 130">
                                                <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                            </g>
                                        </svg>
                                    </li>
                                    <li className={'next_swipper'}>
                                        <svg id="Button_-_Nav_-_Next_Dark_" data-name="Button - Nav - Next (Dark)" xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
                                            <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke="#1e1e2e" stroke-width="1" opacity="0.2">
                                                <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                            </g>
                                            <g id="Group_4824" data-name="Group 4824" transform="translate(-637.5 -1581.5)">
                                                <g id="Group_4823" data-name="Group 4823" transform="translate(11 4)">
                                                    <line id="Line_9" data-name="Line 9" x2="5" y2="5" transform="translate(647.5 1592.5)" fill="none" stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                                    <line id="Line_10" data-name="Line 10" x1="5" y2="5" transform="translate(647.5 1597.5)" fill="none" stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                                </g>
                                                <line id="Line_11" data-name="Line 11" x2="10" transform="translate(653.5 1601.5)" fill="none" stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                            </g>
                                            <g id="Rectangle_5661" data-name="Rectangle 5661" transform="translate(0 41) rotate(-90)" fill="none" stroke="#e72e4b" stroke-width="1" stroke-dasharray="130 130">
                                                <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                            </g>
                                        </svg>

                                    </li>
                                </div>

                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Swiper loop={true}
                    spaceBetween={30}
                    slidesPerView={3}
                    allowSlideNext={true}
                    allowSlidePrev={true}
                    allowTouchMove={true}
                    speed={900}
                    navigation={{
                        prevEl: '.prev_swipper',
                        nextEl: '.next_swipper  ',
                    }}
                    modules={[Autoplay, Pagination, Navigation]}

                // autoplay={{
                //     delay: 2500,
                //     disableOnInteraction: false,
                // }}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 30,
                        },
                        991: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },
                    }} ref={sliderRef2}>
                <SwiperSlide key={0}>
                    <div className=''>
                        <Link to={""}>
                            <a>
                                <div className='presslist__single'>
                                    <div className='presslist__single__img'>
                                        <Img src={'/images/dynamic/publications-1.jpg'}/>
                                    </div>
                                    <div className='presslist__single__content'>
                                        <h2 className={'split-up'}>{moment(Date('May 2023')).format('DD MMMM YYYY')}</h2>
                                        <div className='presslist__single__content__text'>
                                            <h6 className={'split-up'}>{'Journal'} </h6>
                                            <p className={'split-up'}>{'2020 JNHFB Vol 9 Issue 1 '} </p>
                                        </div>

                                    </div>
                                </div>
                            </a>
                        </Link>
                    </div>
                </SwiperSlide>
                <SwiperSlide key={0}>
                    <div className=''>
                        <Link to={""}>
                            <a>
                                <div className='presslist__single'>
                                    <div className='presslist__single__img'>
                                        <Img src={'/images/dynamic/publications-2.jpg'}/>
                                    </div>
                                    <div className='presslist__single__content'>
                                        <h2>{moment(Date('May 2023')).format('DD MMMM YYYY')}</h2>
                                        <div className='presslist__single__content__text'>
                                            <h6 className={'split-up'}>{'Report'} </h6>
                                            <p className={'split-up'}>{'Strengthening advocacy for implementing TFA regulation, adoption of front-of-pack labeling and for sustainable funding for hypertension control in Bangladesh '} </p>
                                        </div>

                                    </div>
                                </div>
                            </a>
                        </Link>
                    </div>
                </SwiperSlide>
                <SwiperSlide key={0}>
                    <div className=''>
                        <Link to={""}>
                            <a>
                                <div className='presslist__single'>
                                    <div className='presslist__single__img'>
                                        <Img src={'/images/dynamic/publications-3.jpg'}/>
                                    </div>
                                    <div className='presslist__single__content'>
                                        <h2 className={'split-up'}>{moment(Date('May 2023')).format('DD MMMM YYYY')}</h2>
                                        <div className='presslist__single__content__text'>
                                            <h6 className={'split-up'}>{'Cardio Research'} </h6>
                                            <p className={'split-up'}>{'Bangladesh Longitudinal Investigation of Emerging Vascular and nonvascular Events (BELIEVE) Study '} </p>
                                        </div>

                                    </div>
                                </div>
                            </a>
                        </Link>
                    </div>
                </SwiperSlide>
                <SwiperSlide key={0}>
                    <div className=''>
                        <Link to={""}>
                            <a>
                                <div className='presslist__single'>
                                    <div className='presslist__single__img'>
                                        <Img src={'/images/dynamic/publications-4.jpg'}/>
                                    </div>
                                    <div className='presslist__single__content'>
                                        <h2 className={'split-up'}>{moment(Date('May 2023')).format('DD MMMM YYYY')}</h2>
                                        <div className='presslist__single__content__text'>
                                            <h6 className={'split-up'}>{'Cardio Research'} </h6>
                                            <p className={'split-up'}>{'Bangladesh Longitudinal Investigation of Emerging Vascular and nonvascular Events (BELIEVE) Study '} </p>
                                        </div>

                                    </div>
                                </div>
                            </a>
                        </Link>
                    </div>
                </SwiperSlide>
                <SwiperSlide key={0}>
                    <div className=''>
                        <Link to={""}>
                            <a>
                                <div className='presslist__single'>
                                    <div className='presslist__single__img'>
                                        <Img src={'/images/dynamic/publications-1.jpg'}/>
                                    </div>
                                    <div className='presslist__single__content'>
                                        <h2 className={'split-up'}>{moment(Date('May 2023')).format('DD MMMM YYYY')}</h2>
                                        <div className='presslist__single__content__text'>
                                            <h6 className={'split-up'}>{'Cardio Research'} </h6>
                                            <p className={'split-up'}>{'Bangladesh Longitudinal Investigation of Emerging Vascular and nonvascular Events (BELIEVE) Study '} </p>
                                        </div>

                                    </div>
                                </div>
                            </a>
                        </Link>
                    </div>
                </SwiperSlide>
                <SwiperSlide key={0}>
                    <div className=''>
                        <Link to={""}>
                            <a>
                                <div className='presslist__single'>
                                    <div className='presslist__single__img'>
                                        <Img src={'/images/dynamic/publications-2.jpg'}/>
                                    </div>
                                    <div className='presslist__single__content'>
                                        <h2 className={'split-up'}>{moment(Date('May 2023')).format('DD MMMM YYYY')}</h2>
                                        <div className='presslist__single__content__text'>
                                            <h6 className={'split-up'}>{'Cardio Research'} </h6>
                                            <p className={'split-up'}>{'Bangladesh Longitudinal Investigation of Emerging Vascular and nonvascular Events (BELIEVE) Study '} </p>
                                        </div>

                                    </div>
                                </div>
                            </a>
                        </Link>
                    </div>
                </SwiperSlide>
                <SwiperSlide key={0}>
                    <div className=''>
                        <Link to={""}>
                            <a>
                                <div className='presslist__single'>
                                    <div className='presslist__single__img'>
                                        <Img src={'/images/dynamic/publications-3.jpg'}/>
                                    </div>
                                    <div className='presslist__single__content'>
                                        <h2 className={'split-up'}>{moment(Date('May 2023')).format('DD MMMM YYYY')}</h2>
                                        <div className='presslist__single__content__text'>
                                            <h6 className={'split-up'}>{'Cardio Research'} </h6>
                                            <p className={'split-up'}>{'Bangladesh Longitudinal Investigation of Emerging Vascular and nonvascular Events (BELIEVE) Study '} </p>
                                        </div>

                                    </div>
                                </div>
                            </a>
                        </Link>
                    </div>
                </SwiperSlide>
                <SwiperSlide key={0}>
                    <div className=''>
                        <Link to={""}>
                            <a>
                                <div className='presslist__single'>
                                    <div className='presslist__single__img'>
                                        <Img src={'/images/dynamic/publications-5.jpg'}/>
                                    </div>
                                    <div className='presslist__single__content'>
                                        <h2 className={'split-up'}>{moment(Date('May 2023')).format('DD MMMM YYYY')}</h2>
                                        <div className='presslist__single__content__text'>
                                            <h6 className={'split-up'}>{'Cardio Research'} </h6>
                                            <p className={'split-up'}>{'Bangladesh Longitudinal Investigation of Emerging Vascular and nonvascular Events (BELIEVE) Study '} </p>
                                        </div>

                                    </div>
                                </div>
                            </a>
                        </Link>
                    </div>
                </SwiperSlide>
                <SwiperSlide key={0}>
                    <div className=''>
                        <Link to={""}>
                            <a>
                                <div className='presslist__single'>
                                    <div className='presslist__single__img'>
                                        <Img src={'/images/dynamic/publications-1.jpg'}/>
                                    </div>
                                    <div className='presslist__single__content'>
                                        <h2 className={'split-up'}>{moment(Date('May 2023')).format('DD MMMM YYYY')}</h2>
                                        <div className='presslist__single__content__text'>
                                            <h6 className={'split-up'}>{'Cardio Research'} </h6>
                                            <p className={'split-up'}>{'Bangladesh Longitudinal Investigation of Emerging Vascular and nonvascular Events (BELIEVE) Study '} </p>
                                        </div>

                                    </div>
                                </div>
                            </a>
                        </Link>
                    </div>
                </SwiperSlide>
            </Swiper>
            <div className={'mobile-button'}>
                <ButtonArrow text={'View All'} background={'transparent'} color={'#1E1E2E'} border={'1px solid rgb(30 30 46 / 20%)'} margin={'0 0 0 40px'}/>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F6F9F8;

  .presslist {
    &__single {
      margin-bottom: 30px;
      //box-sizing: border-box;
      transition: 1s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      border: 1px solid rgb(25 27 29 / 10%);

      //&:hover {
      //  //outline: auto;
      //  //outline-color:  #EE1B24;
      //  border: 1px solid #EE1B24;
      //  transition: 1s ease-in-out;
      //  -webkit-transition: all 0.2s ease-in-out;
      //  box-shadow: 0 5px 30px rgba(238, 27, 36, 0.08);
      //  border-radius: unset;
      //
      //}

      &__img {
        position: relative;
        padding-top: calc(200 / 370 * 100%);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }

      }

      &__content {
        padding: 40px 40px 100px;
        position: relative;
        background-color: #F6F9F8;
        @media (max-width: 992px) {
          font-size: 18px;
          line-height: 27px;
        };

        &__text {
          min-height: 200px;
        }

        &__link {
          position: absolute;
          bottom: 31px;
        }

        h2 {
          color: #1E1E2E;
          opacity: 70%;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 10px;
          font-weight: 400;
        }
        h6{
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          color: #E72E4B;
          margin-bottom: 20px;
        }

        p {
          color: #1E1E2E;
          margin-bottom: 30px;
          font-size: 16px;
          line-height: 20px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 700;

        }

        &__learn {
          position: relative;

          &:after {
            content: '';
            height: 3px;
            width: 0px;
            background-image: linear-gradient(#AD0000, #FF0000);
            position: absolute;
            transition: .3s;
          }

          h2 {
            color: #222222;
            font-weight: 500;
            cursor: pointer;
            border-bottom: 2px solid #222222;
            width: 26%;
          }
        }

      }

      @media (max-width: 767px) {
        margin-bottom: 20px !important;
        :last-child {
          margin-bottom: 0px;
        }
      }

    }
  }
  .navigation_wrapper{
    .title{
      margin-bottom: 40px;
    }
  }
  
  .d-flex{
    justify-content: space-between;
  }

  .hover {
    &.disabled {
      background-color: transparent !important;
      border: 1px solid #222222 !important;
      cursor: default;
      opacity: 0.9;

      &:after {
        display: none;
      }

      svg {
        path {
          fill: #222222;
        }
      }
    }
  }
  @media (min-width: 768px) {
    .mobile-button {
      display: none;
    }
    .navigation-mobile{
      display: none;
    }
  }
  .navigation {
    display: flex;

    &__icon {
      cursor: pointer;;
      display: flex;

      li:first-child {
        margin-right: 20px;
      }
    }
  }

  .slider-nav {
    position: absolute;
    top: 7px;
    right: 15px;

    ul {
      display: flex;
    }

    li {
      height: 50px;
      width: 50px;
      background-color: ${text};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;

      &:nth-of-type(1) {
        margin-right: 20px;
      }

      svg {
        color: #ffffff;
        z-index: 2;
        font-size: 20px;
      }
    }
  }
  //---link border animation
  //animation: draw-line 0.25s ease-in-out;
  @keyframes draw-line {
    0% {
      background-size: 0 2px;
    }
    to {
      background-size: 100% 2px;
    }
  }

  .dc-link{
    a{
      background-image: linear-gradient(currentcolor, currentcolor);
      background-position: 0px 100%;
      background-repeat: no-repeat;
      background-size: 100% 2px;
      padding-bottom: 0px;
      text-decoration: none;
      font-size: 14px;
      line-height: 27px;
      text-transform: capitalize;
      color: rgb(34, 34, 34);
      font-weight: 500;
      &:hover{
        animation: 0.25s ease-in-out 0s 1 normal none running draw-line;
        color: rgb(34, 34, 34) !important;
      }
    }
  }

  @media (min-width: 600px) {
    .swiper-initialized {
      margin-left: ${props => props.offset + 15}px;
      padding-right: ${props => props.offset + 15}px;
    }
  }
  
  .bottom-button {
    position: relative;
    @media (min-width: 768px) {
      .slider-nav {
        display: none;
      }
    }

    .slider-nav {
      top: auto;
      bottom: 10px;
      right: 15px;

      li:nth-of-type(1) {
        margin-right: 14px;
      }

      .hover {
        height: 40px;
        width: 40px;

        svg {
          font-size: 17px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .swiper{
      padding-right: 90px;
    }
    .top {
      display: none;
    }
    .navigation {
      display: none;
    }
    .navigation_wrapper {
      flex-direction: column;
    }

    .navigation-mobile {

      .navigation__icon{
        margin-bottom: 30px;
        cursor: pointer;;
        display: flex;

        li:first-child {
          margin-right: 20px;
        }
      }
    }
    .mobile-button {
      .dc-btn {
        margin: 40px 0 0 15px;
      }
    }
  }

  @media (max-width: 599px) {
    .swiper-container {
      margin-left: 15px;
      padding-right: 15px;
    }

    .presslist__single__content {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

`;

export default React.memo(MyComponent);