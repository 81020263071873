import React from 'react';
import styled from 'styled-components';
import {Link} from "react-router-dom";


const Button = ({
                    onSubmit,
                    text,
                    src,
                    img,
                    hoverImg,
                    fontSize,
                    fontWeight,
                    color,
                    letterSpacing,
                    lineHeight,
                    margin,
                    background,
                    borderRadius,
                    border,
                    width,
                    height,
                    hoverBackground,
                    target,
                    borderColor,
                    externals,
                    hoverColor, icon
                }) => {


    return (
        <StyledBtn className={`dc-btn `}
                   fontSize={fontSize}
                   fontWeight={fontWeight}
                   color={color}
                   background={background}
                   lineHeight={lineHeight}
                   letterSpacing={letterSpacing}
                   margin={margin}
                   border={border}
                   img={img}
                   borderRadius={borderRadius}
                   width={width}
                   hoverImg={hoverImg}
                   hoverBackground={hoverBackground}
                   height={height}
                   borderColor={borderColor}
                   target={target}
                   hoverColor={hoverColor}
                   onSubmit={onSubmit}
                   externals={externals}
        >
            {src ? (
                <Link to={externals ? {pathname: externals} : src} target="_blank" >


                    {
                        icon ?



                            <svg id="Button_-_Open_an_Account_Now_2" data-name="Button - Open an Account Now 2" width="256" height="44" viewBox="0 0 256 44">
                                <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke={`${color ? color : 'white'}`} stroke-width="1" opacity="0.5">
                                    <rect width="202" height="44" rx="22" stroke="none"/>
                                    <rect x="0.5" y="0.5" width="201" height="43" rx="21.5" fill="none"/>
                                </g>
                                <g id="Rectangle_493" data-name="Rectangle 493" fill="none" stroke={`${color ? color : 'white'}`} stroke-width="1" stroke-dasharray="1 520" opacity="0">
                                    <rect width="202" height="44" rx="22" stroke="none"/>
                                    <rect x="0.5" y="0.5" width="201" height="43" rx="21.5" fill="none"/>
                                </g>
                                <g id="Group_4825" data-name="Group 4825" transform="translate(36 12)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.469" height="17" viewBox="0 0 13.469 17">
                                        <g id="surface1" transform="translate(-0.5)">
                                            <path id="Path_8707" data-name="Path 8707" d="M9.661.131A.411.411,0,0,0,9.365,0H2.734A2.243,2.243,0,0,0,.5,2.23V14.77A2.243,2.243,0,0,0,2.734,17h9a2.243,2.243,0,0,0,2.234-2.23V4.812a.442.442,0,0,0-.119-.287ZM9.78,1.45l2.8,2.944H10.762a.978.978,0,0,1-.981-.977Zm1.955,14.729h-9A1.422,1.422,0,0,1,1.321,14.77V2.23A1.422,1.422,0,0,1,2.734.821H8.959v2.6a1.8,1.8,0,0,0,1.8,1.8h2.386V14.77A1.42,1.42,0,0,1,11.735,16.179Zm0,0" fill="#1e1e2e"/>
                                            <path id="Path_8708" data-name="Path 8708" d="M96.341,401.934H89.583a.411.411,0,1,0,0,.821h6.763a.411.411,0,1,0,0-.821Zm0,0" transform="translate(-85.728 -388.589)" fill="#1e1e2e"/>
                                            <path id="Path_8709" data-name="Path 8709" d="M121.14,176.514a.408.408,0,0,0,.6,0l2.406-2.583a.41.41,0,0,0-.6-.558l-1.7,1.819v-4.484a.411.411,0,0,0-.821,0v4.484l-1.692-1.819a.41.41,0,0,0-.6.558Zm0,0" transform="translate(-114.205 -164.643)" fill="#1e1e2e"/>
                                        </g>
                                    </svg>

                                    <text id="Open_an_Account_Now" data-name="Open an Account Now" transform="translate(22 15)" fill={`${color ? color : 'white'}`} font-size="16"  font-weight="400"><tspan x="0" y="0">{text}</tspan></text>
                                </g>
                            </svg>




                            :
                            <svg id="Button_-_Open_an_Account_Now" data-name="Button - Open an Account Now" xmlns="http://www.w3.org/2000/svg" width="238" height="44" viewBox="0 0 238 44">
                                <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke="#fff" stroke-width="1" opacity="0.3">
                                    <rect width="238" height="44" rx="22" stroke="none"/>
                                    <rect x="0.5" y="0.5" width="237" height="43" rx="21.5" fill="none"/>
                                </g>
                                <g id="Rectangle_493" data-name="Rectangle 493" fill="none" stroke="#fff" stroke-width="1" stroke-dasharray="2 520" opacity="0">
                                    <rect width="238" height="44" rx="22" stroke="none"/>
                                    <rect x="0.5" y="0.5" width="237" height="43" rx="21.5" fill="none"/>
                                </g>
                                <text id="Open_an_Account_Now" data-name="Open an Account Now" transform="translate(36 27)" fill={`${color ? color : 'white'}`} font-size="16"  font-weight="400"><tspan x="0" y="0">{text}</tspan></text>
                            </svg>

                    }


                    {/*<svg className={'ok'}><rect x="0.75" y="0.75" rx="1.5" width="1.5" height="1.5"></rect><rect x="0.75" y="0.75" rx="1.5" width="1.5" height="1.5"></rect></svg>*/}
                </Link>



            ) : (
                <Link to={externals ? {pathname: externals} : src} target="_blank" >
                    {
                        icon ?


                            <svg id="Button_-_Open_an_Account_Now_2" data-name="Button - Open an Account Now 2" width="256" height="44" viewBox="0 0 256 44">
                                <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke={`${color ? color : 'white'}`} stroke-width="1" opacity="0.5">
                                    <rect width="256" height="44" rx="22" stroke="none"/>
                                    <rect x="0.5" y="0.5" width="255" height="43" rx="21.5" fill="none"/>
                                </g>
                                <g id="Rectangle_493" data-name="Rectangle 493" fill="none" stroke={`${color ? color : 'white'}`} stroke-width="1" stroke-dasharray="1 520" opacity="0">
                                    <rect width="256" height="44" rx="22" stroke="none"/>
                                    <rect x="0.5" y="0.5" width="255" height="43" rx="21.5" fill="none"/>
                                </g>
                                <g id="Group_4825" data-name="Group 4825" transform="translate(36 12)">
                                    <text id="Open_an_Account_Now" data-name="Open an Account Now" transform="translate(0 15)" fill={`${color ? color : 'white'}`} font-size="16"  font-weight="400"><tspan x="0" y="0">{text}</tspan></text>
                                    <g id="Group_4824" data-name="Group 4824" transform="translate(174 4.5)">
                                        <g id="Group_4823" data-name="Group 4823" transform="translate(5)">
                                            <line id="Line_9" data-name="Line 9" x2="5" y2="5" fill="none" stroke={`${color ? color : 'white'}`} strokeLinecap="round" stroke-width="1"/>
                                            <line id="Line_10" data-name="Line 10" x1="5" y2="5" transform="translate(0 5)" fill="none" stroke={`${color ? color : 'white'}`} strokeLinecap="round" stroke-width="1"/>
                                        </g>
                                        <line id="Line_11" data-name="Line 11" x2="10" transform="translate(0 5)" fill="none" stroke={`${color ? color : 'white'}`} strokeLinecap="round" stroke-width="1"/>
                                    </g>
                                </g>
                            </svg>
                            :
                            <svg id="Button_-_Open_an_Account_Now" data-name="Button - Open an Account Now" xmlns="http://www.w3.org/2000/svg" width="238" height="44" viewBox="0 0 238 44">
                                <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke={`${color ? color : 'white'}`} stroke-width="1" opacity="0.3">
                                    <rect width="175" height="44" rx="22" stroke="none"/>
                                    <rect x="0.5" y="0.5" width="174" height="43" rx="21.5" fill="none"/>
                                </g>
                                <g id="Rectangle_493" data-name="Rectangle 493" fill="none" stroke={`${color ? color : 'white'}`} stroke-width="1" stroke-dasharray="2 520" opacity="0">
                                    <rect width="175" height="44" rx="22" stroke="none"/>
                                    <rect x="0.5" y="0.5" width="174" height="43" rx="21.5" fill="none"/>
                                </g>
                                <text id="Open_an_Account_Now" data-name="Open an Account Now" transform="translate(36 27)" fill={`${color ? color : 'white'}`} font-size="16"  font-weight="400"><tspan x="0" y="0">{text}</tspan></text>
                            </svg>

                    }
                </Link>
            )}


        </StyledBtn>
    )
};

const StyledBtn = styled.div`
  &.dc-btn {
    margin: ${props => props.margin || '0'};
    width: ${props => props.width || '100%'};
    height: ${props => props.height || '44'}px;
    cursor: pointer;

    a {
      display: flex;
      width: fit-content;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-size: ${props => props.fontSize || '16'}px;
      font-weight: ${props => props.fontWeight || 400};
      margin: 0;
      line-height: ${props => props.lineHeight || '24'}px;
      background-color: ${props => props.background || `transparent`};
      position: relative;
      border-radius: ${props => props.borderRadius || '22'}px;
      overflow: hidden;
      z-index: 0;
      transition: border 1s cubic-bezier(.25, .74, .22, .99);
      box-sizing: border-box;
      color: ${props => props.color || `#3C3C3B`};

      svg {
        width: auto;
        height: 100%;
        #Rectangle_493 {
          transition: all 1s cubic-bezier(.25, .74, .22, .99);
        }
      }

      span {
        transition: color 1s cubic-bezier(.25, .74, .22, .99);
        color: ${props => props.color || `#3C3C3B`};
        position: relative;
        margin-top: 5px;
        z-index: 2;
        line-height: 45px;
      }


      &:hover {
        svg {
          line {
            stroke: ${props => props.hoverColor || '#FFF'};
          }
          #Rectangle_493 {
            opacity: 1;
            stroke-dasharray: 590px;
          }
        }
        &:before {
          transform: translate(0);
        }
      }

      &:focus {
        color: #222222;
      }

   



      &:hover {
        color: #222222;
      }



    }

    .ok {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: visible;
      transform: translateX(0.25px);
      margin: 0;

      rect {
        rx: calc(20 / 2);
        ry: calc(20 / 2);
        stroke: #3C3C3B;
      }
    }
  }

`;


export default React.memo(Button);
