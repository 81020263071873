import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import About from "../../components/About/About";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import doctorsReducer, {fetchPosts,fetchChild} from "../../api/redux/doctors";

import FilterPage from "../../components/Doctors/DoctorsFilterPage";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import reactHtmlParser from "react-html-parser";


const DoctorsListing = () => {
    const dispath = useDispatch()
    const getData = useSelector(state => state.doctorsReducer)

    // api call
    useEffect(() => {
        let apiUrl = apiEndPoints.CHILD_PAGE
        let apiUrlSection = apiEndPoints.SECTIONS

        let api_params = {
            [ApiParamKey.page_id]: 30,
            [ApiParamKey.sections]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }
        let api_params_sections = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'doctors',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }

        dispath(fetchChild([apiUrl, api_params]))
        dispath(fetchPosts([apiUrlSection, api_params_sections]))
    }, [])

    // filter data
    const BannerData = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'doctor-banner');
    const DoctorsData = getData?.posts;

    const page_data = getData?.posts?.data?.page_data;
    return (
        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.meta_title ? page_data?.meta_title + ' | ' : ''}`}National Heart
                    Foundation</title>

                <meta name="meta:title" content={page_data?.meta_title}/>
                <meta name="description" content={page_data?.meta_description}/>
                <meta property="og:title" content={page_data?.og_title}/>
                <meta property="og:subtitle" content={page_data?.og_title}/>
                <meta property="og:description" content={page_data?.og_description}/>
                <meta property="og:image" content={BannerData?.images?.list?.[0]?.full_path}/>
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>
            <StyledComponent>
                {
                    BannerData &&
                    <InnerBanner title={reactHtmlParser(BannerData?.section_data?.short_desc ? BannerData?.section_data?.short_desc : '')}
                                 subtitle={reactHtmlParser(BannerData?.section_data?.description ? BannerData?.section_data?.description : '')}
                                 breadcrumbs={reactHtmlParser(BannerData?.section_data?.subtitle ? BannerData?.section_data?.subtitle : '')} img={BannerData?.images?.list[0]?.full_path ? BannerData?.images?.list[0]?.full_path : ''}/>
                }
                {
                    DoctorsData &&
                    <FilterPage data={DoctorsData}/>
                }

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default DoctorsListing;
