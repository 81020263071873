import React, {useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import contactReducer, {fetchContact} from "../../api/redux/contact";
import AddressTab from "../../components/Contact/AddressTab";
import ContactForm from "../../components/Contact/ContactForm";
import AboutInfoCenter from "../../components/Contact/AboutInfoCenter";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import reactHtmlParser from "react-html-parser";
import {fetchInfo} from "../../api/redux/home";


const Contact = () => {
    const dispatch = useDispatch()

    const getData = useSelector(state => state.contactReducer)
    // api call
    useEffect(() => {
        let apiUrl = apiEndPoints.SECTIONS
        let apiUrlSection = apiEndPoints.PAGE_POST_DATA

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'contact',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }
        let api_params_sections = {
            [ApiParamKey.page_id] : 57,
            [ApiParamKey.type]: 'page',
            [ApiParamKey.posttype]: 'yes',
        }

        dispatch(fetchContact([apiUrl, api_params]))
        dispatch(fetchInfo([apiUrlSection, api_params_sections]))
    }, [])

    // filter data
    const BannerData = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'contact-banner');
    const Contactoverview = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'contact-map');
    const Consultation = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'contact-us');

    const Infocenter = getData?.info;

    const page_data = getData?.posts?.data?.page_data;
    return (
        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.meta_title ? page_data?.meta_title + ' | ' : ''}`}National Heart
                    Foundation</title>

                <meta name="meta:title" content={page_data?.meta_title}/>
                <meta name="description" content={page_data?.meta_description}/>
                <meta property="og:title" content={page_data?.og_title}/>
                <meta property="og:subtitle" content={page_data?.og_title}/>
                <meta property="og:description" content={page_data?.og_description}/>
                <meta property="og:image" content={BannerData?.images?.list?.[0]?.full_path}/>
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>
            <StyledComponent>
                {
                    BannerData &&
                    <InnerBanner
                        title={reactHtmlParser(BannerData?.section_data?.short_desc ? BannerData?.section_data?.short_desc : '')}
                        subtitle={reactHtmlParser(BannerData?.section_data?.description ? BannerData?.section_data?.description : '')}
                        breadcrumbs={reactHtmlParser(BannerData?.section_data?.subtitle ? BannerData?.section_data?.subtitle : '')}
                        img={BannerData?.images?.list[0]?.full_path ? BannerData?.images?.list[0]?.full_path : ''}/>
                }

                {
                    Contactoverview &&
                    <AddressTab data={Contactoverview}/>
                }

                {
                    Consultation &&
                    <ContactForm data={Consultation}/>
                }
                {
                    Infocenter &&
                    <AboutInfoCenter data={Infocenter}/>
                }

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default Contact;
