import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Container, Modal, Row} from "react-bootstrap";
import {CSSPlugin, gsap, TimelineLite} from "gsap";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Title from "../Title";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import ButtonArrow from "../ButtonArrow";
import ManagementSingle from "../Home/ManagementSingle";

const MyComponent = ({title, id, padding, getAfterHeight, bg, img, full, project, nolnk, remove, data, after_bg}) => {
    gsap.registerPlugin(CSSPlugin);
    const tl = new TimelineLite()
    const info = data?.posts?.list;
    const [getAfter, setgetAfter] = useState(90)
    const [offset, setOffset] = useState(90)

    const [height, setHeight] = useState()
    const [width, setWidth] = useState()
    // modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        setHeight(window.innerHeight)
        setWidth(window.innerWidth)
    }, [])




    useEffect(() => {

        window.addEventListener('load', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [data])


    // custom scroll height set
    const [scrollHeight, setScrollHeight] = useState()

    const [popupData, setPopupData] = useState('')
    // handle popup
    const handlePopup = (data) => {
        setTimeout(() => {
            setScrollHeight(window.innerHeight - (document.querySelector('.dc-popup__header')?.clientHeight))
        }, 100)

        setPopupData(data)
        setShow(true)
    }


    // half background calculation
    const [theHeight, setTheHeight] = useState()
    useEffect(() => {
        setTimeout(() => {
            if (window.innerWidth > 767) {
                const titleHeight = document.querySelector('.management-team .title')?.clientHeight + 90
                const getItemHeight = document.querySelector('.management-team .leaders__slider__single__img')?.clientWidth / 2
                setTheHeight((titleHeight + getItemHeight + 200))
            } else {
                const titleHeight = document.querySelector('.management-team .title')?.clientHeight
                const getItemHeight = document.querySelector('.management-team .leaders__slider__single__img')?.clientWidth / 2
                setTheHeight((titleHeight + getItemHeight + 290))
            }
        }, 600)


        window.addEventListener('resize', () => {
            if (window.innerWidth > 767) {
                const titleHeight = document.querySelector('.management-team .title')?.clientHeight + 90
                const getItemHeight = document.querySelector('.management-team .leaders__slider__single__img')?.clientWidth / 2
                setTheHeight((titleHeight + getItemHeight + 200))
            } else {
                const titleHeight = document.querySelector('.management-team h2')?.clientHeight
                const getItemHeight = document.querySelector('.management-team .leaders__slider__single__img')?.clientWidth / 2
                setTheHeight((titleHeight + getItemHeight + 290))
            }
        })

    }, [data])

    const awardSlider = data?.posts?.list;
    return (
        <>
            <StyledComponent theHeight={theHeight} getAfterHeight={getAfter} remove={remove} id={`${id ? id : ''}`}
                             offset={offset}
                             className={`pionneering management-team pt-200 pb-200`}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="d-flex navigation_wrapper">
                                <div className="title">
                                    <Title text={data?.section_data?.subtitle} color={'#1E1E2E'}/>
                                </div>
                                <ul className="navigation">
                                    <div className={'navigation__icon'}>
                                        <li className={'prev_swipper1'}>
                                            <svg id="Prev" xmlns="http://www.w3.org/2000/svg" width="44" height="44"
                                                 viewBox="0 0 44 44">
                                                <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke="#fff"
                                                   stroke-width="1" opacity="0.2">
                                                    <rect width="44" height="44" rx="22" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="none"/>
                                                </g>
                                                <g id="Group_4824" data-name="Group 4824" transform="translate(17 17)">
                                                    <g id="Group_4823" data-name="Group 4823">
                                                        <line id="Line_9" data-name="Line 9" x1="5" y2="5" fill="none"
                                                              stroke="#fff" strokeLinecap="round" stroke-width="1"/>
                                                        <line id="Line_10" data-name="Line 10" x2="5" y2="5"
                                                              transform="translate(0 5)" fill="none" stroke="#fff"
                                                              strokeLinecap="round" stroke-width="1"/>
                                                    </g>
                                                    <line id="Line_11" data-name="Line 11" x1="10" transform="translate(0 5)"
                                                          fill="none" stroke="#fff" strokeLinecap="round" stroke-width="1"/>
                                                </g>
                                                <g id="Rectangle_5661" data-name="Rectangle 5661"
                                                   transform="translate(0 44) rotate(-90)" fill="none" stroke="#fff"
                                                   stroke-width="1" strokeDasharray="1 140" opacity="0">
                                                    <rect width="44" height="44" rx="22" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="none"/>
                                                </g>
                                            </svg>
                                        </li>
                                        <li className={'next_swipper1'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                                <g id="Next" transform="translate(16429 -2014)">
                                                    <g id="Rectangle_492" data-name="Rectangle 492"
                                                       transform="translate(-16429 2014)" fill="none" stroke="#fff"
                                                       stroke-width="1" opacity="0.2">
                                                        <rect width="44" height="44" rx="22" stroke="none"/>
                                                        <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="none"/>
                                                    </g>
                                                    <g id="Group_4824" data-name="Group 4824"
                                                       transform="translate(-17065.5 434.5)">
                                                        <g id="Group_4823" data-name="Group 4823" transform="translate(11 4)">
                                                            <line id="Line_9" data-name="Line 9" x2="5" y2="5"
                                                                  transform="translate(647.5 1592.5)" fill="none" stroke="#fff"
                                                                  strokeLinecap="round" stroke-width="1"/>
                                                            <line id="Line_10" data-name="Line 10" x1="5" y2="5"
                                                                  transform="translate(647.5 1597.5)" fill="none" stroke="#fff"
                                                                  strokeLinecap="round" stroke-width="1"/>
                                                        </g>
                                                        <line id="Line_11" data-name="Line 11" x2="10"
                                                              transform="translate(653.5 1601.5)" fill="none" stroke="#fff"
                                                              strokeLinecap="round" stroke-width="1"/>
                                                    </g>
                                                    <g id="Rectangle_5661" data-name="Rectangle 5661"
                                                       transform="translate(-16429 2058) rotate(-90)" fill="none" stroke="#fff"
                                                       stroke-width="1" strokeDasharray="1 140" opacity="0">
                                                        <rect width="44" height="44" rx="22" stroke="none"/>
                                                        <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="none"/>
                                                    </g>
                                                </g>
                                            </svg>

                                        </li>
                                    </div>

                                </ul>

                                <ul className={'navigation-mobile'}>
                                    <div className={'navigation__icon'}>
                                        <li className={'prev_swipper1'}>
                                            <svg id="Button_-_Nav_-_Prev_Dark_" data-name="Button - Nav - Prev (Dark)" xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
                                                <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke="#1e1e2e" stroke-width="1" opacity="0.2">
                                                    <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                                </g>
                                                <g id="Group_4824" data-name="Group 4824" transform="translate(16 15)">
                                                    <g id="Group_4823" data-name="Group 4823">
                                                        <line id="Line_9" data-name="Line 9" x1="5" y2="5" fill="none" stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                                        <line id="Line_10" data-name="Line 10" x2="5" y2="5" transform="translate(0 5)" fill="none" stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                                    </g>
                                                    <line id="Line_11" data-name="Line 11" x1="10" transform="translate(0 5)" fill="none" stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                                </g>
                                                <g id="Rectangle_5661" data-name="Rectangle 5661" transform="translate(0 41) rotate(-90)" fill="none" stroke="#e72e4b" stroke-width="1" stroke-dasharray="130 130">
                                                    <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                                </g>
                                            </svg>
                                        </li>
                                        <li className={'next_swipper1'}>
                                            <svg id="Button_-_Nav_-_Next_Dark_" data-name="Button - Nav - Next (Dark)" xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
                                                <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke="#1e1e2e" stroke-width="1" opacity="0.2">
                                                    <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                                </g>
                                                <g id="Group_4824" data-name="Group 4824" transform="translate(-637.5 -1581.5)">
                                                    <g id="Group_4823" data-name="Group 4823" transform="translate(11 4)">
                                                        <line id="Line_9" data-name="Line 9" x2="5" y2="5" transform="translate(647.5 1592.5)" fill="none" stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                                        <line id="Line_10" data-name="Line 10" x1="5" y2="5" transform="translate(647.5 1597.5)" fill="none" stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                                    </g>
                                                    <line id="Line_11" data-name="Line 11" x2="10" transform="translate(653.5 1601.5)" fill="none" stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                                </g>
                                                <g id="Rectangle_5661" data-name="Rectangle 5661" transform="translate(0 41) rotate(-90)" fill="none" stroke="#e72e4b" stroke-width="1" stroke-dasharray="130 130">
                                                    <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                                </g>
                                            </svg>

                                        </li>
                                    </div>

                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className='about-section__bottom'>
                    <Row>
                        <Swiper
                            loop={true}
                            spaceBetween={30}
                            slidesPerView={3}
                            slideNextClass={'.next'}
                            allowSlideNext={true}
                            slidePrevClass={'.prev'}
                            allowSlidePrev={true}
                            allowTouchMove={true}
                            longSwipesMs={900}
                            speed={900}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 15,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 15,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },

                                1440: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                1920: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },

                            }}
                            navigation={{
                                prevEl: '.prev_swipper1',
                                nextEl: '.next_swipper1  ',
                            }}
                            modules={[Pagination, Navigation]}

                        >
                            {
                                awardSlider && awardSlider.length>0 &&
                                awardSlider.map((i)=>{
                                    return(
                                        <SwiperSlide onClick={() => handlePopup()} key={i} className={'horizontal-scroll'}>
                                            <ManagementSingle
                                                img={i?.images?.[0]?.full_path}
                                                name={i?.data?.subtitle}
                                                designation={i?.data?.short_desc}
                                            />
                                        </SwiperSlide>
                                    )

                                })
                            }


                        </Swiper>
                    </Row>
                </Container>
            </StyledComponent>
        </>

    );
};
const StyledComponent = styled.section`

  
  background-color: #F6F9F8;
  position: relative;
  overflow: hidden;

  .leaders__slider {
    &__single {
      &__img {
        padding-top: calc(370 / 370 * 100%);
        position: relative;

        .global-image {
          overflow: hidden;

          img {
            transform: scale(1.01);
            transition: 1.4s ease;
          }
        }
      }

      &:hover {
        .global-image img {
          transform: scale(1.06) !important;
        }
      }

      &__content {
        margin-top: 20px;
        display: flex;
        flex-direction: column-reverse;

        h4 {
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
        }

        p {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          color: #1e1e2eb3;
        }
      }
    }
  }
@media (min-width: 768px) {
  .mobile-button {
    display: none;
  }
}
  .navigation {
    display: flex;

    &__icon {
      cursor: pointer;;
      display: flex;

      li {
        cursor: pointer;

        &:first-child {
          margin-right: 20px;
        }

        svg {
          height: 41px;
          g,line{
            stroke:black;
            transition: stroke 0.2s ease;
          }
          path, #Rectangle_5661 {
            //transition: stroke-dasharray 0.3s ease-in-out;
            transition: all 1s cubic-bezier(.25, .74, .22, .99);
          }
        }

        &:hover {
          svg {
            g,line{
              stroke:#E72E4B;
              transition: stroke 0.2s ease;
            }
            #Rectangle_5661 {
              opacity: 1;
              stroke-dasharray: 140;
            }
          }
        }
      }
    }
  }

  .title_wrapper {
    display: flex;
  }

  .swiper-initialized {
    padding-right: ${props => props.offset ? props.offset + 15 + 'px' : '90px'};
    @media (max-width: 767px) {
      padding-right: 0 !important;
      width: 100%;
    }
  }
  .swiper-button-prev, .swiper-button-next {
    opacity: 0;
    visibility: hidden;
  }

  .navigation_wrapper {
    justify-content: space-between;
    margin-bottom: 80px;


    @media (max-width: 767px) {
      margin-bottom: 0px;
    }

    .title {
      h3 {
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        color: #4F616B;
      }
    }

    .d-flex {
      justify-content: space-between;
    }
    }
  }

  .swiper-wrapper {
    height: auto !important;
  }

  .about-section__bottom {
    padding-left: ${props => props.offset ? props.offset + 20 + 'px' : '90px'};
    position: relative;
    .row{
      margin-left: -0.4%;
    }

    @media (max-width: 767px) {
      padding-left: 15px !important;
      padding-right: 30px !important;
    }

    &__single {
      max-width: 100%;
    }
  }
  
  @media (min-width: 768px){
    .navigation-mobile{
      display: none;
    }
  }

  @media (max-width: 767px) {
    .navigation-mobile {

      .navigation__icon{
        margin-bottom: 30px;
        cursor: pointer;;
        display: flex;

        li:first-child {
          margin-right: 20px;
        }
      }
    }
    
    .navigation_wrapper {
      flex-direction: column;
    }

    .navigation {
      display: none;


      //.navigation {
      //  min-width: 100%;
      //  margin-top: 40px;
      //  margin-bottom: 20px;
      //}

      .about-section__bottom__single__inner span {
        top: 55px;
        left: 55px;
      }
    }

    .mobile-button {
      .dc-btn {
        margin: 40px 0 0 0;
      }
    }
  }

`;
export default React.memo(MyComponent);