import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Founder from "../../components/TheFounder/Founder";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchFounder} from "../../api/redux/founder";
import FounderSlider from "../../components/TheFounder/FounderSlider";
import FounderPublications from "../../components/TheFounder/FounderPublications";
import Awards from "../../components/TheFounder/Awards";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";


const MyComponent = () => {
    const dispatch = useDispatch()

    // api call
    useEffect(() => {
        let apiUrl = apiEndPoints.SECTIONS

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'founder',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }

        dispatch(fetchFounder([apiUrl, api_params]))
    }, [])

    const getData = useSelector(state=> state.founderReducer)
    const founderSection = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'founder-section');
    const gallerySection = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'gallery');
    const awardSection = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'awards');
    const booksandpublications = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'founder-publications-and-books');

    const page_data = getData?.posts?.data?.page_data;

    return (
        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.meta_title ? page_data?.meta_title + ' | ' : ''}`}National Heart
                    Foundation</title>

                <meta name="meta:title" content={page_data?.meta_title}/>
                <meta name="description" content={page_data?.meta_description}/>
                <meta property="og:title" content={page_data?.og_title}/>
                <meta property="og:subtitle" content={page_data?.og_title}/>
                <meta property="og:description" content={page_data?.og_description}/>
                <meta property="og:image" content={founderSection?.images?.list?.[0]?.full_path}/>
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>
            <StyledComponent>
                {
                    founderSection &&
                    <Founder data={founderSection}/>
                }
                {
                    gallerySection &&
                    <FounderSlider data={gallerySection}/>
                }
                {
                    awardSection &&
                    <Awards data={awardSection}/>
                }
                {/*{*/}
                {/*    OurPublicationsHome &&*/}
                {/*    <PublicationsAndBooks data={OurPublicationsHome}/>*/}
                {/*}*/}

                <FounderPublications data={booksandpublications}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
