import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import SingleControl from "./SingleControl";
import ButtonViewAll from "../ButtonViewAll";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data, dataSlider}) => {

    // filter data
    const PreventionData = data?.section_data;
    const Preventionslider = dataSlider;

    return (
        <StyledComponent className={'pb-200'}>
            <Container>
                <Row>
                    <Col md={4}>
                        <h4 className={'title'}>{reactHtmlParser(PreventionData?.short_desc ? PreventionData?.short_desc : '')}</h4>
                    </Col>
                    <Col md={{span: 7, offset: 1}}>
                        {reactHtmlParser(PreventionData?.description ? PreventionData?.description : '')}
                    </Col>
                </Row>
                <Row>
                    <Col md={{span: 7, offset: 5}}>
                        <ButtonViewAll src={'/control-prevention'} color={'black'} text={'View All'} icon={'black'}
                                       hoverColor={'#E72E4B'} margin={'60px 0 60px 0'}/>
                    </Col>
                </Row>

                <Row className={'box-body'}>
                    {Preventionslider?.length > 0 && Preventionslider?.map(i => (
                        <Col md={4}>
                            <SingleControl link={`/control-prevention/slug?details=${reactHtmlParser(i?.page_data?.slug)}`} image={i?.images?.list[0]?.full_path}
                                           text={i?.page_data?.subtitle} titleColor={'#1E1E2E'}/>
                        </Col>
                    ))}
                </Row>

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F6F9F8;
  margin-top: -1px;

  .box-body {
    .col-md-4 {
      margin-bottom: 60px;
      padding-bottom: 0;
    }

    .col-md-4:nth-last-child(-n+3) {
      margin-bottom: 0px;
    }
    @media (max-width: 767px){
      .col-md-4 {
        margin-bottom: 0px;
        &:last-child{
          .box-image__single{
            margin-bottom: 0;
          }
        }
      }
    }
  }
  @media (max-width: 767px){
    .title {
      margin-bottom: 40px;
    }
    
  }
`;

export default React.memo(MyComponent);
