import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {fetchPosts, fetchPostDetail} from "../../api/redux/ufat";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";

import InnerBannerTwo from "../../components/InnerBannerTwo";
import UfatDetails from "../../components/UFAT/UfatDetails";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchChild} from "../../api/redux/ufat";
import reactHtmlParser from "react-html-parser";

const UfatIndex = () => {

    const dispath = useDispatch()
    const getData = useSelector(state=> state.ufatReducer)

    // api call
    useEffect(() => {
        let apiUrl = apiEndPoints.CHILD_PAGE
        let apiUrlSection = apiEndPoints.SECTIONS

        let api_params = {
            [ApiParamKey.page_id]: 341,
            [ApiParamKey.sections]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.get_section]: 'yes',
        }
        let api_params_sections = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'ufat-page',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }
        dispath(fetchChild([apiUrl, api_params]))
        dispath(fetchPosts([apiUrlSection, api_params_sections]))
    }, [])

    // filter data
    const BannerData = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'ufat-banner');
    const ufatData = getData?.child;

    const page_data = getData?.posts?.data?.page_data;
    return (

        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.meta_title ? page_data?.meta_title + ' | ' : ''}`}National Heart
                    Foundation</title>

                <meta name="meta:title" content={page_data?.meta_title}/>
                <meta name="description" content={page_data?.meta_description}/>
                <meta property="og:title" content={page_data?.og_title}/>
                <meta property="og:subtitle" content={page_data?.og_title}/>
                <meta property="og:description" content={page_data?.og_description}/>
                <meta property="og:image" content={BannerData?.images?.list?.[0]?.full_path}/>
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledSection>
                {
                    BannerData &&
                    <InnerBannerTwo title={reactHtmlParser(BannerData?.section_data?.short_desc ? BannerData?.section_data?.short_desc : '')}
                                    img={BannerData?.images?.list[0]?.full_path ? BannerData?.images?.list[0]?.full_path : ''}/>
                }
                {
                    ufatData &&
                    <UfatDetails data={ufatData}/>
                }
            </StyledSection>
        </HelmetProvider>
    );
};

const StyledSection = styled.section`

`;

export default UfatIndex;
