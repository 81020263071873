import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import {Img} from "../Img";
import ButtonOutlineHover from "../ButtonShort";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data,id,bg,after}) => {
    const title = data?.section_data?.short_desc;
    const desc = data?.section_data?.description;
    const image = data?.images?.list[0]?.full_path;
    const [offset, setOffset] = useState(90)
    useEffect(() => {


        window.addEventListener('load', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [data])

    return (
        <StyledComponent   className={'pb-300'}>
            <Container fluid className={`counter `}>
                <Row>
                    <Col lg={6} className='lube-testing__text'>
                        <div className={'lube-testing__text__body'} style={{paddingLeft: offset + 15 + 'px'}}>
                            {reactHtmlParser(data?.section_data?.description)}
                        </div>
                    </Col>
                    <Col lg={6} className='lube-testing__img'>
                        <div className="lube-testing__img__inner">
                            <div className="image_wrapper">
                                <Img src={data?.images?.list?.[0]?.full_path}/>
                            </div>
                        </div>
                        <div className={`lube-testing__img__box`}
                             style={{left: offset + 30 + 'px'}} data-speed="1.1">
                            <div className="lube-testing__img__box__inner">
                                <Title text={'National Executive <br/> Council'} color={'#f9f9f9'} fontWeight={500} lineHeight={40} fontSize={32} textTransform={'Inherit'}/>
                                <ButtonOutlineHover src={'/executive-council'} color={'white'} text={'Read More'} icon={'white'} hoverColor={'white'}/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #E4ECF0;


  .image_wrapper{
    position: relative;
    padding-top: calc(550 / 668 * 100%)
  }
  .lube-testing__img {
    
    position: relative;
    padding: 0;
    &__inner {
      position: relative;
      min-height: 100%;
    }

    &__box {
      width: 50%;
      position: absolute;
      bottom: -120px;
      left: 100px;

      &__inner {
        //padding-top: calc(224 / 370 * 100%);
        position: relative;
        background-color: #E72E4B;
        height: 224px;
        width: 370px;
        .title {
          position: absolute;
          top: 30px;
          left: 30px;
          right: 30px;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          white-space: normal;
          overflow: hidden;
          width: 100%!important;
        }

        @media(max-width: 767px){
          width: 100%;
        }
      }

      .dc-btn {
        position: absolute;
        bottom: 30px;
        left: 30px;
      }
    }
  }
  //.global-image{
  //  img{
  //    transform: unset!important;
  //  }
  //}

  .lube-testing__text {
    padding-right: 30px;
    padding-left: 0;
    h6{
      padding-top: 80px;
      padding-bottom: 40px;
      color: #1E1E2E;
    }
    p{
      padding-bottom: 80px;
      color: #1E1E2E;
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
    }
    &__body{
      height: 100%;
      background-color: #F6F9F8;
      padding-right: 70px;
    }
    @media(max-width: 767px){
      padding-left: 15px;
    }
  }

  @media (min-width: 768px) and (max-width: 1150px) {
    .lube-testing__img__box {
      width: 65%;
      bottom: -80px;

      &__inner .title {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 992px){
    padding-bottom: 100px!important;
  }

  @media (max-width: 767px) {
    padding-bottom: 0;
    .lube-testing__text__body{
      padding-right: 15px;
      padding-top: 40px;
      p{
        padding-bottom: 60px;
      }
    }
    .lube-testing__img__box__inner .title{
      left: 15px;
      right: 15px;
    }
    .lube-testing__img__box .dc-btn{
      left: 15px;
    }
    .counter{
      padding-top: 0 !important;
    }
    .col-sm-5, .col-sm-6 {
      min-width: 100%;
      margin: 0;
    }

    .lube-testing__img__box {
      //bottom: -150px;
      width: 100%;
      position: relative;
      left: unset !important;
      right: unset !important;
      bottom: unset !important;
    }


    .lube-testing__text {
      padding: 0 !important;
    }

  }

  @media(max-width: 767px){
    .lube-testing__img{
      &__inner {
        min-height: 0;
      }
    }
  }

  @media(max-width: 991px){
    .row{
      flex-direction: column;
      .lube-testing__img , .lube-testing__text{
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 0px !important;
        margin: 0 !important;
      }
    }
  }

`;

export default React.memo(MyComponent);
