import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {fetchHome, fetchInfo} from "../../api/redux/home";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import Banner from "../../components/Home/Banner";
import TheFounder from "../../components/Home/TheFounder";
import OurDoctors from "../../components/Home/OurDoctors";
import InfoCenter from "../../components/Home/InfoCenter";
import NewsEvents from "../../components/Home/NewsEvents";
import ControlPrevention from "../../components/Home/ControlPrevention";
import OurDepartment from "../../components/Home/OurDepartment";
import HomeAboutTwo from "../../components/Home/HomeAbout";
import AcademicActivites from "../../components/Home/AcademicActivites";
import Research from "../../components/Home/Research";
import ChildHeart from "../../components/Home/ChildHeart";
import OnlineSupportNew from "../../components/Home/OnlineSupportNew";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import CCD from "../../components/Home/CCD";
import HomeActvities from "../../components/Home/HomeActvities";
import Quote from "../../components/Home/Quote";
import PublicationsAndBooks from "../../components/Home/PublicationsAndBooks";
import {fetchDepartment} from "../../api/redux/appoinment";



const Home = () => {
    const dispatch = useDispatch()
    const getData = useSelector(state => state.homeReducer)
    // api call
    useEffect(() => {

        let apiUrl = apiEndPoints.SECTIONS
        let apiUrlSection = apiEndPoints.PAGE_POST_DATA

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'home',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }
        let api_params_sections = {
            [ApiParamKey.page_id]: 57,
            [ApiParamKey.type]: 'page',
            [ApiParamKey.posttype]: 'yes',
        }

        dispatch(fetchHome([apiUrl, api_params]))
        dispatch(fetchInfo([apiUrlSection, api_params_sections]))
        dispatch(fetchDepartment([apiEndPoints.DOCTOR_LIST]))

    }, [])

    // filter data
    const BannerData = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'home-banner-silder');
    const HomeOverview = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'home-overview');
    const FounderData = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'the-founder');
    const HomeQuotes = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'home-quotes');
    const OurActivities = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'home-our-activities');
    const Department = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'our-departments');
    const Doctors = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'our-doctors');
    const DoctorsPost = getData?.posts?.doctor_cats;
    const AcademicActivities = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'home-academic-activities');
    const NewsEventsHome = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'home-news--events');
    const OurPublicationsHome = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'publications-and-books');
    const ResearchHome = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'home-research');
    const PreventionHome = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'nhfb-ncd');
    const CCDHome = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'home-nhf-ccd');
    const Childheart = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'child-heart');
    const Onlinesupport = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'home-tobacco-cessation-clinic-');


    const OurActivitiesSlider = getData?.posts?.our_activities?.list;
    const DepartmentSlider = getData?.posts?.departments?.list;
    const AcademicActivitiesSlider = getData?.posts?.academic_activities?.list;
    const NewsEventsSlider = getData?.posts?.news?.list;
    const ResearchSlider = getData?.posts?.research?.list;
    const PreventionSlider = getData?.posts?.prevent_control?.list;
    const Infocenter = getData?.info;
    const page_data = getData?.posts?.data?.page_data;


    return (
        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.meta_title ? page_data?.meta_title + ' | ' : ''}`}National Heart
                    Foundation</title>

                <meta name="meta:title" content={page_data?.meta_title}/>
                <meta name="description" content={page_data?.meta_description}/>
                <meta property="og:title" content={page_data?.og_title}/>
                <meta property="og:subtitle" content={page_data?.og_title}/>
                <meta property="og:description" content={page_data?.og_description}/>
                <meta property="og:image" content={BannerData?.images?.list?.[0]?.full_path}/>
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledSection>
                {
                    BannerData &&
                    <Banner data={BannerData}/>
                }

                {
                    HomeOverview &&
                    <HomeAboutTwo data={HomeOverview}/>
                }
                {
                    FounderData &&
                    <TheFounder data={FounderData}/>
                }
                {/*{*/}
                {/*    PresidentMessage &&*/}
                {/*    <ThePresident data={PresidentMessage}/>*/}
                {/*}*/}
                {/*{*/}
                {/*    SecretaryMessage &&*/}
                {/*    <TheDirector data={SecretaryMessage}/>*/}
                {/*}*/}
                {
                    HomeQuotes &&
                    <Quote data={HomeQuotes}/>
                }

                {
                    OurActivities &&
                    <HomeActvities data={OurActivities} dataSlider={OurActivitiesSlider}/>
                }

                {/*{*/}
                {/*    OurActivities &&*/}
                {/*    <HomeOurActivities data={OurActivities} dataSlider={OurActivitiesSlider}/>*/}
                {/*}*/}

                {
                    Department &&
                    <OurDepartment data={Department} dataSlider={DepartmentSlider}/>
                }

                {
                    Doctors &&
                    <OurDoctors data={DoctorsPost} dataTitle={Doctors}/>
                }

                {
                    ResearchHome &&
                    <Research data={ResearchHome} dataSlider={ResearchSlider}/>
                }
                {
                    PreventionHome &&
                    <ControlPrevention data={PreventionHome} dataSlider={PreventionSlider}/>
                }

                {
                    AcademicActivities &&
                    <AcademicActivites data={AcademicActivities} dataSlider={AcademicActivitiesSlider}/>
                }
                {/*{*/}
                {/*/!*    OurPublicationsHome &&*!/*/}
                {/*/!*    <OurPublications data={OurPublicationsHome} dataSlider={OurPublicationsSlider}/>*!/*/}
                {/*/!*}*!/*/}
                {
                    OurPublicationsHome &&
                    <PublicationsAndBooks data={OurPublicationsHome}/>
                }
                {
                    Childheart &&
                    <ChildHeart data={Childheart}/>
                }

                {
                    NewsEventsHome &&
                    <NewsEvents data={NewsEventsHome} dataSlider={NewsEventsSlider}/>
                }

                {
                    CCDHome &&
                    <CCD CCDHome={CCDHome}/>
                }

                {
                    Onlinesupport &&
                    <OnlineSupportNew data={Onlinesupport}/>
                }

                {/*<ChildHeart2/>*/}
                {
                    Infocenter &&
                    <InfoCenter data={Infocenter}/>
                }
            </StyledSection>
        </HelmetProvider>
    );
};

const StyledSection = styled.section`

`;

export default Home;
