import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Form, Row} from "react-bootstrap";
import Button from "../Button";
import {useDispatch, useSelector} from "react-redux";


import {Link} from "react-router-dom";
import Select, {components} from "react-select";
import {Img} from "../Img";
import ButtonHover from "../ButtonHover";
import moment from "moment/moment";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data, search, cat}) => {

// filter API data
    const DepartmentList = data;

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? '#000' : '#221F1F',
            backgroundColor: state.isSelected ? '' : '#FFF',
            marginTop: 10,
            marginLeft: 2,
            fontSize: 20,
            paddingLeft: 25,
            overflowY: 'auto',
            // borderColor: state.isFocused ? '#FF0000' : '#CCCCCC',
        }),
        menu: (provided, state) => ({
            ...provided,
            color: '#FFF',
            backgroundColor: state.isSelected ? '' : '',
            margin: 0,
            borderRadius: 5,
            fontSize: 12,


        }),
        menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#000' : '#FFF',
            borderRadius: 0,
            fontWeight: '500',
            color: '#FFF',
            fontSize: 20,
            paddingBottom: 25,
        }),

    };


    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="16.414" viewBox="0 0 11.414 16.414">
                        <g id="Group_21092" data-name="Group 21092" transform="translate(-340.293 -2110.293)">
                            <g id="Group_21092-2" data-name="Group 21092"
                               transform="translate(1585.5 2872.5) rotate(180)">
                                <line id="Line_3557" data-name="Line 3557" x2="5" y2="5"
                                      transform="translate(1234.5 756.5)" fill="none" stroke="#fff"
                                      stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_3558" data-name="Line 3558" x1="5" y2="5"
                                      transform="translate(1239.5 756.5)" fill="none" stroke="#fff"
                                      stroke-linecap="round" stroke-width="1"/>
                            </g>
                            <g id="Group_21093" data-name="Group 21093" transform="translate(-893.5 1364.5)">
                                <line id="Line_3557-2" data-name="Line 3557" x2="5" y2="5"
                                      transform="translate(1234.5 756.5)" fill="none" stroke="#fff"
                                      stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_3558-2" data-name="Line 3558" x1="5" y2="5"
                                      transform="translate(1239.5 756.5)" fill="none" stroke="#fff"
                                      stroke-linecap="round" stroke-width="1"/>
                            </g>
                        </g>
                    </svg>

                </components.DropdownIndicator>
            )
        );
    };


    return (
        <StyledComponent id={'got-to-list'} className={'article-list pb-160'}>
            <Container>
                <Row className={'min-height'}>
                    {DepartmentList?.length > 0 && DepartmentList?.map((i, index)=>{
                        const icon = i?.images?.list?.find(f => f?.thumb === 'on')?.full_path;
                        return(
                        <Col lg={4} md={6} className='about-section__bottom__single'>

                            <div className="about-section__bottom__single__inner">
                                <Link
                                    to={`/department/${i?.page_data?.slug}`}></Link>
                                <div className="about-section__bottom__single__inner__img">
                                </div>
                                <p>{reactHtmlParser(i?.page_data?.short_desc)}</p>
                                <h4>{reactHtmlParser(i?.page_data?.title)}</h4>
                                <span>
                                     <img src={icon}/>
                                </span>
                                <div className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="11.414"
                                         viewBox="0 0 11.207 11.414">
                                        <g id="Group_15992" data-name="Group 15992" transform="translate(0.5 0.85)">
                                            <line id="Line_3858" data-name="Line 3858" x2="5" y2="5"
                                                  transform="translate(5 -0.143)" fill="none" stroke="#f9f9f9"
                                                  stroke-linecap="round" stroke-width="1"/>
                                            <line id="Line_3859" data-name="Line 3859" y1="5" x2="5"
                                                  transform="translate(5 4.857)" fill="none" stroke="#f9f9f9"
                                                  stroke-linecap="round" stroke-width="1"/>
                                            <line id="Line_3860" data-name="Line 3860" x1="10"
                                                  transform="translate(0 4.857)" fill="none" stroke="#f9f9f9"
                                                  stroke-linecap="round" stroke-width="1"/>
                                        </g>
                                    </svg>

                                </div>
                            </div>
                        </Col>
                    )
                    })}
                </Row>

            </Container>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  padding-top: 65px;
  overflow: hidden;
  background-color: #F6F9F8;

  .about-section__bottom {
    &__single {
      padding: 15px;
      max-width: 100%;

      &__inner {
        position: relative;
        padding-top: calc(370 / 370 * 100%);
        overflow: hidden;
        border-right: 1px solid #FFF;

        &:after {
          content: '';
          background-color: #1E1E2E;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 100%;
          width: 100%;
          transition: .4s ease;
          //mix-blend-mode: multiply;
        }

        a {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0px;
          z-index: 5;
        }

        &__img {
          &:after {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #1E1E2E;
            mix-blend-mode: multiply;
            opacity: 1;
            transition: .8s ease;
            transition-delay: .3s;
          }
        }

        p {
          position: absolute;
          left: 30px;
          right: 30px;
          top: 70px;
          color: #ffffff;
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          transform: translateY(-100%);
          transition: all .5s ease;
          opacity: 0;
        }

        .img-top {
          position: absolute;
          top: 40px;
          left: 30px;
          z-index: 2;
          opacity: 1;
          transition: .3s ease;
          height: 60px;
        }

        .circle {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 50px;
          width: 50px;
          border: 1px solid #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
          opacity: 1;
          transition: .3s ease;

          svg {
            color: #ffffff;
            font-size: 25px;
            transition: .3s ease;
          }
        }

        h4 {
          position: absolute;
          bottom: 30px;
          left: 30px;
          right: 30px;
          line-height: 36px;
          color: #FFFFFF;
          z-index: 2;
          transition: all .5s ease;

        }

        .icon {
          position: absolute;
          top: 30px;
          right: 30px;
          z-index: 1;
          transition: all .8s ease;
          transform: rotate(0deg);

          svg {
            li {
              transition: all .8s ease;

            }
          }

        }

        span {
          position: absolute;
          z-index: 1;
          top: 30px;
          left: 30px;
          font-size: 60px;
          font-weight: 500;
          line-height: 60px;
          color: #F9F9F9;
          transition: all .5s ease;

        }

      }

      &:hover {
        .about-section__bottom__single__inner {
          &:after {
            height: 0;
          }

          .icon {
            transform: rotate(-45deg);

            svg {
              line {
                stroke: #FFFFFF;
                fill: #FFFFFF;
              }
            }
          }

          span {
            top: -10px;
            opacity: 0;
          }

          p {
            transform: none;
            opacity: 1;
          }


          &__img:after {
            opacity: 1;
            transition-delay: 0s;
          }

          .img-top {
            transform: translateY(-20px);
            opacity: 0;
          }

          .circle {
            transform: scale(1.9);
            opacity: 0;

            svg {
              transform: rotate(50deg);
            }
          }

        }

      }
    }
  }

`;
export default React.memo(MyComponent);