import styled from "styled-components";
import React, {useEffect, useState, useRef} from 'react';
import {Accordion, Col, Container, Row} from "react-bootstrap";
import {BsChevronDown} from "react-icons/bs";
import {Link, useLocation} from "react-router-dom";

import Select, {components} from "react-select";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        color: state.isSelected ? '#000' : '#221F1F',
        backgroundColor: state.isSelected ? '' : '#FFF',
        marginTop: 10,
        marginLeft: 2,
        fontSize: 20,

        paddingLeft: 25,
        overflowY: 'auto',
        // borderColor: state.isFocused ? '#FF0000' : '#CCCCCC',
    }),
    menu: (provided, state) => ({
        ...provided,
        color: '#FFF',
        backgroundColor: state.isSelected ? '' : '',
        margin: 0,
        borderRadius: 5,
        fontSize: 12,


    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#000' : '#FFF',
        borderRadius: 0,
        fontWeight: '500',
        color: '#FFF',
        fontSize: 20,
        paddingBottom: 25,
    }),

};


const Faq = ({data}) => {
    const selectTypeInputRef = useRef();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialDetails = queryParams.get('details');
    const [details, setDetails] = useState(initialDetails);
    const [activeItem, setActiveItem] = useState('Bed/Cabin and Other charges'); // Set 'All' as the default active item

    useEffect(() => {
        setActiveItem('Bed/Cabin and Other charges');
    }, []);


    const filterData = data?.data?.list;
    const [clickedItem, setClickedItem] = useState('');


    const newFilteredData = clickedItem ? filterData?.[0]?.sections?.filter((element) => element?.section_data?.category === clickedItem) : filterData?.[0]?.sections;


    const BodyData = newFilteredData?.[0];

    const detailsImage = BodyData?.images?.list[0];
    let [open, setOpen] = useState(false);

    let handelOpen = (open, id) => {
        setOpen(open);
        setShow(true)
    };

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }


    const handleItemClick = (item) => {
        setClickedItem(item);
        setActiveItem(item);
    };
    const handleType = (e) => {
        setClickedItem(e);
    };

    const handleCloseModal = () => {
        setOpen(false);
        handleClose();
    };

    // offset
    const [offset, setOffset] = useState(100)
    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)

        window.addEventListener('load', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
    }, [])

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Update the window width when the window is resized
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const options = [
        ...(Array.isArray(filterData?.[0]?.sections)
            ? filterData?.[0]?.sections?.map((item) => ({
                value: item?.section_data?.category,
                label: item?.section_data?.category,
            }))
            : [])
    ];


    // Filter out duplicate values based on the "value" property
    const uniqueTypes = Array.from(new Set(options?.map((option) => option.value)))?.map((value) => options.find((option) => option.value === value));
    const defaultValue = uniqueTypes[0]?.value;

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="16.414" viewBox="0 0 11.414 16.414">
                        <g id="Group_21092" data-name="Group 21092" transform="translate(-340.293 -2110.293)">
                            <g id="Group_21092-2" data-name="Group 21092"
                               transform="translate(1585.5 2872.5) rotate(180)">
                                <line id="Line_3557" data-name="Line 3557" x2="5" y2="5"
                                      transform="translate(1234.5 756.5)" fill="none" stroke="#fff"
                                      stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_3558" data-name="Line 3558" x1="5" y2="5"
                                      transform="translate(1239.5 756.5)" fill="none" stroke="#fff"
                                      stroke-linecap="round" stroke-width="1"/>
                            </g>
                            <g id="Group_21093" data-name="Group 21093" transform="translate(-893.5 1364.5)">
                                <line id="Line_3557-2" data-name="Line 3557" x2="5" y2="5"
                                      transform="translate(1234.5 756.5)" fill="none" stroke="#fff"
                                      stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_3558-2" data-name="Line 3558" x1="5" y2="5"
                                      transform="translate(1239.5 756.5)" fill="none" stroke="#fff"
                                      stroke-linecap="round" stroke-width="1"/>
                            </g>
                        </g>
                    </svg>

                </components.DropdownIndicator>
            )
        );
    };


    return (
        <StyledFaq className="job-lists" offset={offset}>
            <Container fluid className={'info-tittle'}>
                <Row>
                    <Col sm={2} className='job-lists__sidebar'>

                        {
                            windowWidth > 767 ?

                                <ul>
                                    {uniqueTypes.map((item, index) => (
                                        <li
                                            key={index}
                                            className={`${activeItem === item.value || (activeItem === 'About' && item.value === 'About') ? 'active' : ''}`}
                                            onClick={() => handleItemClick(item.value)}>
                                            <span>{item.label}</span>
                                        </li>
                                    ))}
                                </ul>

                                :

                                <div className="job-lists__sidebar__mobile-dropdown">
                                    <Select
                                        classNamePrefix="filter"
                                        isSearchable={false}
                                        ref={selectTypeInputRef}
                                        options={uniqueTypes}
                                        onChange={(e) => handleType(e.value)}
                                        defaultValue={{ value: defaultValue, label: 'About' }}
                                        styles={customStyles}
                                        // components={{DropdownIndicator}}
                                    />
                                </div>
                        }
                    </Col>

                    <Col sm={10} className='job-lists__content'>
                        <Row>
                            <Col md={12} className={'left-content'}>
                                <Row className={'upper-text'}>
                                    <Col>
                                        {detailsImage &&
                                            <div className="img-wrapper-two mb-10">
                                                <Img src={detailsImage ? detailsImage?.full_path : ''}/>
                                            </div>
                                        }
                                    </Col>
                                    <Col md={12}>
                                        {BodyData?.posts?.list?.length > 0 && BodyData?.posts?.list?.map(i => (
                                            <div>
                                                {reactHtmlParser(i?.data?.description)}
                                            </div>
                                        ))}
                                    </Col>

                                </Row>

                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>

        </StyledFaq>
    );
};

const StyledFaq = styled.section`
  position: relative;
  background-color: #E4ECF0;


  .department-inner {
    position: relative;
  }

  .job-lists {
    &__sidebar {
      padding: 60px 30px 0 0px;
      @media (min-width: 768px) and (max-width: 991px) {
        max-width: 25% !important;
        padding: 120px 30px;
      }

      &__mobile-dropdown {
        @media (min-width: 551px) {
          display: none;
        }
      }



      p {
        font-size: 32px;
        line-height: 36px;
        font-weight: 600;
        width: 80%;
      }

      ul {
        @media (max-width: 550px) {
          display: none;
        }

        li {
          margin-bottom: 20px;
          position: relative;
          padding-bottom: 20px;
          cursor: pointer;
          color: #1E1E2E;

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: #191B1D;
            opacity: 10%;
            height: 1px;
            width: 100%;
          }

          span {
            color: #1E1E2E;
            font-size: 20px;
            font-weight: 700;
            line-height: 26px;
            position: relative;
            display: block;
            width: fit-content;

            &:after {
              content: '';
              position: absolute;
              border-radius: 50%;
              top: 7px;
              right: -15px;
              margin: auto;
              height: 10px;
              width: 10px;
              background-color: #E72E4B;
              display: none;
            }
          }

          &.active {
            span {
              color: #E72E4B;

              &:after {
                display: block;
              }
            }
          }
        }
      }
    }

    &__content {
      @media (min-width: 768px) and (max-width: 991px) {
        max-width: 75% !important;
      }
      padding-right: ${props => props.offset ? props.offset + 15 + 'px' : '100px'} !important;
      padding-bottom: 320px;
      background-color: #fff;
      @media (max-width: 768px) {
        margin-top: 0;
        padding-right: 15px !important;
      }
    }
  }

  .left-content {
    padding-top: 120px;
    padding-left: 100px;
    
    h6{
      color: rgb(231, 46, 75);
    }
    ul{
      li{
        color: #1E1E2E!important;
        //margin-bottom: 20px;
      }
    }
    @media (max-width: 767px) {
      padding-top: 0;
      padding-left: 15px;
    }
  }

  .info-tittle {
    padding-left: ${props => props.offset ? props.offset + 15 + 'px' : '100px'} !important;
    @media (max-width: 767px) {
      padding-left: 15px !important;
    }

    h1 {
      font-size: 40px;
      line-height: 40px;
      font-weight: 400;
    }
  }

  .accordion-header {
    position: relative;
    margin: 0;

    button {
      border: none;
      background-color: transparent;
      padding-left: 0;
      color: #E72E4B;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      text-transform: capitalize;
      padding-bottom: 15px;
      padding-right: 40px;
      border-bottom: 1px solid #E72E4B;
      width: 100%;
      text-align: left;
      //margin-bottom: 50px;
      transition: all .4s ease;

      @media (min-width: 768px) and (max-width: 991px) {
        padding-right: 50px;
      }

      &.collapsed {
        color: #1A1818;
        border-color: rgb(30 30 46 / 10%);

        span {
          background-color: black;

          svg {
            transform: rotate(0deg);
          }

          &:after {
            background-color: #E72E4B !important;
          }
        }
      }
    }

    span {
      position: absolute;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      color: #ffffff;
      background-color: #E72E4B;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 16px;

      &:after {
        height: 0;
        width: 0;
        background-color: #E72E4B;
        border-radius: 50%;
        opacity: 0;
        transition: all .4s ease;
        content: '';
        position: absolute;
      }

      svg {
        font-size: 13px;
        z-index: 2;
        transform: rotate(180deg);
      }

    }

    &:hover {
      span {
        &:after {
          height: 100%;
          width: 100%;
          opacity: 1;
        }
      }

      button {
        color: #E72E4B;
        border-color: #E72E4B;
      }
    }
  }

  .accordion-body {
    padding-bottom: 20px;

    p {
      color: #1E1E2E;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      padding-top: 40px;
    }
  }

  .css-13cymwt-control, .css-t3ipsp-control {
    background-color: #E72E4B;
    height: 60px;
    border-radius: 50px;

    .css-1jqq78o-placeholder {
      color: white;
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      margin: 10px 0 10px 30px;
    }

    .css-1fdsijx-ValueContainer {
      padding: unset;

      .css-1dimb5e-singleValue {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: #FFF;
      }
    }

    .css-1u9des2-indicatorSeparator {
      display: none;
    }

    .css-1xc3v61-indicatorContainer {
      padding-right: 30px;
    }
  }

  .css-t3ipsp-control {
    border-color: unset !important;
    box-shadow: unset !important;
  }

  .css-qbdosj-Input {
    input {
      min-width: 0px !important;
      opacity: 0 !important;
    }
  }

  .css-15lsz6c-indicatorContainer {
    padding-right: 30px;
    color: hsl(0, 0%, 80%);
  }

  .css-1dimb5e-singleValue {
    padding-left: 20px;
    font-size: 20px;
    opacity: 70%;
    font-weight: 700;
    color: white;
  }

  .css-yk16xz-control, .css-1pahdxg-control {
    border-radius: 18px !important;
    border-color: #989898;
    height: 40px;
    background-color: #E50019;
    cursor: pointer;
    padding-left: 15px;
  }

  .css-yk16xz-control .css-1wa3eu0-placeholder, .css-1pahdxg-control .css-1wa3eu0-placeholder, .css-1uccc91-singleValue {
    color: #ffffff !important;
    font-size: 12px !important;
    font-weight: bold;
    cursor: pointer;
  }

  .css-yk16xz-control .css-tlfecz-indicatorContainer, .css-1pahdxg-control .css-tlfecz-indicatorContainer, .css-yk16xz-control .css-1gtu0rj-indicatorContainer, .css-1pahdxg-control .css-1gtu0rj-indicatorContainer {
    margin-right: 20px;
  }


  @media (max-width: 767px) {
    .filter__single-value{
      opacity: 1;
    }
    .job-lists__sidebar {
      padding: 60px 15px;
      p {
        width: 100%;
      }
    }
  }


  .img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  .upper-text {
    @media (max-width: 767px) {
      padding-top: 60px;
    }

    .mb-60{
      margin-bottom: 60px!important;
    }
    .mb-30{
      margin-bottom: 30px!important;
    }

    .mb-10 {
      margin-bottom: 10px !important;
    }

    p {
      margin-bottom: 10px;
    }

    h3 {
      margin-bottom: 20px;
    }

    .info {
      display: flex;
      margin-bottom: 40px;

      li {
        display: inline-block;
        position: relative;
        padding-right: 20px;
        margin-right: 20px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #1E1E2E;

        &:after {
          margin: 0;
          height: 100%;
          width: 1px;
          background: #707070;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          content: '';
        }

        &:nth-last-child(1) {
          margin-right: 0px;

          &:after {
            content: unset;
          }
        }
      }
    }

    .img-wrapper {
      position: relative;
      padding-top: calc(432 / 870 * 100%);
      margin-bottom: 60px;

      &.img-half {
        padding-top: calc(250 / 420 * 100%);
      }
    }

    .img-wrapper-text {
      margin-bottom: 60px;
    }

    .img-wrapper-two {
      position: relative;
      padding-top: calc(520 / 870 * 100%);
      margin-bottom: 60px !important;
    }

    .map {
      position: relative;
      padding-top: calc(1355 / 870 * 100%);
    }

    h5 {
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      margin-bottom: 40px;
    }

    h6 {
      margin-bottom: 20px;
    }

    .opacity-50 {
      opacity: 50%;
    }
    .opacity-70 {
      opacity: 70%;
    }

    .list {
      margin-bottom: 40px;
      margin-top: 40px;

      li {
        position: relative;
        padding: 15px 0 15px 20px;
        font-size: 20px;
        line-height: 26px;
        font-weight: 300;
        color: #1E1E2E;
        border-bottom: 1px solid rgb(50 50 50 / 10%);
        counter-increment: count;

        b{
          font-weight: 500;
        }


        &:after {
          content: '';
          position: absolute;
          height: 10px;
          width: 10px;
          padding: 3.4px 0px 0px 9px;
          margin: 20px 0;
          font-size: 12px;
          line-height: 20px;
          font-weight: 500;
          border: none;
          background-color: #E50019;
          border-radius: 50%;
          //color: #070524;
          top: 0;
          left: 0;
        }

        &:nth-child(1) {
          padding-top: 0;

          &:after {
            margin-top: 5px;
          }
        }

        &:nth-last-child(1) {
          padding-bottom: 0;
          border-bottom: 0;

        }
      }
    }
    .list-60{
      margin-bottom: 60px;
      li {
        position: relative;
        padding: 15px 0 15px 20px;
        font-size: 20px;
        line-height: 26px;
        font-weight: 300;
        color: #1E1E2E;
        border-bottom: 1px solid rgb(50 50 50 / 10%);
        counter-increment: count;



        &:after {
          content: '';
          position: absolute;
          height: 10px;
          width: 10px;
          padding: 3.4px 0px 0px 9px;
          margin: 20px 0;
          font-size: 12px;
          line-height: 20px;
          font-weight: 500;
          border: none;
          background-color: #E50019;
          border-radius: 50%;
          //color: #070524;
          top: 0;
          left: 0;
        }

        &:nth-child(1) {
          padding-top: 0;

          &:after {
            margin-top: 5px;
          }
        }

        &:nth-last-child(1) {
          padding-bottom: 0;
          border-bottom: 0;

        }
      }
    }

    .span-70{
      li {
        position: relative;
        padding: 15px 0 15px 20px;
        font-size: 20px;
        line-height: 26px;
        font-weight: 400;
        color: #1E1E2E;
        border-bottom: 1px solid rgb(50 50 50 / 10%);
        counter-increment: count;
        span{
          opacity: 70%;
        }
      }
    }

    table {
      margin-bottom: 60px;

      tr {
        border-bottom: 1px solid rgba(50, 50, 50, 0.2);


        &:nth-child(1) {
          border-bottom: 1px solid rgba(50, 50, 50, 1);
        }

        &:nth-last-child(1) {
          border-bottom: 0;

          td {
            padding-bottom: 0;
            min-width: 100px;
          }
        }

        th {
          font-size: 20px;
          line-height: 26px;
          font-weight: 700;
          color: #1E1E2E;
          padding: 0 0 20px 0;
        }

        td {
          font-size: 20px;
          line-height: 26px;
          font-weight: 400;
          padding: 20px 0;
          color: #1E1E2E;
        }
      }
    }

    blockquote {
      font-size: 20px;
      line-height: 24px;
      font-weight: 300;
      margin-bottom: 20px;
      padding-left: 20px;
      color: #323232;
      border-left: 4px solid #E50019;
    }
  }

  @media (max-width: 767px) {
    .portfolio-details__social {
      display: flex;
      justify-content: space-between;

      ul {
        display: flex;
        gap: 20px;
      }
    }

    table {
      th {
        width: 50%;
        padding: 0 20px 20px 0 !important;
      }
    }
  }

  .video {
    .video-wrapper {
      position: relative;
      overflow: hidden;
      padding-top: calc(520 / 870 * 100%);
      margin: 0 0 60px 0;
      cursor: pointer;

      .video-icon {
        overflow: unset !important;
        position: absolute;
        z-index: 8;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: fit-content;
        height: fit-content;
        margin: auto;

        svg {
          overflow: hidden;
          border-radius: 50%;

          .video-hover {
            cx: -90px;
            opacity: 0;
            transition: all 0.8s cubic-bezier(0.76, 0, 0.24, 1);
          }

          path {
            transition: all 0.8s cubic-bezier(0.76, 0, 0.24, 1);
          }
        }
      }

      &:hover {
        .video-icon {
          svg {
            .video-hover {
              cx: 30px;
              opacity: 1;
              transition: all 0.8s cubic-bezier(0.76, 0, 0.24, 1);
            }

            path {
              fill: #fff;
              transition: all 0.8s cubic-bezier(0.76, 0, 0.24, 1);
            }
          }
        }
      }
    }
  }

`;
export default React.memo(Faq);
