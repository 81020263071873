import React, {useState, useEffect, useRef} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import CareerPopup from "./CareerPopUp";
import ButtonshortJob from "../ButtonshortJob";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {


const popdata =data;

    const Availablejobtitle = data?.section_data;
    const Availablejob = data?.posts?.list;

const ForpopupSubtitle = data?.posts?.list?.data?.subtitle;
const ForpopupDescription = data?.posts?.list?.data?.description;

    const [show, setShow] = useState(false);
    const [winWidth, setWinWidth] = useState(true)
    const [popupTitle, setpopupTitle] = useState()
    const [popupDesc, setpopupDesc] = useState()
    const handleClose = () => setShow(false);
    const handleShow = (i) => {
        setShow(true)
        setpopupTitle(i?.data?.subtitle);
        setpopupDesc(i?.data?.description);
    }

    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])


    return (
        <>
            <StyledComponent className={'pt-200 pb-200'}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <h4>{reactHtmlParser(Availablejobtitle?.subtitle)}</h4>
                        </Col>
                    </Row>
                    {Availablejob?.length > 0 && Availablejob?.map(i => (
                    <Row>
                        <Col md={3} className={'mobile'}>
                            <h5 className={'split-up'}>{reactHtmlParser(i?.data?.subtitle)}</h5>
                        </Col>
                        <Col md={{offset: 1, span: 4}} className={'career-details mobile'}>
                           {reactHtmlParser(i?.data?.short_desc)}
                        </Col>
                        <Col md={{offset: 2, span: 2}} className={'button mobile'}>
                            <div onClick={() => handleShow(i)}>
                                <ButtonshortJob color={'black'} text={'View Details'} icon={'black'}
                                                hoverColor={'black'}/>
                            </div>
                        </Col>

                    </Row>
                    ))}

                </Container>
            </StyledComponent>
            <CareerPopup show={show} handleClose={handleClose} ForpopupSubtitle={popupTitle} ForpopupDescription={popupDesc}/>
        </>
    );
};

const StyledComponent = styled.section`
  background-color: #E4ECF0;

  .row:not(:first-child):not(:last-child) {
    padding-bottom: 40px;
    margin-bottom: 40px;
    position: relative;

    &:after {
      content: "";
      width: calc(100% - 15px);
      height: 1px;
      bottom: 0;
      left: 15px;
      position: absolute;
      background: rgb(30 30 46 / 20%);
    }
  }

  .dc-btn {
    a {
      width: 100%;
    }
  }

  .career-details {
    p {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;

      span {
        font-weight: 700;
        padding-left: 15px;
      }
    }

    p:not(:last-child) {
      padding-bottom: 15px;
    }
  }

  h4 {
    padding-bottom: 60px;
  }

  .button {
    padding: 0;
  }

  @media (max-width: 767px) {
    .mobile {
      padding-bottom: 20px;
    }

    .dc-btn {
      padding-left: 15px;

      a {
        width: fit-content;
      }
    }
  }
`;

export default React.memo(MyComponent);
