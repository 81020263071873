import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import affiliatedReducer, {fetchAffiliatedBody} from "../../api/redux/affiliated-body";
import InnerBanner from "../../components/InnerBanner";
import Overview from "../../components/AffiliatedBody/overview";
import Map from "../../components/AffiliatedBody/Map";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";


const MyComponent = () => {

    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let apiUrl = apiEndPoints.SECTIONS

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'affiliated-body',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }

        dispath(fetchAffiliatedBody([apiUrl, api_params]))
    }, [])
    const getData = useSelector(state=> state.affiliatedReducer)
    const Banner = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'affiliated-body-banner');
    const affOverview = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'affiliated-body-overview');
    const affiliatedBody = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'affiliated-bodies');
    const bdMap = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'bangladesh-map');

    const page_data = getData?.posts?.data?.page_data;

    return (
        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.meta_title ? page_data?.meta_title + ' | ' : ''}`}National Heart
                    Foundation</title>

                <meta name="meta:title" content={page_data?.meta_title}/>
                <meta name="description" content={page_data?.meta_description}/>
                <meta property="og:title" content={page_data?.og_title}/>
                <meta property="og:subtitle" content={page_data?.og_title}/>
                <meta property="og:description" content={page_data?.og_description}/>
                <meta property="og:image" content={Banner?.images?.list?.[0]?.full_path}/>
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>
                {
                    Banner &&
                    <InnerBanner subtitle={Banner?.section_data?.description}
                                 breadcrumbs={Banner?.section_data?.subtitle}
                                 title={Banner?.section_data?.short_desc}
                                 img={Banner?.images?.list?.[0]?.full_path}/>
                }
                {
                    affOverview && affiliatedBody &&
                    <Overview overviewData={affOverview} affiliatedData={affiliatedBody}/>
                }
                {
                    bdMap &&
                    <Map data={bdMap}/>
                }


            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`



`;

export default MyComponent;
