export const ApiParam = {
    type: 'type',
    value: 'value',
    image : 'image',
    post : 'post',
    file : 'file',
    get_section : 'get_section',
    gallery : 'gallery',
    page_id:'page_id',
    sections:'sections',
    posttype: 'posttype',
    keyword: 'keyword',
};
