import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {Img} from "../Img";

import reactHtmlParser from "react-html-parser";

const AboutNHF = ({data}) => {

    return (
        <StyledComponent  className='about-partex pt-200 pb-200 parallax-img'>
            <Container >
                <Row>
                    <div>
                        <Img data-speed={'0.2'} src={'images/static/Map.svg'}/>
                    </div>
                    <Col sm={12}>
                        <p className={'upper-title split-up'}>{data?.section_data?.subtitle}</p>
                    </Col>

                    <Col sm={10}>
                        <h4 className={'split-up'}>{data?.section_data?.short_desc}</h4>
                    </Col>

                    <Col sm={{offset:3,span:9}}>
                        {data?.section_data?.description && reactHtmlParser(data?.section_data?.description)}
                    </Col>
                    <Row/>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #F6F9F8;
  position: relative;


  img {
    position: absolute;
    top: 0;
    /* bottom: 50%; */
    right: 0;
    left: 45%;
    height: 100%;
    width: 40%;
    object-fit: unset;
  }

  .upper-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    width: 50%;
    margin: 0 0 20px 0;
  }

  h4 {
    color: #1E1E2E;
    margin: 0 0 80px 0;

    span {
      color: #E50019;
    }
  }

  p:not(:last-child){
    color: #1E1E2E;
    margin: 0 0 80px 0;
  }

  .counter-item {
    h2 {
      padding-bottom: 20px;
      color: #E72E4B;
    }

    p {
      font-weight: 700;
      margin: 0;
    }

    &:nth-child(1) {
      margin-bottom: 40px;
    }
  }

  @media (max-width: 767px) {
    padding-top: 0;
    img {
      top: 40%;
      bottom: 50px;
      right: 15px;
      left: 15px;
      height: unset;
      width: calc(100% - 30px);
      object-fit: unset;
    }


    .upper-title {
      width: 100%;
    }

    .counter-item {
      &:nth-child(n+1) {
        margin-bottom: 40px;
      }
    }
  }

`;

export default React.memo(AboutNHF);
