import React, {useState, useEffect,useRef} from 'react';
import styled from 'styled-components';
import {Col, Container, Form, Row} from "react-bootstrap";
import Button from "../Button";
import {toast} from "react-toastify";
import {Img} from "../Img";
import {useForm} from "react-hook-form";
import reactHtmlParser from "react-html-parser";
import ButtonLong from "../ButtonLong";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/contact";

const ListWithForm = ({padding,data,id}) => {

    const store = useSelector(store=>store)
    const Consultationdata = data;

    // form submission
    const {register, control, handleSubmit, formState: {errors, isSubmitSuccessful}, reset, watch} = useForm({
        mode: "all",
    })
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const dispatch = useDispatch()
    let apiEndPoint = apiEndPoints.FORM_SUBMIT

    const handleFormSubmit = (e) => {
        var formData = new FormData();
        formData.append("spam_protector", "");
        formData.append("form_id", "contact-form");
        formData.append('name', e?.name);
        formData.append('email', e?.email);
        formData.append('phone', e?.number);
        formData.append('message', e?.message);
        dispatch(postForm([apiEndPoint, formData]))
    }

    useEffect(() => {
        if (isSubmitSuccessful && !store?.contactReducer?.loading) {
            success('Thank you for contact us')
            reset()
        }
    }, [isSubmitSuccessful,store?.contactReducer])

    return (
        <StyledListWithForm id={`${id ? id : 'ListWithForm'}`} className={`list_with_form ${padding ? padding : 'pb-200'} `}>


            <Container>
                <Row>
                  <Col lg={6} className={'left-col'}>
                                      <div className="image-wrapper">
                                          <Img src={Consultationdata?.images?.list[0]?.full_path}/>
                                      </div>


                  </Col>
                    <Col lg={{span:5,offset:1}} className={'right-col'}>
                        <div className="form_wrapper">
                            <h4 className={'split-up'}>{reactHtmlParser(Consultationdata?.section_data?.subtitle ? Consultationdata?.section_data?.subtitle : '')}</h4>
                            <Form className={'form'}  onSubmit={''}>
                                <input name={'spam_protector'} type='hidden'/>
                                <div className="form-group">
                                    <Form.Group controlId="formBasicEmail">

                                        <Form.Control className={'form-control-lg'}
                                                      {...register('name', {
                                                          required: {
                                                              value: true,
                                                              message: "Please enter your full name",
                                                          }

                                                      })}
                                                      type="text"
                                                      placeholder="Name*"
                                        />
                                    </Form.Group>
                                    <p className={'form-error'}>{errors.name?.message}</p>
                                </div>
                                <div className="form-group">
                                    <Form.Group controlId="formBasicEmail">

                                        <Form.Control className={'form-control-lg'}
                                                      {...register('email', {
                                                          required: {
                                                              value: true,
                                                              message: "Please enter a valid email address"
                                                          },
                                                          pattern: {
                                                              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                              message: "Please enter a valid email address"
                                                          }
                                                      })}
                                                      type="email"
                                                      placeholder="Email*"/>
                                    </Form.Group>
                                    <p className={'form-error'}>{errors.email?.message}</p>
                                </div>
                                <div className="form-group">
                                    <Form.Group controlId="formBasicPhone">

                                        <Form.Control className={'form-control-lg'}
                                                      {...register('number')}
                                                      type="number"
                                                      placeholder="Phone Number"/>
                                    </Form.Group>
                                </div>
                                <div className="form-group">
                                    <Form.Group controlId="formBasicPhone">

                                        <Form.Control className={'form-control-lg'}
                                                      {...register('message')}
                                                      type="text"
                                                      placeholder="Message (Optional)"/>
                                    </Form.Group>
                                </div>
                                <div onClick={handleSubmit(handleFormSubmit)}>
                                        <ButtonLong  color={'white'} text={'Submit Message'} hoverColor={'white'} margin={'2'}/>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledListWithForm>
    )
};

const StyledListWithForm = styled.div`
  background: #F6F9F8;
  position: relative;
  top: -1px;

  .left-col {
    padding-right: 0px;
  }

  .right-col {
    padding-left: 0px;
  }

  .dc-btn {
    height: unset;
  }

  .image-wrapper {
    position: relative;
    padding-top: calc(604 / 600 * 100%);
    height: 100%;
  }

  .attach-cv {
    label {
      color: white !important;
    }
  }

  .form_wrapper {
    background: #1E1E2E;
    padding: 60px;
    height: 100%;

    h4 {
      color: #fff;
      padding-bottom: 40px;
    }

    @media (min-width: 1550px) {

    }
    @media (max-width: 992px) and (min-width: 768px) {
      padding: 60px;
    }

    form {
      display: flex;
      height: 100%;
      flex-direction: column;
      gap: 40px;
    }

    .title {
      font-size: 24px;
      font-weight: 4000;
      line-height: 28px;
      color: #F9F9F9;
      margin: 0 0 40px;
    }

    .form-group {
      margin-bottom: 0;
      input:-webkit-autofill {
        -webkit-text-fill-color: white !important;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .form-control {
        box-shadow: none;
        outline: 0;
        border-radius: 0;
        background: transparent;
        height: auto;
        border-left: none;
        border-right: none;
        border-top: none;
        border-color: rgba(255, 255, 255, 0.5);
        padding: 0 0 20px;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;

        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }

    .filter__placeholder {
      color: #C4C4C4;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    .filter__control {
      height: 30px !important;
      padding: 0 !important;
      background: transparent !important;
      border-left: none !important;
      border-right: none !important;
      border-top: none !important;
      border-color: rgba(246, 246, 247, 0.2) !important;

      .filter__single-value {
        color: #C4C4C4 !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 20px !important;
      }

      .filter__indicator-separator {
        display: none;
      }

      .filter__value-container {
        padding: 0;
      }

      .filter__dropdown-indicator, .filter__input-container, .filter__placeholder {
        margin: 0;
      }
    }
  }


  @media (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 0;
    .form_wrapper {
      padding: 60px 15px;
      margin-top: 0px;
    }

    .left-col {
      padding-bottom: 0;
    }

    padding-right: 0px;
  }

  .container {
    padding: 0;
  }

  .right-col {
    padding-left: 15px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .left-col {
      padding-right: 0px;
    }

    .right-col {
      padding-left: 15px;
      padding-right: 0;
    }

  }
`;


export default React.memo(ListWithForm);
