import React, {useEffect, useRef} from 'react';
import styled from "styled-components";
import {Img} from "./Img";
import {Container, Row, Col} from "react-bootstrap";
import Title from "./Title";
import reactHtmlParser from "react-html-parser";
import VisibilitySensor from "react-visibility-sensor";
import {ImgParallax} from "./ImgParallax";
import {ImgInnerBanner} from "./ImgInnerBanner";
import {Parallax} from "react-parallax";

const InnerBanner = ({img, breadcrumbs, subtitle, title}) => {
    return (
        <StyledInnerBanner className='InnerBanner'>
            <div className={'parallax-padding'}>
            <Parallax bgImage={img} strength={200}>
                <Container>
                    <Row>
                        <Col>
                            <p className={'breadcrumbs split-up-title-fast'}>{reactHtmlParser(breadcrumbs)}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <h2 className={`anim-active fade-up split-up-title-fast`}>{reactHtmlParser(title)}</h2>
                        </Col>
                        <Col sm={{span: 4, offset: 2}}>
                            <p className={'sub-title split-up-title-fast'}>{reactHtmlParser(subtitle)}</p>
                        </Col>
                    </Row>
                </Container>
            </Parallax>
            </div>

        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`

  
  .parallax-padding{
    position: relative;
    padding-top: calc(533 / 1366 * 100%);
  }
  
  .react-parallax{
    position: absolute!important;
    overflow: hidden!important;
    inset: 0!important;
    height: 100%!important;
    width: 100%!important;
   
  }
  .react-parallax-bgimage{
    height: 100%!important;
    width: 100%!important;
    object-fit: cover!important;
  }
  .react-parallax-content{
    position: unset!important;
  }

  &:after {
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, 0.25);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  .container {
    position: absolute;
    //height: 100%;
    //top: 0;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 71px;
    z-index: 2;
  }


  h2 {
    left: 15px;
    color: #ffffff;
    font-size: 60px;
    font-weight: 400;
    line-height: 65px;
    padding-bottom: 20px;
    z-index: 2;

    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 42px;
      font-weight: 400;
      line-height: 42px;
    }

    span {
      font-weight: 600;
      color: #ffffff;
    }
  }

  .breadcrumbs {
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .sub-title {
    color: #ffffff;
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
  }

  @media (min-width: 767px) {
    .title {
      width: 50%;
    }
  }

  @media (max-width: 767px) {
    .parallax-padding{
      padding-top: calc(560 / 414 * 100%);
    }
    //padding-top: 0;
    .container {
      bottom: 69px;
    }

    .title {
      margin-bottom: 40px !important;
    }

    h2 {
      font-size: 40px;
      line-height: 45px;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .parallax-padding{
      padding-top: calc(750 / 1366 * 100%);
      position: relative;
    }
  }

`;

export default React.memo(InnerBanner);
