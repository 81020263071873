import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async';
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchPosts} from "../../api/redux/post";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchChild} from "../../api/redux/founderpublications";
import {useLocation} from "react-router-dom";
import FounderPublicationsDetails from "../../components/FounderPublications/FounderPublicationsDetailsPage";

const FounderPublicationsPageDetailsIndex = () => {
    const dispatch = useDispatch()
    const getData = useSelector(state => state.founderpublicationsReducer)

    const [searchInput, setSearchInput] = useState('')


    // api call
    useEffect(() => {
        let apiUrl = apiEndPoints.CHILD_PAGE
        let apiUrlSection = apiEndPoints.SECTIONS

        let api_params = {
            [ApiParamKey.page_id]: 435,
            [ApiParamKey.sections]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'yes',
        }
        let api_params_sections = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'founder-publications',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'yes',
        }
        dispatch(fetchChild([apiUrl, api_params]))
        dispatch(fetchPosts([apiUrlSection, api_params_sections]))
    }, [])

    // filter data
    const PublicationsDetailsData = getData?.child?.data?.list;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialDetails = queryParams.get('name');
    const [name, setName] = useState(initialDetails);
    const BannerData = getData?.child?.data?.list?.find(f => f?.page_data?.slug === name);

    const page_data = BannerData?.page_data;



    return (

        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.title ? page_data?.title + ' | ' : ''}`}National Heart
                    Foundation</title>

                <meta name="meta:title" content={page_data?.meta_title}/>
                <meta name="description" content={page_data?.meta_description}/>
                <meta property="og:title" content={page_data?.og_title}/>
                <meta property="og:subtitle" content={page_data?.og_title}/>
                <meta property="og:description" content={page_data?.og_description}/>
                <meta property="og:image" content={BannerData?.images?.list?.[0]?.full_path}/>
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>
                {PublicationsDetailsData && <FounderPublicationsDetails data={PublicationsDetailsData}/>}

            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default FounderPublicationsPageDetailsIndex;
