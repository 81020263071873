import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";

const ActivitiesPage = ({data}) => {

    const ActivitiesData = data?.section_data;

    return (
        <StyledComponent  className='about-partex pt-150 pb-200 parallax-img'>
            <Container >
                <Row>
                    <Col sm={12}>
                        <p className={'upper-title split-up'}>{ActivitiesData?.subtitle}</p>
                    </Col>

                    <Col sm={10} className={'top-left'}>
                        <h4 className={'split-up'}>{reactHtmlParser(ActivitiesData?.short_desc ? ActivitiesData?.short_desc : '')}</h4>
                    </Col>

                    <Col sm={9} className={'top-right'}>
                        <p className={'split-up'}>{reactHtmlParser(ActivitiesData?.description ? ActivitiesData?.description : '')}</p>
                    </Col>
                    <Col sm={{span:10,offset:2}}>
                        <Row>
                            <Col sm={6} className={'bottom-left'}>
                                <p className={'split-up'}>
                                    {reactHtmlParser(ActivitiesData?.text_left ? ActivitiesData?.text_left : '')}
                                </p>
                            </Col>
                            <Col sm={6} className={'bottom-right'}>
                                <p className={'split-up'}>
                                    {reactHtmlParser(ActivitiesData?.text_right ? ActivitiesData?.text_right : '')}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    <Row/>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #E4ECF0;
  position: relative;

  img {
    position: absolute;
    top: 0;
    /* bottom: 50%; */
    right: 0;
    left: 45%;
    height: 100%;
    width: 40%;
    object-fit: unset;
  }

  .upper-title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    width: 50%;
    margin: 0 0 20px 0;
  }

  h4 {
    color: #1E1E2E;
    margin: 0 0 80px 0;

    span {
      color: #E50019;
    }
  }
  
  .top-left,.top-right{
    p{
      color: #1E1E2E;
      margin: 0 0 80px 0;
    }
  }
  

  .counter-item {
    h2 {
      color: #E72E4B;
    }

    p {
      font-weight: 700;
      margin: 0;
    }

    &:nth-child(1) {
      margin-bottom: 40px;
    }
  }

  @media (max-width: 767px) {
    padding-top: 0;
      padding-bottom: 40px;
    img {
      top: 40%;
      bottom: 50px;
      right: 15px;
      left: 15px;
      height: unset;
      width: calc(100% - 30px);
      object-fit: unset;
    }

    .upper-title {
      width: 100%;
    }

    .counter-item {
      &:nth-child(n+1) {
        margin-bottom: 40px;
      }
    }
  }

`;

export default React.memo(ActivitiesPage);
