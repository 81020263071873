import React, {useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import About from "../../components/About/About";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import aboutReducer, {fetchAbout} from "../../api/redux/about";
import AboutSlider from "../../components/About/AboutSlider";
import ExecutiveCouncil from "../../components/About/ExecutiveCouncil";
import Management from "../../components/About/Management";
import AdvisoryCouncil from "../../components/About/AdvisoryCouncil";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";

const MyComponent = () => {
    const dispath = useDispatch()
    const getData = useSelector(state=> state.aboutReducer)
    // api call
    useEffect(() => {
        let apiUrl = apiEndPoints.SECTIONS

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'about',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }

        dispath(fetchAbout([apiUrl, api_params]))
    }, [])

    let getPost = useSelector(state => state.posts)

    const BannerData = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'about-banner');
    const aboutOverview = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'about-overview');
    const aboutObjectSlider = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'about-object-slider');
    const aboutNationalExecutive = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'national-executive-council');
    const aboutAdvisoryCounsil = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'advisory-council');
    const aboutManagement = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'management');
    const page_data = getData?.posts?.data?.page_data;


    return (
        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.meta_title ? page_data?.meta_title + ' | ' : ''}`}National Heart
                    Foundation</title>

                <meta name="meta:title" content={page_data?.meta_title}/>
                <meta name="description" content={page_data?.meta_description}/>
                <meta property="og:title" content={page_data?.og_title}/>
                <meta property="og:subtitle" content={page_data?.og_title}/>
                <meta property="og:description" content={page_data?.og_description}/>
                <meta property="og:image" content={BannerData?.images?.list?.[0]?.full_path}/>
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>
            <StyledComponent>
                {
                    BannerData &&
                    <InnerBanner title={BannerData?.section_data?.short_desc} img={BannerData?.images?.list?.[0]?.full_path}
                                 breadcrumbs={BannerData?.section_data?.subtitle}
                                 subtitle={BannerData?.section_data?.description}/>
                }
                {
                    aboutOverview &&
                    <About data={aboutOverview}/>

                }
                {
                    aboutObjectSlider &&
                    <AboutSlider data={aboutObjectSlider}/>
                }
                {
                    aboutNationalExecutive &&
                    <ExecutiveCouncil data={aboutNationalExecutive}/>
                }
                {
                    aboutAdvisoryCounsil &&
                    <AdvisoryCouncil data={aboutAdvisoryCounsil}/>
                }
                {
                    aboutManagement &&
                    <Management data={aboutManagement}/>
                }

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
