import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    globalLoader: true,
}

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        loading: (state) => {
            state.globalLoader = true
        }
    },
    extraReducers: {
        ['fetchHome/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchAbout/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchChild/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchConference/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchConferenceChild/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchConferenceDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },

        ['fetchPostDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchActivities/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchAffiliatedBody/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchCareer/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchCcd/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchChildHeart/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchContact/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchExecutiveCouncil/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchFounder/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchHyperTension/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchOnlineDetails/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchTobaccoprogramChild/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchTobaccoprogramDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchTobaccoProgram/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchTransfatProgram/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchControlPrevention/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchControlPreventionChild/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchControlPreventionDetails/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchDepartment/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchDepartmentChild/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchDepartmentDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchDoctor/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchDoctorChild/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchDoctorDetails/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchNews/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchNewsChild/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchNewsDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchPublication/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchPublicationChild/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchResearch/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchResearchChild/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchResearchDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchAcademicActivitiesChild/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchAcademicActivities/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchAcademicActivitiesDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchBooks/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchBooksChild/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchBooksDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchTransfatChild/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchTransfat/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchTransfatDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchUfat/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchUfatChild/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchUfatDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchHyper/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchHyperChild/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchHyperDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchClinicalServices/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchClinicalServicesChild/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchClinicalServicesDetails/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchRoadsafety/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchRoadsafetyChild/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchRoadsafetyDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchSaltreduction/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchSaltreductionChild/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchSaltreductionDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchVitamin/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchVitaminChild/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchVitaminDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchHospitalRates/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchHospitalRatesChild/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchHospitalRatesDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchTrustees/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchFounderBooks/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchFounderBooksChild/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchFounderBooksDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchFounderPublications/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchFounderPublicationsChild/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchFounderPublicationsDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },



    }
})


export default globalSlice.reducer
export const {loaded, loading} = globalSlice.actions
