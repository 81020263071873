import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import ButtonLongAppointment from "../ButtonLongAppointment";
import DoctorPopUp from "./DoctorPopUp";
import {useLocation} from "react-router-dom";
import reactHtmlParser from "react-html-parser";

const DoctorsDetails = ({data}) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialDetails = queryParams.get('profile');

    const [profile, setProfile] = useState(initialDetails);
    const filteredData = data?.filter((item) => item.data?.slug === profile);
    const [popupTitle, setpopupTitle] = useState()
    const [popupDesc, setpopupDesc] = useState()
    const [popupImage, setpopupImage] = useState()
    const [popupId, setpopupId] = useState()


    const [show, setShow] = useState(false);
    const [winWidth, setWinWidth] = useState(true)
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
        setpopupImage(filteredData?.[0]?.images?.list?.[0]?.full_path);
        setpopupTitle(filteredData?.[0]?.data?.title);
        setpopupDesc(filteredData?.[0]?.data?.link);
        setpopupId(filteredData?.[0]?.data?.id);
    }

    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])
    return (
        <>
            <StyledComponent className={'pb-200'}>
                <Container>
                    <Row>

                            <Col sm={'4'} className={'legacy-left'}>
                                <div className={'legacy-left__image-wrapper'}>
                                    <div className={'profile-image'}>
                                        <Img src={filteredData?.[0]?.images?.list?.[0]?.full_path}/>
                                    </div>
                                </div>
                                <div className={'legacy-left__image-text'}>
                                    <h6 className={'split-up'}>{reactHtmlParser(filteredData?.[0]?.data?.title)}</h6>
                                    <p className={'sub-title split-up'}>{reactHtmlParser(filteredData?.[0]?.data?.link)}</p>
                                </div>
                            </Col>
                            <Col sm={'8'} className={'legacy-right'}>

                                <div onClick={() => handleShow()}>
                                    <ButtonLongAppointment text={'Get Appointment'} color={'black'} icon={'black'}
                                                           hoverColor={'red'} margin={'0 0 40px 0'}/>
                                </div>

                                <Row>
                                    <Col md={'12'} className={'profile'}>
                                        {reactHtmlParser(filteredData?.[0]?.data?.body)}

                                    </Col>
                                </Row>
                            </Col>

                    </Row>
                </Container>
            </StyledComponent>
            <DoctorPopUp show={show} handleClose={handleClose} doctorImage={popupImage} doctorName={popupTitle} doctorDeg={popupDesc} doctorId={popupId}/>
        </>

    );
};

const StyledComponent = styled.section`
  padding-top: 160px;
  background-color: #F6F9F8;

  .modal-content {
    background-color: #F5F5F5;

    .modal-body {
      padding: 0;
    }
  }

  .legacy-left {
    position: sticky;
    top: 110px;
    height: 100%;

    padding-right: 70px;

    &__image-wrapper {
      position: relative;
      padding-top: calc(458 / 370 * 100%);
    }

    &__image-text {
      padding-top: 20px;

      h6 {
        color: black;
      }

      p {
        padding-top: 5px;
        color: #000000a8;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    @media (max-width: 767px) {
      position: relative;
      top: 0px;
      height: 100%;
      padding-right: 15px;

    }
  }

  @media (max-width: 767px) {
    .modal-data {
      flex-direction: column;
    }
  }


  ul {
    //li {
    //  position: relative;
    //  left: 20px;
    //
    //  &:after {
    //    content: '';
    //    top: 7px;
    //    left: -20px;
    //    position: absolute;
    //    width: 10px;
    //    height: 10px;
    //    background-color: rgb(231, 46, 75);
    //    border-radius: 50px;
    //  }
    //}
    li{
      font-weight: 400;
    }
    li:not(:last-child){
      padding-bottom: 10px;
    }
  }


  .legacy-right {
    position: relative;

    .profile {
      table {
        position: relative;

        &:after {
          content: '';
          height: 1px;
          width: 100%;
          bottom: -40px;
          left: 0;
          position: absolute;
          background-color: #191b1d1a;
        }

        tbody {
          td:first-child {
            font-size: 16px;
            line-height: 20px;
            color: #1E1E2E;
            font-weight: 500;
            display: flex;
            word-break: break-all;
            min-width: 200px;
          }

          tr {
            td:not(:first-child) {
              font-size: 16px;
              line-height: 20px;
              font-weight: 500;
              padding-bottom: 20px;
              color: #1E1E2E;
            }
            @media (max-width: 767px){
              padding-bottom: 20px;
              display: flex;
              flex-direction: column;
              gap:10px;
            }
          }
        }
      }
     @media(max-width: 767px){
       padding-top: 30px;
     }
    }

    span {
      color: #000;
    }

    p {
      color: #1E1E2E;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      padding-bottom: 30px;
    }

    h6 {
      color: #1E1E2E;
      padding-bottom: 40px;
    }

    //ul {
    //  padding-bottom: 45px;
    //
    //  li {
    //    position: relative;
    //    font-size: 20px;
    //    font-weight: 300;
    //    line-height: 24px;
    //    border-bottom: 1px solid #707070;
    //    padding-bottom: 15px;
    //    margin-left: 25px;
    //
    //    :before {
    //      content: "";
    //      position: absolute;
    //      background-color: red;
    //      width: 12px;
    //      height: 12px;
    //      border-radius: 50px;
    //      left: -25px;
    //      margin-top: 2px;
    //    }
    //  }
    //
    //  li:not(:first-child) {
    //    padding-top: 15px;
    //  }
    //}
  }

  @media (max-width: 767px) {
    padding-bottom: unset;
    .legacy-left {
      &__image-text {
        margin-bottom: 30px;
      }
    }

  }


  .modal-backdrop {
    background-color: rgb(34 31 31 / 90%);
    min-width: 100%;
    //z-index: 9999;

    &.show {
      opacity: 1;
    }
  }

  .modal-dialog {
    margin: unset;
  }

`;

export default React.memo(DoctorsDetails);
