import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Modal, Form} from 'react-bootstrap';
import {Col, Container, Row} from "react-bootstrap";
import 'simplebar-react/dist/simplebar.min.css';
import Button from "../Button";

const CareerPopup = ({
                         show,
                         handleClose,
                         item,

                     }) => {


    const [winWidth, setWinWidth] = useState(true)
    const [selectedFileName, setSelectedFileName] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFileName(file.name);
    };
    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])



    return (

        <StyledModal>
            <Modal
                show={show}
                item={item}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="cv-modal popup-version-one submission-popup"
            >
                <Modal.Body>
                    <Container>
                        <Row className={'for-close'} onClick={handleClose}>
                            <div className="modal-close">
                                <svg id="Button_-_Close" data-name="Button - Close" xmlns="http://www.w3.org/2000/svg"
                                     xmlnsxlink="http://www.w3.org/1999/xlink" width="40" height="40"
                                     viewBox="0 0 40 40">
                                    <g id="Mask_Group_396" data-name="Mask Group 396" transform="translate(-1268 -20)">
                                        <g id="Ellipse_18" data-name="Ellipse 18" transform="translate(1268 20)"
                                           fill="none" stroke="#1e1e2e" stroke-width="1">
                                            <circle cx="20" cy="20" r="20" stroke="none"/>
                                            <circle cx="20" cy="20" r="19.5" fill="none"/>
                                        </g>
                                        <circle id="Ellipse_617" data-name="Ellipse 617" cx="20" cy="20" r="20"
                                                transform="translate(1268 60)" fill="#e72e4b"/>
                                        <g id="Group_18979" data-name="Group 18979" transform="translate(-1883 -86.5)">
                                            <line id="Line_4" data-name="Line 4" x2="8" y2="8"
                                                  transform="translate(3167 122.5)" fill="none" stroke="#1e1e2e"
                                                  stroke-linecap="round" stroke-width="1"/>
                                            <line id="Line_3877" data-name="Line 3877" x1="8" y2="8"
                                                  transform="translate(3167 122.5)" fill="none" stroke="#1e1e2e"
                                                  stroke-linecap="round" stroke-width="1"/>
                                        </g>
                                    </g>
                                </svg>

                            </div>
                        </Row>
                    </Container>
                    <Container className={'details'}>
                        <Row>


                            <Col  className={'right-col'}>
                                <div className="form_wrapper">
                                    <h4 className={'split-up'}>Submission Form</h4>
                                    <Form className={'form'} onSubmit={''}>

                                        <input name={'spam_protector'} type='hidden'/>
                                        <input name={'form_id'} value={'contact-form'} type='hidden'/>
                                        <div className="form-group">
                                            <Form.Group controlId="formBasicEmail">

                                                <Form.Control className={'form-control-lg'}
                                                              {...("name", {
                                                                  required: 'Name is required',

                                                              })}
                                                              type="text"
                                                              placeholder="Name*"
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="form-group">
                                            <Form.Group controlId="formBasicEmail">

                                                <Form.Control className={'form-control-lg'}
                                                              {...("email", {
                                                                  required: {
                                                                      value: true,
                                                                      message: 'please enter your email'
                                                                  },
                                                                  pattern: {
                                                                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                      message: 'please enter a valid email address'
                                                                  }
                                                              })}
                                                              type="email"
                                                              placeholder="Email*"/>
                                            </Form.Group>
                                        </div>
                                        <div className="form-group">
                                            <Form.Group controlId="formBasicPhone">

                                                <Form.Control className={'form-control-lg'}
                                                              {...("phone", {
                                                                  required: {
                                                                      value: true,
                                                                      message: 'please enter your phone first'
                                                                  },
                                                                  pattern: {
                                                                      value: /^01[0-9]{9}$/,
                                                                      message: 'please enter a valid 11 digit phone number'
                                                                  }
                                                              })}
                                                              type="number"
                                                              placeholder="Phone Number*"/>
                                            </Form.Group>
                                        </div>
                                        <div className="form-group">
                                            <Form.Group controlId="formBasicPhone">

                                                <Form.Control className={'form-control-lg description'}
                                                              {...("message", {
                                                                  required: {
                                                                      // value:true,
                                                                      message: 'please enter your Message'
                                                                  },

                                                              })}
                                                              type="text"
                                                              placeholder="Cover Letter"/>
                                            </Form.Group>
                                        </div>

                                        <div className={'form-group width-fit'}>
                                            <div className="attachCvName">
                                                <div className="attach-cv">
                                                    <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         xmlnsxlink="http://www.w3.org/1999/xlink" width="17"
                                                         height="17" viewBox="0 0 17 17">
                                                        <g id="Mask_Group_429" data-name="Mask Group 429"
                                                           transform="translate(-858 -2127)">
                                                            <path id="attach"
                                                                  d="M13.468,3.458a3.116,3.116,0,0,0-4.408,0L7.272,5.248a.524.524,0,0,1-.747,0,.538.538,0,0,1,0-.755L8.317,2.7a4.175,4.175,0,0,1,5.894,5.914L7.63,15.2a.524.524,0,0,1-.747,0,.538.538,0,0,1,0-.755l6.582-6.587A3.11,3.11,0,0,0,13.468,3.458ZM8.889,5.563A1.746,1.746,0,0,1,11.35,8.04L5.34,14.055a2.96,2.96,0,0,1-4.177-4.2L4.74,6.279a.524.524,0,0,1,.747,0,.538.538,0,0,1,0,.755l-3.577,3.58A1.9,1.9,0,1,0,4.6,13.3l6.009-6.014a.678.678,0,0,0,0-.96.689.689,0,0,0-.974,0L4.2,11.763a.524.524,0,0,1-.747,0,.538.538,0,0,1,0-.755Z"
                                                                  transform="translate(858.257 2126.737)" fill="#f9f9f9"
                                                                  fill-rule="evenodd"/>
                                                        </g>
                                                    </svg>

                                                    <Form.Control
                                                        type="file"
                                                        accept=".pdf"
                                                        id="resume-upload"
                                                        style={{display: "none"}}
                                                        {...('file')}
                                                        onChange={''}
                                                    />
                                                    <Form.Label htmlFor="resume-upload" className="resume-upload-label">
                                                        Attach PPTX
                                                    </Form.Label>
                                                    <div className="file-name">
                                                        {''}
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            <div onClick={''} className={'submit-button'}>
                                                <Button color={'white'} background={'#323232'} text={'Submit Message'}
                                                        hoverColor={'white'}/>
                                            </div>

                                        </div>

                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </StyledModal>

    )
};


const StyledModal = styled.section`
  background-color: #F5F5F5;

  .modal-dialog {
    max-width: 100% !important;
  }

  h4 {
    letter-spacing: 1.5px;
  }


`;


export default React.memo(CareerPopup);
