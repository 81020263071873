import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from 'react-bootstrap';
import {Img} from "../Img";
import Title from "../Title";

import {useSelector} from "react-redux";
import reactHtmlParser from 'react-html-parser';
import ButtonOutlineHover from "../ButtonShort";
import {Parallax} from "react-parallax";

const AboutV17 = ({data}) => {

    // filter data
    const ChildheartData = data?.section_data;
    const ChildheartSlider = data?.images?.list;

    const getData = useSelector((state) => state.homeReducer);
    const filterData = getData?.posts[0]?.about_section;


// offset
    const [offset, setOffset] = useState(100)
    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)

        window.addEventListener('load', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
    }, [])


    return (
        <StyledParralaxHome offset={offset} background={"#F2F0F0"}
                            className={`about_image_with_text pb-200`}>
                <Container fluid className={'version_one p-0'}>
                    <Row className={'about_image_with_text_wrapper'}>
                        <Col md={{span: 5}} className={'p-0 pr-0'}>
                                <div className="image_wrapper_whole">
                                    <div className="image_right">
                                        {window.innerWidth > 991 ?
                                            <Parallax bgImage={ChildheartSlider?.[1]?.full_path ? ChildheartSlider?.[1]?.full_path : ''} strength={-50}/>
                                            :
                                            <Img src={ChildheartSlider?.[1]?.full_path ? ChildheartSlider?.[1]?.full_path : ''}/>
                                        }
                                    </div>
                                </div>
                        </Col>

                        <Col md={{span: 7}} className={'pr-0 pl-0 right_text_content'}>

                            <div className="right_text_content_wrp">
                                <Title margin={'0 0 60px'} color={'#FFFFFF'}
                                       text={ChildheartData?.subtitle ? ChildheartData?.subtitle : ''}/>
                                <div className="fade-up split-up">
                                    {reactHtmlParser(ChildheartData?.description ? ChildheartData?.description : '')}
                                </div>
                                <ButtonOutlineHover src={'/child-heart-details'} color={'white'} text={'Read More'} icon={'black'} hoverColor={'white'} margin={'60px 0 0 0'} marginMobile={'60px 0 0 0'}/>
                            </div>
                        </Col>
                    </Row>
                </Container>

            <div className="imageFixed">
                <div className="image_left">
                    <Parallax bgImage={ChildheartSlider?.[0]?.full_path} strength={250}/>
                </div>

            </div>
        </StyledParralaxHome>

    );


};


const StyledParralaxHome = styled.div`
  background-color: #FFFFFF;
  position: relative;
  display: flex;
  height: 100%;
  overflow: hidden;

  .react-parallax{
    position: absolute!important;
    overflow: hidden!important;
    inset: 0!important;
    height: 100%!important;
    width: 100%!important;

  }
  
  
  &.about_image_with_text:before{
    content: "";
    width: 100%;
    height: 50%;
    bottom: 0;
    left: 0;
    position: absolute;
    background: #F6F9F8;
  }
  
  .react-parallax{
    overflow: unset!important;
  }

  .fade-up {
    transform: none !important;
    opacity: 1 !important;
    visibility: visible !important;
  }

  .underline-btn {
    //margin-top: 40px;
    a {
      position: relative;

      &:before {

      }

    }

    span {
      &:before {
        background: #010A1A;
        transition: height 0.4s linear;

      }

      &:after {
        display: none;
      }
    }

    &:after {
      display: none;
    }

    &:before {
      display: none;
    }

    span {
      &:before {
        background: #010A1A;
      }

      &:after {
        display: none;
      }
    }

    &:hover {
      &:after {
        width: 100%;
      }

      span {
        &:after {
          width: 100%;
        }

        &:before {
          height: 4px;
        }
      }
    }
  }


  .parallax_one {
    position: relative;
    z-index: 1;
  }

  .parrallax_two {
    position: relative;
    z-index: 2;

  }

  .imageFixed {
    position: absolute;
    left: 0;
    top: 200px;
    width: 50vh;
    //height: 620px;
    min-height: 100vh;
    z-index: 1;
    @media (min-width: 1500px) and (max-width: 1800px) {
      min-height: calc(100vh - 200px);
    }
    @media (max-width: 767px) {
      display: none;
    }
  }

  .about_image_with_text_wrapper {
    //align-items: center;
    .pr-0 {
      padding-right: 0 !important;
    }


  }
  .small_title {
    margin-bottom: 0 !important;
  }

  .version_one {
    padding-left: ${props => props.offset ? props.offset + 30 + 'px' : '100px'}!important;
    position: relative;
    z-index: 2;
    @media (max-width: 767px) {
      top: 0;
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    .parrallax_two {

      @media (max-width: 767px) {
        margin-top: 120px;
      }
    }

    .right_text_content {

      .right_text_content_wrp {
        position: relative;
        top: 25%;
        padding-left: 70px;
        padding-right: ${props => props.offset + 30 + 'px !important' || ''};
        padding-top: 167px;
        padding-bottom: 167px;
        background: #1E1E2E;
        p{
          color: #FFFFFF;
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
        }
        @media (max-width: 767px) {
          top: unset;
          padding-top: 80px;
          padding-bottom: 80px;
          padding-left: 15px !important;
          padding-right: 15px !important;
          background: #1E1E2E;

        }
      }
    }

    .image_wrapper_whole {
      position: relative;

      .image_left {
        padding-top: calc(618 / 420 * 100%);
        position: relative;
        height: 768px;
        z-index: 1;
        @media (max-width: 767px) {

        }
      }

      .image_right {
        padding-top: calc(768 / 500 * 100%);
        z-index: 0;
        width: 100%;
        min-height: 768px;
        @media (max-width: 767px) {
        }
      }

      @media (max-width: 767px) {
        margin-top: 30px;
      }
    }

  }

  @media (max-width: 767px) {
    .title{
      margin-bottom: 60px;
    }
    padding-top: 0px !important;
    .pr-0 {
      padding-right: 15px !important;
    }
    padding-bottom: 120px!important;
    
  }

  &:after {
    height: 200px;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: #FFFFFF;
  }
`;


export default React.memo(AboutV17);
