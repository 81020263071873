import React, {lazy, Suspense, useState} from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';
import Error from './pages/404';
import GlobalStyle from "./styles/globalStyle";
import {ImageReveal} from "./components/animations/ImageReveal";

import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import styled from "styled-components";
import AnimatedIconMoon from "./components/AnimatedIconMoon";
import {useEffect, useLayoutEffect, useRef} from "react";
import {useSelector} from "react-redux";
import BooksAll from "./pages/books";
import BooksPageDetailss from "./pages/books/single";
import pageTransition from "./components/animations/PageTransition";
import {SplitUp} from "./components/animations/TextAnimation";
import {SmootherContext} from "./components/SmootherContext";
import {Loading} from "./components/Loading";
import HospitalServiceRates from "./pages/hospitalrates";
import {useCookies} from "react-cookie";
import doctorsFilterPage from "./components/Doctors/DoctorsFilterPage";
import DoctorsListing from "./pages/doctors";
import NoticePopUp from "./components/Home/NoticePopUp";
import PreloaderSvgAnimation from "./components/PreloaderSvgAnimation";





// page imports
import Home from './pages/home';
import About from './pages/about';
import Components from './pages/components';
import Founder from './pages/founder';
import Activites from './pages/activites';
import MobileMenu from './components/MobileMenu';
import Doctors from './pages/doctors';
import DoctorsPageDetails from './pages/doctors/single';
import PublicationsAll from './pages/publications';
import PublicationsPageDetailss from './pages/publications/single';
import Contact from './pages/contact';
import Career from './pages/career';
import NewsEventsPageDetails from './pages/newsevents/single';
import NewsEventsAll from './pages/newsevents';
import ChildHeartIndex from './pages/childheartdetails';
import CCDIndex from './pages/ccd';
import ResearchIndex from './pages/research';
import ACIndex from './pages/academicactivities';
import UfatIndex from './pages/ufat';
import DepartmentIndex from './pages/department';
import DepartmentPageDetails from './pages/department/single';
import AffiliatedBody from './pages/affiliated-body';
import ExecutiveCouncil from './pages/executive-council';
import ControlpreventionIndex from './pages/controlprevention';
import ConferenceIndex from './pages/conference';
import ClinicalservicesIndex from './pages/clinicalservices';
import AcademicActivitiesDetails from './pages/academicactivities/single';
import ResearchPageDetails from './pages/research/single';
import ControlPreventionDetails from './pages/controlprevention/single';
import ConferenceDetails from './pages/conference/single';
import ClinicalServicesDetails from './pages/clinicalservices/single';
import Boardoftrustees from "./pages/boardoftrustees";
import FounderBooksAll from "./pages/founderbooks";
import FounderBooksDetailIndex from "./pages/founderbooks/single";
import FounderPublicationsFilter from "./components/FounderPublications/FounderPublicationsFilter";
import FounderPublicationsAll from "./pages/founderpublications";
import FounderPublicationsPageDetailsIndex from "./pages/founderpublications/single";



function App() {
    const [menuColor, setMenuColor] = useState('white');
    const store = useSelector(store=>store)

    const location = useLocation();

    const smoother = useRef()
    let [smootherContext, setSmootherContext] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    }

    const [showNotice, setShowNotice] = useState(false);

    useEffect(() => {
        // Check if the 'visited' key is in sessionStorage
        const visited = sessionStorage.getItem('visited');

        // If 'visited' key is not present, it's the first visit
        if (!visited) {
            // Set the 'visited' key in sessionStorage
            sessionStorage.setItem('visited', 'true');
            // Show the notice pop-up
            setShowNotice(true);
        }
    }, []);


    // canonical generate
    useEffect(() => {
        // Get the current URL from the router's full path
        const currentUrl = window.location.origin + location.pathname + location.search;

        // Set the canonical link dynamically
        const link = document.createElement('link');
        link.rel = 'canonical';
        link.href = currentUrl;
        document.head.appendChild(link);

        // Clean up the link when the component unmounts
        return () => {
            document.head.removeChild(link);
        };
    }, [location.pathname, location.search]);


    const closeNotice = () => {
        // Handle the close action if needed
        setShowNotice(false);
    };

    const FloatingButton = styled.div`
      position: fixed;
      top: calc(50% - 15vh);
      right: 0;
      z-index: 1;
      p {
        color: #fff;
      }
    `;

    const [isLoadingContent, setIsLoadingContent] = useState(true);
    useEffect(() => {
        setIsLoadingContent(true);
        setTimeout(() => {
            setIsLoadingContent(false);
        }, 500);
    }, [location?.pathname]);


// menu color change on pathname
    useEffect(() => {
        // Update menu color based on the current route
        if (
            location.pathname.startsWith(`/academic-activities/`)
            || location.pathname.startsWith(`/doctors/`)
            || location.pathname.startsWith(`/news-events/`)
            || location.pathname.startsWith(`/publications/`)
            || location.pathname.startsWith(`/research/`)
            || location.pathname.startsWith(`/conference/`)
            || location.pathname.startsWith(`/activities/`)
            || location.pathname.startsWith(`/books`)
            || location.pathname.startsWith(`/books/`)
            || location.pathname.startsWith(`/hospital-activities/`)
            || location.pathname.startsWith(`/board-of-trustees`)
            || location.pathname.startsWith(`/founder-books/`)
            || location.pathname.startsWith(`/founder-publications/`)
        ) {
            setMenuColor('black');
        } else {
            setMenuColor('white');
        }
    }, [location.pathname]);

    // inner-page-location-fix

    useEffect(() => {
        if (location.pathname !== '/') {
            document.body.classList.add('in-inner-page')
        } else {
            document.body.classList.remove('in-inner-page')
        }
        window.scroll(0, 0)

    }, [location.pathname])



    // route change scroll top & page class
    useEffect(() => {
        if (location.pathname === '/') {
            document.body.classList.add('in-home-page')
        } else {
            document.body.classList.remove('in-home-page')
        }

        window.scroll(0, 0)
    }, [location.pathname])



    // animation functions
    ImageReveal()
    // Parallax()
    // ParallaxImg()
    // SplitUp()
    // // HorizontalScroll()
    // // page transition
    pageTransition()

    return (

            <>
                {store?.careerReucer?.loading || store?.contactReducer?.loading && <Loading/>}
                {/*{store?.careerReucer?.loading || store?.contactReducer && <Loading/>}*/}
                <GlobalStyle/>
                <ToastContainer position="top-right" autoClose={4000} closeOnClick hideProgressBar={true}/>
                {/*page transition */}
                <div className="page-transition">
                    {/*<img className={'logo'} src="/images/static/nhfb.png" alt=""/>*/}
                    <div className={'logo'}>
                        <PreloaderSvgAnimation/>
                    </div>

                </div>


                    <Menu color={menuColor}/>
                    <MobileMenu color={menuColor}/>


                <Switch location={location} key={'location.pathname'}>

                        <FloatingButton>
                            <AnimatedIconMoon/>
                        </FloatingButton>

                </Switch>

                <div className="App">
                    {location.pathname === '/' &&
                        <NoticePopUp show={showNotice} onClose={closeNotice} />
                    }

                    <div>
                            <Switch location={location} key={'location.pathname'}>
                                <Route exact path="/" component={Home}/>
                                <Route exact path="/components" component={Components}/>
                                <Route exact path="/about" component={About}/>
                                <Route exact path="/founder" component={Founder}/>
                                <Route exact path="/nhf-ccd" component={CCDIndex}/>
                                <Route exact path="/our-activities" component={Activites}/>
                                <Route exact path={`/doctors`} component={Doctors}/>
                                <Route exact path={`/research`} component={ResearchIndex}/>
                                <Route exact path={`/ufat`} component={UfatIndex}/>
                                <Route exact path={`/conference`} component={ConferenceIndex}/>
                                <Route exact path={`/hospital-activities`} component={ClinicalservicesIndex}/>
                                <Route exact path={`/department`} component={DepartmentIndex}/>
                                <Route exact path={`/academic-activities`} component={ACIndex}/>
                                <Route exact path={`/control-prevention`} component={ControlpreventionIndex}/>
                                <Route exact path={`/contact`} component={Contact}/>
                                <Route exact path={`/career`} component={Career}/>
                                <Route exact path={`/child-heart-details`} component={ChildHeartIndex}/>
                                <Route exact path={`/doctors-list`} component={DoctorsListing}/>
                                <Route exact path={`/doctors/:slug`} component={DoctorsPageDetails}/>
                                <Route exact path={`/department/:slug`} component={DepartmentPageDetails}/>
                                <Route exact path={`/academic-activities/:slug`} component={AcademicActivitiesDetails}/>
                                <Route exact path={`/publications/:slug`} component={PublicationsPageDetailss}/>
                                <Route exact path={`/books/:slug`} component={BooksPageDetailss}/>
                                <Route exact path={`/news-events/:slug`} component={NewsEventsPageDetails}/>
                                <Route exact path={`/research/:slug`} component={ResearchPageDetails}/>
                                <Route exact path={`/control-prevention/slug`} component={ControlPreventionDetails}/>
                                <Route exact path={`/conference/:slug`} component={ConferenceDetails}/>
                                <Route exact path={`/hospital-activities/:slug`} component={ClinicalServicesDetails}/>
                                <Route exact path="/publications" component={PublicationsAll}/>
                                <Route exact path="/books" component={BooksAll}/>
                                <Route exact path="/founder-books" component={FounderBooksAll}/>
                                <Route exact path="/founder-books/:slug" component={FounderBooksDetailIndex}/>
                                <Route exact path="/founder-publications" component={FounderPublicationsAll}/>
                                <Route exact path="/founder-publications/:slug" component={FounderPublicationsPageDetailsIndex}/>
                                <Route exact path="/board-of-trustees" component={Boardoftrustees}/>
                                <Route exact path="/service-rates" component={HospitalServiceRates}/>
                                <Route exact path="/news-events" component={NewsEventsAll}/>
                                <Route exact path={`/affiliated-body`} component={AffiliatedBody}/>
                                <Route exact path={`/executive-council`} component={ExecutiveCouncil}/>
                                <Route component={Error}/>
                            </Switch>
                            {
                                !isLoadingContent &&
                                (<Footer/>)
                            }
                    </div>
                </div>
            </>


    );
}

export default App;
