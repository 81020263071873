import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Form, Row} from "react-bootstrap";
import Button from "../Button";
import {useDispatch, useSelector} from "react-redux";


import {Link} from "react-router-dom";
import Select, {components} from "react-select";
import {Img} from "../Img";
import ButtonHover from "../ButtonHover";
import moment from "moment/moment";
import SingleControl from "../Home/SingleControl";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data, search, cat}) => {

    // filter API data
    const PreventionList = data;


    return (
        <StyledComponent id={'got-to-list'} className={'article-list pb-200'}>
            <Container>
                <Row className={'min-height box-body'}>
                    {PreventionList?.length > 0 && PreventionList?.map(i => (
                        <Col md={6} lg={4} className={'box-gap'}>
                            <SingleControl link={`/control-prevention/slug?details=${reactHtmlParser(i?.page_data?.slug)}`} image={i?.images?.list[0]?.full_path}
                                           text={i?.page_data?.subtitle} titleColor={'#1E1E2E'}/>
                        </Col>
                        ))}
                </Row>

            </Container>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  padding-top: 65px;
  overflow: hidden;
  background-color: #F6F9F8;

  .box-body{
    .col-md-4{
      margin-bottom: 60px;
      padding-bottom: 0;
    }
    .col-md-4:nth-last-child(-n+3) {
      margin-bottom: 0px;
    }
  }

  .min-height {
    padding-top: 80px;

    .box-gap:nth-child(3n+3) {
      padding-bottom: 80px;
    }
    @media (min-width: 768px) and (max-width: 991px){
      .box-gap:nth-child(2n+2) {
        padding-bottom: 80px;
      }
    }
  }
  
  
   
`;
export default React.memo(MyComponent);