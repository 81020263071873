import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Form, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import Select, {components} from "react-select";
import ButtonHover from "../ButtonHover";
import reactHtmlParser from "react-html-parser";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchSearch} from "../../api/redux/newsevents";
const MyComponent = ({data, search, cat}) => {

    const dispatch = useDispatch()
    //To get the url
    const location = useLocation();
    const [searchInput, setSearchInput] = useState('')
    //To get the params
    const searchSubmit = (e) => {
        e.preventDefault()
        // Router.push({
        //     pathname: '/publications', query: {query: searchInput},
        // })
        setSearchInput('')
    }

    // api call
    useEffect(() => {

        let api_services = apiEndPoints.SEARCHPUBLICATIONS;
        let param = {
            [ApiParam.keyword]: searchInput,
        }
        dispatch(fetchSearch([api_services, param]))
    }, [searchInput])


    const selectTypeInputRef = useRef();
    const [itemsToShow, setItemsToShow] = useState(6);
    const [isCompleted, setIsCompleted] = useState(false)
    const [selectType, setSelectType] = useState('All')
    const [selectedCategory, setSelectedCategory] = useState("All");
    const [filteredData, setFilteredData] = useState(data?.data?.list); // Initialize with all data
    const [activeItem, setActiveItem] = useState('All'); // Set 'All' as the default active item

    const finalData = filteredData

    console.log('shuvo',filteredData)
    const handleInput = (e) => {
        setSearchInput(e?.target?.value)

    }
    useEffect(() => {
        if (data?.data?.list && Array.isArray(data?.data?.list)) {
            const filteredResults = data?.data?.list?.filter(item => {
                return item.page_data.title.toLowerCase().includes(searchInput.toLowerCase());
            });
            setFilteredData(filteredResults?.reverse());
        }
    }, [data?.data?.list, searchInput]);



    const handleItemClick = (item) => {
        setActiveItem(item);
        // Filter the data when an item is clicked
        filterData(item);
    };

    const handleType = (e) => {
        setSelectType(e);
        // Filter the data when the select dropdown changes
        filterData(e);
    };

    useEffect(() => {
        setActiveItem('All');
    }, []);
    const handleCategoryChange = (selectedValue) => {
        setSelectedCategory(selectedValue);
    };

    useEffect(() => {
        setFilteredData(data?.data?.list)
    }, [data?.data?.list])


    const filterData = (filter) => {
        const listCopy = data.data.list;
        if (filter === 'All') {
            setFilteredData(listCopy); // Show all data
        } else {
            // Filter the data based on the selected category title
            setFilteredData(listCopy?.filter((item) => item?.page_data?.category === filter));
        }
    };

    const handleLoadMore = () => {
        setItemsToShow(prevItemsToShow => prevItemsToShow + 6);
        if (itemsToShow + 6 >= filteredData.length) {
            setIsCompleted(true);
        } else {
            setIsCompleted(false);
        }
    };



    const options = [
        {value: 'All', label: 'All'},
        ...(Array.isArray(data?.category_list)
            ? data?.category_list?.map((item) => ({
                value: item?.category,
                label: item?.category,
            }))
            : [])
    ];

    // Filter out duplicate values based on the "value" property
    const uniqueTypes = Array.from(new Set(options?.map((option) => option.value)))?.map((value) => options.find((option) => option.value === value));

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? '#000' : '#221F1F',
            backgroundColor: state.isSelected ? '' : '#FFF',
            marginTop: 10,
            marginLeft: 2,
            fontSize: 20,

            paddingLeft: 25,
            overflowY: 'auto',
            // borderColor: state.isFocused ? '#FF0000' : '#CCCCCC',
        }),
        menu: (provided, state) => ({
            ...provided,
            color: '#FFF',
            backgroundColor: state.isSelected ? '' : '',
            margin: 0,
            borderRadius: 5,
            fontSize: 12,


        }),
        menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#000' : '#FFF',
            borderRadius: 0,
            fontWeight: '500',
            color: '#FFF',
            fontSize: 20,
            paddingBottom: 25,
        }),

    };


    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="16.414" viewBox="0 0 11.414 16.414">
                        <g id="Group_21092" data-name="Group 21092" transform="translate(-340.293 -2110.293)">
                            <g id="Group_21092-2" data-name="Group 21092"
                               transform="translate(1585.5 2872.5) rotate(180)">
                                <line id="Line_3557" data-name="Line 3557" x2="5" y2="5"
                                      transform="translate(1234.5 756.5)" fill="none" stroke="#fff"
                                      stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_3558" data-name="Line 3558" x1="5" y2="5"
                                      transform="translate(1239.5 756.5)" fill="none" stroke="#fff"
                                      stroke-linecap="round" stroke-width="1"/>
                            </g>
                            <g id="Group_21093" data-name="Group 21093" transform="translate(-893.5 1364.5)">
                                <line id="Line_3557-2" data-name="Line 3557" x2="5" y2="5"
                                      transform="translate(1234.5 756.5)" fill="none" stroke="#fff"
                                      stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_3558-2" data-name="Line 3558" x1="5" y2="5"
                                      transform="translate(1239.5 756.5)" fill="none" stroke="#fff"
                                      stroke-linecap="round" stroke-width="1"/>
                            </g>
                        </g>
                    </svg>

                </components.DropdownIndicator>
            )
        );
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Update the window width when the window is resized
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <StyledComponent id={'got-to-list'} className={'article-list pb-200'}>
            <Container>
                <Row className={'filter_wrap'}>
                    <Col md={9}>
                        {
                            windowWidth > 767 ?
                                <div className="filter">

                                    <ul>
                                        {uniqueTypes.map((item, index) => (
                                            <span
                                                key={index}
                                                className={`${activeItem === item.value || (activeItem === 'All' && item.value === 'All') ? 'active' : ''}`}
                                                onClick={() => handleItemClick(item.value)}>
                                                {item.label}
                                            </span>
                                        ))}
                                    </ul>
                                </div>
                                :
                                <div className="filter-mobile">
                                    <Select
                                        classNamePrefix="filter"
                                        isSearchable={false}
                                        ref={selectTypeInputRef}
                                        options={uniqueTypes}
                                        onChange={(e) => handleType(e?.value)}
                                        placeholder='All' styles={customStyles}
                                        // components={{DropdownIndicator}}
                                    />

                                </div>
                        }

                    </Col>
                    <Col md={{span: 3}}>
                        <div className="search">
                            {/*<Form className={'form'} onSubmit={''}>*/}
                            {/*    <div className="form-group">*/}
                            {/*        <Form.Group className={'flex'} controlId="formBasicEmail">*/}
                            {/*            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"*/}
                            {/*                 viewBox="0 0 17 17">*/}
                            {/*                <g id="_12" data-name="12" transform="translate(-1.96 -1.96)">*/}
                            {/*                    <path id="Path_8655" data-name="Path 8655"*/}
                            {/*                          d="M18.709,17.505l-2.4-2.392a8.074,8.074,0,1,0-1.2,1.2l2.392,2.4a.852.852,0,1,0,1.2-1.2ZM3.69,10.051a6.361,6.361,0,1,1,6.361,6.361A6.361,6.361,0,0,1,3.69,10.051Z"*/}
                            {/*                          transform="translate(0 0)" fill="#e72e4b"/>*/}
                            {/*                </g>*/}
                            {/*            </svg>*/}


                            {/*            <Form.Control type="text" value={searchInput} onChange={e => handleInput(e)}*/}
                            {/*                          placeholder="Search"*/}
                            {/*                          name={'search'}*/}

                            {/*                          className={'form-control-lg'}*/}

                            {/*            />*/}
                            {/*        </Form.Group>*/}
                            {/*    </div>*/}
                            {/*</Form>*/}
                        </div>
                    </Col>
                </Row>


                <Row className={'min-height'}>

                    {filteredData &&
                        filteredData?.slice(0, itemsToShow)?.map((i, index) => {
                            return (
                                <Col className={'profile-gap'} md={6} lg={4}>
                                    <div className=''>
                                        <Link to={`/news-events/:slug?details=${reactHtmlParser(i?.page_data?.slug)}`}>
                                            <a>
                                                <div className='presslist__single'>
                                                    <div className='presslist__single__content'>
                                                        <div className={'presslist__single__content__height'}>
                                                            <p className={'split-up'}>{reactHtmlParser(i?.page_data?.subtitle)}</p>
                                                            <span className={'split-up'}>{i?.page_data?.description}</span>
                                                        </div>
                                                        <div className='presslist__single__content__text'>
                                                            <div>
                                                                <h2>{i?.page_data?.short_desc}</h2>
                                                                <h6>{i?.page_data?.category} </h6>
                                                            </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                 xmlnsxlink="http://www.w3.org/1999/xlink" width="30"
                                                                 height="30" viewBox="0 0 30 30">
                                                                <g id="Mask_Group_344" data-name="Mask Group 344"
                                                                   transform="translate(-128 -1437)">
                                                                    <circle id="Ellipse_475" data-name="Ellipse 475" cx="15"
                                                                            cy="15" r="15"
                                                                            transform="translate(128 1467) rotate(-90)"
                                                                            fill="#191b1d"/>
                                                                    <circle id="Ellipse_476" data-name="Ellipse 476" cx="15"
                                                                            cy="15" r="15"
                                                                            transform="translate(128 1467) rotate(-90)"
                                                                            fill="#191b1d"/>
                                                                    <g id="Arrow" transform="translate(0.5 1.5)">
                                                                        <line id="Line_3960" data-name="Line 3960" x2="4" y2="4"
                                                                              transform="translate(140.5 1446.5)" fill="none"
                                                                              stroke="#fff" stroke-linecap="round"
                                                                              stroke-width="1"/>
                                                                        <line id="Line_3961" data-name="Line 3961" y1="4" x2="4"
                                                                              transform="translate(140.5 1450.5)" fill="none"
                                                                              stroke="#fff" stroke-linecap="round"
                                                                              stroke-width="1"/>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </Link>
                                    </div>
                                </Col>

                            );
                        })}



                    {itemsToShow < filteredData?.length && (
                        <Col sm={12} className={'load_more'}>
                            <div onClick={handleLoadMore}>
                                <ButtonHover text={'Load More'} margin={'0 auto'} color={'#FFFFFF'} background={'#E8E8E8'}/>
                            </div>
                        </Col>
                    )}
                </Row>

            </Container>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  padding-top: 200px;
  background-color: #F6F9F8;
  .presslist {
    &__single {
      min-height: 100%;
      margin-bottom: 30px;
      //box-sizing: border-box;
      transition: 1s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      position: relative;
      padding-top: calc(460/370*100%);


      &__content {
        width: 100%;
        padding: 40px;
        position: absolute;
        background-color: rgb(30, 30, 46);
        top: 0;
        left: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 992px) {
          font-size: 18px;
          line-height: 27px;
        };

        //&__text {
        //  min-height: 135px;
        //}

        &__link {
          position: absolute;
          bottom: 31px;
        }
        span{
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          min-height: 290px;
        }

        h2 {
          color: #fff;
          opacity: 70%;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 10px;
          font-weight: 400;
        }
        h6{
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          color: #fff;
        }

        p {
          color: #fff;
          margin-bottom: 30px;
          font-size: 16px;
          line-height: 20px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 700;

        }

        &__learn {
          position: relative;

          &:after {
            content: '';
            height: 3px;
            width: 0px;
            background-image: linear-gradient(#AD0000, #FF0000);
            position: absolute;
            transition: .3s;
          }

          h2 {
            color: #222222;
            font-weight: 500;
            cursor: pointer;
            border-bottom: 2px solid #222222;
            width: 26%;
          }
        }
        &__text {
          display: flex;
          justify-content: space-between;

          svg {

            border-radius: 30px;

            g {
              #Ellipse_475 {
                fill: white;
              }
            }

            circle[id="Ellipse_476"] {
              cy: -15;
              fill: #E72E4B;
              transition: all 0.5s ease;
            }
            #Arrow {
              line {
                stroke: #000;
                transition: all 0.5s ease;
              }
            }

            &:hover {
              #Arrow {
                line {
                  stroke: #fff;
                  transition: all 0.5s ease;
                }
              }
              circle[id="Ellipse_476"] {
                cy: 15;
                fill: #E72E4B;
                transition: all 0.5s ease;
              }
            }
          }
        }

        &__height{
          overflow: hidden;
          p {
            font-size: 20px;
            line-height: 26px;
          }
          min-height: 190px;
        }
      }

      @media (max-width: 767px) {
        margin-bottom: 20px !important;
        :last-child {
          margin-bottom: 0px;
        }
      }
      &:hover {
        svg{
          #Arrow {
            line {
              stroke: #fff;
              transition: all 0.5s ease;
            }
          }
          circle[id="Ellipse_476"] {
            cy: 15;
            fill: #E72E4B;
            transition: all 0.5s ease;
          }
        }
      }

    }
  }
  
  

  .filter_wrap {
    .filter {
      ul {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        row-gap: 30px;

        span {
          overflow: hidden;
          display: inline-block;
          border-radius: 50px;
          padding: 12px 36px;
          color: #1E1E2E;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          border: 1px solid #1E1E2E;
          margin-right: 30px;
          position: relative;
          cursor: pointer;
          transition: 0.7s all ease;
          z-index: 10;
          position: relative;

          &:after {
            position: absolute;
            background: #E72E4B;
            width: 100%;
            height: 100%;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: 0.5s all ease;
            transform: translateY(100px);;
            border-radius: 21px;
            z-index: -1;
          }

          span {
            position: relative;
            z-index: 1;
            transition: 0.5s all ease;

          }

          &:last-child {
            margin-right: 0;
          }

          &.active {
            background: #E72E4B;
            color: white;
            border: 1px solid #E72E4B;
          }

          &:hover {
            color: white !important;
            border: 1px solid #E72E4B;
            span {
              color: white;
              border: 1px solid white;
            }

            &:after {
              transform: scale(1);
            }
          }
        }
      }
    }
  }
  .load_more {
    .dc-btn {
      display: flex;
      justify-content: center;
      a {

        margin: auto;
        background-color: #1E1E2E;
        &:hover{
          color: white!important;
        }
      }
    }
  }

  .search {
    .form-group {
      position: relative;

      svg {
        position: absolute;
        top: 12px;
        left: 20px;
        right: 12px;
        bottom: 12px;
      }

      .form-control {
        border: 1px solid #1E1E2E;
        background: transparent;
        border-radius: 21px;
        height: 40px;
        padding: 0 15px 0 45px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #1E1E2E;

        &::placeholder {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          color: #1E1E2E;
        }
      }
    }
  }
}
.min-height{
  padding-top: 80px;
  .profile-gap{
    padding-bottom: 40px;
  }
}

.profile-image{
  position: relative;
  padding-top: calc(458/360 * 100%);
}
.profile-text{
  h6{
    padding-top: 20px;
    color: #1E1E2E;
  }
  p{
    padding-top: 5px;
    color: #000000a8;
    font-size: 16px;
    font-weight: 400;
  }
}

.filter-mobile {
  display: none;
}

.css-1wa3eu0-placeholder, .css-1uccc91-singleValue {
  color: white !important;
  font-size: 10px;
  font-weight: 600 !important;
}

.read-btn {

  position: relative;
}

@media (min-width: 992px) {
  .min-height {
    min-height: 490px;
  }
}
@media (max-width: 767px) {
  padding-top: 60px;
  .profile-gap:not(:last-child){
    padding-bottom: 60px;
  }
  .filter_wrap {
    flex-direction: column-reverse;

    .search {
      margin-bottom: 40px;
    }
  }
  .load_more{
    margin-top: 0;
  }

  .filter-mobile {
    display: block;
  }

  .filter {
    display: none;
  }

  .css-13cymwt-control, .css-t3ipsp-control{
    background-color: #E72E4B;
    border-radius: 50px;
    .css-1jqq78o-placeholder{
      color: white;
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      margin:10px 0 10px 30px;
    }
    .css-1fdsijx-ValueContainer{
      padding: unset;
    }
    .filter__single-value{
      margin-left: 15px!important;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      color: rgb(255, 255, 255);
    }
    .css-1u9des2-indicatorSeparator{
      display: none;
    }
    .css-1xc3v61-indicatorContainer{
      padding-right: 30px;
    }
  }

  .css-t3ipsp-control{
    border-color:unset!important;
    box-shadow: unset!important;
  }
  .css-qbdosj-Input{
    input{
      min-width: 0px!important;
      opacity: 0!important;
    }
  }
  .css-15lsz6c-indicatorContainer{
    padding-right: 30px;
    color: hsl(0, 0%, 80%);
  }


  .css-yk16xz-control, .css-1pahdxg-control {
    border-radius: 18px !important;
    border-color: #989898;
    height: 40px;
    background-color: #E50019;
    cursor: pointer;
    padding-left: 15px;
  }

  .css-yk16xz-control .css-1wa3eu0-placeholder, .css-1pahdxg-control .css-1wa3eu0-placeholder, .css-1uccc91-singleValue {
    color: #ffffff !important;
    font-size: 12px !important;
    font-weight: bold;
    cursor: pointer;
  }

  .css-yk16xz-control .css-tlfecz-indicatorContainer, .css-1pahdxg-control .css-tlfecz-indicatorContainer, .css-yk16xz-control .css-1gtu0rj-indicatorContainer, .css-1pahdxg-control .css-1gtu0rj-indicatorContainer {
    margin-right: 20px;
  }

`;
export default React.memo(MyComponent);