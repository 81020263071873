import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";


import {ImgParallax} from "../ImgParallax";
import ButtonLong from "../ButtonLong";
import SubmissionPopUp from "./SubmissionPopUp";
import Title from "../Title";
import ButtonViewAll from "../ButtonViewAll";
import {Parallax} from "react-parallax";
import ButtonOutlineHover from "../ButtonShort";


const MyComponent = ({CCDHome}) => {


    const [show, setShow] = useState(false);
    const [winWidth, setWinWidth] = useState(true)
    const [popupTitle, setpopupTitle] = useState()
    const [popupDesc, setpopupDesc] = useState()
    const handleClose = () => setShow(false);
    const handleShow = (i) => {
        setShow(true)
        setpopupTitle(i?.data?.subtitle);
        setpopupDesc(i?.data?.description);
    }

    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])

    return (
        <>
            <StyledTitle>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className={'ccd-title'}>
                                <div className="title">
                                    <Title text={"NHF CCD"} color={'#1E1E2E'}/>
                                </div>
                                <div>
                                    <ButtonOutlineHover src={'/nhf-ccd'} color={'black'} text={'View Details'} icon={'black'}
                                                   hoverColor={'#E72E4B'} margin={'0 0 0 40px'}/>

                                </div>
                            </div>w
                        </Col>
                    </Row>
                </Container>
            </StyledTitle>
            <StyledComponent className='sustainability-text parallax-img'>
                {/*<ImgParallax src={CCDHome?.images?.list[0]?.full_path ? CCDHome?.images?.list[0]?.full_path : ''}/>*/}
                <Parallax bgImage={CCDHome?.images?.list[0]?.full_path ? CCDHome?.images?.list[0]?.full_path : ''} strength={200}/>
                <Container>
                    <Row>
                        <Col md={6}>
                            {/*<h2 className={'split-up'}>{CCDHome?.section_data?.subtitle ? CCDHome?.section_data?.subtitle : ''}</h2>*/}
                            <p className={'split-up'}>{CCDHome?.section_data?.description ? CCDHome?.section_data?.description : ''}</p>
                            <ButtonLong icon={'black'} src={'/nhf-ccd'} svgColor={'rgb(30, 30, 46)'}
                                        background={'transparent'} color={'rgb(30, 30, 46)'} text={'Register Now!'}
                                        hoverColor={'black'}/>

                            <div onClick={() => handleShow()}>
                                {/*<ButtonLong icon={'black'} src={'/'} svgColor={'rgb(30, 30, 46)'} background={'transparent'} color={'rgb(30, 30, 46)'} text={'Submission'} hoverColor={'black'} margin={'60px 0 0 0'}/>*/}
                            </div>
                        </Col>

                        <Col>

                        </Col>
                    </Row>
                </Container>
            </StyledComponent>
            <SubmissionPopUp show={show} handleClose={handleClose}/>
        </>
    );
};

const StyledComponent = styled.section`
  position: relative;
  height: calc(100vh + 100px);
  overflow: hidden;


  .react-parallax{
    position: absolute!important;
    overflow: hidden!important;
    inset: 0!important;
    height: 100%!important;
    width: 100%!important;

  }
  .react-parallax-bgimage{
    height: 100%!important;
    width: 100%!important;
    object-fit: cover!important;
  }
  .react-parallax-content{
    position: unset!important;
  }
  .container {
    position: absolute;
    bottom: 80px;
    left: 0;
    right: 0;
  }

  .col-md-7 {
    padding-bottom: 40px;
  }

  .global-image {
    img {
      top: -70px;
    }
  }

  p {
    color: rgb(30, 30, 46);
    margin: 40px 0 40px 0;
  }

  h2 {
    color: rgb(30, 30, 46);
  }

  .upper-title {
    width: 50%;
    margin: 0 0 20px 0;
  }

  h4 {
    color: rgb(30, 30, 46);
  }

  @media (max-width: 767px) {
    .upper-title {
      width: 100%;
    }

    h4 {
      margin: 0 0 80px 0;
    }
  }

`;

const StyledTitle = styled.section`
  background-color: rgb(246, 249, 248);
  .ccd-title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 80px;
    @media (max-width: 767px){
      padding-bottom: 40px;
    }
  }
`;

export default React.memo(MyComponent);
