import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {HelmetProvider, Helmet} from 'react-helmet-async';
import DoctorsDetails from "../../components/Doctors/DetailsPage";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchPosts} from "../../api/redux/post";
import DoctorsPublications from "../../components/Doctors/DoctorsPublications";
import PublicationsPageDetails from "../doctors/single";
import PublicationsDetailsPage from "../../components/Publications/PublicationsDetailsPage";
import RelatedPublications from "../../components/Publications/RelatedPublications";
import AcademicDetailsPage from "../../components/AcademicActivities/AcademicDetailsPage";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchChild} from "../../api/redux/academicactivities";
import {useLocation} from "react-router-dom";

const AcademicActivitiesDetails = () => {

    const dispath = useDispatch()
    const getData = useSelector(state=> state.academicReducer)
    // api call
    useEffect(() => {
        let apiUrl = apiEndPoints.CHILD_PAGE
        let apiUrlSection = apiEndPoints.SECTIONS

        let api_params = {
            [ApiParamKey.page_id]: 20,
            [ApiParamKey.sections]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }
        let api_params_sections = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'academic-activities',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }

        dispath(fetchChild([apiUrl, api_params]))
        dispath(fetchPosts([apiUrlSection, api_params_sections]))
    }, [])

    const DetailsPage = getData?.child?.data?.list
    // meta data
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialFilter = queryParams.get('details');
    const [filter, setFilter] = useState(initialFilter);
    const Data = getData?.child?.data?.list?.find(f=>f?.page_data?.slug === filter);
    let MetaData = Data?.page_data;

    const page_data = Data?.page_data;

    return (

        <HelmetProvider>

            <Helmet>
                <title>{`${page_data?.meta_title ? page_data?.meta_title + ' | ' : ''}`}National Heart
                    Foundation</title>

                <meta name="meta:title" content={page_data?.meta_title}/>
                <meta name="description" content={page_data?.meta_description}/>
                <meta property="og:title" content={page_data?.og_title}/>
                <meta property="og:subtitle" content={page_data?.og_title}/>
                <meta property="og:description" content={page_data?.og_description}/>
                <meta property="og:image" content={Data?.images?.list?.[0]?.full_path}/>
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>
                {
                    DetailsPage &&
                    <AcademicDetailsPage data={DetailsPage}/>
                }
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default AcademicActivitiesDetails;
