import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";


const Component = ({data}) => {
    return (
        <StyledComponent className=' pb-200 parallax-img'>
            <Container>
                <Row>
                    <Col sm={12}>
                        <p className={'upper-title'}>{reactHtmlParser(data?.section_data?.subtitle)}</p>
                    </Col>

                    <Col sm={12}>
                        <h4>{reactHtmlParser(data?.section_data?.description)} </h4>
                    </Col>
                    <Row/>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #F6F9F8;
  position: relative;


  .upper-title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    width: 50%;
    margin: 0 0 20px 0;
  }


  p {
    color: #1E1E2E;
    margin: 0 0 80px 0;
  }

  @media (max-width: 767px) {
    padding-top: 0;
    p {
      margin: 0 0 40px 0;
    }

    .upper-title {
      width: 100%;
    }

  }

`;

export default React.memo(Component);
