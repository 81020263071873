import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {get} from "../../network/axiosServices";


const initialState = {
    loading: false,
    posts: [],
    error: '',
    info: [],
    infoLoading: false,
    infoError : '',
}

export const fetchHome = createAsyncThunk("fetchHome", (params, {dispatch}) => {
    return get(params);
});

export const fetchInfo = createAsyncThunk("fetchInfo", (params, {dispatch}) => {
    return get(params);
});


const postSlice = createSlice({
    name: 'home',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchHome.pending, (state) => {
            state.loading = true
            state.posts = []
            state.error = ''
        })
        builder.addCase(fetchHome.fulfilled, (state, action) => {
            state.loading = false
            state.posts = action.payload
            state.error = ''

        })
        builder.addCase(fetchHome.rejected, (state, action) => {
            state.loading = false
            state.posts = []
            state.error = action.error
        })
        builder.addCase(fetchInfo.pending, (state) => {
            state.infoLoading = true
            state.info = []
            state.infoError = ''
        })
        builder.addCase(fetchInfo.fulfilled, (state, action) => {
            state.infoLoading = false
            state.info = action.payload
            state.infoError = ''

        })
        builder.addCase(fetchInfo.rejected, (state, action) => {
            state.infoLoading = false
            state.info = []
            state.infoError = action.error
        });

    }
})


export default postSlice.reducer
