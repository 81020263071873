import styled from "styled-components";
import {Accordion, Col, Container, Row} from "react-bootstrap";
import {BsChevronDown} from "react-icons/bs";
import {Link} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import Select, {components} from "react-select";
import {Img} from "../Img";
import Title from "../Title";
import TitleBig from "../TitleBig";
import reactHtmlParser from "react-html-parser";


const customStyles = {

    option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        color: state.isSelected ? '#000' : '#221F1F',
        backgroundColor: state.isSelected ? '' : '#FFF',
        marginTop: 10,
        marginLeft: 2,
        fontSize: 20,

        paddingLeft: 25,
        overflowY: 'auto',
        // borderColor: state.isFocused ? '#FF0000' : '#CCCCCC',
    }),
    menu: (provided, state) => ({
        ...provided,
        color: '#FFF',
        backgroundColor: state.isSelected ? '' : '',
        margin: 0,
        borderRadius: 5,
        fontSize: 12,


    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#000' : '#FFF',
        borderRadius: 0,
        fontWeight: '500',
        color: '#FFF',
        fontSize: 20,
        paddingBottom: 25,
    }),

};

const Faq = ({data}) => {

    const selectTypeInputRef = useRef();
    const [itemsToShow, setItemsToShow] = useState(6);
    const [isCompleted, setIsCompleted] = useState(false)
    const [selectType, setSelectType] = useState(1)
    const [selectedCategory, setSelectedCategory] = useState(1);
    const [filteredData, setFilteredData] = useState(); // Initialize with all data
    const [activeItem, setActiveItem] = useState(1); // Set 'All' as the default active item


    const handleItemClick = (item) => {
        setActiveItem(item);
        // Filter the data when an item is clicked
        filterData(item);
    };

    const handleType = (e) => {
        setSelectType(e);
        // Filter the data when the select dropdown changes
        filterData(e);
    };

    useEffect(() => {
        setActiveItem(1);
    }, []);
    const handleCategoryChange = (selectedValue) => {
        setSelectedCategory(selectedValue);
    };


    const filterData = (filter) => {
        setFilteredData(data?.data?.list?.filter((item) => item?.data?.type == filter));
    };

    const handleLoadMore = () => {
        setItemsToShow(prevItemsToShow => prevItemsToShow + 6);
        if (itemsToShow + 6 >= filteredData.length) {
            setIsCompleted(true);
        } else {
            setIsCompleted(false);
        }
    };
    useEffect(() => {
        if (data) {
            filterData(selectType);
        }
    }, [data, selectType]);


    const options = [
        ...(Array.isArray(data?.data?.type_list)
            ? data?.data?.type_list?.map((item) => ({
                value: item?.title,
                label: item?.title,
                id: item?.id,
            }))
            : [])
    ];
    // Filter out duplicate values based on the "value" property
    const uniqueTypes = Array.from(new Set(options?.map((option) => option.value)))?.map((value) => options.find((option) => option.value === value));

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Update the window width when the window is resized
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="16.414" viewBox="0 0 11.414 16.414">
                        <g id="Group_21092" data-name="Group 21092" transform="translate(-340.293 -2110.293)">
                            <g id="Group_21092-2" data-name="Group 21092"
                               transform="translate(1585.5 2872.5) rotate(180)">
                                <line id="Line_3557" data-name="Line 3557" x2="5" y2="5"
                                      transform="translate(1234.5 756.5)" fill="none" stroke="#fff"
                                      stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_3558" data-name="Line 3558" x1="5" y2="5"
                                      transform="translate(1239.5 756.5)" fill="none" stroke="#fff"
                                      stroke-linecap="round" stroke-width="1"/>
                            </g>
                            <g id="Group_21093" data-name="Group 21093" transform="translate(-893.5 1364.5)">
                                <line id="Line_3557-2" data-name="Line 3557" x2="5" y2="5"
                                      transform="translate(1234.5 756.5)" fill="none" stroke="#fff"
                                      stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_3558-2" data-name="Line 3558" x1="5" y2="5"
                                      transform="translate(1239.5 756.5)" fill="none" stroke="#fff"
                                      stroke-linecap="round" stroke-width="1"/>
                            </g>
                        </g>
                    </svg>

                </components.DropdownIndicator>
            )
        );
    };


    return (
        <StyledFaq className="job-lists pt-200 pb-200">
            <Img src={'/images/static/info-bc.svg'}/>
            <Container className={'info-tiitle'}>
                <TitleBig text={'Info Center'} color={'#1E1E2E'} margin={'0 0 80px 0'}/>
                <Row>
                    <Col sm={4} className='job-lists__sidebar'>
                        {
                            windowWidth > 767 ?

                                <ul>
                                    {uniqueTypes.map((item, index) => (
                                        <li
                                            key={index}
                                            className={`${activeItem === item.id || (activeItem === '1' && item.id === '1') ? 'active' : ''}`}
                                            onClick={() => handleItemClick(item.id)}>
                                            <span>{item.label}</span>
                                        </li>
                                    ))}
                                </ul>

                                :

                                <div className="job-lists__sidebar__mobile-dropdown">
                                    <Select
                                        classNamePrefix="filter"
                                        isSearchable={false}
                                        ref={selectTypeInputRef}
                                        options={uniqueTypes}
                                        onChange={(e) => handleType(e?.id)}
                                        placeholder='All' styles={customStyles}
                                        // components={{DropdownIndicator}}
                                    />
                                </div>
                        }
                    </Col>

                    <Col sm={8} className='job-lists__content'>
                        <Accordion defaultActiveKey="0">
                            {filteredData &&
                                filteredData?.slice(0, itemsToShow)?.map((e, index) => {
                                    return (
                                        <Accordion.Item eventKey={index.toString()} key={index}>
                                            <Accordion.Header className={'split-up'}>
                                                {reactHtmlParser(e?.data?.title)}
                                                <span><BsChevronDown/></span>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {reactHtmlParser(e?.data?.description)}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                })}
                        </Accordion>
                    </Col>
                </Row>
            </Container>

        </StyledFaq>
    );
};

const StyledFaq = styled.section`
  position: relative;
  background-color: #E4ECF0;

  .job-lists__sidebar {
    padding-top: 35px!important;

    &__mobile-dropdown {
      @media (min-width: 551px) {
        display: none;
      }
    }

    p {
      font-size: 32px;
      line-height: 36px;
      font-weight: 600;
      width: 80%;
    }

    ul {
      @media (max-width: 550px) {
        display: none;
      }

      li {
        margin-bottom: 20px;
        cursor: pointer;

        span {
          color: #1E1E2E;
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            border-radius: 50%;
            top: 7px;
            right: -15px;
            margin: auto;
            height: 10px;
            width: 10px;
            background-color: #E72E4B;
            display: none;
          }
        }

        &.active {
          span {
            color: #E72E4B;

            &:after {
              display: block;
            }
          }
        }
      }
    }
  }

  .accordion-header {
    position: relative;
    margin: 0;

    button {
      border: none;
      background-color: transparent;
      padding-left: 0;
      color: #E72E4B;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      text-transform: capitalize;
      padding-bottom: 15px;
      padding-right: 40px;
      border-bottom: 1px solid #E72E4B;
      width: 100%;
      text-align: left;
      //margin-bottom: 50px;
      transition: all .4s ease;

      @media (min-width: 768px) and (max-width: 991px) {
        padding-right: 50px;
      }

      &.collapsed {
        color: #1A1818;
        border-color: rgb(30 30 46 / 10%);

        span {
          background-color: black;

          svg {
            transform: rotate(0deg);
          }

          &:after {
            background-color: #E72E4B !important;
          }
        }
      }
    }

    span {
      position: absolute;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      color: #ffffff;
      background-color: #E72E4B;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 16px;

      &:after {
        height: 0;
        width: 0;
        background-color: #E72E4B;
        border-radius: 50%;
        opacity: 0;
        transition: all .4s ease;
        content: '';
        position: absolute;
      }

      svg {
        font-size: 13px;
        z-index: 2;
        transform: rotate(180deg);
      }

    }

    &:hover {
      span {
        &:after {
          height: 100%;
          width: 100%;
          opacity: 1;
        }
      }

      button {
        color: #E72E4B;
        border-color: #E72E4B;
      }
    }
  }
  .job-lists__content{
    padding-bottom: 0!important;
  }

  .accordion-body {
    padding-bottom: 20px;

    p {
      color: #1E1E2E;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      padding-top: 40px;
    }
  }

  .css-13cymwt-control, .css-t3ipsp-control {
    background-color: #E72E4B;
    height: 60px;
    border-radius: 50px;

    .css-1jqq78o-placeholder {
      color: white;
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      margin: 10px 0 10px 30px;
    }

    .css-1fdsijx-ValueContainer {
      padding: unset;

      .css-1dimb5e-singleValue {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: #FFF;
      }
    }

    .css-1u9des2-indicatorSeparator {
      display: none;
    }

    .css-1xc3v61-indicatorContainer {
      padding-right: 30px;
    }
  }

  .css-t3ipsp-control {
    border-color: unset !important;
    box-shadow: unset !important;
  }

  .css-qbdosj-Input {
    input {
      min-width: 0px !important;
      opacity: 0 !important;
    }
  }

  .css-15lsz6c-indicatorContainer {
    padding-right: 30px;
    color: hsl(0, 0%, 80%);
  }

  .css-1dimb5e-singleValue {
    padding-left: 20px;
    font-size: 20px;
    opacity: 70%;
    font-weight: 700;
    color: white;
  }

  .css-yk16xz-control, .css-1pahdxg-control {
    border-radius: 18px !important;
    border-color: #989898;
    height: 40px;
    background-color: #E50019;
    cursor: pointer;
    padding-left: 15px;
  }

  .css-yk16xz-control .css-1wa3eu0-placeholder, .css-1pahdxg-control .css-1wa3eu0-placeholder, .css-1uccc91-singleValue {
    color: #ffffff !important;
    font-size: 12px !important;
    font-weight: bold;
    cursor: pointer;
  }

  .css-yk16xz-control .css-tlfecz-indicatorContainer, .css-1pahdxg-control .css-tlfecz-indicatorContainer, .css-yk16xz-control .css-1gtu0rj-indicatorContainer, .css-1pahdxg-control .css-1gtu0rj-indicatorContainer {
    margin-right: 20px;
  }


  @media (max-width: 767px) {
    .job-lists__sidebar {
      margin-bottom: 50px;

      p {
        width: 100%;
      }
    }
    .job-lists__content{
      padding-bottom: 0!important;
    }
  }

`;
export default React.memo(Faq);
