import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({Founder,viceP,President,jointsecGeneral,treasurer,mediaSec,member,Secretary}) => {
    const vicePresidentSlider = viceP?.images?.list;
    const Presidentdetails = President;
    const jointsecGeneralSlider = jointsecGeneral?.images?.list;
    const treasurerSlider = treasurer?.images?.list;
    const mediaSecSlider = mediaSec?.images?.list;
    const memberSlider = member?.images?.list;
    const SecretaryDetails = Secretary?.images?.list;

    return (
        <StyledComponent className={'people pt-150'}>
            <Container>

                <Row>
                    <Col sm={12}>
                        <h4>{reactHtmlParser(Presidentdetails?.section_data?.subtitle)}</h4>
                    </Col>

                    <div className="people__items d-flex flex-wrap">
                                    <Col sm={4} className="people__items__single">
                                        <div className="people__items__single__img">
                                            <Img src={Presidentdetails?.images?.list?.[0]?.full_path}/>
                                        </div>
                                        <h5>{reactHtmlParser(Presidentdetails?.images?.list?.[0]?.short_title)}</h5>
                                        <p>{reactHtmlParser(Presidentdetails?.images?.list?.[0]?.short_desc)}</p>
                                    </Col>
                    </div>
                </Row>

                <Row>
                    <Col sm={12}>
                        <h4>{reactHtmlParser(Secretary?.section_data?.subtitle)}</h4>
                    </Col>

                    <div className="people__items d-flex flex-wrap">

                        {
                            SecretaryDetails && SecretaryDetails.length>0 &&
                            SecretaryDetails?.map((i)=>{
                                return(
                                    <Col sm={4} className="people__items__single" key={i}>
                                        <div className="people__items__single__img">
                                            <Img src={i?.full_path}/>
                                        </div>
                                        <h5>{reactHtmlParser(i?.short_title)}</h5>
                                        <p>{reactHtmlParser(i?.short_desc)} </p>
                                    </Col>
                                )

                            })
                        }
                    </div>
                </Row>

                <Row>
                    <Col sm={12}>
                        <h4>{reactHtmlParser(viceP?.section_data?.subtitle)}</h4>
                    </Col>

                    <div className="people__items d-flex flex-wrap">

                        {
                            vicePresidentSlider && vicePresidentSlider.length>0 &&
                            vicePresidentSlider?.map((i)=>{
                                return(
                                    <Col sm={4} className="people__items__single" key={i}>
                                        <div className="people__items__single__img">
                                            <Img src={i?.full_path}/>
                                        </div>
                                        <h5>{reactHtmlParser(i?.short_title)}</h5>
                                        <p>{reactHtmlParser(i?.short_desc)} </p>
                                    </Col>
                                )

                            })
                        }
                    </div>
                </Row>

                <Row>
                    <Col sm={12}>
                        <h4>{reactHtmlParser(jointsecGeneral?.section_data?.subtitle)}</h4>
                    </Col>

                    <div className="people__items d-flex flex-wrap">
                        {
                            jointsecGeneralSlider && jointsecGeneralSlider.length>0 &&
                            jointsecGeneralSlider?.map((i)=>{
                                return(
                                    <Col sm={4} className="people__items__single" key={i}>
                                        <div className="people__items__single__img">
                                            <Img src={i?.full_path}/>
                                        </div>
                                        <h5>{reactHtmlParser(i?.short_title)}</h5>
                                        <p>{reactHtmlParser(i?.short_desc)} </p>
                                    </Col>
                                )

                            })
                        }
                    </div>
                </Row>
                <Row>
                    <Col sm={12}>
                        <h4>{reactHtmlParser(treasurer?.section_data?.subtitle)}</h4>
                    </Col>

                    <div className="people__items d-flex flex-wrap">
                        {
                            treasurerSlider && treasurerSlider.length>0 &&
                            treasurerSlider?.map((i)=>{
                                return(
                                    <Col sm={4} className="people__items__single" key={i}>
                                        <div className="people__items__single__img">
                                            <Img src={i?.full_path}/>
                                        </div>
                                        <h5>{reactHtmlParser(i?.short_title)}</h5>
                                        <p>{reactHtmlParser(i?.short_desc)} </p>
                                    </Col>
                                )

                            })
                        }
                    </div>
                </Row>
                <Row>
                    <Col sm={12}>
                        <h4>{reactHtmlParser(mediaSec?.section_data?.subtitle)}</h4>
                    </Col>

                    <div className="people__items d-flex flex-wrap">
                        {
                            mediaSecSlider && mediaSecSlider.length>0 &&
                            mediaSecSlider?.map((i)=>{
                                return(
                                    <Col sm={4} className="people__items__single" key={i}>
                                        <div className="people__items__single__img">
                                            <Img src={i?.full_path}/>
                                        </div>
                                        <h5>{reactHtmlParser(i?.short_title)}</h5>
                                        <p>{reactHtmlParser(i?.short_desc)} </p>
                                    </Col>
                                )

                            })
                        }
                    </div>
                </Row>
                <Row>
                    <Col sm={12}>
                        <h4>{reactHtmlParser(member?.section_data?.subtitle)}</h4>
                    </Col>

                    <div className="people__items d-flex flex-wrap">
                        {
                            memberSlider && memberSlider.length>0 &&
                            memberSlider?.map((i)=>{
                                return(
                                    <Col sm={4} className="people__items__single" key={i}>
                                        <div className="people__items__single__img">
                                            <Img src={i?.full_path}/>
                                        </div>
                                        <h5>{reactHtmlParser(i?.short_title)}</h5>
                                        <p>{reactHtmlParser(i?.short_desc)} </p>
                                    </Col>
                                )

                            })
                        }
                    </div>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  padding-bottom: 120px;

  h4 {
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 42px;
  }

  .people__items {
    width: 100%;
    height: auto;

    &__single {
      margin-bottom: 60px;

      &__img {
        padding-top: calc(330 / 270 * 100%);
        position: relative;
      }

      h5 {
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        margin-top: 20px;
        margin-bottom: 5px;
      }

      p {
        color: rgba(30, 30, 46, 0.7);
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 50px;
  }

`;

export default React.memo(MyComponent);
