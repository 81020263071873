import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Img} from "./../Img";
import Slick from "react-slick";
import reactHtmlParser from "react-html-parser";



const MyComponent = ({data}) => {
    const objective = data?.posts?.list;
    const [offset, setOffset] = useState(90)
    const [activeSlide, setactiveSlide] = useState(0)
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const [total, settotal] = useState();
    const slider1 = useRef(null);
    const slider2 = useRef(null);


    useEffect(() => {
        setNav1(slider1.current);
        setNav2(slider2.current);

        // settotal(slider2.current.props.children.length)
    }, [data]);

    useEffect(() => {
        setOffset(document.querySelector(' .container').offsetLeft)
        settotal(slider2?.current?.props?.children?.length)

    }, [data])

    return (
        <StyledComponent className={`SliderCount pb-200`} id={'objectives'}>

            <Container fluid className={'p-0'} id={'objectives'}>
                <Row>
                    <Col md={6} className={'p-0'}>
                        <div className="slider-wrapper-left">
                            <div className="slider-wrapper-left__init">
                                <Slick asNavFor={nav2} speed={1200}
                                       arrows={false} ref={slider1}>

                                    {
                                        objective && objective.length>0 &&
                                        objective?.map((i)=>{
                                            return(
                                                <div key={i} className="single-item">
                                                    <div className="image-wrapper">
                                                        <Img
                                                            src={i?.images?.[0]?.full_path}/>
                                                    </div>
                                                </div>
                                            )

                                        })
                                    }
                                </Slick>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className={'p-0'}>
                        <div className="slider_wrapper_right">
                            <div className="slider_wrapper_right__init" style={{paddingRight: offset + 15 + 'px'}}>
                                <Slick asNavFor={nav1}
                                       ref={slider2}
                                       arrows={false}
                                       dots={false}
                                       speed={1200}
                                       slidesToShow={1}
                                       swipeToSlide={true}
                                       focusOnSelect={true}
                                >
                                    {
                                        objective && objective.length>0 &&
                                        objective?.map((i)=>{
                                            return(
                                                <div key={i} className="single-item_content">
                                                    <p className={'split-up'}>
                                                        {i?.data?.title}
                                                    </p>
                                                    <h6 className={'split-up'}>
                                                        {reactHtmlParser(i?.data?.description)}
                                                    </h6>
                                                </div>
                                            )

                                        })
                                    }


                                </Slick>


                                <div className="footer">
                                    <ul className="slider-nav">
                                        <li onClick={() => slider2?.current?.slickPrev()} className={'slider-prev'}>
                                            <svg id="Prev" xmlns="http://www.w3.org/2000/svg" width="44" height="44"
                                                 viewBox="0 0 44 44">
                                                <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke="#fff"
                                                   stroke-width="1" opacity="0.2">
                                                    <rect width="44" height="44" rx="22" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="none"/>
                                                </g>
                                                <g id="Group_4824" data-name="Group 4824" transform="translate(17 17)">
                                                    <g id="Group_4823" data-name="Group 4823">
                                                        <line id="Line_9" data-name="Line 9" x1="5" y2="5" fill="none"
                                                              stroke="#fff" strokeLinecap="round" stroke-width="1"/>
                                                        <line id="Line_10" data-name="Line 10" x2="5" y2="5"
                                                              transform="translate(0 5)" fill="none" stroke="#fff"
                                                              strokeLinecap="round" stroke-width="1"/>
                                                    </g>
                                                    <line id="Line_11" data-name="Line 11" x1="10" transform="translate(0 5)"
                                                          fill="none" stroke="#fff" strokeLinecap="round" stroke-width="1"/>
                                                </g>
                                                <g id="Rectangle_5661" data-name="Rectangle 5661"
                                                   transform="translate(0 44) rotate(-90)" fill="none" stroke="#fff"
                                                   stroke-width="1" strokeDasharray="1 140" opacity="0">
                                                    <rect width="44" height="44" rx="22" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="none"/>
                                                </g>
                                            </svg>
                                        </li>
                                        <li onClick={() => slider2?.current?.slickNext()} className={'slider-next'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                                <g id="Next" transform="translate(16429 -2014)">
                                                    <g id="Rectangle_492" data-name="Rectangle 492"
                                                       transform="translate(-16429 2014)" fill="none" stroke="#fff"
                                                       stroke-width="1" opacity="0.2">
                                                        <rect width="44" height="44" rx="22" stroke="none"/>
                                                        <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="none"/>
                                                    </g>
                                                    <g id="Group_4824" data-name="Group 4824"
                                                       transform="translate(-17065.5 434.5)">
                                                        <g id="Group_4823" data-name="Group 4823" transform="translate(11 4)">
                                                            <line id="Line_9" data-name="Line 9" x2="5" y2="5"
                                                                  transform="translate(647.5 1592.5)" fill="none" stroke="#fff"
                                                                  strokeLinecap="round" stroke-width="1"/>
                                                            <line id="Line_10" data-name="Line 10" x1="5" y2="5"
                                                                  transform="translate(647.5 1597.5)" fill="none" stroke="#fff"
                                                                  strokeLinecap="round" stroke-width="1"/>
                                                        </g>
                                                        <line id="Line_11" data-name="Line 11" x2="10"
                                                              transform="translate(653.5 1601.5)" fill="none" stroke="#fff"
                                                              strokeLinecap="round" stroke-width="1"/>
                                                    </g>
                                                    <g id="Rectangle_5661" data-name="Rectangle 5661"
                                                       transform="translate(-16429 2058) rotate(-90)" fill="none" stroke="#fff"
                                                       stroke-width="1" strokeDasharray="1 140" opacity="0">
                                                        <rect width="44" height="44" rx="22" stroke="none"/>
                                                        <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="none"/>
                                                    </g>
                                                </g>
                                            </svg>

                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #E4ECF0;
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #E4ECF0;
    height: 50vh;
    z-index: 0;
  }


  .container, .container-fluid {
    position: relative;
    z-index: 2;
  }


  .slider-wrapper-left {
    &__init {
      @media (max-width: 992px) {
        .slick-list {
          margin-bottom: -8px !important;
        }
      }

      .single-item {
        .image-wrapper {
          padding-top: calc(600 / 728 * 100%);
          position: relative;

          @media (max-width: 1124px) {
            padding-top: calc(700 / 728 * 100%);
          }
          @media (max-width: 992px) {
            height: 100%;

          }

          .content {
            position: absolute;
            z-index: 99;
            top: 70px;

            @media (max-width: 992px) {
              display: none;
            }

            p {
              font-size: 40px;
              line-height: 48px;
              color: #E9E4DC;

            }
          }

        }
      }
    }
  }

  .slider_wrapper_right {
    height: 100%;

    .slider_wrapper_right__init {
      background: #1E1E2E;
      padding: 70px 0 70px 70px;
      height: calc(100% - 20%);
      position: relative;

      .single-item_content {
        h3 {
          display: none;
        }

        p {
          margin-right: 15px;
          position: relative;
          color: #F7F4F4;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          padding-bottom: 20px;
          &:after{
            content: "";
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            position: absolute;
            background: rgb(249 249 249 / 40%);
          }
        }
        h6{
          color: #FFFFFF;
          padding-top: 40px;
          padding-right: 10px;
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    padding: 0 70px;
    bottom: 70px;
    @media(max-width: 767px){
      justify-content: flex-end;
    }

    .slider-nav {
      display: flex;

      li:first-child {
        margin-right: 30px;
      }

      li {
        svg {
          cursor: pointer;
          height: 41px;
          g,line{
            stroke:white;
            transition: stroke 0.2s ease;
          }
          path, #Rectangle_5661 {
            //transition: stroke-dasharray 0.3s ease-in-out;
            transition: all 1s cubic-bezier(.25, .74, .22, .99);
          }
        }

        &:hover {
          svg {
            g,line{
              stroke:#E72E4B;
              transition: stroke 0.2s ease;
            }
            #Rectangle_5661 {
              opacity: 1;
              stroke-dasharray: 140;
            }
          }
        }
      }
    }
  }


  //responsive

  @media (max-width: 1124px) {

    .slider-wrapper-left {
      &__init {
        .single-item {
          .image-wrapper {
            padding-top: calc(700 / 728 * 100%);
            .content {
            }

          }
        }
      }
    }
  }
  @media (max-width: 992px) and (min-width: 768px) {
    .slider-wrapper-left{
      height: 100%;
      .slider-wrapper-left__init{
        height: 100%;
        .slick-slider{
          height: 100%;
          .slick-list{
            height: 100%;
            .slick-track{
              height: 100%;
              .slick-slide{
                height: 100%;
                >div{
                  height: 100%;
                }
                .single-item{
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 767px){
   padding-bottom: 0!important;
  }
  @media (max-width: 992px) {
    &:after {
      height: 100px;
      z-index: 0;
    }

    .slider-wrapper-left {
      &__init {
        .slick-list {
          margin-bottom: -8px !important;
        }

        .single-item {
          .image-wrapper {
            height: 100%;
            .content {
              display: none;
            }

          }
        }
      }
    }


    .slider_wrapper_right {
      .slider_wrapper_right__init {
        height: auto;
        padding: 70px 30px;

        .single-item_content {
          h3 {
            display: block;
            font-size: 24px;
            line-height: 30px;
            color: #E9E4DC;
            margin: 0 0 30px;
          }
        }

      }
    }


    .footer {
      position: relative;
      margin-top: 30px;
      padding: 0;
      left: unset;
      right: unset;
      bottom: unset;
      flex-direction: row-reverse;

      .count {
        padding-right: 15px;
      }

      .slider-nav {
        li:first-child {
          margin-right: 15px;
        }

        li {
          svg {
            height: 50px;
            width: 50px;
          }
        }
      }

      .slick-slider {
        margin: 0 0 -10px;
      }
    }

  }

`;

export default React.memo(MyComponent);
