import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Title from "../Title";
import {BsChevronLeft, BsChevronRight} from 'react-icons/bs';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {text} from "../../styles/globalStyleVars";
import {Link} from "react-router-dom";

import ButtonArrow from "../ButtonArrow";
import ButtonViewAll from "../ButtonViewAll";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data,dataSlider}) => {


    // filter data
    const ResearchData = data?.section_data;
    const Researchslider = dataSlider;



    // offset
    const [offset, setOffset] = useState(100)
    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)

        window.addEventListener('load', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
    }, [])

    // slider next prev action
    return (
        <StyledComponent offset={offset}
                         className='media-event-slider asNewsOnly pb-200'>

            <Container>
                <Row>
                    <Col md={12}>
                        <div className="d-flex navigation_wrapper">
                            <div className="title">
                                <Title text={ResearchData?.subtitle ? ResearchData?.subtitle : ''} color={'#1E1E2E'}/>
                            </div>
                            <ul className="navigation">
                                <div className={'navigation__icon'}>
                                    <li className={'prev_swipper4'}>
                                        <svg id="Prev" xmlns="http://www.w3.org/2000/svg" width="44" height="44"
                                             viewBox="0 0 44 44">
                                            <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke="#fff"
                                               stroke-width="1" opacity="0.2">
                                                <rect width="44" height="44" rx="22" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="none"/>
                                            </g>
                                            <g id="Group_4824" data-name="Group 4824" transform="translate(17 17)">
                                                <g id="Group_4823" data-name="Group 4823">
                                                    <line id="Line_9" data-name="Line 9" x1="5" y2="5" fill="none"
                                                          stroke="#fff" strokeLinecap="round" stroke-width="1"/>
                                                    <line id="Line_10" data-name="Line 10" x2="5" y2="5"
                                                          transform="translate(0 5)" fill="none" stroke="#fff"
                                                          strokeLinecap="round" stroke-width="1"/>
                                                </g>
                                                <line id="Line_11" data-name="Line 11" x1="10" transform="translate(0 5)"
                                                      fill="none" stroke="#fff" strokeLinecap="round" stroke-width="1"/>
                                            </g>
                                            <g id="Rectangle_5661" data-name="Rectangle 5661"
                                               transform="translate(0 44) rotate(-90)" fill="none" stroke="#fff"
                                               stroke-width="1" strokeDasharray="1 140" opacity="0">
                                                <rect width="44" height="44" rx="22" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="none"/>
                                            </g>
                                        </svg>
                                    </li>
                                    <li className={'next_swipper4'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                            <g id="Next" transform="translate(16429 -2014)">
                                                <g id="Rectangle_492" data-name="Rectangle 492"
                                                   transform="translate(-16429 2014)" fill="none" stroke="#fff"
                                                   stroke-width="1" opacity="0.2">
                                                    <rect width="44" height="44" rx="22" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="none"/>
                                                </g>
                                                <g id="Group_4824" data-name="Group 4824"
                                                   transform="translate(-17065.5 434.5)">
                                                    <g id="Group_4823" data-name="Group 4823" transform="translate(11 4)">
                                                        <line id="Line_9" data-name="Line 9" x2="5" y2="5"
                                                              transform="translate(647.5 1592.5)" fill="none" stroke="#fff"
                                                              strokeLinecap="round" stroke-width="1"/>
                                                        <line id="Line_10" data-name="Line 10" x1="5" y2="5"
                                                              transform="translate(647.5 1597.5)" fill="none" stroke="#fff"
                                                              strokeLinecap="round" stroke-width="1"/>
                                                    </g>
                                                    <line id="Line_11" data-name="Line 11" x2="10"
                                                          transform="translate(653.5 1601.5)" fill="none" stroke="#fff"
                                                          strokeLinecap="round" stroke-width="1"/>
                                                </g>
                                                <g id="Rectangle_5661" data-name="Rectangle 5661"
                                                   transform="translate(-16429 2058) rotate(-90)" fill="none" stroke="#fff"
                                                   stroke-width="1" strokeDasharray="1 140" opacity="0">
                                                    <rect width="44" height="44" rx="22" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="none"/>
                                                </g>
                                            </g>
                                        </svg>

                                    </li>
                                </div>
                                <div>
                                    <ButtonViewAll src={'/research'} color={'black'} text={'View All'} icon={'black'}
                                                   hoverColor={'#E72E4B'} margin={'0 0 0 40px'}/>
                                </div>

                            </ul>

                            <ul className={'navigation-mobile'}>
                                <div className={'navigation__icon'}>
                                    <li className={'prev_swipper4'}>
                                        <svg id="Button_-_Nav_-_Prev_Dark_" data-name="Button - Nav - Prev (Dark)" xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
                                            <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke="#1e1e2e" stroke-width="1" opacity="0.2">
                                                <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                            </g>
                                            <g id="Group_4824" data-name="Group 4824" transform="translate(16 15)">
                                                <g id="Group_4823" data-name="Group 4823">
                                                    <line id="Line_9" data-name="Line 9" x1="5" y2="5" fill="none" stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                                    <line id="Line_10" data-name="Line 10" x2="5" y2="5" transform="translate(0 5)" fill="none" stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                                </g>
                                                <line id="Line_11" data-name="Line 11" x1="10" transform="translate(0 5)" fill="none" stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                            </g>
                                            <g id="Rectangle_5661" data-name="Rectangle 5661" transform="translate(0 41) rotate(-90)" fill="none" stroke="#e72e4b" stroke-width="1" stroke-dasharray="130 130">
                                                <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                            </g>
                                        </svg>
                                    </li>
                                    <li className={'next_swipper4'}>
                                        <svg id="Button_-_Nav_-_Next_Dark_" data-name="Button - Nav - Next (Dark)" xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
                                            <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke="#1e1e2e" stroke-width="1" opacity="0.2">
                                                <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                            </g>
                                            <g id="Group_4824" data-name="Group 4824" transform="translate(-637.5 -1581.5)">
                                                <g id="Group_4823" data-name="Group 4823" transform="translate(11 4)">
                                                    <line id="Line_9" data-name="Line 9" x2="5" y2="5" transform="translate(647.5 1592.5)" fill="none" stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                                    <line id="Line_10" data-name="Line 10" x1="5" y2="5" transform="translate(647.5 1597.5)" fill="none" stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                                </g>
                                                <line id="Line_11" data-name="Line 11" x2="10" transform="translate(653.5 1601.5)" fill="none" stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                            </g>
                                            <g id="Rectangle_5661" data-name="Rectangle 5661" transform="translate(0 41) rotate(-90)" fill="none" stroke="#e72e4b" stroke-width="1" stroke-dasharray="130 130">
                                                <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                            </g>
                                        </svg>

                                    </li>
                                </div>

                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>

            {Researchslider?.length > 0 &&
            <Swiper loop={true}
                    spaceBetween={30}
                    slidesPerView={3}
                    allowSlideNext={true}
                    allowSlidePrev={true}
                    allowTouchMove={true}
                    speed={900}
                    navigation={{
                        prevEl: '.prev_swipper4',
                        nextEl: '.next_swipper4  ',
                    }}
                    modules={[Autoplay, Pagination, Navigation]}

                // autoplay={{
                //     delay: 2500,
                //     disableOnInteraction: false,
                // }}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 30,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },
                    }}>

                {Researchslider?.length > 0 && Researchslider?.map((i,index) => (
                <SwiperSlide key={index+1} className={'horizontal-scroll'}>
                    <div className=''>
                        <Link to={`/research/:slug?details=${reactHtmlParser(i?.page_data?.slug)}`}>
                            <a>
                                <div className='presslist__single'>
                                    <div className='presslist__single__content'>
                                        <div className={'presslist__single__content__height'}>
                                            <h6 className={'split-up'}>{i?.page_data?.subtitle}</h6>
                                        </div>
                                        <div className='presslist__single__content__text'>
                                            <div>
                                                <h2>{i?.page_data?.short_desc}</h2>
                                                <h6>{i?.page_data?.category} </h6>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
                                                <g id="Mask_Group_344" data-name="Mask Group 344" transform="translate(-128 -1437)">
                                                    <circle id="Ellipse_475" data-name="Ellipse 475" cx="15" cy="15" r="15" transform="translate(128 1467) rotate(-90)" fill="#191b1d"/>
                                                    <circle id="Ellipse_476" data-name="Ellipse 476" cx="15" cy="15" r="15" transform="translate(128 1467) rotate(-90)" fill="#191b1d"/>
                                                    <g id="Arrow" transform="translate(0.5 1.5)">
                                                        <line id="Line_3960" data-name="Line 3960" x2="4" y2="4" transform="translate(140.5 1446.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                                        <line id="Line_3961" data-name="Line 3961" y1="4" x2="4" transform="translate(140.5 1450.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </Link>
                    </div>
                </SwiperSlide>
                    ))}

            </Swiper>
            }
            <div className={'mobile-button'}>
                <ButtonArrow src={'/research'} text={'View All'} background={'transparent'} color={'#1E1E2E'}
                             border={'1px solid rgb(30 30 46 / 20%)'} margin={'0 0 0 40px'}/>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F6F9F8;

  .presslist {
    &__single {
      min-height: 100%;
      position: relative;
      padding-top: calc(460/370*100%);
      //box-sizing: border-box;
      transition: 1s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      

      &__content {
        padding: 40px 40px 40px;
        position: absolute;
        background-color: #E4ECF0;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        @media (max-width: 992px) {
          font-size: 18px;
          line-height: 27px;
        };

        //&__text {
        //  min-height: 135px;
        //}

        &__link {
          position: absolute;
          bottom: 31px;
        }
        span{
          color: #1E1E2E;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          min-height: 290px;
        }

        h2 {
          color: #1E1E2E;
          opacity: 70%;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 10px;
          font-weight: 400;
        }
        h6{
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          color: #E72E4B;
        }

        p {
          color: #1E1E2E;
          margin-bottom: 30px;
          font-size: 16px;
          line-height: 20px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 700;

        }

        &__learn {
          position: relative;

          &:after {
            content: '';
            height: 3px;
            width: 0px;
            background-image: linear-gradient(#AD0000, #FF0000);
            position: absolute;
            transition: .3s;
          }

          h2 {
            color: #222222;
            font-weight: 500;
            cursor: pointer;
            border-bottom: 2px solid #222222;
            width: 26%;
          }
        }
        &__text {
          display: flex;
          justify-content: space-between;

          svg {
            border-radius: 30px;
            circle[id="Ellipse_476"] {
              cy: -15;
              fill: #E72E4B;
              transition: all 0.5s ease;
            }
            &:hover {
              circle[id="Ellipse_476"] {
                cy: 15;
                fill: #E72E4B;
                transition: all 0.5s ease;
              }
            }
          }
        }

        &__height{
          h6 {
            color: #1E1E2E;
            font-size: 20px;
            font-weight: 700;
            line-height: 26px;
          }
          min-height: 190px;
        }
      }

      @media (max-width: 767px) {
        margin-bottom: 20px !important;
        :last-child {
          margin-bottom: 0px;
        }
      }
      &:hover {
        svg{
          #Arrow {
            line {
              stroke: #fff;
              transition: all 0.5s ease;
            }
          }
          circle[id="Ellipse_476"] {
            cy: 15;
            fill: #E72E4B;
            transition: all 0.5s ease;
          }
        }
      }
    }
  }
  .navigation_wrapper {
    justify-content: space-between;
    margin-bottom: 80px;


    @media (max-width: 767px) {
      margin-bottom: 0px;
    }

    .title {
      h3 {
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        color: #4F616B;
      }
    }

    .d-flex {
      justify-content: space-between;
    }
  }
  .hover {
    &.disabled {
      background-color: transparent !important;
      border: 1px solid #222222 !important;
      cursor: default;
      opacity: 0.9;

      &:after {
        display: none;
      }

      svg {
        path {
          fill: #222222;
        }
      }
    }
  }

  .navigation {
    display: flex;

    &__icon {
      cursor: pointer;;
      display: flex;

      li {
        cursor: pointer;

        &:first-child {
          margin-right: 20px;
        }

        svg {
          height: 41px;

          g, line {
            stroke: black;
            transition: stroke 0.2s ease;
          }

          path, #Rectangle_5661 {
            //transition: stroke-dasharray 0.3s ease-in-out;
            transition: all 1s cubic-bezier(.25, .74, .22, .99);
          }
        }

        &:hover {
          svg {
            g, line {
              stroke: #E72E4B;
              transition: stroke 0.2s ease;
            }

            #Rectangle_5661 {
              opacity: 1;
              stroke-dasharray: 140;
            }
          }
        }
      }
    }
  }
  .slider-nav {
    position: absolute;
    top: 7px;
    right: 15px;

    ul {
      display: flex;
    }

    li {
      height: 50px;
      width: 50px;
      background-color: ${text};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;

      &:nth-of-type(1) {
        margin-right: 20px;
      }

      svg {
        color: #ffffff;
        z-index: 2;
        font-size: 20px;
      }
    }
  }
  //---link border animation
  //animation: draw-line 0.25s ease-in-out;
  @keyframes draw-line {
    0% {
      background-size: 0 2px;
    }
    to {
      background-size: 100% 2px;
    }
  }

  .dc-link{
    a{
      background-image: linear-gradient(currentcolor, currentcolor);
      background-position: 0px 100%;
      background-repeat: no-repeat;
      background-size: 100% 2px;
      padding-bottom: 0px;
      text-decoration: none;
      font-size: 14px;
      line-height: 27px;
      text-transform: capitalize;
      color: rgb(34, 34, 34);
      font-weight: 500;
      &:hover{
        animation: 0.25s ease-in-out 0s 1 normal none running draw-line;
        color: rgb(34, 34, 34) !important;
      }
    }
  }

  @media (min-width: 600px) {
    .swiper-initialized {
      margin-left: ${props => props.offset + 15}px;
      padding-right: ${props => props.offset + 15}px;
    }
  }
  @media (min-width: 768px){
    .mobile-button {
      display: none;
    }
    .navigation-mobile{
      display: none;
    }
  }
  .navigation {
    display: flex;

    &__icon {
      cursor: pointer;;
      display: flex;

      li:first-child {
        margin-right: 20px;
      }
    }
  }
  }
  .bottom-button {
    position: relative;
    @media (min-width: 768px) {
      .slider-nav {
        display: none;
      }
    }

    .slider-nav {
      top: auto;
      bottom: 10px;
      right: 15px;

      li:nth-of-type(1) {
        margin-right: 14px;
      }

      .hover {
        height: 40px;
        width: 40px;

        svg {
          font-size: 17px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .top {
      display: none;
    }

    .swiper-slide {
      padding-left: 15px;
      padding-right: 15px;
    }

    .navigation {
      display: none;
    }

    .navigation_wrapper {
      flex-direction: column;
    }
    .navigation-mobile {

      .navigation__icon{
        margin-bottom: 30px;
        cursor: pointer;;
        display: flex;

        li:first-child {
          margin-right: 20px;
        }
      }
    }
    .mobile-button {
      .dc-btn {
        margin: 40px 0 0 15px;
      }
    }
  }

  @media (max-width: 599px) {
    .swiper-container {
      margin-left: 15px;
      padding-right: 15px;
    }

    .presslist__single__content {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  // @media (max-width: 767px) {
  //   .swiper-container {
    //     padding-right: ${props => props.offset + 20}px;
  //   }
  // }

`;

export default React.memo(MyComponent);