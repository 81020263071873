import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async';
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import departmentReducer, {fetchPosts,fetchChild} from "../../api/redux/department";
import DepartmentDetailsPage from "../../components/Departments/DepartmentDetailsPage";
import InnerBannerTwo from "../../components/InnerBannerTwo";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {useLocation, useParams} from "react-router-dom";

const DepartmentPageDetails = () => {

    const {slug} = useParams();

    const dispath = useDispatch()

    const getData = useSelector(state=> state.departmentReducer)
    // api call
    useEffect(() => {
        let apiUrl = apiEndPoints.CHILD_PAGE
        let apiUrlSection = apiEndPoints.SECTIONS

        let api_params = {
            [ApiParamKey.page_id]: 32,
            [ApiParamKey.sections]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }
        let api_params_sections = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: `${slug}`,
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }

        dispath(fetchChild([apiUrl, api_params]))
        dispath(fetchPosts([apiUrlSection, api_params_sections]))
    }, [])

    // filter data
    const BannerData = getData?.child?.data?.list?.find(f=>f?.page_data?.slug === `${slug}`);
    const DepartmentData = getData?.posts?.data;

    const page_data = BannerData?.page_data;
    return (

        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.title ? page_data?.title + ' | ' : ''}`}National Heart
                    Foundation</title>

                <meta name="meta:title" content={page_data?.meta_title}/>
                <meta name="description" content={page_data?.meta_description}/>
                <meta property="og:title" content={page_data?.og_title}/>
                <meta property="og:subtitle" content={page_data?.og_title}/>
                <meta property="og:description" content={page_data?.og_description}/>
                <meta property="og:image" content={BannerData?.images?.list?.[0]?.full_path}/>
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>
            <StyledComponent>
                {
                    BannerData &&
                    <InnerBannerTwo
                                    img={BannerData?.images?.list[0]?.full_path ? BannerData?.images?.list[0]?.full_path : ''}/>
                }
                {
                    DepartmentData &&
                    <DepartmentDetailsPage data={DepartmentData}/>
                }
                {/*<DoctorsPublications/>*/}
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default DepartmentPageDetails;
