import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Modal} from "react-bootstrap";
import {Swiper, SwiperSlide} from 'swiper/react';
import swiper, {Autoplay, Navigation, Pagination,EffectFade} from "swiper";
// import required modules
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
// import Sustainability from "../../pages/activites/SustainabilitySingle";
import Title from "../Title";
import Button from "../Button";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data,id,no_slider}) => {
    const nextRef = useRef()
    const prevRef = useRef()
    const [current, setCurrent] = useState('1')
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    // filter data
    const OtherSliderData = data?.section_data;
    const OtherSlider = data?.posts?.list;



    const handleSliderCurrent = () => {
        setTimeout(() => {
            if (document.querySelector('.sustain .swiper-pagination-current')) {
                setCurrent(document.querySelector('.sustain .swiper-pagination-current').innerHTML)
            }
        }, 200)
    }

    const [offset, setOffset] = useState(90)
    useEffect(() => {


        window.addEventListener('load', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [data])

    return (
        <StyledComponent offset={offset} id={`${id ? id : "Sustainability"}`} className={`sustain ${no_slider ? 'slider_no' : ''}`}>
            <ul className={'navigation_item'}>
                <li className={'prev_swipper'} ref={prevRef} >
                    <svg id="Button_-_Nav_-_Prev" data-name="Button - Nav - Prev" xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
                        <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke="#fff" stroke-width="1" opacity="1">
                            <rect width="41" height="41" rx="20.5" stroke="none"/>
                            <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                        </g>
                        <g id="Group_4824" data-name="Group 4824" transform="translate(16 15)">
                            <g id="Group_4823" data-name="Group 4823">
                                <line id="Line_9" data-name="Line 9" x1="5" y2="5" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_10" data-name="Line 10" x2="5" y2="5" transform="translate(0 5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                            </g>
                            <line id="Line_11" data-name="Line 11" x1="10" transform="translate(0 5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                        </g>
                        <g id="Rectangle_5661" data-name="Rectangle 5661" transform="translate(0 41) rotate(-90)" fill="none" stroke="#fff" stroke-width="1" stroke-dasharray="0 130" opacity="0">
                            <rect width="41" height="41" rx="20.5" stroke="none"/>
                            <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                        </g>
                    </svg>

                </li>
                <li className={'next_swipper'} ref={nextRef}>
                    <svg id="Button_-_Nav_-_Next" data-name="Button - Nav - Next" xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
                        <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke="#fff" stroke-width="1" opacity="1">
                            <rect width="41" height="41" rx="20.5" stroke="none"/>
                            <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                        </g>
                        <g id="Group_4824" data-name="Group 4824" transform="translate(-637.5 -1581.5)">
                            <g id="Group_4823" data-name="Group 4823" transform="translate(11 4)">
                                <line id="Line_9" data-name="Line 9" x2="5" y2="5" transform="translate(647.5 1592.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_10" data-name="Line 10" x1="5" y2="5" transform="translate(647.5 1597.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                            </g>
                            <line id="Line_11" data-name="Line 11" x2="10" transform="translate(653.5 1601.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                        </g>
                        <g id="Rectangle_5661" data-name="Rectangle 5661" transform="translate(0 41) rotate(-90)" fill="none" stroke="#fff" stroke-width="1" stroke-dasharray="0 130" opacity="0">
                            <rect width="41" height="41" rx="20.5" stroke="none"/>
                            <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                        </g>
                    </svg>


                </li>
            </ul>
                    <Container fluid className={`sustain`}>
                        {OtherSlider?.length > 0 &&
                        <Swiper
                            Swiper modules={[Autoplay, Pagination, Navigation, EffectFade]}
                            allowTouchMove={true}
                            autoHeight={true}
                            slidesPerView={1}
                            loop={true}
                            pagination={{
                                type: "fraction",
                            }}
                            navigation={{
                                prevEl: prevRef.current,
                                nextEl: nextRef.current,
                            }}
                            onBeforeInit={(swiper) => {
                                swiper.params.navigation.prevEl = prevRef.current;
                                swiper.params.navigation.nextEl = nextRef.current;
                            }}
                            shouldSwiperUpdate={(prev, next) => {
                                if (prev.activeIndex === next.slides.length - 1) {
                                    setButtonDisabled(true);
                                } else {
                                    setButtonDisabled(false);
                                }
                            }}
                            effect="fade" // Set the fade effect
                            fadeEffect={{
                                crossFade: true, // Add fade transition duration
                            }}
                            onSlideChange={(s) => handleSliderCurrent()}
                        >
                            <div className={'sustain__top'}>
                                <h6>{reactHtmlParser(OtherSliderData?.subtitle ? OtherSliderData?.subtitle : '')}</h6>
                                <p><span>{current}</span>/<span>3</span></p>
                            </div>

                            {OtherSlider?.length > 0 && OtherSlider?.map((i,index)=>(
                                        <SwiperSlide>
                                            <Row className={'sustainability'}>
                                                <Col md={7} className={'sustainability__left'}>
                                                    <div className={'sustainability__left__data'}>
                                                        <Title text={reactHtmlParser(i?.data?.subtitle)}fontSize={60} fontWeight={400} lineHeight={60} color={'#F9F9F9'}/>
                                                        <p className={'text split-up'}>{reactHtmlParser(i?.data?.description)}</p>
                                                    </div>
                                                </Col>
                                                <Col md={5} className={'sustainability__right'}>
                                                    <img src={i?.images[0]?.full_path} alt={'esrs'}/>
                                                </Col>
                                            </Row>
                                        </SwiperSlide>
                            ))}

                        </Swiper>
                        }
                    </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  margin-top: -1px;
  
  @media(max-width: 767px){
    .title{
      h2{
        font-size: 40px;
      }
    }
  }
  .reveal {
    overflow: hidden;

    img {
      transform-origin: left;
      inset: 0px;
    }
  }
.sustainability{
  &__left{
    padding-left: ${props => props.offset ? props.offset + 15 + 'px' : '90px'};
    @media(max-width: 767px){
      padding-left: 0px;
    }
  }
}

  &.slider_no{
    .navigation_item{
      display: none;
    }
    .sustainability__left__data .title{
      margin-left: 0;
     
    }
    .sustainability__left__data{
      padding-bottom: 200px;
      @media(max-width: 767px){
        padding-bottom: 0px;
      }
    }
  }
  
  .swiper-button-prev , .swiper-button-next{
    opacity: 0;
    visibility: hidden;
  }
  ul{
    position: absolute;
    top: 210px;
    right: 128px;
    z-index: 150;
    display: flex;
    
    li{
      cursor: pointer;
      svg{
        #Ellipse_4378 {
          transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
          r: 0;
        }

        &:hover {
          #Ellipse_4378 {
            fill: rgb(208, 202, 16);
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            r: 26px;
          }
          g{
            line{
              stroke: #f9f9f9;
            }
          }
        }
      }
    }
    li:first-child{
      padding-right: 20px;
    }
  }
  .sustain{
    padding-left: 0;
    padding-right: 0;
    &__top{
      padding-left: ${props => props.offset ? props.offset + 15 + 'px' : '90px'};
      position: absolute;
      top: 120px;
      z-index: 150;
      width: 100%;
      padding-right: ${props => props.offset ? props.offset + 15 + 'px' : '90px'};
      .subtitle:after{
        background: #f9f9f9;
      }
      p{
        color: #f9f9f9;
        right: ${props => props.offset ? props.offset + 15 + 'px' : '90px'};
        position: absolute;
        top: 0;
        @media(max-width: 767px){
          right: 15px;
        }
      }
      h6{
        color: #f9f9f9;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        position: relative;
        padding-bottom: 20px;
        &:after{
          content: '';
          bottom: 0;
          left: 0;
          height: 1px;
          width: 100%;
          position: absolute;
          background: rgb(249 249 249 / 40%);;
        }
      }
      
    }
   
  }
  
  
  @media(max-width: 767px) {
    .cPlyYs .sustainability__left__data .title {
      h2{
        font-size: 40px;
        line-height: 45px;
      }
    
    }
   
    .sustain{
      &__top{
        top: 40px;
        padding: 0 15px;
        width: 100%;
      }
    }
  }
  @media(max-width: 767px){
  ul{
    position: absolute;
    top: 175px;
    left: 15px;
    z-index: 150;
  }
}

  .sustainability{
    margin: 0;
    &__left{
      background-color: #98223C;
      margin-left: 0;
      &__data{
        position: relative;
        padding-right: 100px;
        margin-top: 200px;
        ul{
          position: relative;
          left: unset;
          top: unset;
        }
        .title{
          margin-bottom: 80px;
          width: 100%;
          z-index: 5;
        }
        .text{
          margin-bottom: 40px;
          color: #f9f9f9;
        }
      }
    }
    &__right{
      position: relative;
      padding-top: calc(568 / 816 * 100%);
      img{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  @media(max-width: 767px) {
    .sustainability{
      flex-direction: column-reverse;
      margin: 0;
      &__left{
        &__data{
          margin-top: 0;
          margin-bottom: 120px;
          padding: 0 15px;
          .title{
            margin-left: 0;
            margin-bottom: 40px;
            font-size: 40px;
            line-height: 40px;
            font-weight: 400;
            margin-top: -75px;
            width: initial;
          }
        }
      }
      &__right{
        position: relative;
        padding-top: calc(375 / 375 * 100%);
        img{
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
  @media(max-width: 992px) and (min-width: 768px){
    .sustainability__left__data{
      padding-right: 40px !important;
      padding-left: 40px !important;
    }
  }


  .swiper-pagination {
    opacity: 0;
    visibility: hidden;
  }
`;

export default React.memo(MyComponent);