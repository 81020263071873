import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchExecutiveCouncil} from "../../api/redux/executive-council";
import People from "../../components/ExecutiveCouncil/People";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";


const MyComponent = () => {

    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let apiUrl = apiEndPoints.SECTIONS

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'national-executive-councill',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }

        dispath(fetchExecutiveCouncil([apiUrl, api_params]))
    }, [])
    const getData = useSelector(state=> state.executiveCouncilReducer)
    const BannerData = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'national-executive-council-banner');
    const Founder = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'founder-details');
    const Secretary = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'secretary-general');
    const vicePresident = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'vice-president');
    const president = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'president');
    const jointSecretaryGeneral = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'joint-secretary-general');
    const Treasurer = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'treasurer');
    const mediaPublicationSecretary	 = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'media--publication-secretary');
    const members	 = getData?.posts?.data?.sections?.find(f=>f?.section_data?.slug === 'members');


    const page_data = getData?.posts?.data?.page_data;

    return (
        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.meta_title ? page_data?.meta_title + ' | ' : ''}`}National Heart
                    Foundation</title>

                <meta name="meta:title" content={page_data?.meta_title}/>
                <meta name="description" content={page_data?.meta_description}/>
                <meta property="og:title" content={page_data?.og_title}/>
                <meta property="og:subtitle" content={page_data?.og_title}/>
                <meta property="og:description" content={page_data?.og_description}/>
                <meta property="og:image" content={BannerData?.images?.list?.[0]?.full_path}/>
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>
                {
                    BannerData &&
                    <InnerBanner title={BannerData?.section_data?.short_desc}
                                 breadcrumbs={BannerData?.section_data?.subtitle}
                                 subtitle={BannerData?.section_data?.description}
                                 img={BannerData?.images?.list?.[0]?.full_path}/>
                }

                    <People Founder={Founder}
                            viceP={vicePresident}
                            President={president}
                            jointsecGeneral={jointSecretaryGeneral}
                            treasurer={Treasurer}
                            mediaSec={mediaPublicationSecretary}
                            member={members}
                            Secretary={Secretary}
                    />

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
