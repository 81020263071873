import React, {useEffect, useRef,useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import 'react-modal-video/css/modal-video.min.css'
import {LightgalleryItem, LightgalleryProvider} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Mousewheel, Navigation, Pagination} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import reactHtmlParser from "react-html-parser";
const Overview = ({data}) => {
    const founderSlider= data?.images?.list;
    const next = () => {
        document.querySelector('.living-legend .mySwiper .swiper-button-next').click();
    }

    const prev = () => {
        document.querySelector('.living-legend .mySwiper .swiper-button-prev').click();
    }


    const [offset, setOffset] = useState(100)


    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)

        window.addEventListener('load', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
    }, [])


    // <width control

    const sourceDivRef = useRef(null);
    const targetDivRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            const fullWidth = sourceDivRef.current.offsetWidth;
            const adjustedWidth = fullWidth-15;
            document.documentElement.style.setProperty('--target-width', `${adjustedWidth}px`);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // halfcut

    const sourceDivReff = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            const fullHeight = sourceDivReff.current?.offsetHeight;
            const adjustedHeight = (fullHeight / 3)+15;

            document.documentElement.style.setProperty('--target-height', `${adjustedHeight}px`);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <StyledComponent className={'living-legend'} offset={offset}>
            <Container >
                <Row>
                    <Col md={6} ref={sourceDivRef}>

                    </Col>
                </Row>
                <Row className={'gallery-svg__section'}>

                    <Col md={9}>
                        <h4 className={'gallery-svg__section__title'}>Gallery</h4>
                    </Col>
                    <Col md={3}>
                        <div className="gallery-svg__section__arrow">
                            <div className="slider-nav">
                                <ul>
                                    <li onClick={prev} className="left">
                                        <svg id="Prev" xmlns="http://www.w3.org/2000/svg" width="44" height="44"
                                             viewBox="0 0 44 44">
                                            <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke="#fff"
                                               stroke-width="1" opacity="0.2">
                                                <rect width="44" height="44" rx="22" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="none"/>
                                            </g>
                                            <g id="Group_4824" data-name="Group 4824" transform="translate(17 17)">
                                                <g id="Group_4823" data-name="Group 4823">
                                                    <line id="Line_9" data-name="Line 9" x1="5" y2="5" fill="none"
                                                          stroke="#fff" strokeLinecap="round" stroke-width="1"/>
                                                    <line id="Line_10" data-name="Line 10" x2="5" y2="5"
                                                          transform="translate(0 5)" fill="none" stroke="#fff"
                                                          strokeLinecap="round" stroke-width="1"/>
                                                </g>
                                                <line id="Line_11" data-name="Line 11" x1="10" transform="translate(0 5)"
                                                      fill="none" stroke="#fff" strokeLinecap="round" stroke-width="1"/>
                                            </g>
                                            <g id="Rectangle_5661" data-name="Rectangle 5661"
                                               transform="translate(0 44) rotate(-90)" fill="none" stroke="#fff"
                                               stroke-width="1" strokeDasharray="1 140" opacity="0">
                                                <rect width="44" height="44" rx="22" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="none"/>
                                            </g>
                                        </svg>
                                    </li>
                                    <li onClick={next} className="next">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                            <g id="Next" transform="translate(16429 -2014)">
                                                <g id="Rectangle_492" data-name="Rectangle 492"
                                                   transform="translate(-16429 2014)" fill="none" stroke="#fff"
                                                   stroke-width="1" opacity="0.2">
                                                    <rect width="44" height="44" rx="22" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="none"/>
                                                </g>
                                                <g id="Group_4824" data-name="Group 4824"
                                                   transform="translate(-17065.5 434.5)">
                                                    <g id="Group_4823" data-name="Group 4823" transform="translate(11 4)">
                                                        <line id="Line_9" data-name="Line 9" x2="5" y2="5"
                                                              transform="translate(647.5 1592.5)" fill="none" stroke="#fff"
                                                              strokeLinecap="round" stroke-width="1"/>
                                                        <line id="Line_10" data-name="Line 10" x1="5" y2="5"
                                                              transform="translate(647.5 1597.5)" fill="none" stroke="#fff"
                                                              strokeLinecap="round" stroke-width="1"/>
                                                    </g>
                                                    <line id="Line_11" data-name="Line 11" x2="10"
                                                          transform="translate(653.5 1601.5)" fill="none" stroke="#fff"
                                                          strokeLinecap="round" stroke-width="1"/>
                                                </g>
                                                <g id="Rectangle_5661" data-name="Rectangle 5661"
                                                   transform="translate(-16429 2058) rotate(-90)" fill="none" stroke="#fff"
                                                   stroke-width="1" strokeDasharray="1 140" opacity="0">
                                                    <rect width="44" height="44" rx="22" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="none"/>
                                                </g>
                                            </g>
                                        </svg>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/*Gallery*/}
            <Container className={'version_one'}>
                <Row>

                    <Col md={{span: 10, offset: 1}} className={'slider_left'}>


                    </Col>
                </Row>
            </Container>
            <div className="clearfix"></div>
            <LightgalleryProvider>

                <Container fluid className={'p-0'}>
                    <Row>
                        <Col className={'slider_overlap'}>
                            <Swiper
                                loop={true}
                                spaceBetween={15}
                                speed='1000'
                                slidesPerView={3}
                                keyboardControl={true}
                                initialSlide={1}
                                modules={[ Pagination, Navigation, Mousewheel]}
                                navigation
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 15,

                                    },
                                    768: {
                                        slidesPerView: 1,
                                        spaceBetween: 15,

                                    },
                                    992: {
                                        slidesPerView: 3,
                                        spaceBetween: 15,
                                    },
                                }}
                                className="mySwiper"
                            >
                                {
                                    founderSlider && founderSlider.length>0 &&
                                    founderSlider.map((i)=>{
                                        return(
                                            <SwiperSlide key={i} className={''}>
                                                <div className="single single_testimonial">
                                                    <LightgalleryItem>
                                                        <div className="wrapper_box ">
                                                            <div className="image_wrapper">
                                                                <Img src={i?.full_path}/>
                                                            </div>
                                                        </div>
                                                    </LightgalleryItem>
                                                </div>

                                            </SwiperSlide>
                                        )

                                    })
                                }



                            </Swiper>
                        </Col>
                    </Row>
                </Container>
            </LightgalleryProvider>

        </StyledComponent>
    )
};

const StyledComponent = styled.section`
  margin-top: -1px;
  &.living-legend{
    background-color: #1E1E2E;
    .container-fluid{
      bottom: -2px;
      &:after{
        margin-top: -1px;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: calc(50% - 80px);
        background-color: #1E1E2E;
        position: absolute;
        @media (max-width: 767px){
          margin-top: -1px;
          height: 37%;
        }
        @media (max-width: 991px){
          height: 50%;
        }
      }
    }
    .bg-text-one{
      color: #fff;
    }
  }




  .container-fluid{
    background-color: #F6F9F8;
    position: relative;
    @media(max-width: 767px){
      padding-bottom: 150px;
    }
  }
  .row {
    position: relative;
  }

  .about-partex__text {
    position: relative;
  }

  .bg-text-one {
    font-size: 240px;
    line-height: 240px;
    font-weight: 500;
    opacity: 60%;
    margin: 0;
    color: #E4E4E4;
    position: absolute;
    white-space: nowrap;


    @media (max-width: 767px) {
      font-weight: 400;
      font-size: 240px;
      line-height: 240px;
      position: absolute;
      top: -270px;
      padding-left: 15px;
    }
  }


  .bg-text-two {
    left: -10%;
    bottom: 0;
  }

  .upper-title {
    width: 50%;
    margin: 0 0 20px 0;
    font-weight: 500;;
  }


  p {
    margin: 0 0 80px 0;
  }

  .upper-pera {
    font-weight: 500;
    margin: 0 0 20px 0;
  }

  .lower-para {
    margin: 0;
  }

  @media (max-width: 767px) {

    .upper-title {
      width: 100%;
    }

    h3, p, .sub-col {
      margin: 0 0 40px 0;
    }
  }

  //Gallery

  .swiper {
    padding-left: ${props => props.offset ? props.offset + 15 + 'px' : '100px'};
    height: var(--target-width) !important;
    @media (min-width: 768px) and (max-width: 991px){
      height: 100%!important;
    }
  }

  .swiper-button-next, .swiper-button-prev {
    display: none;
  }

  .gallery-svg__section { 
    padding-bottom: 80px;
    h4{
      color: #FFFFFF;
    }
    ul {
      display: flex;
      justify-content: flex-end;

      li {
        cursor: pointer;

        &:first-child {
          margin-right: 20px;
        }

        svg {
          height: 41px;
          g,line{
            stroke:white;
            transition: stroke 0.2s ease;
          }
          path, #Rectangle_5661 {
            //transition: stroke-dasharray 0.3s ease-in-out;
            transition: all 1s cubic-bezier(.25, .74, .22, .99);
          }
        }

        &:hover {
          svg {
            g,line{
              stroke:#E72E4B;
              transition: stroke 0.2s ease;
            }
            #Rectangle_5661 {
              opacity: 1;
              stroke-dasharray: 140;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px){
    .gallery-svg__section{
      padding-bottom: 0;
      ul{
        padding-top: 40px;
        justify-content: flex-start!important;
      }
    }
  }

  .swiper-slide {
    transition: 0.7s all ease;
    padding-right: 15px;
    @media (max-width: 767px){
      padding-right: 0;
    }
    @media(min-width: 768px) and (max-width: 991px){
      padding-left: 15px;
    }
  }

  .swiper-pagination {
    width: auto;
    bottom: 120px;
    left: 155px;
    @media (min-width: 1550px) {
      //bottom: 60px;
    }
    @media (max-width: 767px) {
      left: 5px;
      bottom: 85px;
    }

    .swiper-pagination-bullet {
      width: 8px;
      height: 3px;
      display: inline-block;
      background: #C49F8A;
      border-radius: 4px;
      opacity: 1;
      margin: 0 10px;

      &.swiper-pagination-bullet-active {
        width: 70px !important;
        background: #565440;
      }
    }
  }

  .swiper-slide {
    @media (max-width: 767px) {
      //width: inherit !important;

    }
  }

  .swiper-slide-active {
    width: var(--target-width) !important;
    @media (max-width: 991px) {
      width: 100% !important;
    }

  }

  .single_testimonial {

    .image_wrapper {
      padding-top: calc(570 / 570 * 100%) !important;
      img{
        transform: unset!important;
      }
    }
  }
}

.single_testimonial {
  //padding-right: 15px;
  box-sizing: border-box;
  @media (max-width: 991px) {
    padding-right: 15px;
    padding-left: 15px;
  }
  @media (max-width: 991px) {
    padding-right: 15px;
  }

  .wrapper_box {
    display: flex;
    position: relative;

    @media (max-width: 767px) {
      flex-direction: column;
      .image_wrapper {
        margin-bottom: 30px;
      }
    }


    .image_wrapper {
      padding-top: calc(312 / 370 * 100%);
      transition: 0.7s all ease;
      position: relative;
      cursor: pointer;
      overflow: hidden;

      width: 100%;

      .play {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;

        svg {
          #Ellipse_396 {
            transition: 0.7s all ease;
          }
        }
      }

      .global-image {
        transition: 0.7s all ease;
        transform: scale(1.01);
      }

      &:hover {
        .global-image {
          transform: scale(1.06);
        }

        svg {
          #Ellipse_396 {
            r: 25;
          }
        }
      }
    }


  }
}

@media (max-width: 991px) {
  .swiper {
    padding-left: unset;

    .container-fluid {
      padding: 0 15px 0 0 !important;
    }

    .title {
      margin-bottom: 30px !important;
    }
  }

`;

export default React.memo(Overview);
