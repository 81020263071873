import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {get} from "../../network/axiosServices";


const initialState = {
    loading: false,
    posts: [],
    error: '',
    child:[],
    detail: [],
    detailLoading: false,
    detailError: ''
}

export const fetchSearch = createAsyncThunk("searchPublication", (params) => {
    return get(params);
});
export const fetchPosts = createAsyncThunk("fetchFounderPublications", (params) => {
    return get(params);
});

export const fetchChild = createAsyncThunk("fetchFounderPublicationsChild", (params) => {
    return get(params);
});

export const fetchPostDetail = createAsyncThunk("fetchFounderPublicationsDetail", (params) => {
    return get(params);
});


const postSlice = createSlice({
    name: 'founderpublications',
    initialState,
    extraReducers: builder => {

        builder.addCase(fetchSearch.pending, (state) => {
            state.loading = true
            state.posts = []
            state.error = ''
        })
        builder.addCase(fetchSearch.fulfilled, (state, action) => {
            state.loading = false
            state.posts = action.payload
            state.error = ''
        })
        builder.addCase(fetchSearch.rejected, (state, action) => {
            state.loading = false
            state.posts = []
            state.error = action.error
        })

        builder.addCase(fetchPosts.pending, (state) => {
            state.loading = true
            state.posts = []
            state.error = ''
        })
        builder.addCase(fetchPosts.fulfilled, (state, action) => {
            state.loading = false
            state.posts = action.payload
            state.error = ''
        })
        builder.addCase(fetchPosts.rejected, (state, action) => {
            state.loading = false
            state.posts = []
            state.error = action.error
        })
        builder.addCase(fetchChild.pending, (state) => {
            state.loading = true
            state.child = []
            state.error = ''
        })
        builder.addCase(fetchChild.fulfilled, (state, action) => {
            state.loading = false
            state.child = action.payload
            state.error = ''
        })
        builder.addCase(fetchChild.rejected, (state, action) => {
            state.loading = false
            state.child = []
            state.error = action.error
        })
        builder.addCase(fetchPostDetail.pending, (state, action) => {
            state.detailLoading = true
            state.detail = []
            state.detailError = ''
        })
        builder.addCase(fetchPostDetail.fulfilled, (state, action) => {
            state.detailLoading = false
            state.detail = action.payload
            state.detailError = ''
        })
        builder.addCase(fetchPostDetail.rejected, (state, action) => {
            state.detailLoading = false
            state.detail = []
            state.detailError = action.error
        })
    }
})


export default postSlice.reducer
