import React, {useEffect, useLayoutEffect, useState} from "react";
import styled from "styled-components";
import {Col, Container, Modal, Row} from "react-bootstrap";
import {CSSPlugin, gsap, TimelineLite} from "gsap";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Title from "../Title";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import BoxImageWithText from "./BoxImageWithText";
import ButtonShort from "../ButtonShort";
import ReactHtmlParser from "react-html-parser";
import reactHtmlParser from "react-html-parser";
import {Link} from "react-router-dom";

const MyComponent = ({data}) => {

    const [offset, setOffset] = useState(100)
    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)

        window.addEventListener('load', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
    }, [])



        // filter data
        const HomeAboutSlider = data?.posts?.list;
        const HomeAboutData = data?.section_data;

        return (

                <StyledComponent
                    offset={offset}
                    className={`pionneering management-team pt-200 pb-200`}>
                    <Container>
                        <Row>
                            <Col md={5}>
                                <h4 className={'left-width'}>{HomeAboutData?.short_desc ? HomeAboutData?.short_desc : ''}</h4>
                            </Col>

                            <Col md={7}>

                                <p className={'split-up'}>{ReactHtmlParser(HomeAboutData?.description ? HomeAboutData?.description : '')}</p>

                            </Col>
                        </Row>
                        <Row>
                            <Col md={5}>
                                <div className="d-flex navigation_wrapper">
                                    <ul className="navigation">
                                        <div className={'navigation__icon'}>
                                            <li className={'prev_swipper5'}>
                                                <svg id="Prev" xmlns="http://www.w3.org/2000/svg" width="44" height="44"
                                                     viewBox="0 0 44 44">
                                                    <g id="Rectangle_492" data-name="Rectangle 492" fill="none"
                                                       stroke="#fff"
                                                       stroke-width="1" opacity="0.2">
                                                        <rect width="44" height="44" rx="22" stroke="none"/>
                                                        <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="none"/>
                                                    </g>
                                                    <g id="Group_4824" data-name="Group 4824" transform="translate(17 17)">
                                                        <g id="Group_4823" data-name="Group 4823">
                                                            <line id="Line_9" data-name="Line 9" x1="5" y2="5" fill="none"
                                                                  stroke="#fff" strokeLinecap="round" stroke-width="1"/>
                                                            <line id="Line_10" data-name="Line 10" x2="5" y2="5"
                                                                  transform="translate(0 5)" fill="none" stroke="#fff"
                                                                  strokeLinecap="round" stroke-width="1"/>
                                                        </g>
                                                        <line id="Line_11" data-name="Line 11" x1="10"
                                                              transform="translate(0 5)"
                                                              fill="none" stroke="#fff" strokeLinecap="round"
                                                              stroke-width="1"/>
                                                    </g>
                                                    <g id="Rectangle_5661" data-name="Rectangle 5661"
                                                       transform="translate(0 44) rotate(-90)" fill="none" stroke="#fff"
                                                       stroke-width="1" strokeDasharray="1 140" opacity="0">
                                                        <rect width="44" height="44" rx="22" stroke="none"/>
                                                        <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="none"/>
                                                    </g>
                                                </svg>
                                            </li>
                                            <li className={'next_swipper5'}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44"
                                                     viewBox="0 0 44 44">
                                                    <g id="Next" transform="translate(16429 -2014)">
                                                        <g id="Rectangle_492" data-name="Rectangle 492"
                                                           transform="translate(-16429 2014)" fill="none" stroke="#fff"
                                                           stroke-width="1" opacity="0.2">
                                                            <rect width="44" height="44" rx="22" stroke="none"/>
                                                            <rect x="0.5" y="0.5" width="43" height="43" rx="21.5"
                                                                  fill="none"/>
                                                        </g>
                                                        <g id="Group_4824" data-name="Group 4824"
                                                           transform="translate(-17065.5 434.5)">
                                                            <g id="Group_4823" data-name="Group 4823"
                                                               transform="translate(11 4)">
                                                                <line id="Line_9" data-name="Line 9" x2="5" y2="5"
                                                                      transform="translate(647.5 1592.5)" fill="none"
                                                                      stroke="#fff"
                                                                      strokeLinecap="round" stroke-width="1"/>
                                                                <line id="Line_10" data-name="Line 10" x1="5" y2="5"
                                                                      transform="translate(647.5 1597.5)" fill="none"
                                                                      stroke="#fff"
                                                                      strokeLinecap="round" stroke-width="1"/>
                                                            </g>
                                                            <line id="Line_11" data-name="Line 11" x2="10"
                                                                  transform="translate(653.5 1601.5)" fill="none"
                                                                  stroke="#fff"
                                                                  strokeLinecap="round" stroke-width="1"/>
                                                        </g>
                                                        <g id="Rectangle_5661" data-name="Rectangle 5661"
                                                           transform="translate(-16429 2058) rotate(-90)" fill="none"
                                                           stroke="#fff"
                                                           stroke-width="1" strokeDasharray="1 140" opacity="0">
                                                            <rect width="44" height="44" rx="22" stroke="none"/>
                                                            <rect x="0.5" y="0.5" width="43" height="43" rx="21.5"
                                                                  fill="none"/>
                                                        </g>
                                                    </g>
                                                </svg>

                                            </li>
                                        </div>

                                    </ul>

                                    <ul className={'navigation-mobile'}>
                                        <div className={'navigation__icon'}>
                                            <li className={'prev_swipper5'}>
                                                <svg id="Button_-_Nav_-_Prev_Dark_" data-name="Button - Nav - Prev (Dark)"
                                                     xmlns="http://www.w3.org/2000/svg" width="41" height="41"
                                                     viewBox="0 0 41 41">
                                                    <g id="Rectangle_492" data-name="Rectangle 492" fill="none"
                                                       stroke="#1e1e2e" stroke-width="1" opacity="0.2">
                                                        <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                        <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                                    </g>
                                                    <g id="Group_4824" data-name="Group 4824" transform="translate(16 15)">
                                                        <g id="Group_4823" data-name="Group 4823">
                                                            <line id="Line_9" data-name="Line 9" x1="5" y2="5" fill="none"
                                                                  stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                                            <line id="Line_10" data-name="Line 10" x2="5" y2="5"
                                                                  transform="translate(0 5)" fill="none" stroke="#e72e4b"
                                                                  stroke-linecap="round" stroke-width="1"/>
                                                        </g>
                                                        <line id="Line_11" data-name="Line 11" x1="10"
                                                              transform="translate(0 5)" fill="none" stroke="#e72e4b"
                                                              stroke-linecap="round" stroke-width="1"/>
                                                    </g>
                                                    <g id="Rectangle_5661" data-name="Rectangle 5661"
                                                       transform="translate(0 41) rotate(-90)" fill="none" stroke="#e72e4b"
                                                       stroke-width="1" stroke-dasharray="130 130">
                                                        <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                        <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                                    </g>
                                                </svg>
                                            </li>
                                            <li className={'next_swipper5'}>
                                                <svg id="Button_-_Nav_-_Next_Dark_" data-name="Button - Nav - Next (Dark)"
                                                     xmlns="http://www.w3.org/2000/svg" width="41" height="41"
                                                     viewBox="0 0 41 41">
                                                    <g id="Rectangle_492" data-name="Rectangle 492" fill="none"
                                                       stroke="#1e1e2e" stroke-width="1" opacity="0.2">
                                                        <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                        <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                                    </g>
                                                    <g id="Group_4824" data-name="Group 4824"
                                                       transform="translate(-637.5 -1581.5)">
                                                        <g id="Group_4823" data-name="Group 4823"
                                                           transform="translate(11 4)">
                                                            <line id="Line_9" data-name="Line 9" x2="5" y2="5"
                                                                  transform="translate(647.5 1592.5)" fill="none"
                                                                  stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                                            <line id="Line_10" data-name="Line 10" x1="5" y2="5"
                                                                  transform="translate(647.5 1597.5)" fill="none"
                                                                  stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                                        </g>
                                                        <line id="Line_11" data-name="Line 11" x2="10"
                                                              transform="translate(653.5 1601.5)" fill="none"
                                                              stroke="#e72e4b" stroke-linecap="round" stroke-width="1"/>
                                                    </g>
                                                    <g id="Rectangle_5661" data-name="Rectangle 5661"
                                                       transform="translate(0 41) rotate(-90)" fill="none" stroke="#e72e4b"
                                                       stroke-width="1" stroke-dasharray="130 130">
                                                        <rect width="41" height="41" rx="20.5" stroke="none"/>
                                                        <rect x="0.5" y="0.5" width="40" height="40" rx="20" fill="none"/>
                                                    </g>
                                                </svg>

                                            </li>
                                        </div>

                                    </ul>
                                </div>
                            </Col>
                            <Col md={'7'}>
                                <ButtonShort src={'/about'} color={'black'} text={'Read More'} icon={'black'}
                                             hoverColor={'#E72E4B'} margin={'60px 0 60px 0'}
                                             marginMobile={'40px 0 40px 0'}/>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className='about-section__bottom'>
                        <Row>
                            {HomeAboutSlider &&
                                <Swiper
                                    loop={false}
                                    spaceBetween={30}
                                    slidesPerView={3}
                                    // slideNextClass={'.next'}
                                    allowSlideNext={true}
                                    // slidePrevClass={'.prev'}
                                    allowSlidePrev={true}
                                    allowTouchMove={true}
                                    // longSwipesMs={900}
                                    speed={900}
                                    breakpoints={{
                                        320: {
                                            slidesPerView: 1,
                                            spaceBetween: 15,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                            spaceBetween: 15,
                                        },
                                        1024: {
                                            slidesPerView: 3,
                                            spaceBetween: 30,
                                        }

                                    }}
                                    navigation={{
                                        prevEl: '.prev_swipper5',
                                        nextEl: '.next_swipper5',
                                    }}
                                    modules={[Pagination, Navigation]}
                                    // onSwiper={(swiper) => console.log(swiper)}
                                >
                                    {
                                        // HomeAboutSLider
                                        HomeAboutSlider?.length > 0 &&
                                        HomeAboutSlider?.map((i, index) => (
                                                <SwiperSlide key={index + 1}>
                                                    <BoxImageWithText name={i?.data?.title} number={index + 1}
                                                                      image={i?.images?.[0]?.full_path}
                                                                      desc={reactHtmlParser(i?.data?.short_desc)}
                                                                      link={`/${i?.data?.subtitle}`}/>
                                                </SwiperSlide>
                                        ))}
                                </Swiper>
                            }
                        </Row>
                    </Container>
                </StyledComponent>
        );
    }
;
const StyledComponent = styled.section`

  position: relative;
  background: rgb(246, 249, 248);
  overflow: hidden;


  .left-width {
    width: 90%;
  }

  .navigation {
    display: flex;

    &__icon {
      cursor: pointer;;
      display: flex;

      li {
        cursor: pointer;

        &:first-child {
          margin-right: 20px;
        }

        svg {
          height: 41px;

          g, line {
            stroke: black;
            transition: stroke 0.2s ease;
          }

          path, #Rectangle_5661 {
            //transition: stroke-dasharray 0.3s ease-in-out;
            transition: all 1s cubic-bezier(.25, .74, .22, .99);
          }
        }

        &:hover {
          svg {
            g, line {
              stroke: #E72E4B;
              transition: stroke 0.2s ease;
            }

            #Rectangle_5661 {
              opacity: 1;
              stroke-dasharray: 140;
            }
          }
        }
      }
    }
  }

  .title_wrapper {
    display: flex;
  }

  .swiper-button-disabled {
    opacity: 1 !important;
  }

  .swiper-initialized {
    padding-right: ${props => props.offset ? props.offset + 15 + 'px' : '90px'};
    @media (max-width: 767px) {
      padding-right: 0 !important;
      width: 100%;
    }
  }

  .swiper-button-prev, .swiper-button-next {
    opacity: 0;
    visibility: hidden;
  }

  .navigation_wrapper {
    justify-content: space-between;
    margin: 60px 0 60px 0;


    @media (max-width: 767px) {
      margin-bottom: 0px;
    }

    .title {
      h3 {
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        color: #4F616B;
      }
    }

    .d-flex {
      justify-content: space-between;
    }
  }
}

.swiper-wrapper {
  height: auto !important;
}

.about-section__bottom {
  padding-left: ${props => props.offset ? props.offset + 20 + 'px' : '90px'};
  position: relative;

  .row {
    margin-left: -0.4%;
  }

  @media (max-width: 767px) {
    padding-left: 15px !important;
    padding-right: 30px !important;
  }

  &__single {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .navigation-mobile {
    display: none;
  }
}

@media (max-width: 767px) {
  .left-width {
    margin-bottom: 40px;
  }

  .navigation-mobile {
    .navigation__icon {
      margin-bottom: 30px;
      cursor: pointer;;
      display: flex;

      li:first-child {
        margin-right: 20px;
      }
    }
  }

  .navigation_wrapper {
    flex-direction: column;
  }

  .navigation {
    display: none;


    //.navigation {
    //  min-width: 100%;
    //  margin-top: 40px;
    //  margin-bottom: 20px;
    //}

    .about-section__bottom__single__inner span {
      top: 55px;
      left: 55px;
    }
  }

  .mobile-button {
    .dc-btn {
      margin: 40px 0 0 0;
    }
  }
}

`;
export default React.memo(MyComponent);