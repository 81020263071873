import React, {useEffect, useState, useRef} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {Img} from "../Img";
import ButtonOutlineHover from "../ButtonShort";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {


// offset
    const [offset, setOffset] = useState(100)
    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)

        window.addEventListener('load', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
    }, [])

    // halfcut

    const sourceDivRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            const fullHeight = sourceDivRef.current?.offsetHeight;
            const adjustedHeight = (fullHeight / 2) + 200;
            document.documentElement.style.setProperty('--target-height', `${adjustedHeight}px`);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // filter data
    const FounderImage = data?.images?.list[0]?.full_path;
    const Founderdetails = data?.section_data;


    return (
        <StyledComponent offset={offset} className={'pb-200'}>
            <Container fluid className="profile">
                <Row>
                    <Col lg={'7'} className="profile__body">
                      <div className={'profile__body__wrapper'}>
                          <div className={'profile__body__inner'}>
                              <div className="profile__body__name">
                                  <h4 className={'split-up'}>{Founderdetails?.subtitle ? Founderdetails?.subtitle : ''}</h4>
                              </div>
                              <p className={'split-up'}>{reactHtmlParser(Founderdetails?.description ? Founderdetails?.description : '')}</p>


                          </div>
                          <h6 className={'split-up'}>{Founderdetails?.category ? Founderdetails?.category : ''}</h6>
                          <p className={'sub-deg split-up'}>{Founderdetails?.short_desc ? Founderdetails?.short_desc : ''}</p>
                          <ButtonOutlineHover src={'/founder'} color={'white'} text={'Read More'} icon={'black'}
                                              hoverColor={'white'} margin={'40px 0 0 0'} marginMobile={'40px 0 0 0'}/>
                      </div>
                    </Col>
                    <Col lg={'5'} className={'profile-left'}>
                        <div className="profile__image reveal" ref={sourceDivRef}>
                            <Img src={FounderImage ? FounderImage : ''}/>
                        </div>
                    </Col>
                </Row>

            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F6F9F8;
  position: relative;

  .profile {
    z-index: 1;
    position: relative;
    @media (max-width: 767px) {
      padding-bottom: 80px;
      padding-top: 150px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      .row {
        flex-direction: column-reverse;
      }
    }

    &__body {
      padding-left: ${props => props.offset ? props.offset + 15 + 'px' : '100px'} !important;
      padding-top: 120px;
      padding-bottom: 120px;
      padding-right: 100px;
      background-color: #98223C;

      h6 {
        color: white;
      }

      @media (min-width: 768px) and (max-width: 991px) {
        padding-right: ${props => props.offset ? props.offset + 15 + 'px' : '100px'} !important;
      }

      p {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }

      &__name {
        padding-bottom: 40px;

        p {
          color: #323232;
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
        }

        h4 {
          color: white;
        }
      }

      &__inner {
        padding-bottom: 40px;
      }

      .sub-deg {
        padding-top: 10px;
        opacity: 50%;
      }
      &__wrapper{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &__image {
      position: relative;
      padding-top: calc(662 / 470 * 100%);
      height: 100%;
    }
  }

  .profile-left {
    padding-left: 0;
    padding-right: ${props => props.offset ? props.offset + 15 + 'px' : '100px'} !important;
    @media (min-width: 768px) and (max-width: 991px) {
      padding-right: 0;
    }
  }

  .row:nth-child(even) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    .row {
      flex-direction: column-reverse !important;
    }
  }


  @media (max-width: 767px) {
    .profile-left {
      padding-right: 0px !important;
    }

    .profile {
      padding-top: 0px;
      padding-bottom: unset;

      &__body {
        padding-left: 15px !important;
        padding-bottom: 80px;
        padding-top: 40px;
        padding-right: 15px;

        &__name {
          padding-bottom: 40px;
        }
      }
    }
  }
  //
  //&::after {
  //  content: '';
  //  height: var(--target-height);
  //  width: 100%;
  //  bottom: 0;
  //  left: 0;
  //  position: absolute;
  //  background-color: #E4ECF0;
  //}

  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 120px;
    .profile-left {
      padding-right: 0 !important;
    }

    h4, h6 {
      color: #FFFFFF;
    }
  }

`;

export default React.memo(MyComponent);
