import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Link} from "react-router-dom";
import AppointmentPopUp from "./AppointmentPopUp";

const AnimatedIconMoon = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    }
    const [offset, setOffset] = useState(100);
    const containerRef = useRef(null);

    const updateOffset = () => {
        if (containerRef.current) {
            setOffset(containerRef.current.offsetLeft);
        }
    };

    useEffect(() => {
        updateOffset(); // Set initial offset

        const handleResize = () => {
            updateOffset(); // Update offset on window resize
        };

        window.addEventListener('resize', handleResize);

        return () => {
            // Cleanup event listener
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <>
            <StyledMessage offset={offset} className={`sticky-menu-middle`}>
                {offset && (
                    <div>
                        <li><Link to={'/doctors-list'}>
                            <div className={`theme-button`}>
                                <div className="moon">
                                    <ul>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             xmlnsxlink="http://www.w3.org/1999/xlink"
                                             width="18.5" height="18.5" viewBox="0 0 18.5 18.5">
                                            <defs>
                                                <clipPath id="clip-path">
                                                    <rect id="Rectangle_5288" data-name="Rectangle 5288" width="18.5"
                                                          height="18.5"
                                                          transform="translate(0 296.925)" fill="#fff" stroke="#707070"
                                                          stroke-width="1"/>
                                                </clipPath>
                                            </defs>
                                            <g id="Mask_Group_281" data-name="Mask Group 281"
                                               transform="translate(0 -296.925)">
                                                <g id="Group_1868" data-name="Group 1868"
                                                   transform="translate(2.22 298.018)">
                                                    <ellipse id="Ellipse_99" data-name="Ellipse 99" cx="3.431"
                                                             cy="3.517" rx="3.431"
                                                             ry="3.517" transform="translate(3.449)" fill="none"
                                                             stroke="#fff"
                                                             stroke-linecap="round" stroke-linejoin="round"
                                                             stroke-width="1.5"/>
                                                    <path id="Rectangle_1270" data-name="Rectangle 1270"
                                                          d="M6.88,0h0a6.88,6.88,0,0,1,6.88,6.88v2.4a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V6.88A6.88,6.88,0,0,1,6.88,0Z"
                                                          transform="translate(0 7.035)" fill="none" stroke="#fff"
                                                          stroke-linecap="round" stroke-linejoin="round"
                                                          stroke-width="1.5"/>
                                                    <path id="Path_1684" data-name="Path 1684"
                                                          d="M2419.74,664.993a1.984,1.984,0,0,0,1.308,2.873"
                                                          transform="translate(-2414.167 -657.958)" fill="none"
                                                          stroke="#fff"
                                                          stroke-linecap="round" stroke-linejoin="round"
                                                          stroke-width="1.5"/>
                                                    <path id="Path_1686" data-name="Path 1686"
                                                          d="M2431.139,664.993a1.984,1.984,0,0,1-1.307,2.873v6.407"
                                                          transform="translate(-2422.952 -657.958)" fill="none"
                                                          stroke="#fff"
                                                          stroke-linecap="round" stroke-linejoin="round"
                                                          stroke-width="1.5"/>
                                                </g>
                                            </g>
                                        </svg>
                                        <p>Find Doctors</p>

                                    </ul>
                                </div>
                            </div>
                        </Link></li>

                        <div onClick={() => handleShow()}>
                            <li><a>
                                <div className={`theme-button theme-button-two`}>
                                    <div className="moon">
                                        <ul>
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 xmlnsXlink="http://www.w3.org/1999/xlink"
                                                 width="18.5" height="18.5" viewBox="0 0 18.5 18.5">

                                                <g id="Group_20865" data-name="Group 20865"
                                                   transform="translate(14952 -369)">
                                                    <g id="Mask_Group_282" data-name="Mask Group 282"
                                                       transform="translate(-14952 -0.075)">
                                                        <g id="_42-Calendar" data-name="42-Calendar"
                                                           transform="translate(1.85 371.295)">
                                                            <g id="Group_1867" data-name="Group 1867">
                                                                <g id="Group_1862" data-name="Group 1862">
                                                                    <g id="Group_1861" data-name="Group 1861">
                                                                        <g id="Group_1860" data-name="Group 1860">
                                                                            <g id="Group_1859" data-name="Group 1859">
                                                                                <g id="Group_1858"
                                                                                   data-name="Group 1858">
                                                                                    <g id="Group_1853"
                                                                                       data-name="Group 1853">
                                                                                        <g id="Group_1851"
                                                                                           data-name="Group 1851">
                                                                                            <path id="Path_1680"
                                                                                                  data-name="Path 1680"
                                                                                                  d="M301.413,103.8H289.388A1.389,1.389,0,0,1,288,102.413V92.238a1.389,1.389,0,0,1,1.388-1.387h.462v-.463a1.387,1.387,0,1,1,2.775,0v.463h1.388v-.463a1.387,1.387,0,1,1,2.775,0v.463h1.388v-.463a1.387,1.387,0,1,1,2.775,0v.463h.462a1.389,1.389,0,0,1,1.388,1.387v10.175A1.389,1.389,0,0,1,301.413,103.8ZM289.388,91.775a.463.463,0,0,0-.462.463v10.175a.463.463,0,0,0,.462.462h12.025a.463.463,0,0,0,.462-.462V92.238a.463.463,0,0,0-.462-.463h-.925a.462.462,0,0,1-.462-.463v-.925a.463.463,0,0,0-.925,0v.925a.462.462,0,0,1-.462.463h-2.312a.462.462,0,0,1-.462-.463v-.925a.463.463,0,0,0-.925,0v.925a.462.462,0,0,1-.462.463h-2.312a.462.462,0,0,1-.462-.463v-.925a.462.462,0,1,0-.925,0v.925a.462.462,0,0,1-.463.463Z"
                                                                                                  transform="translate(-288 -89)"
                                                                                                  fill="#fff"/>
                                                                                        </g>
                                                                                        <g id="Group_1852"
                                                                                           data-name="Group 1852"
                                                                                           transform="translate(1.85 4.625)">
                                                                                            <path id="Path_1681"
                                                                                                  data-name="Path 1681"
                                                                                                  d="M302.638,99.925H292.462a.462.462,0,0,1,0-.925h10.175a.462.462,0,0,1,0,.925Z"
                                                                                                  transform="translate(-292 -99)"
                                                                                                  fill="#fff"/>
                                                                                        </g>
                                                                                    </g>
                                                                                    <g id="Group_1854"
                                                                                       data-name="Group 1854"
                                                                                       transform="translate(12.025 6.475)">
                                                                                        <circle id="Ellipse_95"
                                                                                                data-name="Ellipse 95"
                                                                                                cx="0.463" cy="0.463"
                                                                                                r="0.463"
                                                                                                fill="#fff"/>
                                                                                    </g>
                                                                                    <g id="Group_1855"
                                                                                       data-name="Group 1855"
                                                                                       transform="translate(1.85 6.475)">
                                                                                        <circle id="Ellipse_96"
                                                                                                data-name="Ellipse 96"
                                                                                                cx="0.463" cy="0.463"
                                                                                                r="0.463"
                                                                                                fill="#fff"/>
                                                                                    </g>
                                                                                    <g id="Group_1856"
                                                                                       data-name="Group 1856"
                                                                                       transform="translate(12.025 12.025)">
                                                                                        <circle id="Ellipse_97"
                                                                                                data-name="Ellipse 97"
                                                                                                cx="0.463" cy="0.463"
                                                                                                r="0.463"
                                                                                                fill="#fff"/>
                                                                                    </g>
                                                                                    <g id="Group_1857"
                                                                                       data-name="Group 1857"
                                                                                       transform="translate(1.85 12.025)">
                                                                                        <circle id="Ellipse_98"
                                                                                                data-name="Ellipse 98"
                                                                                                cx="0.463" cy="0.463"
                                                                                                r="0.463"
                                                                                                fill="#fff"/>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                                <g id="Group_1866" data-name="Group 1866"
                                                                   transform="translate(4.162 6.475)">
                                                                    <g id="Group_1865" data-name="Group 1865">
                                                                        <g id="Group_1864" data-name="Group 1864">
                                                                            <g id="Group_1863" data-name="Group 1863">
                                                                                <path id="Path_1682"
                                                                                      data-name="Path 1682"
                                                                                      d="M300.237,109.475a1.389,1.389,0,0,1-1.387-1.387v-.463h-.462a1.388,1.388,0,1,1,0-2.775h.462v-.463a1.387,1.387,0,1,1,2.775,0v.463h.462a1.388,1.388,0,1,1,0,2.775h-.462v.463A1.389,1.389,0,0,1,300.237,109.475Zm-1.85-3.7a.462.462,0,1,0,0,.925h.925a.462.462,0,0,1,.462.463v.925a.463.463,0,0,0,.925,0v-.925a.462.462,0,0,1,.462-.463h.925a.462.462,0,0,0,0-.925h-.925a.462.462,0,0,1-.462-.463v-.925a.463.463,0,0,0-.925,0v.925a.462.462,0,0,1-.462.463Z"
                                                                                      transform="translate(-297 -103)"
                                                                                      fill="#fff"/>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                            <p>Appointment</p>

                                        </ul>
                                    </div>
                                </div>
                            </a></li>
                        </div>

                        <li><Link to={'/nhf-ccd'}>
                            <div className={`theme-button theme-button-three`}>
                                <div className="moon">
                                    <ul>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             xmlnsXlink="http://www.w3.org/1999/xlink"
                                             width="18.5" height="18.5" viewBox="0 0 18.5 18.5">

                                            <g id="Mask_Group_284" data-name="Mask Group 284"
                                               transform="translate(0 -518.925)">
                                                <g id="_3-Smartphone" data-name="3-Smartphone"
                                                   transform="translate(1.654 520.035)">
                                                    <g id="Group_1850" data-name="Group 1850">
                                                        <g id="Group_1848" data-name="Group 1848">
                                                            <g id="Group_1847" data-name="Group 1847">
                                                                <g id="Group_1846" data-name="Group 1846">
                                                                    <g id="Group_1845" data-name="Group 1845">
                                                                        <g id="Group_1844" data-name="Group 1844">
                                                                            <g id="Group_1841" data-name="Group 1841">
                                                                                <g id="Group_1840"
                                                                                   data-name="Group 1840">
                                                                                    <g id="Group_1839"
                                                                                       data-name="Group 1839">
                                                                                        <g id="Group_1837"
                                                                                           data-name="Group 1837">
                                                                                            <path id="Path_1677"
                                                                                                  data-name="Path 1677"
                                                                                                  d="M301.158,61.28h-7.122A2.037,2.037,0,0,1,292,59.245V47.035A2.037,2.037,0,0,1,294.035,45h7.122a2.037,2.037,0,0,1,2.035,2.035v12.21A2.037,2.037,0,0,1,301.158,61.28Zm-7.122-15.263a1.019,1.019,0,0,0-1.017,1.017v12.21a1.019,1.019,0,0,0,1.017,1.017h7.122a1.019,1.019,0,0,0,1.017-1.017V47.035a1.019,1.019,0,0,0-1.017-1.017Z"
                                                                                                  transform="translate(-292 -45)"
                                                                                                  fill="#fff"/>
                                                                                        </g>
                                                                                        <g id="Group_1838"
                                                                                           data-name="Group 1838"
                                                                                           transform="translate(3.053 2.035)">
                                                                                            <path id="Path_1678"
                                                                                                  data-name="Path 1678"
                                                                                                  d="M300.544,50.017h-2.035a.509.509,0,1,1,0-1.017h2.035a.509.509,0,0,1,0,1.017Z"
                                                                                                  transform="translate(-298 -49)"
                                                                                                  fill="#fff"/>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                            <g id="Group_1842" data-name="Group 1842"
                                                                               transform="translate(7.122 2.035)">
                                                                                <circle id="Ellipse_93"
                                                                                        data-name="Ellipse 93"
                                                                                        cx="0.509" cy="0.509" r="0.509"
                                                                                        fill="#fff"/>
                                                                            </g>
                                                                            <g id="Group_1843" data-name="Group 1843"
                                                                               transform="translate(5.088 13.227)">
                                                                                <circle id="Ellipse_94"
                                                                                        data-name="Ellipse 94"
                                                                                        cx="0.509" cy="0.509" r="0.509"
                                                                                        fill="#fff"/>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                        <g id="Group_1849" data-name="Group 1849"
                                                           transform="translate(2.035 4.579)">
                                                            <path id="Path_1679" data-name="Path 1679"
                                                                  d="M299.561,61.123a1.528,1.528,0,0,1-1.526-1.526v-.509h-.509a1.526,1.526,0,0,1,0-3.053h.509v-.509a1.526,1.526,0,1,1,3.052,0v.509h.509a1.526,1.526,0,0,1,0,3.053h-.509V59.6A1.528,1.528,0,0,1,299.561,61.123Zm-2.035-4.07a.509.509,0,0,0,0,1.018h1.018a.509.509,0,0,1,.509.509V59.6a.509.509,0,1,0,1.017,0V58.579a.509.509,0,0,1,.509-.509H301.6a.509.509,0,0,0,0-1.018h-1.018a.509.509,0,0,1-.509-.509V55.526a.509.509,0,1,0-1.017,0v1.017a.509.509,0,0,1-.509.509Z"
                                                                  transform="translate(-296 -54)" fill="#fff"/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>

                                        <p>NHF CCD</p>
                                    </ul>
                                </div>
                            </div>
                        </Link></li>
                    </div>
                )}
            </StyledMessage>
            <AppointmentPopUp show={show} handleShow={handleShow} handleClose={handleClose}/>
        </>
    );


};
const StyledMessage = styled.div`
  position: relative;
  z-index: 10 !important;

  .theme-button {
    cursor: pointer;
    height: 80px;
    width: 100px;
    //border-top-left-radius: 35px;
    //border-bottom-left-radius: 35px;
    background: #98223C;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 1s all cubic-bezier(.25, .74, .22, .99);
    position: relative;
    overflow: hidden;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    padding: 12px;
    border-bottom: 1px solid rgb(255 255 255 / 20%);

    .moon {
      transition: 1s all cubic-bezier(.25, .74, .22, .99);
      margin: auto;
      inset: 0px;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      //transform: rotate(-90deg);
      p {
        padding-top: 10px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
      }
    }
    @media(max-width: 767px){
      width: 40px;
      height: 50px;
      .moon{
        p{
          display: none;
        }
      }
    }
  }

  .theme-button-three {
    background: #E72E4B;
  }
`;


export default React.memo(AnimatedIconMoon);
