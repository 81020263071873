import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Form, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data, search, cat}) => {

    // filter API data
    const ClinicalservicesList = data;

    return (
        <StyledComponent id={'got-to-list'} className={'article-list pb-200'}>
            <Container>
                <Row className={'min-height'}>
                    {ClinicalservicesList?.length > 0 && ClinicalservicesList?.map((i, index) => (
                        <Col sm={'5'} className={'blog'}>
                            <Link to={`/hospital-activities/:slug?category=${reactHtmlParser(i?.page_data?.slug)}`}>
                                <a>
                                    <div className='blog-single fade-up'>
                                        <div className="blog-single__inner">
                                            {/*<Link to={'/blog-details'}></Link>*/}
                                            <div className="image-wrapper">
                                                <Img src={i?.images?.list[0]?.full_path}/>
                                            </div>
                                            <div className="blog-single__inner__content" data-index={index + 1}>
                                                <div className="blog-single__inner__content__top">
                                                    <h2 className={'split-up'}>{i?.page_data?.title}</h2>

                                                    <div>
                                                        <p>{reactHtmlParser(i?.page_data?.short_desc)}</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </Link>
                        </Col>
                    ))}

                </Row>

            </Container>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  .swiper-button-disabled {
    opacity: 0.5 !important;
  }

  margin-top: 40px;


  .short-desc {
    //padding-left: 0;
    font-size: 28px;
    font-weight: 300;
    line-height: 36px;
    color: #3C3C3B;
    margin-bottom: 75px;
    display: flex;
    justify-content: flex-end;

    h5 {
      color: #1E1E2E;
    }

    @media (max-width: 767px) {
      margin-bottom: 40px;
      justify-content: unset;
    }
  }

  .services {
    padding-bottom: 60px;
    @media (max-width: 767px) {
      padding-bottom: 0;
      h5 {
        padding-bottom: 40px;
      }
    }
  }

  .blog {
    margin: 40px 40px 40px 40px;
    padding: 0;
  }

  @media (max-width: 767px) {
    .blog {
      margin: 0;
      padding: 15px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .blog {
      margin: 0 0 0 40px;
      padding: 0;
    }
  }

  .blog:nth-last-child(-n+2) {
    margin-bottom: 0;
  }


  .image-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50%;
    padding-top: calc(50%);
  }

  .blog-button {
    display: flex;
    justify-content: space-between;
    //margin-bottom: 85px;
    align-items: center;
    //position: absolute;
    //top: -160px;
    //left: 15px;

    .slider-nav {
      ul {
        display: flex;

        li {
          cursor: pointer;

          &:first-child {
            margin-right: 20px;
          }

          svg {
            height: 41px;

            g, line {
              stroke: black;
              transition: stroke 0.2s ease;
            }

            path, #Rectangle_5661 {
              //transition: stroke-dasharray 0.3s ease-in-out;
              transition: all 1s cubic-bezier(.25, .74, .22, .99);
            }
          }

          &:hover {
            svg {
              g, line {
                stroke: #E72E4B;
                transition: stroke 0.2s ease;
              }

              #Rectangle_5661 {
                opacity: 1;
                stroke-dasharray: 140;
              }
            }
          }
        }
      }
    }

    @media (min-width: 768px) and (max-width: 991px) {
      align-items: flex-start
    }
  }


  .swiper-slide {
    overflow: hidden;
  }

  .swiper-button-next, .swiper-button-prev, .swiper-pagination {
    opacity: 0;
    visibility: hidden;
  }

  .swiper-initialized {
    margin-left: ${props => props.offset + 15}px;
    padding-right: 25%;
  }

  .slider-nav-mobile {
    display: none;
  }


  .blog-single__inner {
    padding-top: calc(470 / 470 * 100%);
    position: relative;


    a {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 3;
    }

    &__content {

      //counter-reset: counter 0;
      position: absolute;
      top: 0;
      height: 50%;
      right: 0;
      left: 0;
      transition: 1s all cubic-bezier(.25, .74, .22, .99);

      &:after {
        content: '';
        height: 100%;
        width: 100%;
        background-color: #1E1E2E;
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
        transition: 1s all cubic-bezier(.25, .74, .22, .99);

      }

      &:before {
        content: attr(data-index);
        position: absolute;
        z-index: 99;
        left: 35px;
        color: #FFFFFF;
        font-size: 140px;
        line-height: 140px;
        font-weight: 400;
        bottom: -75px;
        //top: 50%;
        //transform: translateY(25%);
        transition: 1s all cubic-bezier(.25, .74, .22, .99);

      }

      &__top {
        p {
          font-size: 16px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 20px;
          top: 35%;
          position: absolute;
          left: 40px;
          z-index: 2;
          right: 40px;
          margin: 0;
          transform: translateY(100%);
          opacity: 0;
          transition: all 1s cubic-bezier(.25, .74, .22, .99);
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }

        h2 {
          position: absolute;
          top: 40px;
          left: 40px;
          right: 40px;
          z-index: 2;
          font-size: 32px;
          font-weight: 500;
          line-height: 36px;
          letter-spacing: -1px;
          color: #FFFFFF;
          margin: 0;
          transition: all 1s cubic-bezier(.25, .74, .22, .99);
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }
      }

      &__bottom {
        position: absolute;
        margin: 0;
        left: 40px;
        right: 40px;
        bottom: 24px;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #221F1F;
        padding-top: 20px;
        z-index: 2;

        h4 {
          font-size: 60px;
          font-weight: 600;
          color: black;
          line-height: 60px;
          transition: color 1s cubic-bezier(.25, .74, .22, .99);
        }

        p {
          font-size: 12px;
          color: black;
          text-align: right;
          font-weight: 600;
          line-height: 18px;
          transition: color 1s cubic-bezier(.25, .74, .22, .99);

          span {
            display: block;
            color: black;
          }
        }
      }
    }

    &:hover {
      .blog-single__inner__content:after {
        height: 100%;
      }

      .blog-single__inner__content:before {
        transform: translateY(80%);
        opacity: 0;
      }

      .blog-single__inner__content {
        height: 100%;
      }

      .blog-single__inner__content__top {
        h2 {
          //opacity: 0;
          //transform: translateY(-20px);
        }

        p {
          transform: none;
          opacity: 1;
        }
      }

      .blog-single__inner__content__bottom {
        border-color: #FFF;

        h4 {
          color: #ffffff;
        }

        p {
          color: #ffffff;

          span {
            color: #ffffff;
          }
        }
      }
    }
  }

  .swiper {
    @media (max-width: 767px) {
      padding-bottom: 120px;
      background-color: #fff;
    }
  }

  @media (max-width: 992px) and (min-width: 768px) {
    .blog-single__inner__content::before {
      font-size: 60px;
      line-height: 122px;

      bottom: -66px;

    }

    .blog-single__inner__content__top
    h2 {
      font-size: 16px;
    }
  }

  @media (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 0;

    .swiper-initialized {
      margin-left: 0;
      padding-right: 0;
    }

    //.mobile-reverse{
    //  flex-direction: column-reverse;
    //}
    .blog-slider {
      &__slider-wrap {
        margin-left: 0px;
        margin-right: 0px;

        .slider-nav-mobile {
          margin-top: 40px;

          ul {
            display: flex;
          }

          li {
            height: 50px;
            width: 50px;
            background-color: #000;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;

            &:nth-of-type(1) {
              margin-right: 20px;
            }

            svg {
              color: #ffffff;
              z-index: 2;
            }
          }
        }
      }
    }

    .blog-button {
      margin-bottom: 40px;

      .slider-nav {
        //display: none;
      }
    }

    .slider-nav-mobile {
      //display: block;
    }

  }

`;
export default React.memo(MyComponent);