import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import ButtonOutlineHover from "../ButtonShort";
import reactHtmlParser from "react-html-parser";


const MyComponent = ({data}) => {


    // filter data
    const OnlinesupportData = data?.section_data;

    // padding-left offset

    const [offset, setOffset] = useState(90)

    useEffect(() => {


        window.addEventListener('load', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [data])
    return (
        <StyledComponent className={'pb-200 pt-200'} offset={offset}>
            <Container fluid>
                <Row>
                    <Col md={6} className={'onlinesupport__left'}>
                        <div className={'onlinesupport__left__inner'}>
                            <h4 className={'split-up'}>{OnlinesupportData?.subtitle ? OnlinesupportData?.subtitle :''}</h4>
                            <p className={'split-up'}>{reactHtmlParser(OnlinesupportData?.description ? OnlinesupportData?.description : '')}</p>
                            <ButtonOutlineHover src={'/online-support-details'} color={'black'} text={'Read More'} icon={'black'} hoverColor={'#E72E4B'} margin={'60px 0 0 0'} marginMobile={'60px 0 0 0'}/>
                        </div>
                    </Col>
                    <Col md={{span: 5, offset: 1}} className={'onlinesupport__right'}>
                        <div className={'onlinesupport__right__inner'}>
                            <Img src={'/images/dynamic/onlinesupportnew.jpg'}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
background-color: #F6F9F8;

  .onlinesupport {
    &__left {
      display: flex;
      align-items: center;
      padding-left: ${props => props.offset ? props.offset + 15 + 'px' : '90px'};
    &__inner{
      h4 {
        max-width: 80%;
        padding-bottom: 60px;
      }
      p{
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    }

    &__right {
      padding-right: 0;
     &__inner{
       position: relative;
       padding-top: calc(568 / 568 * 100%);
     }
    }
  }
 
  @media (max-width: 767px){
    .onlinesupport {
      &__left {
        padding: 0 15px 60px 15px;
        &__inner{
          h4 {
            padding-bottom: 60px;
          }
        }
      }

      &__right {
        padding-right: 15px;
      }
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px){
    padding-top: 120px;
  }

`;

export default React.memo(MyComponent);
