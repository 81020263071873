import {createGlobalStyle, css} from 'styled-components';
import {hover, text, title} from './globalStyleVars';

function createCSS() {
    let styles = '';

    for (let i = 2; i < 20; i += 1) {
        styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * .12}s;
        }
     `
    }

    for (let a = 2; a < 100; a += 1) {
        styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * .03}s;
        }
     `
    }

    return css`${styles}`;
}

export default createGlobalStyle`

  ${createCSS()}
  #root {
    min-height: 100vh;
    overflow-x: hidden;
  }

  @font-face {
    font-family: 'Haffer';
    src: url('/fonts/Haffer-SemiBold.woff2') format('woff2'),
    url('/fonts/Haffer-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Haffer';
    src: url('/fonts/Haffer-Medium.woff2') format('woff2'),
    url('/fonts/Haffer-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Haffer';
    src: url('/fonts/Haffer-Regular.woff2') format('woff2'),
    url('/fonts/Haffer-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }


  body {
    font-family: "Haffer", Helvetica, freesans, sans-serif !important;
    font-style: normal;
    margin: 0;
    color: ${text};
    padding: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    font-size: 16px;
    line-height: 24px;
  }

  a {
    transition: color .3s ease;
    text-decoration: none;

    &:hover {
      color: ${hover} !important;
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }

    &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
      color: ${text};
    }
  }

  ::selection {
    background: ${hover};
    color: #FFF;
  }

  p, a, h4, h3, h5, h6 {
    color: #1E1E2E;
    font-weight: 400;
    margin: 0;
  }

  h1, h2 {
    font-family: ${title};
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0
  }

  li {
    list-style: none;
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }


  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    font-family: ${title};
  }

  h1 {
    font-size: 80px;
    font-weight: 400;
    line-height: 80px;
    font-family: ${title};
    @media (max-width: 767px) {
      font-size: 50px;
      font-weight: 400;
      line-height: 50px;
    }
  }

  h2 {
    font-size: 60px;
    font-weight: 400;
    line-height: 60px;
    font-family: ${title};
    @media (max-width: 767px) {
      font-size: 42px;
      font-weight: 400;
      line-height: 42px;
    }
  }

  h3 {
    font-size: 40px;
    font-weight: 700;
    line-height: 44px;
    font-family: ${title};
    @media (max-width: 767px) {
      font-size: 36px;
      font-weight: 700;
      line-height: 36px;
    }
  }

  h4 {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    font-family: ${title};
    @media (max-width: 767px) {
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
    }
  }

  h5 {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    font-family: ${title};
    @media (max-width: 767px) {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
    }
  }

  h6 {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    font-family: ${title};
    @media (max-width: 767px) {
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
    }
  }


  .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none;
    box-shadow: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid rgba(0, 0, 0, 0);
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
  }


  table {
    width: 100%;
  }

  form div {
    position: relative;
  }

  .form-control {
    box-shadow: none;
    outline: 0;
    border-radius: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .p-0 {
    padding: 0 !important;
  }

  .pl-0 {
    padding-left: 0;
  }

  .pr-0 {
    padding-right: 0;
  }

  .pt-200 {
    padding-top: 200px;
    @media (max-width: 767px) {
      padding-top: 120px;
    }
  }

  .pb-200 {
    padding-bottom: 200px;
    @media (max-width: 767px) {
      padding-bottom: 120px;
    }
  }

  .pb-300 {
    padding-bottom: 300px;
    @media (max-width: 767px) {
      padding-bottom: 150px;
    }
  }

  .pt-160 {
    padding-top: 160px;
    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }

  .pt-120 {
    padding-top: 120px;
    @media (max-width: 767px) {
      padding-top: 60px !important;
    }
  }

  .pt-150 {
    padding-top: 150px;
    @media (max-width: 767px) {
      padding-top: 75px;
    }
  }

  .pb-160 {
    padding-bottom: 160px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pb-130 {
    padding-bottom: 130px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-100 {
    padding-top: 100px;
    @media (max-width: 767px) {
      padding-top: 60px;
    }
  }

  .pb-100 {
    padding-bottom: 100px;
    @media (max-width: 767px) {
      padding-bottom: 60px;
    }
  }

  .pt-80 {
    padding-top: 80px;
    @media (max-width: 767px) {
      padding-top: 40px;
    }
  }

  .pb-80 {
    padding-bottom: 80px;
    @media (max-width: 767px) {
      padding-bottom: 40px;
    }
  }


  .MuiDrawer-paper {
    width: 500px !important;
    padding: 20px;
    @media (max-width: 767px) {
      width: 100% !important;
    }
  }

  .swiper-button-disabled {
    opacity: 0 !important;
  }

  @media (min-width: 1500px) {
    .container {
      //min-width: 1366px;
      min-width: 85%;
      margin: auto;
    }
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    .container, .container-lg, .container-md, .container-sm {
      max-width: 90%;
      margin: auto;
    }
  }


  @media (max-width: 767px) {
    .container, .container-sm {
      max-width: 100%;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


  //video modal

  .modal_video_popup {
    .modal-content, .modal-video, .modal-dialog {
      background: transparent !important;
      border: none !important;
      box-shadow: none !important;
      outline: none !important;

    }

    .modal-dialog {
      border: 0 !important;
      margin: 0 !important;
      max-width: unset !important;
      width: 100% !important;
      height: 100% !important;
    }

    .modal-body {
      border: none !important;
      box-shadow: none !important;
    }

    .modal-close {
      opacity: 0;
    }
  }


  //modal popup for Appointment


  .appointment {
    padding-left: 0 !important;
    z-index: 99999999;

    @media (min-width: 320px) {
      .modal-dialog {
        max-width: unset;
        margin: 0;
        height: 100vh;
      }
    }

    .modal-content {
      height: 100vh;
      background: #F5F5F5;
      color: #323232;
      @media (max-width: 767px) {
        height: 100vh;
        margin-bottom: 0;
        margin-top: 0;
      }

      .modal-body {
        background: white;
        padding: 0;

        .for-close {
          padding-top: 20px;
          padding-bottom: 20px;
          justify-content: flex-end;
          position: relative;
          right: -20px;
          @media (max-width: 767px) {
            right: 15px;
          }
        }
      }
    }

    .modal-close {
      border-radius: 50px;

      &:hover {
        svg {
          position: relative;
          z-index: 1;

          #Ellipse_18 {
            stroke: rgb(231, 46, 75);
            //opacity: 50%;
            transition: stroke .8s ease;
          }

          g {
            line {
              stroke: #ffffff !important;
              transition: stroke .5s ease;
            }
          }
        }
      }
    }
  }

  .profile-popup {
    .form {
      .form-group {
        .description {
          flex-direction: column !important;
          gap: 40px !important;
        }
      }
    }
  }

  .appointment {
    .details {
      padding-bottom: 100px;
    }

    .filter__control--is-focused {
      box-shadow: unset !important;
    }

    .left-col {
      padding-top: 90px;
      padding-right: 0px;

      @media (max-width: 767px) {
        padding-top: unset;
      }

      .image-wrapper-doctor {
        position: relative;
        padding-top: calc(458 / 370 * 100%);
      }

      h6 {
        padding-top: 20px;
      }

      p {
        font-size: 16px;
        padding-bottom: 6px;
        color: #1e1e2eb3;
      }

      .title {
        p {
          position: relative;
          padding-bottom: 20px;
          margin-bottom: 20px;
        }
      }

      .title-border {
        p {
          padding-left: 20px;

          &:before {
            content: "";
            position: absolute;
            top: 8px;
            left: 0;
            height: 10px;
            width: 10px;
            background: #978C21;
            border-radius: 50px;
          }
        }

        p:not(:last-child) {
          &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: 0;
            left: 0;
            background: rgb(30 30 46 / 20%);
          }
        }
      }

      .title:not(:first-child) {
        padding-top: 60px;
      }

      @media (max-width: 767px) {
        padding-right: 15px;

      }
    }


    .right-col {
      padding-left: 0px;
    }

    .image-wrapper {
      position: relative;
      padding-top: calc(604 / 600 * 100%);
      height: 100%;
    }

    .form_wrapper {

      h4 {
        color: rgb(231, 46, 75);
        padding-bottom: 40px;
      }

      .phone {
        p {
          color: rgb(30, 30, 46);
          margin-top: 40px !important;

          a {
            color: rgb(231, 46, 75);
            font-size: 20px;
            transition: font-size 0.5s ease;

            &:hover {
              font-size: 21px;
              transition: font-size 0.5s ease;
            }
          }
        }
      }

      form {
        display: flex;
        height: 100%;
        flex-direction: column;
        gap: 40px;
      }

      .title {
        font-size: 24px;
        font-weight: 4000;
        line-height: 28px;
        color: #F9F9F9;
        margin: 0 0 40px;
      }

      .form-group {
        margin-bottom: 0;

        @media (min-width: 768px) {
          .double-field {
            display: flex;
            gap: 70px;
          }
        }
        @media (max-width: 767px) {
          .double-field {
            input:not(:last-child), .dropdown:not(:last-child) {
              margin-bottom: 40px;
            }

            .genderdropdown {
              margin-bottom: 40px;
            }
          }
        }

        .dropdown {
          width: 100%;
          cursor: pointer;
          border-bottom: 1px solid #1e1e2e80;

          .filter__placeholder {
            font-size: 16px;
            color: #1e1e2e80;
          }

          .filter__single-value {
            color: rgb(30, 30, 46) !important;
            font-size: 14px !important;
            font-weight: 400 !important;
            line-height: 20px !important;
          }
        }

        .description {
          display: flex;
          gap: 70px;
          @media (min-width: 768px) {
            .form-control {
              padding: 0 0 20px;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        .form-control {
          box-shadow: none;
          outline: 0;
          border-radius: 0;
          background: transparent;
          height: auto;
          border-left: none;
          border-right: none;
          border-top: none;
          border-color: #1e1e2e80;
          padding: 0 0 20px;
          color: #1E1E2E;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;

          &::placeholder {
            color: #1e1e2e80;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }

      .dc-btn {
        a {
          background-color: transparent;
          border: 1px solid #ffffff36;

          &:before {
            background: #fff;
          }

          span {
            color: #FFFFFF;
          }

          &:hover {
            span {
              color: #3C3C3B;
            }
          }
        }
      }

      .filter__placeholder {
        color: #C4C4C4;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }

      .filter__control {
        cursor: pointer;
        height: 30px !important;
        padding: 0 !important;
        background: transparent !important;
        border-left: none !important;
        border-right: none !important;
        border-top: none !important;
        border-color: rgba(246, 246, 247, 0.2) !important;

        .filter__single-value {
          color: #C4C4C4 !important;
          font-size: 14px !important;
          font-weight: 400 !important;
          line-height: 20px !important;
        }

        .filter__indicator-separator {
          display: none;
        }

        .filter__value-container {
          padding: 0;
        }

        .filter__dropdown-indicator, .filter__input-container, .filter__placeholder {
          margin: 0;
        }
      }
    }

    .width-fit {
      display: flex;
      gap: 40px;

      .attachCvName {
        width: 38%;
        border-radius: 50px;
        border: 1px solid rgb(30 30 46 / 20%);
        border-style: dashed;

        @media (max-width: 767px) {
          width: 50%;
        }


        &:hover {
          &:before {
            inset: 0px;
            content: "";
            display: block;
            position: absolute;
            background-color: rgb(152, 34, 60);
            height: 0%;
            width: 0%;
            margin: auto;
            transition: all 0.5s ease 0s;
            border-radius: 22px;
          }
        }

        .attach-cv {
          position: relative;
          margin-left: 23%;

          @media (max-width: 767px) {
            margin-left: 13%;
          }

          svg {
            g {
              path {
                fill: #1E1E2E;
              }
            }
          }

          label {
            margin-left: 8px;
            margin-top: 8px;
            color: #1E1E2E;
          }
        }
      }

      .submit-button {
        a {
          border-radius: 50px;
          border: 1px solid rgb(30 30 46 / 20%);
          padding: 12px 36px;
          position: relative;

          &:after {
            position: absolute;
            background: #E72E4B;
            width: 100%;
            height: 100%;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: 0.5s all ease;
            transform: translateY(300px);;
            border-radius: 21px;
            z-index: 0;
          }

          span {
            color: #000;
            white-space: nowrap;
          }
        }

        &:hover {
          a {
            &:after {
              transform: scale(1);
            }

            span {
              color: #fff;
            }
          }
        }
      }
    }


    @media (max-width: 767px) {
      padding-top: 0;
      padding-bottom: 120px;
      .form_wrapper {
        padding: 0px 15px 15px;
        margin-top: 0px;
      }

      .left-col {
        padding-bottom: 40px;
        padding-right: 15px;
      }

      .container {
        padding: 15px;
      }

      .right-col {
        padding: 0px;
      }
    }

    @media (min-width: 768px) and (max-width: 991px) {
      .left-col {
        padding-bottom: 40px;
        padding-right: 0px;
      }

      .right-col {
        padding-left: 15px;
        padding-right: 0;
      }

    }
  }


  //modal pop-up for CCD submission 


  .submission-popup {
    padding-left: 0 !important;
    z-index: 99999999;

    @media (min-width: 320px) {
      .modal-dialog {
        max-width: unset;
        margin: 0;
        height: 100vh;
      }
    }

    .modal-content {
      height: 100vh;
      background: #F5F5F5;
      color: #323232;
      @media (max-width: 767px) {
        height: 100vh;
        margin-bottom: 0;
        margin-top: 0;
      }

      .modal-body {
        background: white;
        padding: 0;

        .for-close {
          padding-top: 20px;
          padding-bottom: 20px;
          justify-content: flex-end;
          right: -25px;
          position: relative;
          @media (max-width: 767px) {
            right: 15px;
          }
        }
      }
    }

    .modal-close {
      border-radius: 50px;

      &:hover {
        svg {
          position: relative;
          z-index: 1;

          #Ellipse_18 {
            stroke: rgb(231, 46, 75);
            //opacity: 50%;
            transition: stroke .8s ease;
          }

          g {
            line {
              stroke: #ffffff !important;
              transition: stroke .5s ease;
            }
          }
        }
      }
    }
  }

  .submission-popup {
    .details {
      padding-bottom: 100px;
    }

    .left-col {
      padding-right: 0px;

      h4 {
        padding-bottom: 60px;
      }

      h5 {
        padding-bottom: 20px;
        color: #98223C;
      }

      .title {
        p {
          position: relative;
          padding-bottom: 20px;
          margin-bottom: 20px;
        }
      }

      .title-border {
        p {
          padding-left: 20px;

          &:before {
            content: "";
            position: absolute;
            top: 8px;
            left: 0;
            height: 10px;
            width: 10px;
            background: #978C21;
            border-radius: 50px;
          }
        }

        p:not(:last-child) {
          &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: 0;
            left: 0;
            background: rgb(30 30 46 / 20%);
          }
        }
      }

      .title:not(:first-child) {
        padding-top: 60px;
      }
    }

    .right-col {
      padding-left: 0px;
    }

    .image-wrapper {
      position: relative;
      padding-top: calc(604 / 600 * 100%);
      height: 100%;
    }

    .form_wrapper {

      h4 {
        color: rgb(231, 46, 75);
        padding-bottom: 40px;
      }

      @media (min-width: 1550px) {

      }


      form {
        display: flex;
        height: 100%;
        flex-direction: column;
        gap: 40px;
      }

      .title {
        font-size: 24px;
        font-weight: 4000;
        line-height: 28px;
        color: #F9F9F9;
        margin: 0 0 40px;
      }

      .form-group {
        margin-bottom: 0;

        @media (min-width: 767px) {
          .file-name {
            width: 100px;
          }
        }

        .d-flex {
          gap: 40px;
          @media (max-width: 767px) {
            flex-direction: column;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .description {
        padding: 0 0 80px !important;
      }

      .form-control {
        box-shadow: none;
        outline: 0;
        border-radius: 0;
        background: transparent;
        height: auto;
        border-left: none;
        border-right: none;
        border-top: none;
        border-color: #1e1e2e80;
        padding: 0 0 20px;
        color: #1E1E2E;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;

        &::placeholder {
          color: #1e1e2e80;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }

    .dc-btn {
      a {
        background-color: transparent;
        border: 1px solid #ffffff36;

        &:before {
          background: #fff;
        }

        span {
          color: #FFFFFF;
        }

        &:hover {
          span {
            color: #3C3C3B;
          }
        }
      }
    }

    .filter__placeholder {
      color: #C4C4C4;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    .filter__control {
      height: 30px !important;
      padding: 0 !important;
      background: transparent !important;
      border-left: none !important;
      border-right: none !important;
      border-top: none !important;
      border-color: rgba(246, 246, 247, 0.2) !important;

      .filter__single-value {
        color: #C4C4C4 !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 20px !important;
      }

      .filter__indicator-separator {
        display: none;
      }

      .filter__value-container {
        padding: 0;
      }

      .filter__dropdown-indicator, .filter__input-container, .filter__placeholder {
        margin: 0;
      }
    }
  }

  .width-fit {
    display: flex;
    gap: 40px;

    .attachCvName {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 250px;
      border-radius: 50px;
      border: 1px solid rgb(30 30 46 / 20%);
      border-style: dashed;

      .gph_upload {
        padding-bottom: 0;
        border: unset;
      }

      @media (max-width: 767px) {
        width: 50%;
        padding-left: 0;

      }


      &:hover {
        &:before {
          inset: 0px;
          content: "";
          display: block;
          position: absolute;
          background-color: rgb(152, 34, 60);
          height: 0%;
          width: 0%;
          margin: auto;
          transition: all 0.5s ease 0s;
          border-radius: 22px;
        }
      }

      .attach-cv {
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: center;

        svg {
          cursor: pointer;

          g {
            path {
              fill: #1E1E2E;
            }
          }
        }

        label {
          cursor: pointer;
          margin-left: 8px;
          margin-top: 8px;
          color: #1E1E2E;
        }
      }
    }

    .submit-button {
      a {
        border-radius: 50px;
        border: 1px solid rgb(30 30 46 / 20%);
        padding: 12px 36px;
        position: relative;

        &:after {
          position: absolute;
          background: #E72E4B;
          width: 100%;
          height: 100%;
          content: '';
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          transition: 0.5s all ease;
          transform: translateY(300px);;
          border-radius: 21px;
          z-index: 0;
        }

        span {
          color: #000;
          white-space: nowrap;
        }
      }

      &:hover {
        a {
          &:after {
            transform: scale(1);
          }

          span {
            color: #fff;
          }
        }
      }
    }
  }


  @media (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 120px;
    .form_wrapper {
      padding: 60px 15px;
      margin-top: 0px;
    }

    .left-col {
      padding-bottom: 40px;
      padding-right: 15px;
    }

    .container {
      padding: 15px;
    }

    .right-col {
      padding: 0px;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .left-col {
      padding-bottom: 40px;
      padding-right: 0px;
    }

    .right-col {
      padding-left: 15px;
      padding-right: 0;
    }

  }

  @media (max-width: 767px) {
    .double-field__right input {
      margin-bottom: 0;
    }
  }

  .double-field {
    &__left {
      width: 100%;
    }

    &__right {
      width: 100%;
    }

    .date {
      color: rgba(30, 30, 46, 0.5) !important;
    }
  }


  //modal popup for career page
  .notice_modal {
    height: 100svh;

    @media (max-width: 767px) {
      .modal-data {
        margin-top: 80px !important;
        padding-right: 0 !important;
        margin-bottom: 60px;
      }

      .modal-data__content {
        padding: 0;
        height: 100% !important;
        overflow-x: inherit;
        overflow-y: hidden;

        h3 {
          margin-bottom: 40px;
        }
      }

    }

    .modal-content {
      border-radius: 0 !important;
      background: transparent !important;
      padding: unset !important;
      height: 100vh;
      @media (max-width: 767px) {
        justify-content: center;
      }
    }

    .modal-close {
      position: absolute;
      top: 20px;
      right: 30px;
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      height: 45px;
      width: 45px;
      border: 1px solid rgb(196, 196, 196);
      border-radius: 50%;
      cursor: pointer;
      z-index: 9;
      background: white;

      &:hover {
        svg {
          position: relative;
          z-index: 1;

          g {
            line {
              stroke: #ffffff !important;
              transition: stroke .5s ease;
            }
          }
        }
      }

      @media (max-width: 767px) {
        top: calc(100svh - 50% - 268px);;
      }
    }

    .modal-dialog {
      max-width: 100%;
      margin: 0px 0px 0px auto;
      height: 100%;
      @media (max-width: 992px) {
        max-width: 100%;
        padding-top: 1px;

      }
    }

    .modal-data {
      margin-top: 0 !important;
      padding-right: 100px;
      @media (max-width: 767px) {
        margin-top: 80px;
      }
    }

    .notice_image {
      max-width: 700px;
      margin: auto;
    }

    .bod_img {
      position: relative;
      padding-top: calc(620 / 447 * 100%);

      img {
        padding: 100px 50px 50px;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }

      @media (max-width: 767px) {
        img {
          padding: unset;
        }
      }
    }


    .modal-data.d-flex {
      flex-direction: column;
      display: block !important;
      @media (max-width: 992px) {
        padding-left: 0px;
        margin-top: 40px;


      }

      h3 {
        font-family: ${title};
        color: #180E81;
        margin-bottom: 10px !important;

      }

      .deg {
        margin-bottom: 40px !important;

        color: rgba(79, 97, 107, 0.5) !important;
      }
    }

    .modal-body {
      position: relative;
      padding: 1rem;
      //padding-top: 100px;
      overflow: hidden;

      .row {
        height: 100%;

        .notice_image {
          height: 100%;
        }
      }

      @media (max-width: 767px) {
        padding-top: 100px;
      }

    }
  }

  .career-popup {
    padding-left: 0 !important;
    z-index: 99999999;

    @media (min-width: 320px) {
      .modal-dialog {
        max-width: unset;
        margin: 0;
        height: 100vh;
      }
    }

    .modal-content {
      height: 100vh;
      background: #F5F5F5;
      color: #323232;
      @media (max-width: 767px) {
        height: 100vh;
        margin-bottom: 0;
        margin-top: 0;
      }

      .modal-body {
        background: white;
        padding: 0;

        .for-close {
          padding-top: 20px;
          padding-bottom: 20px;
          justify-content: flex-end;
          right: -25px;
          position: relative;
          @media (max-width: 767px) {
            right: 15px;
          }
        }
      }
    }

    .modal-close {
      border-radius: 50px;

      &:hover {
        svg {
          position: relative;
          z-index: 1;

          #Ellipse_18 {
            stroke: rgb(231, 46, 75);
            //opacity: 50%;
            transition: stroke .8s ease;
          }

          g {
            line {
              stroke: #ffffff !important;
              transition: stroke .5s ease;
            }
          }
        }
      }
    }
  }

  //form upload button
  .gph_upload {
    position: relative;
    cursor: pointer;
    border: none !important;
    //border-radius: 25px !important;
    overflow: hidden !important;
    padding: 12px;
    text-indent: 0 !important;
    border-radius: 25px;

    &.hide {
      &:before {
        opacity: 0;
      }
    }

    &:after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      content: attr(text);
      background: #ffffff;
      z-index: 1;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      color: #373737;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.7s all ease;
      //padding-left: 20px;
    }


    @media (min-width: 1024px) and (max-width: 1280px) {
      &:after {
        //padding-left: 20px;
      }
    }
  }


  .career-popup {

    .modal-close {

      border-radius: 50px;

      &:hover {
        svg {
          position: relative;
          z-index: 1;

          #Ellipse_18 {
            stroke: rgb(231, 46, 75);
            //opacity: 50%;
            transition: stroke .8s ease;
          }

          g {
            line {
              stroke: #ffffff !important;
              transition: stroke .5s ease;
            }
          }
        }
      }
    }

    .details {
      padding-bottom: 100px;
    }

    .left-col {
      padding-right: 0px;

      h4 {
        padding-bottom: 60px;
      }

      h5 {
        padding-bottom: 20px;
        color: #98223C;
      }

      .title {
        p {
          position: relative;
          padding-bottom: 20px;
          margin-bottom: 20px;
        }
      }

      .title-border {
        p {
          padding-left: 20px;

          &:before {
            content: "";
            position: absolute;
            top: 8px;
            left: 0;
            height: 10px;
            width: 10px;
            background: #978C21;
            border-radius: 50px;
          }
        }

        p:not(:last-child) {
          &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: 0;
            left: 0;
            background: rgb(30 30 46 / 20%);
          }
        }
      }

      .title:not(:first-child) {
        padding-top: 60px;
      }
    }

    .right-col {
      padding-left: 0px;
    }

    .image-wrapper {
      position: relative;
      padding-top: calc(604 / 600 * 100%);
      height: 100%;
    }

    .form_wrapper {

      h4 {
        color: #1E1E2E;
        padding-bottom: 40px;
      }

      @media (min-width: 1550px) {

      }


      form {
        display: flex;
        height: 100%;
        flex-direction: column;
        gap: 40px;
      }

      .title {
        font-size: 24px;
        font-weight: 4000;
        line-height: 28px;
        color: #F9F9F9;
        margin: 0 0 40px;
      }

      .form-group {
        margin-bottom: 0;

        &:last-child {
          margin-bottom: 0;
        }

        .description {
          padding: 0 0 80px !important;
        }

        .form-control {
          box-shadow: none;
          outline: 0;
          border-radius: 0;
          background: transparent;
          height: auto;
          border-left: none;
          border-right: none;
          border-top: none;
          border-color: #1e1e2e80;
          padding: 0 0 20px;
          color: #1E1E2E;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;

          &::placeholder {
            color: #1e1e2e80;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }

      .dc-btn {
        a {
          background-color: transparent;
          border: 1px solid #ffffff36;

          &:before {
            background: #fff;
          }

          span {
            color: #FFFFFF;
          }

          &:hover {
            span {
              color: #3C3C3B;
            }
          }
        }
      }

      .filter__placeholder {
        color: #C4C4C4;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }

      .filter__control {
        height: 30px !important;
        padding: 0 !important;
        background: transparent !important;
        border-left: none !important;
        border-right: none !important;
        border-top: none !important;
        border-color: rgba(246, 246, 247, 0.2) !important;

        .filter__single-value {
          color: #C4C4C4 !important;
          font-size: 14px !important;
          font-weight: 400 !important;
          line-height: 20px !important;
        }

        .filter__indicator-separator {
          display: none;
        }

        .filter__value-container {
          padding: 0;
        }

        .filter__dropdown-indicator, .filter__input-container, .filter__placeholder {
          margin: 0;
        }
      }
    }

    .width-fit {
      display: flex;
      gap: 40px;

      .attachCvName {
        width: 169px;
        cursor: pointer;
        border-radius: 50px;
        gap: 5px;
        border: 1px solid rgb(30 30 46 / 20%);
        border-style: dashed;

        .attach-cv {
          position: relative;
          gap: 5px;
          height: 41px;
          display: flex;
          justify-content: center;
          align-content: center;
          align-items: center;
          cursor: pointer;
          padding-left: 12px;
          padding-right: 12px;

          input {
            margin-left: 15%;
            position: relative;
            cursor: pointer;
            border: none !important;
            //border-radius: 25px !important;
            overflow: hidden !important;
            padding: 12px;
            text-indent: 0 !important;
            border-radius: 25px !important;
            height: 0 !important;

            &.hide {
              &:before {
                opacity: 0;
              }
            }

            &::after {
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0px;
              top: 0px;
              content: attr(text);
              background: #fff;
              z-index: 1;
              font-size: 16px;
              line-height: 20px;
              font-weight: 400;
              color: #000;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: 0.7s all ease;
              //padding-left: 20px;
            }
          }

          @-moz-document url-prefix() {
            input {
              height: fit-content !important;
            }
          }

          p {
            position: absolute;
            bottom: -25px;
            color: #000;
            font-size: 12px;
            //left: 0;
          }

          svg {
            z-index: 100;
            position: absolute;
            left: 15%;

            g {
              path {
                fill: black;
              }
            }
          }

          label {
            cursor: pointer;
            margin-bottom: 0;
            margin-top: 0;
            color: black;
          }
        }
      }

      .small-screen {
        display: none;
      }

      .all-screen {
        a {
          height: unset !important;

          svg {
            g {
              stroke: black !important;
            }

            text {
              fill: black !important;
            }
          }
        }
      }

      @media (min-width: 991px) and (max-width: 1150px) {
        .all-screen {
          display: none;
        }

        .small-screen {
          display: block;

          .dc-btn {
            a {
              padding: 12px 36px;
              background-color: transparent;
              border: 1px solid #000000;

              &:before {
                background: #000;
              }

              span {
                color: #000;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
              }

              svg {
                g {
                  stroke: black !important;
                }

                text {
                  fill: black !important;
                }
              }

              &:hover {
                span {
                  color: #3C3C3B;
                }
              }
            }
          }
        }
      }

      .submit-button {
        a {
          border-radius: 50px;
          border: 1px solid rgb(30 30 46 / 20%);
          padding: 12px 36px;
          position: relative;

          &:after {
            position: absolute;
            background: #E72E4B;
            width: 100%;
            height: 100%;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: 0.5s all ease;
            transform: translateY(300px);;
            border-radius: 21px;
            z-index: 0;
          }

          span {
            color: #000;
            white-space: nowrap;
          }
        }

        &:hover {
          a {
            &:after {
              transform: scale(1);
            }

            span {
              color: #fff;
            }
          }
        }
      }
    }


    @media (max-width: 767px) {
      padding-top: 0;
      padding-bottom: 120px;
      .form_wrapper {
        padding: 60px 15px;
        margin-top: 0px;
      }

      .left-col {
        padding-bottom: 40px;
        padding-right: 15px;
      }

      .container {
        padding: 15px;
      }

      .right-col {
        padding: 0px;
      }
    }

    @media (min-width: 768px) and (max-width: 991px) {
      .left-col {
        padding-bottom: 40px;
        padding-right: 0px;
      }

      .right-col {
        padding-left: 15px;
        padding-right: 0;
      }

    }
  }

  .career_upload_pop {
    //position: relative;
    //cursor: pointer;
    //border: none !important;
    ////border-radius: 25px !important;
    //overflow: hidden !important;
    //padding: 12px;
    //text-indent: 0 !important;
    //border-radius: 25px !important;
    //height: 0px !important;
    //
    //&.hide {
    //  &:before {
    //    opacity: 0;
    //  }
    //}
    //
    //&::after {
    //  position: absolute;
    //  left: 0;
    //  right: 0;
    //  bottom: 0px;
    //  top: 0px;
    //  content: attr(text);
    //  background: #fff;
    //  z-index: 1;
    //  font-size: 16px;
    //  line-height: 20px;
    //  font-weight: 400;
    //  color: #000;
    //  display: flex;
    //  align-items: center;
    //  justify-content: center;
    //  transition: 0.7s all ease;
    //  //padding-left: 20px;
    //}
    //
    //
    //@media (min-width: 1024px) and (max-width: 1280px) {
    //  &:after {
    //    //padding-left: 20px;
    //  }
    //}
  }


  //react select
  .css-yk16xz-control, .css-1pahdxg-control {
    height: 50px;
    border-radius: 0 !important;
    padding-left: 5px;
    font-size: 16px;
    outline: none !important;
    border-color: #D9D9D9 !important;
    box-shadow: none !important;

    .css-1wa3eu0-placeholder {
      font-weight: 300;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.5);
      outline: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
      margin-right: 10px;
    }
  }

  .css-2613qy-menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
  }

  //animation class


  .info-window {
    max-width: 200px;
  }

  .gm-style-iw {
    border-radius: 0 !important;
  }

  .swiper-pagination-bullet {
    outline: none;
  }

  .css-nmuc1a-menu {
    z-index: 5 !important;
  }


  .map-info__img {
    img {
      height: 100px;
      margin-bottom: 12px;
      object-fit: cover;
    }
  }

  .map-info__content {
    h4 {
      font-size: 20px;
    }

    p {
      margin-bottom: 5px;
    }
  }

  .overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    //background-color: rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    display: none;

    &.show {
      display: block;
    }
  }

  .form-control.has-error {
    border-color: #dc004e !important;
  }

  .has-error .find-retainer-filter__control {
    border-color: #dc004e !important;
  }

  //preloader
  .content-loader {
    position: absolute;
    height: 70%;
    width: 70%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .loading-before-submit {
    position: fixed;
    height: 100vh;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }
  }


  .swiper-slide {
    height: auto;
  }

  .slick-slide {
    div {
      outline: none !important
    }
  }

  button, button:active, button:focus, button:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }


  .hover {
    position: relative;
    overflow: hidden;

    span {
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: -100px;
      margin: auto;
      background-color: ${hover};
      transition: all .5s ease;
      border-radius: 19px;
    }

    &:hover {
      &:after {
        bottom: 0;
      }
    }
  }


  .modal-backdrop {
    background-color: rgb(34 31 31 / 90%);
    min-width: 100%;
    //z-index: 9999;

    &.show {
      opacity: 1;
    }
  }


  .valid {
    color: ${hover};
    position: absolute;
    font-size: 12px;
    top: 44px;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }


  //sticky menu

  //menu fixed

  .scroll-down .main-menu-desktop, .scroll-down .Mobile-menu-wrap {
    transform: translate3d(0, -105%, 0);
    transition: all 0.5s ease-in-out;

    .header__right__logo {
      p {
        color: black;
        transition: color 0.5s ease;
        transition-delay: 1s;
      }

      .hamburger-icon span {
        background: #000;
        transition: all 0.5s ease;
        transition-delay: 1s;
      }
    }


    .logo {
      svg {
        g {
          #Path_8654 {
            transition: fill 0.5s ease 1s !important;
          }
        }

        #Path_8732 {
          transition: 0.5s fill ease;
        }
      }
    }

  }


  .scroll-up .main-menu-desktop, .scroll-up .Mobile-menu-wrap {
    transition-delay: 10s;
    background-color: #FFFFFF;
    transition: all 0.5s ease-in-out 0s;

    &:after {
      content: '';
      position: absolute;
      height: 1px;
      width: 100%;
      bottom: 0;
      left: 0;
      background: #191b1d1a;
    }


    .header__right__logo {
      p {
        color: black !important;
        transition: all 0.1s ease;
      }

      .hamburger-icon span {
        background: #000 !important;
        transition: all 0.1s ease;
      }
    }


    .logo {
      svg {
        g {
          #Path_8654 {
            fill: black;
            transition: all 0.1s ease;
          }

          #Group_20865 {
            path:not(:first-child) {
              fill: black;
              transition: all 0.1s ease;
            }
          }
        }

        #Group_20865 {
          path:not(:first-child) {
            fill: #000000;
          }
        }

        #Path_8732 {
          fill: rgb(30, 30, 46);
          transition: 0.5s fill ease;
        }
      }

      #Group_21243 {
        path {
          fill: black;
        }
      }
    }


    //for mobile


    .mobile-menu {
      .hamburger {
        svg {
          g {
            line {
              stroke: black !important;
            }
          }
        }
      }
    }

  }


  .form-control:disabled {
    background-color: transparent;
  }

  @media (max-width: 600px) {
    .prevent-overflow {
      overflow: hidden;
      height: 100vh;
    }
  }

  //toastify
  .Toastify {
    position: fixed;
    z-index: 999999999;
  }

  .toastify__toast {
    z-index: 999999999;
  }


  .Toastify__toast-container {
    z-index: 99999999;
  }

  .mobile-menu {
    #fb-root, .fb_reset {
      display: none !important;
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }

  .slick-slide {
    -webkit-transform: translate3d(0, 0, 0);
  }

  .reveal, .revealFast {
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }

  //calender
  ._3efP_GeH5kyBAzqnLzL._kN_bCa3VNYpqFLH311L {
    border-radius: 0 !important;
  }


  .page-loader {
    position: fixed !important;
    background-color: rgb(36, 24, 67);
    width: 100vw;
    height: 0;
    z-index: 999999999;
    overflow: hidden;
    //opacity: 0;
    //height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    //left: 0; //width: 0;
    //transition: all 2.6s ease;
    //.anim-logo {
    //  width: 130px;
    //  overflow: hidden;
    //  height: fit-content;
    //  position: absolute;
    //  left: 0;
    //  right: 0;
    //  top: 0;
    //  bottom: 0;
    //  opacity: 0;
    //  margin: auto;
    //  z-index: 2;
    //
    //  img {
    //    height: 55px;
    //  }
    //}

    //.hide-logo {
    //  background-color: #010A1A;
    //  width: 50%;
    //  height: 100vh;
    //  top: 0;
    //  left: 0;
    //  right: 0;
    //  margin: auto;
    //  position: absolute;
    //  z-index: 999;
    //}

    .pre-loader__img {
      //position: fixed;
      height: 100px;
      width: 100px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgb(36, 24, 67);
      z-index: 99999999;

      //flex: 1;
      margin: auto;
      opacity: 0;

      img {
        display: block;
      }

      .top {
        height: 8px;
      }

      .bottom {
        height: 30px;
        margin-top: 5px;
        animation: flip 1s linear infinite;
      }

      @keyframes flip {
        0% {
          transform: rotateY(0deg)
        }
        100% {
          transform: rotateY(180deg)
        }
      }
    }

  }

  //------------------------animation
  .split-parent {
    overflow: hidden;
  }

  .split-child {
    overflow: hidden;
  }

  .reveal {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }


  //page transition
  .page-transition {
    position: fixed;
    height: 0%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #1E1E2E;
    z-index: 999999999;
    display: none;
    opacity: 1;
    //display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      height: 100px;
      opacity: 0;

      .animated-svg-wrapper {
        text-align: center;

        p {
          font-kerning: none;
          color: #ffffff;

          span {
            font-kerning: none;
          }
        }
      }
    }
  }

  //split-text
  .split-up-titlee, .split-up-title, .split-up-title-fast {
    .line {
      overflow: hidden;
      //div.char{
      //  top: -1px;
      //}
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-moz-scrollbar {
    display: none;

  }

  -ms-overflow-style: none;
  /* IE 11 */
  scrollbar-width: none;
  

  .job-lists__sidebar {
    padding: 120px 30px 0 0 !important;
  }

  @media (max-width: 767px) {
    .filter__control {
      background-color: rgb(231, 46, 75);
      border-radius: 50px;
      height: 60px;
    }

    .job-lists__sidebar {
      margin-bottom: 0px!important;
      padding: 60px 15px !important;
    }
  }

  .job-lists__content {
    padding-bottom: 160px !important;
  }

  /* Styles for the black menu */
  .black-menu {
    display: none; /* By default, hide the black menu */
  }

  .menu-showed {

    .mobile-menu {
      background-color: rgb(30, 30, 46);

      .hamburger {
        span {
          display: none;

          &:nth-last-child(1) {
            display: block !important;
          }
        }
      }
    }

    .Mobile-menu-wrap {
      background-color: rgb(30, 30, 46);
    }
  }

  .form-group {
    position: relative;
  }

  p.form-error {
    position: absolute;
    font-size: 11px;
    bottom: -15px;
    line-height: 14px;
    color: #a70100;
  }

  .attach-cv {
    position: relative;

    p {
      bottom: -35px;
      position: absolute;
      font-size: 12px;
      line-height: 14px;
      left: 0;
      right: 0;
    }
  }

  .parallax-image {
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .upper-text {
    .mb-20 {
      margin-bottom: 20px !important;
    }
  }

  //for shaking 
  #smooth-content {
    will-change: transform;
  }

  section {
    will-change: transform;
  }

  .form-error {
    border-color: rgb(231, 46, 75) !important;
  }


`;



