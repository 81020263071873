import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    const [offset, setOffset] = useState(90)
    const [getHeight, setHeight] = useState()
    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft + 15)
        window.addEventListener('resize', () => {
            setOffset(document.querySelector('.container').offsetLeft + 15)
        })

        // background set
        setHeight((document.querySelector('.affiliated-body__bottom__content').clientHeight / 2) + 200)
        window.addEventListener('resize', () => {
            setHeight((document.querySelector('.affiliated-body__bottom__content').clientHeight / 2) + 200)
        })
    }, [])

    return (
        <StyledComponent getHeight={getHeight} className={'affiliated-body pt-150'}>

            <Container fluid className={'affiliated-body__bottom'} style={{paddingLeft: offset + 'px'}}>
                <div className="affiliated-body__bottom__content">
                    <h3>{reactHtmlParser(data?.subtitle)}</h3>

                   <div>
                       {reactHtmlParser(data?.description)}
                   </div>

                </div>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #E4ECF0;

  .affiliated-body__top {
    margin-bottom: 200px;

    h6 {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 20px;
      font-weight: 400;
    }

    h4 {
      font-size: 32px;
      line-height: 40px;
      font-weight: 500;
      margin-bottom: 60px;
    }

    p {
      font-size: 20px;
      line-height: 26px;
    }
  }

  .container-fluid {
    padding: 0;
  }

  .affiliated-body__bottom {
    position: relative;
    padding-bottom: 200px;


    &__content {
      background-color: #1E1E2E;
      padding: 120px 100px;
      position: relative;
      z-index: 2;

      h3 {
        font-size: 60px;
        line-height: 60px;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 40px;
      }

      table {
        tbody {
          tr {
            &:not(:last-child) {
              border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            }

            td {
              padding: 10px 0;
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .affiliated-body__bottom__content {
      padding: 80px 20px;
    }
  }

  @media (max-width: 767px) {
    padding-top: 100px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px;
    .affiliated-body__top {
      margin-bottom: 100px;
    }


    .affiliated-body__bottom {
      padding-bottom: 100px;
      padding-left: 0 !important;
    }

    .affiliated-body__bottom__content {
      h3 {
        font-size: 40px;
        line-height: 42px;
        margin-bottom: 30px;
      }

    }
  }


`;

export default MyComponent;
