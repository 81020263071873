import React from 'react';
import styled from 'styled-components';
import {hover} from "../styles/globalStyleVars";
import {Link} from "react-router-dom";


const Button = ({
                    onSubmit,
                    text,
                    src,
                    img,
                    hoverImg,
                    fontSize,
                    fontWeight,
                    color,
                    letterSpacing,
                    lineHeight,
                    margin,
                    background,
                    borderRadius,
                    border,
                    width,
                    height,
                    hoverBackground,
                    target,
                    hoverColor,
                    svgColor,
                }) => {


    return (
        <StyledBtn className={`dc-btn fade-up`}
                   fontSize={fontSize}
                   fontWeight={fontWeight}
                   color={color}
                   background={background}
                   lineHeight={lineHeight}
                   letterSpacing={letterSpacing}
                   margin={margin}
                   border={border}
                   img={img}
                   borderRadius={borderRadius}
                   width={width}
                   hoverImg={hoverImg}
                   hoverBackground={hoverBackground}
                   height={height}
                   target={target}
                   hoverColor={hoverColor}
                   onSubmit={onSubmit}
                   svgColor={svgColor}
        >
            {src ? (
                <Link to={src || '/'}>
                    <span> {text}  </span>
                    <div className={'svg-padding'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="11.414" viewBox="0 0 11.207 11.414">
                            <g id="Group_19646" data-name="Group 19646" transform="translate(-653 -1595.793)">
                                <g id="Group_4823" data-name="Group 4823" transform="translate(11 4)">
                                    <line id="Line_9" data-name="Line 9" x2="5" y2="5" transform="translate(647.5 1592.5)" fill="none" stroke="#1e1e2e" stroke-linecap="round" stroke-width="1"/>
                                    <line id="Line_10" data-name="Line 10" x1="5" y2="5" transform="translate(647.5 1597.5)" fill="none" stroke="#1e1e2e" stroke-linecap="round" stroke-width="1"/>
                                </g>
                                <line id="Line_11" data-name="Line 11" x2="10" transform="translate(653.5 1601.5)" fill="none" stroke="#1e1e2e" stroke-linecap="round" stroke-width="1"/>
                            </g>
                        </svg>

                    </div>
                </Link>
            ) : (
                <a target={target || '_self'}>
                    <span>{text}</span>
                    <div className={'svg-padding'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="11.414" viewBox="0 0 11.207 11.414">
                            <g id="Group_19646" data-name="Group 19646" transform="translate(-653 -1595.793)">
                                <g id="Group_4823" data-name="Group 4823" transform="translate(11 4)">
                                    <line id="Line_9" data-name="Line 9" x2="5" y2="5" transform="translate(647.5 1592.5)" fill="none" stroke="#1e1e2e" stroke-linecap="round" stroke-width="1"/>
                                    <line id="Line_10" data-name="Line 10" x1="5" y2="5" transform="translate(647.5 1597.5)" fill="none" stroke="#1e1e2e" stroke-linecap="round" stroke-width="1"/>
                                </g>
                                <line id="Line_11" data-name="Line 11" x2="10" transform="translate(653.5 1601.5)" fill="none" stroke="#1e1e2e" stroke-linecap="round" stroke-width="1"/>
                            </g>
                        </svg>

                    </div>
                </a>
            )}
        </StyledBtn>
    )
};

const StyledBtn = styled.div`
  &.dc-btn {
    margin: ${props => props.margin || '0'};
    width: ${props => props.width || '100%'};
    height: ${props => props.height || '41'}px;
    cursor: pointer;

    a {
      display: flex;
      width: fit-content;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-size: ${props => props.fontSize || '16'}px;
      font-weight: ${props => props.fontWeight || 400};
      margin: 0;
      line-height: ${props => props.lineHeight || '20'}px;
      background-color: ${props => props.background || `#1C1718`};
      position: relative;
      border-radius: ${props => props.borderRadius || '22'}px;
      overflow: hidden;
      z-index: 0;
      padding: 12px 36px;
      box-sizing: border-box;
      border: ${props => props.border || '1px solid white'};
      transition: border 3s cubic-bezier(.25, .74, .22, .99);

      span {
        transition: color .3s ease;
        color: ${props => props.color || `#FFF`};
        position: relative;
        z-index: 2;
      }
      .svg-padding{
        padding-left: 10px;
        svg{
          #Group_19646{
            line{
              stroke: ${props => props.svgColor || `black`};
            }
          }
        }
      }
      


      &:before {
        bottom: 0;
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        left: 0;
        background-color: ${p => p.hoverBackground || hover};
        height: 0%;
        width: 0%;
        margin: auto;
        transition: all .5s ease;
        border-radius: 22px;
      }

      // &:hover {
      //   span {
      //     color: ${props => props.hoverColor || `#FFF`};
      //   }
      //
      //   svg {
      //     line {
      //       stroke: ${props => props.hoverColor || '#FFF'};
      //     }
      //   }
      //
      //   &:before {
      //     height: 100%;
      //     width: 100%;
      //   }
      // }

      &:focus {
        color: #222222;
      }
      &:hover {
        border-color: white;
        transition: border-color 3s cubic-bezier(.25, .74, .22, .99);
      }
    }
  }

`;


export default React.memo(Button);
